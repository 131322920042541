import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { resetPasswordAction } from "../../store/actions/AuthActions";
// image
import logo from "../../images/logo-full.png";
import pic3 from '../../images/pic3.png';
import translations from "../components/AppsMenu/Candidate/mulitlingue";

const ForgotPassword = ({ resetPassword, history }) => {
  const [email, setEmail] = useState("");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);

      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const onSubmit = (e) => {
    e.preventDefault();
    resetPassword(email, history);
  };
  return (
    // <div className="authincation h-100 p-meddle">
    //   <div className="container h-100">
      
    //   </div>
    // </div>
      <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
   <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
   <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content style-2">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-5">
                      <Link to="/dashboard">
                        <img src={logo} alt="logo" className='logologin'/>
                      </Link>
                    </div>
                    
                    <form onSubmit={onSubmit}>
                      <div className="form-group">
                        <label className="">
                          <strong>{lang.menu.email}</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Type Your Email Address"
                        />
                      </div>
                      <br></br>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {lang.menu.submit}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
       <div className="login-aside text-center d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
         
          <h1 className="mb-1">Forgot Password!</h1>
          <p>User Experience & Interface Design <br /> Strategy SaaS Solutions</p>
        </div>
        <div className="aside-image" style={{ backgroundImage: `url(${pic3})` }}></div>
      </div>
      
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (email, history) =>
      dispatch(resetPasswordAction(email, history)),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
