import React, { useState, useEffect } from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

import { Link, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faSignOutAlt,
  faUser,
  faGear,
  faBuilding,
  faFileInvoice
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiReturnArrow } from "react-icons/gi";

/// Image
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import Switch from "./Switch";
import axios from "axios";
import swal from "sweetalert";

const Header = ({ onNote }) => {
  const [userRole, setuserRole] = useState([]);
  const [searchBut, setSearchBut] = useState(false);
  const [selected, setSelected] = useState(false);
  const [notifPer, setNotifPer] = useState([]);
  const [CounAlertes, setAlerteCount] = useState([]);
  const [alertes, setAlertes] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setNotifPer(
            data.some((permission) => permission.title === "notif-per")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const updateSelectedValue = () => {
    // Send the 'selected' value to the server
    axios
      .put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/updateCompiler/` +
          userRole[0].uid,
        {
          selected: !selected, // Invert the current value
          userRole,
        }, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((response) => {
        // Handle the response if needed
        setSelected(!selected); // Update the state after a successful update
        window.location.reload();
      })
      .catch((error) => {
        // Handle errors if needed
      });
  };

  useEffect(() => {
    if (userRole[0]?.recruiterComplier !== null) {
      if (userRole[0]?.recruiterComplier === "1") {
        setSelected(true);
      } else if (userRole[0]?.recruiterComplier === "0") {
        setSelected(false);
      }
    } else if (userRole[0]?.companyComplier !== null) {
      if (userRole[0]?.companyComplier === "1") {
        setSelected(true);
      } else if (userRole[0]?.companyComplier === "0") {
        setSelected(false);
      }
    } else if (userRole[0]?.adminComplier === "1") {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [userRole]); // Listen for changes in userRole

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [selectedLang, setSelectedLang] = useState(lang);

  const handleSettings = () => {
    history.push("/profile");
  };

  const handleCompanySettings = () => {
    history.push("/company");
  };

  const handleAdminDashboard = () => {
    history.push("/admin-dashboard/users");
  };
  const handleRolesManagment = () => {
    history.push("/roles-managment");
  };
  const handleSubscriptionManagment = () => {
    history.push("/SubscriptionAndInvoices");
  };
  const history = useHistory(); // Use the useHistory hook

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout(history));
  };

  const handleLogs = () => {
    history.push("/UserLogs");
  };
  function handleChangeLang(langValue) {
    let selectedLang = null;
    if (langValue === "en") {
      selectedLang = translations.en;
    } else if (langValue === "fr") {
      selectedLang = translations.fr;
    }
    if (selectedLang) {
      localStorage.setItem("selectedLang", JSON.stringify(selectedLang));
      setLang(selectedLang);
      setSelectedLang(selectedLang);
    }
    window.location.reload();
  }

  const [notifications, setNotifications] = useState([]);
  const [Count, setNotificationsCount] = useState([]);

  const avatarClasses = [
    "empty-avatar-red",
    "empty-avatar-green",
    "empty-avatar-pimary",
    "empty-avatar-yellow",
    "empty-avatar-purple",
  ];
  const randomAvatarClass =
    avatarClasses[Math.floor(Math.random() * avatarClasses.length)];
    function convertNotifs(notifs) {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser ? currentUser.localId : null;
      const userRole=currentUser ? currentUser.userRole : null;

    const typeAbbreviations = {
      "pending-user-admin": "PUA",
      "Company-created": "CC",
      "pending-user": "PU",
      "test-tech-result-admin": "TR",
      "test-psycho-result-admin": "PR",
      "report-sent-admin": "TF",
      "Recruter_created":"RC",
      "Onboarding":"ON"
    };
  
    const convertedNotifs = notifs.map(notif => {
      const newNotif = { ...notif };
  
      if (newNotif.type in typeAbbreviations) {
        newNotif.type = typeAbbreviations[newNotif.type];
      }
  
      
      if (userId === "YqHWFANVBFP8qqLat8jHKyJ1BFt2" || userRole===1) {
        
        switch (newNotif.type) {
          case "PUA":
            newNotif.url = newNotif.link;
            newNotif.message = lang.menu.newUser + newNotif.content + lang.menu.PendengApproval;
            break;
          case "CC":
            newNotif.url = newNotif.link;
            newNotif.message = newNotif.content;
            break;
          case "TR":
            newNotif.url = newNotif.link;
            newNotif.message = lang.menu.testResult + newNotif.content + lang.menu.restReady;
            break;
          case "PR":
            newNotif.url = newNotif.link;
            newNotif.message = lang.menu.testPResult + newNotif.content + lang.menu.restPReady;
            break;
          case "TF":
            newNotif.url = newNotif.link;
            newNotif.message = lang.menu.testFResult + newNotif.content + lang.menu.restFReady;
            break;
          case "RC":
            newNotif.url = newNotif.link;
            newNotif.message =  newNotif.content;
            break;
          case "ON":
            newNotif.url = newNotif.link;
            newNotif.message =  newNotif.content;
            break;
          
          default:
            break;
        }
      } else {
        switch (newNotif.type) {
       
          case "CC":
            newNotif.url = newNotif.link;
            newNotif.message = newNotif.content;
            break;
       case "RC":
            newNotif.url = newNotif.link;
            newNotif.message =  newNotif.content;
            break;
        case "ON":
            newNotif.url = newNotif.link;
            newNotif.message =  newNotif.content;
              break;
              case "TR":
            newNotif.url = newNotif.link;
            newNotif.message = lang.menu.testResult + newNotif.content + lang.menu.restReady;
            break;
          case "PR":
            newNotif.url = newNotif.link;
            newNotif.message = lang.menu.testPResult + newNotif.content + lang.menu.restPReady;
            break;
          case "TF":
            newNotif.url = newNotif.link;
            newNotif.message = lang.menu.testFResult + newNotif.content + lang.menu.restFReady;
            break;

          default:
            break;
        }
      }
  
      if (newNotif.date !== null && typeof newNotif.date !== 'undefined') {
        newNotif.date = newNotif.date.replace(/T/, " ").replace(/\..+/, "");
      }
  
      return newNotif;
    });
  
    return convertedNotifs;
  }
  function convertAlertes(notif) {
    const convertednotifs = notif.map((notif) => {
      const newnotif = { ...notif };
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
     

  if (newnotif.date !== null && typeof newnotif.date !== 'undefined') {
    newnotif.date = newnotif.date.replace(/T/, " ").replace(/\..+/, "");
}
return newnotif;
    });

    return convertednotifs;
  }
  const notifyInfo = (data) => {
    swal(data.message, {
      icon: "info",
    });
  
  };
  const showFeatures = async () => {
    try {
       const currentUser = JSON.parse(localStorage.getItem("userDetails"));
       const userId = currentUser.localId;
       const userRole=currentUser.userRole;
      if(userRole===2 ||userRole===1 ){
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Alerte/findByuser/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setAlerteCount(
           response.data.length === 0 ? "" : response.data.length
         );
      }
       
    } catch (error) {
       console.error("Error fetching features:", error);
    }
 };
 useEffect(() => {
 

   // showFeatures();
 }, []);
  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_APP_DOMAIN}`, {
      transports: ["websocket"],
    });
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    const userId = currentUser.localId;
    socket.emit("send-notification", userId);
    socket.on("notifications-"+userId, (notifications) => {
      const convertedData = convertNotifs(notifications);
      setNotifications(convertedData);
      const unviewedNotifications = convertedData.filter(
        (notification) => notification.treated === 0
      );

      setNotificationsCount(
        unviewedNotifications.length === 0 ? "" : unviewedNotifications.length
      );
    });

//Alertes
socket.emit("send-alertes", userId);
socket.on("alertes", (alertes) => {

  const convertedData = convertNotifs(alertes);

  setAlertes(convertedData);
  const unviewedAlertes = convertedData.filter(
    (alertes) => alertes.read === false
  );

  setAlerteCount(
    unviewedAlertes.length === 0 ? "" : unviewedAlertes.length
  );
});
socket.on("alerte-toast", (alertes) => {
  const convertedData = convertAlertes(alertes);
  const unviewedAlertes = convertedData.filter(
    (alerte) => alerte.read === false
  );
  notifyInfo(unviewedAlertes[0]);
});
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }

    return () => {
      socket.disconnect();
    };
  }, []);

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  const [isDarkMode, setIsDarkMode] = useState(path === "dashboard-dark");

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const [connectedUser, setconnectedUser] = useState({
    FirstName: "",
    LastName: "",
  });

  
  
  
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
          );
          const data = await response.json();
          setuserRole(data[0]);
          setconnectedUser(data[0]);
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
       
      }
    };
  
    fetchUserPermissions();
  }, []);
  const handleNotificationClick = async (notification) => {
    if (notification.type !== "PU" && notification.type !== "PUA") {
      try {
        // Assuming there's an API endpoint to delete a notification
        await axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/notification/deletenotification/${notification.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Redirect to the notification URL
        window.location.href = notification.url;
      } catch (error) {
        console.error("Error deleting notification:", error);
      }
    } else {
      // Just redirect for PU and PUA notifications
      window.location.href = notification.url;
    }
  };
  
  return (
    <div className="header">
      {notifPer ? (
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      ) : (
        <></>
      )}
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
      {/*         <h3><strong> {finalName.join(" ").length === 0

                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")}</strong></h3> */}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">
              {/*               <li className="nav-item">
                <div className="input-group search-area">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                  />
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <i className="flaticon-381-search-2"></i>
                    </Link>
                  </span>
                </div>
              </li> */}
              <li className="nav-item">
                {" "}
                <Dropdown>
                  <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                    <span className="material-icons">{lang.language}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleChangeLang("en")}>
                      English
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleChangeLang("fr")}>
                      Français
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
       <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link bell bell-link i-false c-pointer"
                  onClick={() => onNote()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="22.871"
                    viewBox="0 0 24 22.871"
                  >
                    <g data-name="Layer 2" transform="translate(-2 -2)">
                      <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M23.268,2H4.73A2.733,2.733,0,0,0,2,4.73V17.471A2.733,2.733,0,0,0,4.73,20.2a.911.911,0,0,1,.91.91v1.94a1.82,1.82,0,0,0,2.83,1.514l6.317-4.212a.9.9,0,0,1,.5-.153h4.436a2.742,2.742,0,0,0,2.633-2L25.9,5.462A2.735,2.735,0,0,0,23.268,2Zm.879,2.978-3.539,12.74a.915.915,0,0,1-.88.663H15.292a2.718,2.718,0,0,0-1.514.459L7.46,23.051v-1.94a2.733,2.733,0,0,0-2.73-2.73.911.911,0,0,1-.91-.91V4.73a.911.911,0,0,1,.91-.91H23.268a.914.914,0,0,1,.879,1.158Z"
                        transform="translate(0 0)"
                      />
                      <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M7.91,10.82h4.55a.91.91,0,1,0,0-1.82H7.91a.91.91,0,1,0,0,1.82Z"
                        transform="translate(-0.45 -0.63)"
                      />
                      <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M16.1,13H7.91a.91.91,0,1,0,0,1.82H16.1a.91.91,0,1,0,0-1.82Z"
                        transform="translate(-0.45 -0.99)"
                      />
                    </g>
                  </svg>
                  <span className="badge light text-white bg-primary rounded-circle">
                  {CounAlertes}
                  </span>
                </Dropdown.Toggle>
              </Dropdown> 
         <li className="nav-item">
    <button
        className="back-button"
        title={lang.menu.retour}
        onClick={() => history.goBack()}
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 20px",
            border: "none",
            backgroundColor: "transparent", 
            color: "none",
            fontSize: "16px",
            fontWeight: "bold",
            textTransform: "uppercase",
            borderRadius: "4px",
            transition: "none",
        }}
    >
        <GiReturnArrow size={23}/>
    </button>
</li>


              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown"
              >
                <Dropdown.Toggle
                  className="nav-link i-false c-pointer"
                  variant=""
                  as="a"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g data-name="Layer 2" transform="translate(-2 -2)">
                      <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M22.571,15.8V13.066a8.5,8.5,0,0,0-7.714-8.455V2.857a.857.857,0,0,0-1.714,0V4.611a8.5,8.5,0,0,0-7.714,8.455V15.8A4.293,4.293,0,0,0,2,20a2.574,2.574,0,0,0,2.571,2.571H9.8a4.286,4.286,0,0,0,8.4,0h5.23A2.574,2.574,0,0,0,26,20,4.293,4.293,0,0,0,22.571,15.8ZM7.143,13.066a6.789,6.789,0,0,1,6.78-6.78h.154a6.789,6.789,0,0,1,6.78,6.78v2.649H7.143ZM14,24.286a2.567,2.567,0,0,1-2.413-1.714h4.827A2.567,2.567,0,0,1,14,24.286Zm9.429-3.429H4.571A.858.858,0,0,1,3.714,20a2.574,2.574,0,0,1,2.571-2.571H21.714A2.574,2.574,0,0,1,24.286,20a.858.858,0,0,1-.857.857Z"
                      />
                    </g>
                  </svg>
                  <span className="badge light text-white bg-primary rounded-circle">
                    {Count}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-2 dropdown-menu dropdown-menu-end"
                >
                  <PerfectScrollbar
                    className="widget-media dlab-scroll p-3"
                    style={{ maxHeight: "400px" }}
                  >
                    {Count === 0 ? (
                      <ul className="timeline">
                        <li>
                          <div className="timeline-panel">
                            <div className="media-body text-center">
                              <h6 className="mb-1">
                                {lang.menu.NoNotifications}
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <>
                        <ul className="timeline">
                          {notifications.map((content, index) => (
                            <li key={index}>
                              <div className="timeline-panel">
                                <div
                                  className={`media me-2 ${
                                    [
                                      "empty-avatar-pimary"
                                    ][
                                      Math.floor(
                                        Math.random() * avatarClasses.length
                                      )
                                    ]
                                  }`}
                                >
                                  {content.type}
                                </div>
                                <div className="media-body">
                                <div onClick={() => handleNotificationClick(content)} style={{ cursor: 'pointer' }}>
                                          <h6 className="mb-1">{content.message}</h6>
                                          <small className="d-block">{content.date}</small>
                                        </div>

                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </PerfectScrollbar>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as="li" className="nav-item  notification_dropdown ">
                {/* <Link
                  className={`${
                    path === "dashboard-dark" ? (
                      <FaSun size={24} />
                    ) : (
                      <FaMoon size={24} />
                    )
                  }`}
                  to="/dashboard-dark"
                ></Link>*/}

                <Link
                  className="mode-toggle"
                  to={isDarkMode ? "/dashboard" : "/dashboard-dark"}
                  onClick={toggleDarkMode}
                >
                  {isDarkMode ? <FaSun size={24} /> : <FaMoon size={24} />}
                </Link>
              </Dropdown>
              <li className="nav-item header-profile">
                {/* <Link to={"#"} className="btn btn-primary d-sm-inline-block d-none">Generate Report<i className="las la-signal ms-3 scale5"></i></Link> */}
                <Dropdown>
                  <Dropdown.Toggle
                    className="nav-link i-false c-pointer"
                    variant=""
                    as="a"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {connectedUser.Avatar ? (
                      <img
                        className="rounded-circle"
                        width="43"
                        src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${connectedUser.Avatar}`}
                        alt=""
                      />
                    ) : (
                      <div
                        className={`empty-avatar rounded-circle ${randomAvatarClass}`}
                      >
                        {connectedUser.FirstName.charAt(0)}
                        {connectedUser.LastName.charAt(0)}
                      </div>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="profile-dropdown">
                    <h5 className="dropdown-header text-center">
                      {connectedUser.email}
                    </h5>
                    <>
                    <Switch onChange={updateSelectedValue} isSelected={selected}>
      {selected ? "Compiler with Database" : "Compiler without Database"}
    </Switch>



                      
                    </>
                    {userRole.userRole === 1 ? (
                      <>
                        <Dropdown.Item
                          onClick={handleAdminDashboard}
                          className="profile-drop"
                        >
                          <FontAwesomeIcon icon={faGauge} className="me-2" />
                          {lang.menu.AdminDashboard}
                        </Dropdown.Item>
                      </>
                    ) : (
                      <></>
                    )}
    {userRole.userRole === 2 || (userRole.userRole === 3 && userRole.companyID===1)  ? (
                      <>
                        <Dropdown.Item
                          onClick={handleSubscriptionManagment}
                          className="profile-drop"
                        >
                          <FontAwesomeIcon icon={faFileInvoice} className="me-2" />
                          {lang.menu.subscriptions}
                        </Dropdown.Item>
                      </>
                    ) : (
                      <></>
                    )}
                    {userRole.userRole === 1 || userRole.userRole === 2 ? (
                      <>
                        <Dropdown.Item
                          onClick={handleRolesManagment}
                          className="profile-drop"
                        >
                          <FontAwesomeIcon icon={faGear} className="me-2" />
                          {lang.menu.RolesManagment}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={handleCompanySettings}
                          className="profile-drop"
                        >
                          <FontAwesomeIcon icon={faBuilding} className="me-2" />
                          {lang.menu.CompanyDashboard}
                        </Dropdown.Item>
                      
                      </>
                      
                    ) : (
                      <></>
                    )}

                    <Dropdown.Item
                      onClick={handleSettings}
                      className="profile-drop"
                    >
                      <FontAwesomeIcon icon={faUser} className="me-2" />
                      {lang.menu.mySettings}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogs}
                      className="profile-drop"
                    >
                      <FontAwesomeIcon icon={faUserSecret} className="me-2" />
                      {lang.menu.userLogs}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogout}
                      className="profile-drop"
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                      {lang.menu.Logout}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
