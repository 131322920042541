import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {  Button, Card,ListGroup,Modal ,Form ,Row ,Col } from "react-bootstrap";
import axios from "axios";
import { RiArrowGoBackFill } from "react-icons/ri";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { FaCheckCircle } from "react-icons/fa";

const QuestionOfCategory = () => {
  const token=localStorage.getItem("token");

  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [categorieName, setCategorieName] = useState("");
  const history = useHistory();
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const pathname = window.location.pathname;
  const idCat = pathname.split("/").pop();
  const getImageUrlFromServer = (imageName, categorieName, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/question1/images/${categorieName}/${id}/${imageName}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/categories/getCategorieNameById/${idCat}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setCategorieName(response.data.name);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idCat]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/question1/FindByCategorie/${idCat}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setQuestions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idCat]);

  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };

  const getImageUrl = (imageRef, id) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, categorieName, id);
      return imageUrl;
    }
    return null;
  };

  const renderContent = (content, id) => {
    const imageUrl = getImageUrl(content, id);
    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " width={150} height={100}/>
        </div>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  const renderContentRef = (content, id) => {
    const imageUrl = getImageUrl(content, id);
    if (imageUrl) {
      return (
        <div className="frame center">
          <img src={imageUrl} alt=" " width={300} height={200}/>
        </div>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  const handleBonneReponseClick = (question) => {
    setSelectedQuestion(question);
  };
  const [searchTerm, setSearchTerm] = useState('');
  const filterTestByTitle = () => {
    return questions.filter(test => test.question.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const getBonneReponseLabel = (question) => {
    switch (question.BonneReponse) {
      case "choix1":
        return `${lang.menu.choix1}`;
      case "choix2":
        return `${lang.menu.choix2}`;
      case "choix3":
        return `${lang.menu.choix3}`;
      case "choix4":
        return `${lang.menu.choix4}`;
      default:
        return ""; 
    }
  };
  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Row>
       
       <h2 className="fs-20 font-w800  me-auto">
         {categorieName}
       </h2>
</Row>
<Row>
<Form.Group controlId="formBasicSearch">
       <Form.Control
         type="text"
         placeholder={lang.menu.serachQuestion}
         value={searchTerm}
         onChange={handleSearch}
       />
     </Form.Group>
     </Row>
   
<br/>
    

      <div>
      {filterTestByTitle().map(question => (
          <Card key={question.id} className="mb-4 shadow-sm border-0">
      
            <Card.Body style={{ backgroundColor: "#f8f9fa", padding: "1.5rem" }}>
            <Card.Title
              className="mb-4"
              style={{
                color: "#333",
                textAlign: "left",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
                {question.question}
              </Card.Title>
              {question.imageRef &&
                renderContentRef(question.imageRef, question.id)}
            <ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
  <Form.Check
    type="radio"
    label={renderContent(question.choix1, question.id)}
    name={`bonneReponse-${question.id}`} // Groupement par question
    id={`choix1-${question.id}`}
    checked={question.BonneReponse === "choix1"} // Vérifie si c'est la bonne réponse
    readOnly // Empêche la modification par l'utilisateur
  />
</ListGroup.Item>
<ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
  <Form.Check
    type="radio"
    label={renderContent(question.choix2, question.id)}
    name={`bonneReponse-${question.id}`}
    id={`choix2-${question.id}`}
    checked={question.BonneReponse === "choix2"}
    readOnly
  />
</ListGroup.Item>
<ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
  <Form.Check
    type="radio"
    label={renderContent(question.choix3, question.id)}
    name={`bonneReponse-${question.id}`}
    id={`choix3-${question.id}`}
    checked={question.BonneReponse === "choix3"}
    readOnly
  />
</ListGroup.Item>
{question.choix4 && (
  <ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
    <Form.Check
      type="radio"
      label={renderContent(question.choix4, question.id)}
      name={`bonneReponse-${question.id}`}
      id={`choix4-${question.id}`}
      checked={question.BonneReponse === "choix4"}
      readOnly
    />
  </ListGroup.Item>
)}

             
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default QuestionOfCategory;
