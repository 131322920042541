import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown,Modal,Col,Row,Form, Badge ,Image} from "react-bootstrap";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";
import axios from "axios";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlinePieChart } from "react-icons/ai";
import swal from "sweetalert";

function JobLists() {
  const [viewJobs, setViewJobs] = useState([]);
  const [quotaMax, setQuotaMax] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [isDisable, setIsDisable] = useState("");
  const [userConnect, setUserConnect] = useState("");
  const token=localStorage.getItem("token");

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setUserConnect(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setViewJobs(
            data.some((permission) => permission.title === "view-job")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false); 

  useEffect(() => {
    if (!viewJobs) {
      setShowAccessDeniedModal(true); 
    }
  }, [viewJobs]);
  const [selectedLang, setSelectedLang] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const [jobListData, setJobListData] = useState([]);
  const fetchJobs = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;

      const getUserCompanyID = async () => {
        const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
        const data = await responseC.json();
        return data[0].companyID;
      };
  
      const compId = await getUserCompanyID();
      setCompanyID(compId)
      const jobResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const jobs = jobResponse.data;
      if(compId!==1){
          const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${compId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const subscriptionData = await subscriptionResponse.json();
          console.log("comp id sub:", subscriptionData);
          if(subscriptionResponse.status===200){
            setQuotaMax(subscriptionData[3])
            const isDisabled = subscriptionData[3].numberquota_max <= subscriptionData[3].quotaJobRole;
            setIsDisable(isDisabled)
          }
    }
    else  if(compId===1 && currentUser.userRole===3){
      const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const subscriptionData = await subscriptionResponse.json();
      console.log("comp id sub:", subscriptionData);
      if(subscriptionResponse.status===200){
        setQuotaMax(subscriptionData[3])
        const isDisabled = subscriptionData[3].numberquota_max <= subscriptionData[3].quotaJobRole;
        setIsDisable(isDisabled)
      }
}
      let filteredJobs;
      console.log("jobss:",jobs)
      if (compId != 1) {
        filteredJobs = jobs.filter(job => job.companyid === compId && job.archived === 0);
      } else {
        if(userConnect.userRole==1){

          filteredJobs = jobs.filter(job => job.archived === 0);


        }else if(userConnect.userRole==3){
          filteredJobs = jobs.filter(job =>job.creator_uid === userConnect.localId && job.archived === 0);
          console.log("rec solo: ", filteredJobs);

        }
      }
  
      setJobListData(filteredJobs);
      console.log("response.data: ", filteredJobs);

      //pagnation
      setTotalPages(Math.floor( filteredJobs.length  / process.env.REACT_APP_PAGINATION) + 1);

  
    } catch (error) {
      console.error('Erreur lors de la récupération des emplois:', error);
    }
  };
  
  useEffect(async() => {
  if(userConnect !=""){
    fetchJobs()

  }

  }, [userConnect]);

  const handleDelete = (id) => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    axios
      .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/delete/` + id+`/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {

        console.log(res);
        if(res.data.Message && res.data.Message.message==="Job cannot be deleted as it is in use" || res.data.Message==="Error inside server"){
          swal(lang.menu.jobDeleteError, {
            icon: "error",
          });

        }else{
          swal(lang.menu.jobDeleteted, {
            icon: "success",
          });

 fetchJobs()
        }
      })

      .catch((err) => console.log(err));
  };
  const handleArchive = async(jobAr) => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;

    const jobUpdateResponse = await axios.put(
      `${process.env.REACT_APP_APP_DOMAIN}/api/job-list/archived/${jobAr.id}/${userId}`,
      { jobTitle: jobAr.JobTitle } , 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
  );
  console.log("jobUpdateResponse .",jobUpdateResponse)
  
    if(jobUpdateResponse.status===200){
      swal(lang.menu.jobArchivedSucc, {
        icon: "success",
      });
     fetchJobs()
    }
    
  };
  const handleupdate = (tableData) => {
    const redirectionPath =`/job-list/update/${tableData.id}`

    window.location.href = redirectionPath;

  };
  const handleDetails = (tableData) => {
    const redirectionPath =`/job-list/read/${tableData.id}`

    window.location.href = redirectionPath;

  };
  const [searchTerm, setSearchTerm] = useState('');
  const filterByTitle = () => {
    console.log("jobListData :", jobListData);
    return jobListData.filter(job => 
      job.JobTitle && job.JobTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const renderDropdownItem = (tableData) => {
    if (tableData.checkLiaison === 0) {
      return (
        <DropdownItem onClick={() => handleDelete(tableData.id)}>
          <Button className="btn btn-danger light">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              className="svg-main-icon"
            >
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                  fill="#000000"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                  fill="#000000"
                  opacity="0.3"
                ></path>
              </g>
            </svg>
          </Button>{" "}
          <span title="Remove"> {lang.menu.delete}</span>
        </DropdownItem>
      );
    } else {
      return (
        <DropdownItem onClick={() => handleArchive(tableData)}>
          <Button className="btn btn-danger light">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              className="svg-main-icon"
            >
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                  fill="#000000"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                  fill="#000000"
                  opacity="0.3"
                ></path>
              </g>
            </svg>
          </Button>{" "}
          <span title="Remove"> {lang.menu.archived}</span>
        </DropdownItem>
      );
    }
  };
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const dataCmp = await response.json();
        setCompanies(dataCmp);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);
  const getCompanyLogo = (companyId) => {
    const company = companies.find((comp) => comp.companyid === companyId);
    console.log("company :",company)
    let pathImage=`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`
    return company
      ? pathImage
      : null;
  };
  return (
    <>
    {viewJobs ? (<>
     <PageTitle
        activeMenu={lang.menu.jobList}
        motherMenu={lang.menu.jobs}
        pageContent="Candidate"
      />
 
 <Row className="mb-3 ">
  <Col xs={7}>
    <Form.Group controlId="formBasicSearch">
      <Form.Control
        type="text"
        placeholder={lang.menu.serachJob}
        value={searchTerm}
        onChange={handleSearch}
      />
    </Form.Group>
  </Col>
  <Col xs={1} className="d-flex offset-md-1">
    {quotaMax ? (
      <Badge className="d-flex align-items-center justify-content-center badge-rounded" 
      variant="outline-danger"      style={{ color: quotaMax.quotaJobRole >=  quotaMax.numberquota_max ? 'red' : '#74188D' }}
>
        <AiOutlinePieChart size={20} />{' '}
        {lang.menu.quotaUsed} : {quotaMax.quotaJobRole}/{quotaMax.numberquota_max}
      </Badge>
    ) : null}
  </Col>
  <Col xs={2} className="d-flex align-items-end justify-content-end offset-md-1">
  {companyID && companyID!==1 ? (
      <Link
      to={isDisable ? "#" : "/post-job"}
      className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
      onClick={e => isDisable && e.preventDefault()}
    >
      <i className="fas fa-plus me-2"></i>
      {lang.menu.addJob}
    </Link>):
    companyID && companyID===1 && userConnect &&userConnect.userRole===3 ? 
    (
<Link
      to={isDisable ? "#" : "/post-job"}
      className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
      onClick={e => isDisable && e.preventDefault()}
    >
      <i className="fas fa-plus me-2"></i>
      {lang.menu.addJob}
    </Link>):(

 <Link to={"/post-job"} className="btn btn-primary me-3 btn-sm">
 <i className="fas fa-plus me-2"></i>
 {lang.menu.addJob}
</Link>
    )}
  </Col>
</Row>

      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead style={{ backgroundColor: "#8cd6c8"}}>
                <tr>
                  <th>{" "}</th>
                  <th>{lang.menu.jobTitle}</th>

                  <th>Type</th>
                  <th>{lang.menu.applicant}</th>

                  <th>{lang.menu.postDate}</th>
                  <th>{lang.menu.closeDate}</th>

                  <th>{lang.menu.status}</th>
                  <th>{lang.menu.actions}</th>
                </tr>
              </thead>
              <tbody>
              {filterByTitle().length > 0 && filterByTitle().slice((currentPage - 1)* process.env.REACT_APP_PAGINATION, currentPage * process.env.REACT_APP_PAGINATION).map(tableData => (
                 
                    <tr >
                      <td>
                      {tableData.companyid!=null && getCompanyLogo(tableData.companyid) ? (
                          <Image
                            src={getCompanyLogo(tableData.companyid)}
                            alt="Company Logo"
                            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                          />
                        ) : (
                          <i className="fas fa-building" style={{ fontSize: "24px", color: "#ccc" }}></i>
                        )}
                      </td>
                      <td>

                      {tableData.JobTitle.length > 24 ? `${tableData.JobTitle.substring(0, 24)}...` : tableData.JobTitle}
                      </td>

                      <td>{tableData.JobType}</td>
                      <td>{tableData.Applicant}</td>
                      <td>{tableData.PostedDate.split("T")[0]}</td>
                      <td>{tableData.CloseDate.split("T")[0]}</td>

                      <td>
                        <span
                          className={`badge badge-lg light ${
                            tableData.Status === "Active"
                              ? "badge-success"
                              : "badge-danger"
                          }`}
                        >
                          {tableData.Status}
                        </span>
                      </td>

                      <td>
                        <div className="action-buttons ">
                        <Dropdown>
                  <Dropdown.Toggle
                    className="nav-link i-false c-pointer"
                    as="a"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{textAlign:"center"}}
                  ><i  className="flaticon-032-ellipsis"></i></Dropdown.Toggle>
                  <Dropdown.Menu className="profile-dropdown">
                 
  {(tableData.creator_uid === userId || userId===process.env.REACT_APP_SUPER_ADMIN )? (<>
    <DropdownItem onClick={() => handleupdate(tableData)}>
    <Link
      to={`/job-list/update/${tableData.id}`}
      className="btn btn-secondary light mr-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        className="svg-main-icon"
      >
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect x="0" y="0" width="24" height="24"></rect>
          <path
            d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
          ></path>
          <rect
            fill="#000000"
            opacity="0.3"
            x="5"
            y="20"
            width="15"
            height="2"
            rx="1"
          ></rect>
        </g> 
      </svg>
    </Link>
      <span title="Edit" onClick={() => handleupdate(tableData)}>{lang.menu.edit}</span> </DropdownItem>


      {renderDropdownItem(tableData)}
       <DropdownItem onClick={() => handleDetails(tableData)}>
                  <Link
                            to={`/job-list/read/${tableData.id}`}
                            className="btn btn-success light mr-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="svg-main-icon"
                              width="24px"
                              height="24px"
                              viewBox="0 0 32 32"
                              x="0px"
                              y="0px"
                            >
                              <g data-name="Layer 21">
                                <path
                                  d="M29,14.47A15,15,0,0,0,3,14.47a3.07,3.07,0,0,0,0,3.06,15,15,0,0,0,26,0A3.07,3.07,0,0,0,29,14.47ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></path>
                                <circle
                                  cx="16"
                                  cy="16"
                                  r="3"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></circle>
                              </g>
                            </svg>
                          </Link>  <span title="View Details" onClick={() => handleDetails(tableData)} > {lang.menu.details}</span>
                  </DropdownItem>
       
       </>
  ) : (
    
    <DropdownItem>
                  <Link
                            to={`/job-list/read/${tableData.id}`}
                            className="btn btn-success light mr-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="svg-main-icon"
                              width="24px"
                              height="24px"
                              viewBox="0 0 32 32"
                              x="0px"
                              y="0px"
                            >
                              <g data-name="Layer 21">
                                <path
                                  d="M29,14.47A15,15,0,0,0,3,14.47a3.07,3.07,0,0,0,0,3.06,15,15,0,0,0,26,0A3.07,3.07,0,0,0,29,14.47ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></path>
                                <circle
                                  cx="16"
                                  cy="16"
                                  r="3"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></circle>
                              </g>
                            </svg>
                          </Link>  <span title="View Details" onClick={() => handleDetails(tableData)} > {lang.menu.details}</span>
                  </DropdownItem>
    
  )}



                  
                  </Dropdown.Menu>
                  </Dropdown>
                        </div>
                      </td>
                    </tr>
                  
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="sm-mb-0 mb-3">
          <h5 className="mb-0">{currentPage + "/" + totalPages}</h5>
          </div>
          <nav>
          <ul className="pagination pagination-circle">
              <li className="page-item page-indicator">
                <Button
                 style={{width:"100%"}}
                  onClick={()=> {
                    handlePageChange((currentPage === 1) ? currentPage : currentPage - 1);
                  }}
                 className="page-link">
                  {lang.menu.previous}
                </Button>
              </li>
              
              {currentPage > 1 && 
              <li className="page-item">
              <Button 
              className="page-link"
              
                key={currentPage - 1} 
                value={currentPage - 1} 
                onClick={()=>handlePageChange(currentPage - 1)} >
                  {currentPage - 1}
              </Button>
              </li>}

              <li className="page-item active">
          <Button
            className="page-link"
             key={currentPage} 
             value={currentPage} 
              active>
                {currentPage}
                </Button>
                </li>

          {currentPage < totalPages && 
            <li className="page-item ">
            <Button 
              className="page-link"
              key={currentPage + 1} 
              value={currentPage + 1} 
              onClick={()=>handlePageChange(currentPage + 1)}>
                {currentPage + 1}
            </Button>
            </li>}
          
           
              <li className="page-item page-indicator">
                <Button
                className="page-link"
                style={{width:"110%"}}
                onClick={()=> {
                  handlePageChange((currentPage === totalPages) ? currentPage : currentPage + 1);
                }}>
                  {lang.menu.next}
                </Button>
                  
              
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <ToastContainer />
      </>) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}
    </>
  );
}
export default JobLists;
