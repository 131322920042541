import React, { useEffect, useState } from "react";
import "./PostJob.css";
import translations from "../AppsMenu/Candidate/mulitlingue";
import Select from "react-select";
import data from "../data";
import { Button,Row,Col } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { compareAsc } from "date-fns";
import Swal from 'sweetalert2';

function JobRole() {
  const token=localStorage.getItem("token");

  const history = useHistory();
  const [height, setHeight] = useState("200px");
  const [persoTest,setPersoTest] = useState([]);
  const [technicalTest,setTechnicalTest] = useState([]);
  const [psychoTest,setPsychoTest] = useState([]);
  const [psycho,setPsycho] = useState("");
  const [perso,setPerso ]= useState("");
  const [selectedTech, setSelectedTech] = useState([]);
  const [allAssignments, setAllAssignments] = useState([]);

  const [companyID, setCompanyID] = useState("");
  const [isDisable, setIsDisable] = useState("");
  const [quotaMax, setQuotaMax] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonSendDisabled, setIsButtonSeendDisabled] = useState(true);
  const [userConnect, setUserConnect] = useState("");
  const [creatingProfile, setCreatingProfile] = useState(false);

  const getUserCompanyID = async () => {
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  
  
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    setUserConnect(currentUser)
    const userId = currentUser.localId;
    const fetchRows = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      console.log("compId :",compId)
      setCompanyID(compId)
      if(compId!==1){
        const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const subscriptionData = await subscriptionResponse.json();
        console.log("subscriptionData :",subscriptionData)
        if(subscriptionResponse.status===200){
          setQuotaMax(subscriptionData[2])
      
          const isDisabled = subscriptionData[2].numberquota_max <=  subscriptionData[2].quotaJobProfile;
          console.log("isDisabled :",isDisabled)
      
          setIsDisable(isDisabled)
        }
    }  
    else if(compId===1 && currentUser.userRole===3){
      const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${currentUser.localId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const subscriptionData = await subscriptionResponse.json();
      console.log("subscriptionData :",subscriptionData)
      if(subscriptionResponse.status===200){
        setQuotaMax(subscriptionData[2])
    
        const isDisabled = subscriptionData[2].numberquota_max <=  subscriptionData[2].quotaJobProfile;
        console.log("isDisabled :",isDisabled)
    
        setIsDisable(isDisabled)
      }
  } 
  
  }
    fetchRows()
  }, []);
  const currentUser = JSON.parse(localStorage.getItem('userDetails'));
  const userId = currentUser.localId;
  const [jobProfileData, setJobProfileData] = useState({
    title: "",
    description: "",
    requiredQualifications: "",
    languages: "",
    Tests: "",
    testsUpdate: [],
    testsToDisplay: [],
    personality: "",
    technical: "",
    psycho: "",
    companyid :"",
    userId:userId,
    assignment: ""

  });
  //Style Select
  const selectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "15px",
      boxShadow: "none",
      border: "none",
      "&:hover": {
        border: "none",
      },
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#333",
      "&:hover": {},
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "15px",
      marginTop: "-1px",
      boxShadow: "none",
      border: "none",
      "&:before": {
        display: "none",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#555",
    }),
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonSeendDisabled(true);
    setCreatingProfile(true)
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    localStorage.setItem("updateProfile", 0);
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      if (response.data && response.data[0] && response.data[0].companyID) {
        const companyId = response.data[0].companyID;
        setCompanyID(companyId);
  
        let subscriptionResponse = "";
        let subscriptionData = "";
  
        if (companyId !== 1) {
          subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${companyId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          subscriptionData = await subscriptionResponse.json();
        } else if (companyId === 1 && currentUser.userRole === 3) {
          subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          subscriptionData = await subscriptionResponse.json();
        }
  
        if (companyId !== 1 && subscriptionData[2].numberquota_max > subscriptionData[2].quotaJobProfile) {
          await axios.post(
            `${process.env.REACT_APP_APP_DOMAIN}/api/job-profile/createProfile`,
            {
              ...jobProfileData,
              companyid: companyId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ).then(async (res) => {
            const newProfileId = res.data.id;
            swal(lang.menu.createjobPMsgSucc, {
              icon: "success",
            });
            console.log("jobProfileSended " + JSON.stringify(jobProfileData));
  
            await axios.put(
              `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${companyId}`,
              { fieldName: "quotaJobProfile", "incrementValue": 1, quota_max: subscriptionData[2].numberquota_max, quota_used: subscriptionData[2].quotaJobProfile },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            history.push(`update-coefficient/${newProfileId}`);
            setCreatingProfile(false)

          }).catch((err) => {
            swal(
              lang.menu.deleteTitleWrong,
              lang.menu.deleteMsgProfileJob,
              "error"
            );
            console.log(err);
            setCreatingProfile(false)

          });
        } else if (companyId === 1 && currentUser.userRole === 3 && subscriptionData[2].numberquota_max > subscriptionData[2].quotaJobProfile) {
          await axios.post(
            `${process.env.REACT_APP_APP_DOMAIN}/api/job-profile/createProfile`,
            {
              ...jobProfileData,
              companyid: companyId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ).then(async (res) => {
            const newProfileId = res.data.id;
            swal(lang.menu.createjobPMsgSucc, {
              icon: "success",
            });
            console.log("jobProfileSended " + JSON.stringify(jobProfileData));
  
            await axios.put(
              `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateRecruiterField/${userId}`,
              { fieldName: "quotaJobProfile", "incrementValue": 1, quota_max: subscriptionData[2].numberquota_max, quota_used: subscriptionData[2].quotaJobProfile },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            history.push(`update-coefficient/${newProfileId}`);
            setCreatingProfile(false)

          }).catch((err) => {
            swal(
              lang.menu.deleteTitleWrong,
              lang.menu.deleteMsgProfileJob,
              "error"
            );
            console.log(err);
            setCreatingProfile(false)

          });
        } else {
          await axios.post(
            `${process.env.REACT_APP_APP_DOMAIN}/api/job-profile/createProfile`,
            {
              ...jobProfileData,
              companyid: companyId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ).then((res) => {
            const newProfileId = res.data.id;
            swal(lang.menu.createjobPMsgSucc, {
              icon: "success",
            });
            console.log("jobProfileSended " + JSON.stringify(jobProfileData));
            setCreatingProfile(false)

            history.push(`update-coefficient/${newProfileId}`);
          }).catch((err) => {
            swal(
              lang.menu.deleteTitleWrong,
              lang.menu.deleteMsgProfileJob,
              "error"
            );
            console.log(err);
            setCreatingProfile(false)

          });
        }
      } else {
        console.log("companyId not found or invalid.");
        setCreatingProfile(false)

      }
    } catch (error) {
      console.error("Error fetching companyId:", error);
      setCreatingProfile(false)

    }
  
    setIsButtonSeendDisabled(false);
  };
  
  
  const [TestsOptions, setTestsOptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getAllTests`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        // Filter tests by type
        console.log("res.data :",res.data)
        console.log("companyID1 :",companyID)

        const technicalTests = res.data.filter(
          (test) => test.test_source === "TechnicalTest" && parseInt(test.type, 10)===companyID
        );
        setTechnicalTest(technicalTests)
        console.log("technicalTests :",technicalTests)
        const personalityTests = res.data.filter(
          (test) => test.test_source === "PersonalityTest"
        );
        setPersoTest(personalityTests)
        const psychoTests = res.data.filter(
          (test) => test.test_source === "Psychoest" && (parseInt(test.type, 10)===companyID || test.type===null)
        );
        setPsychoTest(psychoTests)

        // Map them to options
        const updatedTestsOptions = [
          {
            label: "-----------Technical Test-------------",
            options: technicalTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Technical Test",
              test_source: test.test_source,
            })),
          },
          {
            label: "-----------Personality Test-----------",
            options: personalityTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Personality Test",
              test_source: test.test_source,
            })),
          },
          {
            label: "-----------Psychotechnical Test-------",
            options: psychoTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Technical Test",
              test_source: test.test_source,
            })),
          },
        ];
        setJobProfileData((prevJobData) => ({
          ...prevJobData,
          testsToDisplay: res.data,
        }));
        console.log("updatedTestsOptions :",updatedTestsOptions)
        setTestsOptions(updatedTestsOptions);
      })
      .catch((err) => console.log(err));
  }, [companyID]);

  const [newSpecialism, setNewSpecialism] = useState("");
  const [options, setOptions] = useState(data.SpecialismOptions);
  
 
  const normalizeString = (str) => {
    return str.toLowerCase().replace(/\s+/g, '');
  }
  
  const handleAddSpecialism = () => {
    const normalizedNewSpecialism = normalizeString(newSpecialism);
  
    const similarSpecialism = options.find(
      option => {
        const normalizedOptionValue = normalizeString(option.value);
        return normalizedOptionValue.includes(normalizedNewSpecialism) || normalizedNewSpecialism.includes(normalizedOptionValue);
      }
    );
  
    if (similarSpecialism) {
      Swal.fire({
        title: `${lang.menu.exist} "${similarSpecialism.value}".`,
        text: `${lang.menu.swalAddQualification1} "${newSpecialism}", ${lang.menu.swalAddQualification2}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: `${lang.menu.Discard}`
      }).then((result) => {
        if (result.isConfirmed) {
          addNewSpecialism();
        } else {
          setNewSpecialism(""); 
        }
      });
    } else {
      addNewSpecialism();
    }
  };
  
  const addNewSpecialism = () => {
    const updatedSpecialismOptions = [
      ...options,
      { value: newSpecialism, label: newSpecialism },
    ];
    data.SpecialismOptions = updatedSpecialismOptions;
    setOptions(updatedSpecialismOptions);
    setNewSpecialism("");
  };
  /**Technical */

  const handleTechChange = (selectedOptions) => {  
    setSelectedTech(selectedOptions);
    console.log("selectedOptions : ", selectedOptions);
    if (selectedOptions.length > 0 || psycho || perso) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    const selectedValues = selectedOptions.map(option => option.value);
    const selectedValuesString = selectedValues.join(',');
    const testUp = jobProfileData.testsUpdate;
  
    const testTech = selectedOptions.map(option => {
      const filteredTests = jobProfileData.testsToDisplay.filter(test => {
        return (
          test.Id === option.value &&
          test.test_source === "TechnicalTest" &&
          test.name === option.label
        );
      });
  
      return filteredTests.length > 0 ? {
        value: filteredTests[0].Id,
        label: filteredTests[0].name,
        type: "Technical Test",
        test_source: filteredTests[0].test_source
      } : null;
    });
  
    console.log("testTech : ", testTech);
  
    const combinedTests = [...testUp, ...testTech];
  
    const uniqueTests = new Set(combinedTests.map(JSON.stringify));
    const uniqueTestsArray = Array.from(uniqueTests).map(JSON.parse);
  
    setJobProfileData({
      ...jobProfileData,
      testsUpdate: uniqueTestsArray,
      technical: selectedValuesString,
    });
  
    console.log("jobProfileData:", jobProfileData);
  };
  

const handleDropdownSelect12 = (selectedOptions) => {  
  setPsycho(selectedOptions);
  const testUp= jobProfileData.testsUpdate
  if (selectedOptions || selectedTech.length > 0 || perso) {
    setIsButtonDisabled(false);
  } else {
    setIsButtonDisabled(true);
  }
  const filteredTestsUp = testUp.filter(test => test.test_source !== "Psychoest");
  const filteredTests = jobProfileData.testsToDisplay.filter(test => {
    return (
      test.Id === selectedOptions.value &&
      test.test_source === "Psychoest" &&
      test.name === selectedOptions.label 
    );
  });

  const testSelect = filteredTests.map(test => ({
    value: test.Id,
    label: test.name,
    type: "Technical Test",
    test_source: test.test_source
  }));

  console.log("testSelect :", testSelect);
  const combinedTests = [...filteredTestsUp, ...testSelect];

  const uniqueTests = new Set(combinedTests.map(JSON.stringify));
  const uniqueTestsArray = Array.from(uniqueTests).map(JSON.parse);

    setJobProfileData(prevData => ({
    ...prevData,
    testsUpdate: uniqueTestsArray,
    psycho: selectedOptions.value
  }));

  console.log("jobProfileData:", jobProfileData);
};



//***Perso */
const handleDropdownSelect22 = (selectedOptions) => {  
  setPerso(selectedOptions);
  const testUp= jobProfileData.testsUpdate
  const filteredTestsUp = testUp.filter(test => test.test_source !== "PersonalityTest");
  if (selectedOptions || selectedTech.length > 0 || psycho) {
    setIsButtonDisabled(false);
  } else {
    setIsButtonDisabled(true);
  }
  const filteredTests = jobProfileData.testsToDisplay.filter(test => {
    return (
      test.Id === selectedOptions.value &&
      test.test_source === "PersonalityTest" &&
      test.name === selectedOptions.label 
    );
  });

  const testSelect = filteredTests.map(test => ({
    value: test.Id,
    label: test.name,
    type: "Personality Test",
    test_source: test.test_source
  }));
  console.log("testSelect :", testSelect);
  const combinedTests = [...filteredTestsUp, ...testSelect];

  const uniqueTests = new Set(combinedTests.map(JSON.stringify));
  const uniqueTestsArray = Array.from(uniqueTests).map(JSON.parse);
  setJobProfileData({
    ...jobProfileData,
    testsUpdate: uniqueTestsArray,
    personality: selectedOptions.value,
  });
  console.log("jobProfileData:", jobProfileData);

};


// assignment
  useEffect(()=>{
    const getAllAssignments = async ()=>{
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/getAll/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        console.log("assignments", response.data.assignments);
        setAllAssignments(response.data.assignments);

      } catch (error) {
        console.error(error);
      }
    }

    getAllAssignments();
  },[]);


  const handleAssignment = (selectedOptions)=>{
    console.log('h:',selectedOptions)
    if (selectedOptions.value || selectedTech.length > 0 || psycho || perso) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    setJobProfileData({
      ...jobProfileData,
     // testsUpdate: uniqueTestsArray,
      assignment: selectedOptions.value,
    });
   
  }
  return (
    <>
      <h1>{lang.menu.CreateJobProfile} </h1>
      <br />
      <div className="max-widht">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h3 className="left-align-container ">
                    {lang.menu.profilTitle} 
                  </h3>

                  <div className="input-group ">
                    <input
                      required
                      type="text"
                      className={`form-control `}
                      placeholder=" Title"
                      maxLength={128} 
                      onChange={(e) =>
                        setJobProfileData({
                          ...jobProfileData,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <br />
                  <h3 className="left-align-container smallscreen">
                    {lang.menu.profilDESC}
                  </h3>
                  <div>
                    <textarea
                      required
                      style={{ height: height }}
                      maxLength={1000} 
                      onChange={(e) =>
                        setJobProfileData({
                          ...jobProfileData,
                          description: e.target.value,
                        })
                      }
                      type="text"
                      className={`form-control`}
                      placeholder=" Job Description"
                    />
                  </div>
                  <br />
<Row>
                  <Col className="select-container">
                    <div className="select-wrapper-left">
                      <h4 className="smallscreen">
                        {lang.menu.RequiredQualifications}
                      </h4>
                      <Select
                        className={`select-multi`}
                        required
                        isMulti
                        options={data.SpecialismOptions}
                        styles={selectStyles}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => ({
                              skill: option.value,
                              count: 1,
                            })
                          );

                          setJobProfileData({
                            ...jobProfileData,
                            requiredQualifications: selectedValues,
                          });
                        }}
                      />
                    </div>
                   
                  </Col>
                  <Col className="select-wrapper-left">
                      <div className="buttonAline">
                      
                        <h4 className="smallscreen">  {lang.menu.AddNeededQualification} </h4>
                        
                       
                        <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                       {lang.menu.iJobProfile}                        </div>
                      </div>
                      </div>
                      <div className="input-button-container">
                        <input
                          type="text"
                          className={`form-control`}
                          value={newSpecialism}
                          onChange={(e) => setNewSpecialism(e.target.value)}
                        />
                        <button
                          className="buttonSkills"
                          onClick={handleAddSpecialism}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </Col>
                    </Row>
                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-right">
                      <h4>{lang.menu.language}</h4>
                      <Select
                        isMulti
                        className={`form-control`}
                        required
                        options={data.Languages}
                        styles={selectStyles}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.value
                          );

                          setJobProfileData({
                            ...jobProfileData,
                            languages: JSON.stringify(selectedValues),
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/**
                <div className="select-container">
                 <div className="select-wrapper-right">
                    <h4>{lang.menu.TestsTypes}</h4>
                    <Select
                     required
                     isMulti
                     className={`form-control`}
                     options={data.TestsTypes}
                     styles={selectStyles}
                     onChange={(selectedOptions) => {
                       const selectedValues = selectedOptions.map((option) => option.value);
                     
                   
                       setJobProfileData((prevJobData) => ({
                         ...prevJobData,
                         Tests: JSON.stringify(selectedValues),
                       }));
                     }}
                    />
                  </div>
                </div> */}
               
                  <br/>
                  <Row>
                  <Col>
                    <label><strong>{lang.menu.Assignment}:</strong> </label>
                    <br/>
                    <Select
    options={allAssignments.map(test => ({ value: test.id, label: test.name }))}
    
    onChange={
      handleAssignment
    }
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  />
                <br/>
                    </Col>
                <Col className="form-group">
                    <label><strong>{lang.menu.testTechnique}:</strong> </label>
                    <br/>
                    <Select
    options={technicalTest.map(test => ({ value: test.Id, label: test.name }))}
    isMulti
    onChange={handleTechChange}
    value={selectedTech}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  /> <br/>
                    
                    </Col>
                    <Col>
                    <label><strong>{lang.menu.testPsychoTechnique}:</strong> </label>
                    <br/>
                    <Select
    options={psychoTest.map(test => ({ value: test.Id, label: test.name }))}
    
    onChange={
      handleDropdownSelect12
    }
    value={psycho}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  />
                <br/>
                    </Col>
                    <Col>
                    <label><strong>{lang.menu.perTest}:</strong> </label>
                    <br/>
                    <Select
    options={persoTest.map(test => ({ value: test.Id, label: test.name }))}
    
    onChange={
      handleDropdownSelect22
    }
    value={perso}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  /> <br/>
                    </Col>
                    </Row>
                </div>



                <div className="card-footer border-0">
                  <div className="d-flex justify-content-end">
                  {companyID && companyID!==1 ? (
                    <Button
                      type="submit"
                      className="btn btn-primary btn sweet-confirm"
                      disabled={isDisable || isButtonDisabled && isButtonSendDisabled}
                    >
                      {lang.menu.PostNow}
                    </Button>):
                    companyID && companyID===1 && userConnect && userConnect.userRole===3 ? (
                      <Button
                        type="submit"
                        className="btn btn-primary btn sweet-confirm"
                        disabled={isDisable || isButtonDisabled && isButtonSendDisabled ||creatingProfile}
                      >
                        {lang.menu.PostNow}
                      </Button>)
                    :( <Button
                      type="submit"
                      disabled={isButtonDisabled}

                      className="btn btn-primary btn sweet-confirm"
                    >
                      {lang.menu.PostNow}
                    </Button>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default JobRole;
