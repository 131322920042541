import React, { useEffect,useState } from 'react';
import CVEditor from './CVEditor';
import { useLocation } from 'react-router-dom';
import translations from "../AppsMenu/Candidate/mulitlingue";

const Resume = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resumeId = queryParams.get('resumeId');
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  return (
    <div style={{ padding: '20px' }}>
      <h1>{resumeId ? lang.menu.updateResume : lang.menu.creeCv}</h1>
      <CVEditor resumeId={resumeId}  />
    </div>
  );
};

export default Resume;
