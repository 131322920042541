import React, { useState, useEffect } from "react";
import ISO6391 from "iso-639-1";

function SoftSkills({ lang,toggleCategory, expandedCategories, refreshData }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [proficiencyLevels, setProficiencyLevels] = useState({});

  const proficiencyLevelsOptions = [
    "Beginner",
    "Intermediate",
    "Advanced",
    "Mastery",
  ];
  const proficiencyLevelsOptionsName = {
    "Beginner": `${lang.menu.Beginner}`,
    "Intermediate":`${lang.menu.Intermediate}`,
    "Advanced":`${lang.menu.Advanced}`,
    "Mastery":`${lang.menu.Mastery}`,
  };

  const languageCodes = ISO6391.getAllCodes();
  const languageNames = languageCodes.map((code) => ISO6391.getName(code));
  useEffect(() => {
    const storedData = localStorage.getItem("SoftSkills");
    if (storedData) {
      const {
        selectedLanguages: storedSelectedLanguages,
        proficiencyLevels: storedProficiencyLevels,
      } = JSON.parse(storedData);
      setSelectedLanguages(storedSelectedLanguages);
      setProficiencyLevels(storedProficiencyLevels);
    }
  }, [refreshData]);

  useEffect(() => {
    localStorage.setItem(
      "SoftSkills",
      JSON.stringify({ selectedLanguages, proficiencyLevels })
    );
  }, [selectedLanguages, proficiencyLevels]);

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
    if (language && !selectedLanguages.includes(language)) {
      setSelectedLanguages([...selectedLanguages, language]);
      setProficiencyLevels({ ...proficiencyLevels, [language]: "Beginner" });
      setSelectedLanguage(""); // Reset the dropdown value
    }
  };

  const handleProficiencyLevelClick = (language, level) => {
    setProficiencyLevels({ ...proficiencyLevels, [language]: level });
  };

  const handleRemoveLanguage = (languageToRemove) => {
    setSelectedLanguages(
      selectedLanguages.filter((language) => language !== languageToRemove)
    );
    setProficiencyLevels((prevLevels) => {
      const { [languageToRemove]: removedLanguage, ...remainingLanguages } =
        prevLevels;
      return remainingLanguages;
    });
  };

  const isSelectedProficiency = (language, level) => {
    return proficiencyLevels[language] === level ? "selected" : "notselected";
  };

  return (
    <div className="category">
      <h2 className='single-line' onClick={() => toggleCategory("softSkills")}>{lang.menu.SoftSkills}</h2>
      {expandedCategories.softSkills && (
        <div className="category-content">
          <div>
            <select
              className="select-style"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              <option value="">{lang.menu.sal}</option>
              {languageCodes.map((langue) => (
                <>
             

                <option
                  key={ISO6391.getName(langue)}
                  value={ISO6391.getName(langue)}
                  disabled={ISO6391.getName(langue) === lang.menu.sal}

                >
                  {ISO6391.getName(langue)}
                </option>
                </>
              ))}
            </select>
          </div>
          {selectedLanguages.length > 0 && (
            <div>
              <h3>{lang.menu.sl}</h3>
              {selectedLanguages.map((language, index) => (
                <div
                  key={index}
                  style={{ display: "flex", "flex-direction": "row" ,marginTop: "6px" }}
                >
                  <h4 style={{ margin: 0, width:"15%"}}>{language}</h4>
                  {proficiencyLevelsOptions.map((level, index) => (
                    <button
                      style={{ paddingLeft: "3px", paddingRight: "3px",marginLeft: "3px" }}
                      key={index}
                      className={isSelectedProficiency(language, level)}
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default form submission
                        handleProficiencyLevelClick(language, level);
                      }}
                    >
                      {proficiencyLevelsOptionsName[level]}
                    </button>
                  ))}
                  <button
                                      style={{ paddingLeft: "5px", paddingRight: "5px", marginLeft: "2%" ,color:"white", background:"#74188D",borderRadius: "10px"}}
                                      onClick={(e) => {
                      e.preventDefault();
                      handleRemoveLanguage(language);
                    }}
                  >
                    <b>{lang.menu.X}</b>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SoftSkills;
