import React, { useRef, useEffect,useState } from 'react';
import html2canvas from 'html2canvas';

import {Row,Card,Col, Button, Carousel, Modal, Form} from 'react-bootstrap';
import { MdOutlineEmail } from "react-icons/md";
import { Link,useHistory } from "react-router-dom";

import { IoChevronBack } from "react-icons/io5";
import ReactStars from "react-rating-stars-component";
import { SlUserUnfollow } from "react-icons/sl";
import { MdOutlineAlternateEmail } from "react-icons/md";
import translations from"../AppsMenu/Candidate/mulitlingue";
 
import axios from 'axios';
import { FaAngleDown } from "react-icons/fa6";
import { FcCheckmark , FcCancel} from "react-icons/fc";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BsFiletypePdf } from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import Select from "react-select";

import {
  Comment,
  CommentContent,
  CommentAvatar,
  CommentAuthor,
  Form as SemanticUIForm,
  CommentText,
  CommentMetadata,
  Header,
  CommentActions,
  CommentAction
} from 'semantic-ui-react';
import { ca } from 'date-fns/locale';
import swal from "sweetalert";
import { SlLike } from "react-icons/sl";
import { RiReplyLine } from "react-icons/ri";

const CandidateDetails = () => {
    const candidateDetails = localStorage.getItem('candidateDetails');
    var candidate = JSON.parse(candidateDetails);
    const history = useHistory();
    const footerRef = useRef(null);
    const token=localStorage.getItem("token");

    const [showDetails, setShowDetails] = useState(false);
  const [testPassedTech, setTestPassedTech] = useState([]);
  const [testPassedCoding, setTestPassedCoding] = useState([]);
  const [stars, setStars] = useState(0);
  const [isContentFilled, setIsContentFilled] = useState(false);
  const [content, setContent] = useState('');
  const [testPassedPsycho, setTestPassedPsycho] = useState([]);
  const [showDetails2, setShowDetails2] = useState(false);
  const [reference, setReference] = useState([]);
  const [ratingsList, setRatingsList] = useState([]);
    const [selectedLang, setSelectedLang] = useState(null);
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyName, setCompanyName] = useState('');

    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem('selectedLang');
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);


          if (parsedLang.language === 'Français') {
            return translations.fr;
          }
        }
        return translations.en;
      });
      useEffect(() => {
        const fetchRef = async () => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/getRefferenceByEmail/${candidate.email}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setReference(response.data.candidates[0])
      }
      
      }
        fetchRef()
      }, []);
      useEffect(() => {
        const getUserCompanyID = async () => {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await responseC.json();
          const compId = data[0].companyID;

          const foundCompany = companies.find(company => company.companyid === compId);
          if (foundCompany) {
            setCompanyName(foundCompany.companyName);
          }
        };
        getUserCompanyID();
      }, [companies]); 
      useEffect(() => {
        const fetchCompanyName = async () => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setCompanies(response.data)
      }
      
      }
      fetchCompanyName()
      }, []);
      useEffect(() => {
        const fetchRef = async () => {
          const userResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
        setUsers(userResponse.data);
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/JobRole/findRatingById/${candidate.id_candidate}/${candidate.id_jobrole}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setRatingsList(response.data.data)
      }
      
      }
        fetchRef()
      }, []);
    useEffect(() => {
      const footerHeight = footerRef.current.offsetHeight;
    const textarea = document.getElementById('myTextarea');
      textarea.style.height = `${footerHeight}px`;
    }, []);


    const getCandAcceptStatus = (candAccept) => {
      switch (candAccept) {
        case 2:
          return (
            <div style={{fontSize: 'smaller'}}>
              <i className="fa fa-circle text-success me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i> {lang.menu.passUser}
            </div>
          );
        case 1:
          return (
            <div style={{fontSize: 'smaller'}}>
            <i className="fa fa-circle text-warning me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i><span > {lang.menu.pendingUser}</span>
          </div>
          
          );
        case 0:
          return (
            <div style={{fontSize: 'smaller'}}>
              <i className="fa fa-circle text-dark me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i> {lang.menu.appliedUser}
            </div>
          );
        default:
          return "";
      }
    };
  
    const getTestPsychoName = async (testId) => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestById/${testId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});

        const testName = response.data.test_name;
        return testName
    }
    const handleButtonClick1 = (noteTech,noteCoding) => {
      setShowDetails(!showDetails);

  setTestPassedTech(noteTech)
  setTestPassedCoding(noteCoding)
    };
    const handleButtonClick = (note) => {
      setShowDetails2(!showDetails2);

  setTestPassedPsycho(note)
    };

    const handleButtonClick2 = (noteTech) => {
    setShowDetails(!showDetails);
  setTestPassedTech(noteTech)

    };
    const handleButtonClick3 = (noteCoding) => {
      setShowDetails(!showDetails);

  setTestPassedCoding(noteCoding)
    };
    const handleSubmitRating = async () => {
      try {
        if (!isContentFilled) return;
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
        const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/updateCandidateJobRating/${candidate.id_candidate}/${candidate.id_jobrole}`, {
          recruteurRate: userId,
          content: content,
          stars: stars
        }, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});  
        swal("Rating submitted successfully", {
          icon: "success",
        });
      }
      catch{
        swal('Failed to submit the form', {
          icon: "error",
        });
       }
    }
    const ratingcandidateChanged = (newRating) => {
      setStars(newRating)
    };
    const handleContentChange = (e) => {
      setContent(e.target.value);
      setIsContentFilled(!!e.target.value); 
    };
    const handlePDFDownload = () => {
      const element = document.querySelector('.CandidateJob'); 
  
      html2canvas(element, {
          scale: 2,
          logging: true, 
      }).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4'); 
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
  
          const imgWidth = pdfWidth;
          const imgHeight = (canvasHeight * pdfWidth) / canvasWidth; 
  
          let position = 0;
  
          if (imgHeight > pdfHeight) {
              while (position < imgHeight) {
                  pdf.addImage(imgData, 'PNG', 0, position - imgHeight + pdfHeight, imgWidth, imgHeight);
                  position += pdfHeight;
                  if (position < imgHeight) {
                      pdf.addPage();
                  }
              }
          } else {
              pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          }
  
          pdf.save('download.pdf');
      });
  };
  
  const sendEmailToCandidate = async () => {
    try {
      const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);
      const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmailFinal`, {
        email: candidate.email,
        jobName: candidate.jobName,
        lname:candidate.LastName,
        fname:candidate.FirstName,
        comp:companyName,             
         lang:parsedLang.language

      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});
  
      if (response.status === 200) {
        swal('Email sent successfully', {
          icon: "success",
        });
      } else {
        swal('Failed to send email', {
          icon: "error",
        });
      }
    } catch (error) {
      swal('Error sending email:', error, {
        icon: "error",
      });
    }
  };
  const sendEmailToCandidateReject = async () => {
    try {
      const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);
      const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmailFinalRejct`, {
        email: candidate.email,
        jobName: candidate.jobName,
        lname:candidate.LastName,
        fname:candidate.FirstName,
        comp:companyName,
        lang:parsedLang.language

      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});
  
      if (response.status === 200) {
        swal(lang.menu.emailsentSucc, {
          icon: "success",
        });
        const responseReject = await axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/RejectCandidate/${candidate.id_candidate}/${candidate.id_jobrole}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      } else {
        swal(lang.menu.emailsentfailed, {
          icon: "error",
        });

      }
    } catch (error) {
      swal(lang.menu.emailsentfailed, {
        icon: "error",
      });
    }
  };

  const [techTestAntiCheatingReport, setTechTestAntiCheatingReport]= useState([]);
  const [testTech, setTestTech]= useState();
  useEffect(()=>{
    const getTechnicalTestResult= async ()=>{
    try {
      const candidateid= candidate.id_candidate;
      const jobid=candidate.id_jobrole;
      const response= await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/technicalTest/${candidateid}/${jobid}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
          console.log("check:",response.data)
      setTechTestAntiCheatingReport(response.data);
      setTestTech(response.data.technicalTest[0]);
    } catch (error) {
      console.log(error);
    }
    }

    getTechnicalTestResult();


},[]);

const [displaySnapshots, setDisplaySnapshots]= useState(true);
const [imageUrls, setImageUrls]= useState([]);

const fetchSnapShots = async (index) => {
  try {
      console.log("index:", index);
      console.log('technicalTest:', techTestAntiCheatingReport?.technicalTest);
      console.log('TestTech:', techTestAntiCheatingReport?.technicalTest?.[index]?.TestTech);

      // Ensure that the necessary data is present before accessing properties
      if (
          techTestAntiCheatingReport &&
          Array.isArray(techTestAntiCheatingReport.technicalTest) &&
          techTestAntiCheatingReport.technicalTest.length > index && // Check that index is within bounds
          techTestAntiCheatingReport.technicalTest[index] && // Ensure the element at index exists
          techTestAntiCheatingReport.technicalTest[index].TestTech && // Ensure TestTech exists
          techTestAntiCheatingReport.technicalTest[index].TestTech.takeSnapShots // Check takeSnapShots is true
      ) {
          console.log("Fetching snapshots for TestTech:", techTestAntiCheatingReport.technicalTest[index].TestTech);

          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/getSnapShots/${techTestAntiCheatingReport.technicalTest[index].id_user}/${techTestAntiCheatingReport.technicalTest[index].id_job}/${techTestAntiCheatingReport.technicalTest[index].id_test}/${techTestAntiCheatingReport.technicalTest[index].techTestType}`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );
          console.log("Snapshots fetched:", response.data);

          setImageUrls(response.data);
      } else {
          console.error('No snapshots available for this test or required properties are missing.');
      }
  } catch (error) {
      console.error('Error fetching images from directory:', error);
  }
};



const fetchScreenShots = async (index) => {
  try {
      console.log("index:", index);
      console.log('technicalTest:', techTestAntiCheatingReport?.technicalTest);
      console.log('TestTech:', techTestAntiCheatingReport?.technicalTest?.[index]?.TestTech);

      // Ensure that the necessary data is present before accessing properties
      if (
          techTestAntiCheatingReport &&
          Array.isArray(techTestAntiCheatingReport.technicalTest) &&
          techTestAntiCheatingReport.technicalTest.length > index && // Check that index is within bounds
          techTestAntiCheatingReport.technicalTest[index] && // Ensure the element at index exists
          techTestAntiCheatingReport.technicalTest[index].TestTech && // Ensure TestTech exists
          techTestAntiCheatingReport.technicalTest[index].TestTech.takeScreenShots // Check takeScreenShots is true
      ) {
          console.log("Fetching screenshots for TestTech:", techTestAntiCheatingReport.technicalTest[index].TestTech);

          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/getScreenshots/${techTestAntiCheatingReport.technicalTest[index].id_user}/${techTestAntiCheatingReport.technicalTest[index].id_job}/${techTestAntiCheatingReport.technicalTest[index].id_test}/${techTestAntiCheatingReport.technicalTest[index].techTestType}`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );
          console.log("Screenshots fetched:", response.data);

          setImageUrls(response.data);
      } else {
          console.error('No screenshots available for this test or required properties are missing.');
      }
  } catch (error) {
      console.error('Error fetching images from directory:', error);
  }
};



  const [currentIndex, setCurrentIndex]= useState(0);
  useEffect(() => {
    console.log("techTestAntiCheatingReport:", techTestAntiCheatingReport);

    if (techTestAntiCheatingReport 
        && techTestAntiCheatingReport.technicalTest 
        && Array.isArray(techTestAntiCheatingReport.technicalTest) 
        && techTestAntiCheatingReport.technicalTest.length > 0) {

        // Loop through each test and fetch snapshots or screenshots accordingly
        techTestAntiCheatingReport.technicalTest.forEach((test, idx) => {
            if (test?.TestTech?.takeSnapShots) {
                fetchSnapShots(idx);
            } else if (test?.TestTech?.takeScreenShots) {
              console.log("test?.TestTech?.takeScreenShots;",test.TestTech.takeScreenShots)
                fetchScreenShots(idx);
            } else {
                console.log(`No snapshots or screenshots available for test at index ${idx}`);
            }
        });
    } else {
        console.log("techTestAntiCheatingReport does not contain any technical tests or is empty.");
    }
}, [techTestAntiCheatingReport]);



const updateCurrentIndex = (e) => {
  try {
      let index = currentIndex;

      if (e.target.id === "next") {
          // Chercher le prochain test de type "Coding" à partir de l'index actuel
          const nextCodingIndex = techTestAntiCheatingReport.technicalTest.findIndex(
              (test, i) => i > currentIndex && test.techTestType === "Coding"
          );
          // Si on trouve un test "Coding", on met à jour l'index, sinon on passe au suivant normal
          index = nextCodingIndex !== -1 ? nextCodingIndex : (currentIndex + 1) % techTestAntiCheatingReport.technicalTest.length;
      } else if (e.target.id === "prev") {
          // Chercher le test "Coding" précédent à partir de l'index actuel
          const previousCodingIndex = [...techTestAntiCheatingReport.technicalTest].reverse().findIndex(
              (test, i) => (techTestAntiCheatingReport.technicalTest.length - 1 - i) < currentIndex && test.techTestType === "Coding"
          );
          index = previousCodingIndex !== -1
              ? techTestAntiCheatingReport.technicalTest.length - 1 - previousCodingIndex
              : (currentIndex - 1 + techTestAntiCheatingReport.technicalTest.length) % techTestAntiCheatingReport.technicalTest.length;
      }

      setCurrentIndex(index);
      setTestTech(techTestAntiCheatingReport.technicalTest[index]);
      techTestAntiCheatingReport.technicalTest[index].TestTech.takeSnapShots
          ? fetchSnapShots(index)
          : fetchScreenShots(index);

      setDisplaySnapshots(true);
  } catch (error) {
      console.error(error);
  }
};


  const [sent, setSent]=useState(false);
  const resendTest= async ()=>{
    try {
      const candidateid= candidate.id_candidate;
      const jobid=candidate.id_jobrole;
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const recruiterid=  currentUser.localId;
      const testid = testTech.id_test;
      const testType= testTech.techTestType;
      
      const response= await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/retakeTest/${recruiterid}/${candidateid}/${jobid}/${testid}/${testType}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      setSent(true);
    } catch (error) {
      console.error(error);
    }
  }

  


  const [currentIndexImage, setCurrentIndexImage]= useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleModal = ()=>{
    setShowModal(!showModal);
  }
  
  const updateCurrentIndexImage=(index)=>{
    
    setCurrentIndexImage(index);
  }

  const handleChangeImages= (e)=>{
    try {

    setDisplaySnapshots(e.target.id === "snapshots" ? true : false);
    if(e.target.id === "snapshots"){
      fetchSnapShots(currentIndex);
    }else if(e.target.id === "screenshots"){
      fetchScreenShots(currentIndex);
    }
    } catch (error) {
      console.error(error);
    }

  }

  function formatCalendarDate(date1) {
    const dateObj = new Date(date1);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-GB', options);
    return formattedDate;
  }
  
  //assignment
  var assignments_scores = candidate.detailed_assignment_score ? JSON.parse(candidate.detailed_assignment_score): {};
  const [hasAssignment, setHasAssignment] = useState(false);
  const [assignments, setAssignments]= useState([]);
  const [showDetailsAssignment, setShowDetailsAssignment]= useState(false);
  useEffect(()=>{
    const getHasAssignment = async()=>{
      try {
        const jobid = candidate.id_jobrole;
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/hasAssignment/${jobid}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setHasAssignment(response.data.hasAssignment);
        if(response.data.hasAssignment){
          setAssignments(response.data.assignment);
          
        }

        
      } catch (error) {
        console.error(error);
      }
    }

    getHasAssignment();
  }, [])

  //update assignment score
  const [updateAssignment, setUpdateAssignment]= useState();
  const [assignmentScore, setAssignmentScore]= useState(0);
  const handleChangeAssignmentScore = async (assignmentId)=>{
    try {
 
      const candidateid= candidate.id_candidate;
      const jobid=candidate.id_jobrole;
      if((assignmentScore > 100) || (assignmentScore < 0 )){
        swal(lang.menu.scoreCorrect, {
          icon: "error",
        });
        return;
      }
      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/score/${candidateid}/${jobid}/${assignmentId}`, {
        assignmentScore : parseFloat(assignmentScore)
      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }})

      if(response.data.candidate){
        assignments_scores = response.data.candidate.detailed_assignment_score;
        candidate.assignment_score = parseFloat(response.data.candidate.assignment_score);
        candidate.detailed_assignment_score = assignments_scores;
        localStorage.setItem('candidateDetails',  JSON.stringify(candidate));
      }

      setUpdateAssignment(null);
      setAssignmentScore(0);

    } catch (error) {
      console.error(error);
    }
  }


  //Interview Management
  const [recruiters, setRecruiters] = useState([]);

  const [showModalInterview, setShowModalInterview] = useState(false); 
  const [selectedRecruteurs, setSelectedRecruteurs] = useState([]);
  const [dateTime, setDateTime] = useState('');
  const [meetLink, setMeetLink] = useState('');

  const handleOpenForm = () => setShowModalInterview(true);
  const handleCloseForm = () => setShowModalInterview(false);
  useEffect(async()=>{
    try{
      const responsejob = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/read/${candidate.id_jobrole}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
const jobDetails=responsejob.data[0]
console.log("jobDetails :",jobDetails)

      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
          console.log("usrs :",response.data)
          const allUsers=response.data
          const filterRecruiter=allUsers.filter(user =>user.companyID===jobDetails.companyid && (user.userRoleID===3 ||user.userRoleID===2||user.userRoleID===1))
          setRecruiters(filterRecruiter)
          console.log("recruiter :",filterRecruiter)

    }
    catch(error){
      console.error(error);
    }
  },[])

  const handleRecruteurChange = (selectedOptions) => {
    // Met à jour `selectedRecruteurs` avec les IDs des recruteurs sélectionnés
    setSelectedRecruteurs(selectedOptions ? selectedOptions.map((option) => option.value) : []);
  };
  
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log('Recruteurs sélectionnés:', selectedRecruteurs);
    console.log('Date et heure de la réunion:', dateTime);
    console.log('Lien du Google Meet:', meetLink);

   const dataInterview={
    candidate_id:candidate.id_candidate,
    recruiter_ids:selectedRecruteurs,
    date_time:dateTime,
    jobrole_id:candidate.id_jobrole,
    status:"pending",
    lien:meetLink,
   }
   console.log('dataInterview:', dataInterview);
   const response = await axios.post(
    `${process.env.REACT_APP_APP_DOMAIN}/api/interview/Createinterviews`,
    dataInterview, 
    {
      headers: {
      Authorization: `Bearer ${token}`,
      }}
  );
    handleCloseForm();
  };
  
  return (
    <div className='CandidateJob'>
         <Card className='testet'>
         <Card.Body>
         <Row className="d-flex align-items-center">
  {/* Colonne avec bouton Retour et email */}
  <Col xl={3} className="d-flex align-items-center">
    <button
      className="back-button"
      onClick={() => history.goBack()}
      style={{ height: "100%", width: "auto" }}
    >
      <span style={{ marginRight: "5px" }}>
        <IoChevronBack />
      </span>
    </button>
    
    <div className="ms-2">
      {candidate.FirstName !== "N/A" ? (
        <strong style={{ fontSize: "180%" }}>
          {candidate.FirstName} {candidate.LastName}
        </strong>
      ) : (
        <></>
      )}
      <br />
      <span>
        <MdOutlineAlternateEmail size={17} /> {candidate.email}
      </span>
    </div>
  </Col>
  
  {/* Colonnes avec les trois autres boutons alignés à droite */}
  <Col xl={9} className="d-flex justify-content-end">
    <Button
      style={{ backgroundColor: "transparent", border: "0", color: "black", marginRight: "10px" }}
      onClick={() => handlePDFDownload()}
      title={`Download results PDF`}
    >
      <BsFiletypePdf size={23} />
    </Button>

    <Button
    onClick={handleOpenForm}
      style={{ backgroundColor: "transparent", border: "0", color: "black", marginRight: "10px" }}
    >
      <SiGooglemeet size={23} />
    </Button>

    <Button
      className="border-0"
      style={{ backgroundColor: "transparent", color: "black" }}
      title={`Send email`}
      onClick={sendEmailToCandidate}
    >
      <MdOutlineEmail size={23} />
    </Button>
  </Col>
</Row>


            
         
            
          </Card.Body>
        </Card>
        <Card>
            <Card.Body >
            <Row>
  <Col xl={2}>
  <strong style={{ fontSize: '160%' }}>{lang.menu.assessment}</strong> 
  <ReactStars
  count={5}
  value={Math.round(parseFloat(candidate.ratingJobSum))} 
  size={24}
  activeColor="#74188D"
  half={true}
  edit={false}
  
/>
  </Col>
  <Col xl={2}>
  <h4> {candidate.jobName} </h4>
  </Col>
  <Col xl={1} className="ms-auto">

  <Button className="border-0" style={{backgroundColor:"#8cd6c8d0"}} title={` Reject  `}
  onClick={sendEmailToCandidateReject}
  ><SlUserUnfollow size={23}/></Button>

  </Col>
</Row>
<br/><br/>
<Row>
    <Col xl={3}>
        <h4>Invited</h4>
        <p>{formatCalendarDate(candidate.createdAt)}</p>
        {candidate.Personnality ?(
        <>
        <h4>Personnality</h4>
        <p>{candidate.Personnality} </p>
        </> ) : (
    <></>
  )}
<h4>{lang.menu.hiringStage}  </h4>
<p>
  {getCandAcceptStatus(candidate.candAccept)}
</p>


{reference && reference.comment!=null ?(
< div>
  <h4>Reference </h4>
<strong>{lang.menu.ref} : </strong><p>{reference.nameRef}</p>
<strong>Note :  </strong><p>{reference.note}</p>

<strong>{lang.menu.comment} : </strong><p>{reference.comment}</p>
</div>)  : (
    <></>
  )}
    </Col>
    <Col xl={5}>
    
                
        <Card style={{backgroundColor:"#8cd6c8d0"}}>
            <Card.Body>
            {candidate.note.length>0 || (candidate.noteTech.length>0 || candidate.noteCoding.length>0) ||  candidate.assignment_score >= 0 ?(<>
                            <Row>
        <Col xl={11}>
{lang.menu.scoringMeth}
<h5>{lang.menu.Percentile}</h5>
</Col>
                </Row>
            
 <span style={{ fontSize: '90%' ,fontWeight: 'bold'}}>{lang.menu.Testscores}</span>
 {candidate.note ? (
  candidate.note.map((techPsycho) => {
    const codingTest = techPsycho.note;
    const totalTechNote = (codingTest * 100) / 10;
    const roundedTotalTechNote = Math.round(totalTechNote); 

    const testName = getTestPsychoName(techPsycho.idtest);

    return (
      <div key={techPsycho.idtest}>
        <Button style={{ backgroundColor: "#ffffff", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: "black" }}
          onClick={() => handleButtonClick(candidate.note)}>
          <span style={{ textAlign: "left" }}><FaAngleDown />{lang.menu.psychoResult}</span>
          <span style={{ textAlign: "right" }}>{roundedTotalTechNote} %</span>
        </Button>
        <br />
        {showDetails2 && (
          <Card>
            <Card.Body>
              {testPassedPsycho.map(test => (
                <Row key={test.testName}> 
                  <Col xl={8}><strong>Test :</strong> {test.testName}</Col>
                  <Col><strong>Note :</strong> {Math.round(test.note * 10)} % </Col> 
                </Row>
              ))}
            </Card.Body>
          </Card>
        )}
      </div>
    );
  })
) : (
  <></>
)}
<br/>
{(candidate.noteTech.length > 0 || candidate.noteCoding.length > 0) && (
  <div style={{ marginBottom: '20px', maxHeight: '100%' }}>
   {candidate.noteTech.length > 0 && candidate.noteCoding.length > 0 ? (
  <>
    {(() => {
      const totalTechNote = candidate.noteTech.reduce((acc, techTest) => {
        return acc + (parseFloat(techTest.noteTech) || 0);
      }, 0);

      const totalCodingNote = candidate.noteCoding.reduce((acc, codingTest) => {
        return acc + (parseFloat(codingTest.noteCoding) || 0);
      }, 0);

      const averageGlobalNote = ((totalTechNote/candidate.noteTech.length + totalCodingNote/candidate.noteCoding.length) / 2) * 100 / 10;
      const roundedGlobalNote = Math.round(averageGlobalNote);

      return (
        <div>
          <Button
            style={{
              backgroundColor: "#ffffff",
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: "black"
            }}
            onClick={() => handleButtonClick1(candidate.noteTech, candidate.noteCoding)}
          >
            <span style={{ textAlign: "left" }}>
              <FaAngleDown /> {lang.menu.techResult}
            </span>
            <span style={{ textAlign: "right" }}>{roundedGlobalNote} %</span>
          </Button>
          <br />
          {showDetails && (
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={8}><strong>{lang.menu.globalMCQ} :</strong></Col>
                  <Col><strong>{Math.round((totalTechNote/candidate.noteTech.length)*100/10)} %</strong></Col>
                </Row>
                {testPassedTech.map(test => (
                  <Row key={test.testName}>
                    <Col xl={8}><strong>Test :</strong> {test.testName}</Col>
                    <Col><strong>Note :</strong>{Math.round((test.noteTech * 100 / 10))} %</Col>
                  </Row>
                ))}
                <br/>
                <Row>
                  <Col xl={8}><strong>{lang.menu.globalCoding}:</strong></Col>
                  <Col><strong>{Math.round((totalCodingNote/candidate.noteCoding.length)*100/10)} %</strong></Col>
                </Row>
                {testPassedCoding.map(test => (
                  <Row key={test.testName}>
                    <Col xl={8}><strong>Test :</strong> {test.testName}</Col>
                    <Col><strong>Note :</strong>{Math.round((test.noteCoding * 100 / 10))} %</Col>
                  </Row>
                ))}
              </Card.Body>
            </Card>
          )}
        </div>
      );
    })()}
  </>
): candidate.noteTech.length > 0 ? (
  <>
  {(() => {
    const totalTechNote = candidate.noteTech.reduce((acc, techTest) => {
      return acc + (parseFloat(techTest.noteTech) || 0);
    }, 0);

   

    const averageGlobalNote = ((totalTechNote/candidate.noteTech.length) * 100) / 10;
    const roundedGlobalNote = Math.round(averageGlobalNote);

    return (
      <div>
        <Button
          style={{
            backgroundColor: "#ffffff",
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: "black"
          }}
          onClick={() => handleButtonClick2(candidate.noteTech)}
        >
          <span style={{ textAlign: "left" }}>
            <FaAngleDown /> {lang.menu.techResult}
          </span>
          <span style={{ textAlign: "right" }}>{roundedGlobalNote} %</span>
        </Button>
        <br />
        {showDetails && (
          <Card>
            <Card.Body>
              <Row>
                <Col xl={8}><strong>{lang.menu.globalMCQ} :</strong></Col>
                <Col><strong>{Math.round((totalTechNote/candidate.noteTech.length)*100/10)} %</strong></Col>
              </Row>
              {testPassedTech.map(test => (
                <Row key={test.testName}>
                  <Col xl={8}><strong>Test :</strong> {test.testName}</Col>
                  <Col><strong>Note :</strong>{Math.round((test.noteTech * 100 / 10))} %</Col>
                </Row>
              ))}
              <br/>
             
            </Card.Body>
          </Card>
        )}
      </div>
    );
  })()}</>
    ) : (
      // Affichage des résultats de codage seulement
     <>
     
     {(() => {
     

      const totalCodingNote = candidate.noteCoding.reduce((acc, codingTest) => {
        return acc + (parseFloat(codingTest.noteCoding) || 0);
      }, 0);

      const averageGlobalNote = (totalCodingNote/candidate.noteCoding.length) * 100 / 10;
      const roundedGlobalNote = Math.round(averageGlobalNote);

      return (
        <div>
          <Button
            style={{
              backgroundColor: "#ffffff",
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: "black"
            }}
            onClick={() => handleButtonClick3(candidate.noteCoding)}
          >
            <span style={{ textAlign: "left" }}>
              <FaAngleDown /> {lang.menu.techResult}
            </span>
            <span style={{ textAlign: "right" }}>{roundedGlobalNote} %</span>
          </Button>
          <br />
          {showDetails && (
            <Card>
              <Card.Body>
                
                <Row>
                  <Col xl={8}><strong>{lang.menu.globalCoding}:</strong></Col>
                  <Col><strong>{Math.round((totalCodingNote/candidate.noteCoding.length)*100/10)} %</strong></Col>
                </Row>
                {testPassedCoding.map(test => (
                  <Row key={test.testName}>
                    <Col xl={8}><strong>Test :</strong> {test.testName}</Col>
                    <Col><strong>Note :</strong>{Math.round((test.noteCoding * 100 / 10))} %</Col>
                  </Row>
                ))}
              </Card.Body>
            </Card>
          )}
        </div>
      );
    })()}
     
     </>
    )}
  </div>
)}




  {hasAssignment && (
    <div style={{ marginBottom: '20px', maxHeight: '100%' }}>
    <Button style={{ backgroundColor: "#ffffff", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,color:"black"}}
    onClick={(e)=>{
      setShowDetailsAssignment(!showDetailsAssignment);
    }}>
<span style={{ textAlign: "left" }}><FaAngleDown /> {lang.menu.AssignmentResult}</span>
<span style={{ textAlign: "right" }} >{candidate.assignment_score ? (Math.round(candidate.assignment_score) + "%") : ("---")} </span>

</Button>
<br/>
{showDetailsAssignment && 
        assignments.length > 0 &&  assignments.map((assignment, index) =>( 
                 <Card> 
                   <Card.Body> 
                    
                   
                   <div key={index}>
                   <Row >
                      <Col xl={8}><strong>Test :</strong> {assignment.name}</Col>
                      {!(updateAssignment === index) ? <Col><strong>Note :</strong>{(assignments_scores && assignments_scores[assignment.id]) ? (Math.round(assignments_scores[assignment.id]) + "%") : ("---")}   </Col>
                      : <Col><strong>Note :</strong>
                      <input
                        className="input-search form-control"
                       value={assignmentScore} onChange={(e)=>{
                        setAssignmentScore(e.target.value)
                      }}/>
                      </Col>}
                   </Row>
                   <br/>
                   <Row style={{textAlign: "center"}}>
                   <div className="test-card-icons">
                  
                
                  { !(updateAssignment === index) ? 
                  <button 
                    style={{ border: "none" }}
                    onClick={()=>{
                      setAssignmentScore((assignments_scores && assignments_scores[assignment.id]) ? assignments_scores[assignment.id] : 0);
                    setUpdateAssignment(index);
                  }}>
                    <i className="fas fa-pencil-alt fa-lg" ></i>
                  </button>
                  
                :  <button 
                   style={{ border: "none" }}
                    onClick={()=>handleChangeAssignmentScore(assignment.id)}>
                  <i className="fas fa-check fa-lg" ></i>
                  </button>
                }
                
                <Link to='/assignment-result' style={{ border: "none" }}>
                <i className="fa fa-file-code fa-lg" ></i>
                  </Link>
               </div>
               

                   </Row>
                   
                   </div>
                                   
                </Card.Body> 
            </Card>
          )
         )}
</div>
    
  )}
  </> ) : (
    <div className='text-center' ><p>{lang.menu.notyet}</p>
    <p>{lang.menu.noTestRes}</p>
    </div>
  )}
            </Card.Body>
        </Card>
    </Col>
    <Col xl={4}>
    <div style={{position:"relative"}} >

    
      {((techTestAntiCheatingReport.length != 0) && testTech) ? 
      (
        <div >
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
      {console.log("check techTestAntiCheatingReport :", techTestAntiCheatingReport)}
{(techTestAntiCheatingReport.technicalTest.length > 1 && 
  techTestAntiCheatingReport.technicalTest.some(test => test.techTestType === "Coding")) && (
  <Button 
    id='prev' 
    variant='success' 
    style={{ position: "absolute", top: "0", left: "0" }} 
    onClick={updateCurrentIndex}
  >
    &lt;
  </Button>
)}


              <h4 style={{textAlign:"center"}}>{lang.menu.anticheetingMon}</h4>

              {(techTestAntiCheatingReport.technicalTest.length > 1 && 
  techTestAntiCheatingReport.technicalTest.some(test => test.techTestType === "Coding")) && <Button id='next' 
                  variant='success' 
                  style={{ position:"absolute", top:"0", right:"0"}} 
                  onClick={updateCurrentIndex}>
                     &gt; 
                </Button> }
      </div>
      <div key={currentIndex} style={{paddingTop: "10%"}}>
        <Row>
      <Col xl={5}>  <span>{lang.menu.TestName}</span></Col>
      <Col>
    <strong>{testTech.TestTech.name}</strong>
      </Col>
    </Row>
    <Row>
      <Col xl={5}>  <span>{lang.menu.testType}</span></Col>
      <Col>
    <strong>{(testTech.techTestType === "QCM") ? lang.menu.QCM : testTech.techTestType }</strong>
      </Col>
    </Row>
      <Row>
      <Col xl={5}>  <span>{lang.menu.deviceused}</span></Col>
      <Col>
    <strong>{testTech.deviceType}</strong>
      </Col>
    </Row>
   
    <Row>
      <Col xl={5}>  <span>{lang.menu.location}</span></Col>
      <Col>
      { (testTech.city && testTech.country) ? (
      <strong>{testTech.city + "," + testTech.country}
</strong> ):(
  <div className='text-center' ><p>{lang.menu.accessdisabled}</p>    </div>
     ) }
      </Col>
      
    </Row>
     
    <Row>
      <Col xl={10}>  <span>{lang.menu.ipAdress}</span></Col>
      <Col>
      {!testTech.Attempt_to_access_from_another_IP_address ? <FcCheckmark /> : <FcCancel/>}
</Col>
</Row>
    <Row>
      <Col xl={10}>  <span>{lang.menu.webcamEnable}</span></Col>
      <Col>
      {testTech.isCameraOpen ? <FcCheckmark /> : <FcCancel/>} 
</Col>
    </Row>
    <Row>
      <Col xl={10}>  <span>{lang.menu.mouseEnable}</span></Col>
      <Col>  {testTech.mouseAlwaysInWindow ? <FcCheckmark /> : <FcCancel/>}
      </Col>
    </Row>
    {/*<Row>
      <Col  xl={10}>  <span>{lang.menu.fullScreenEnable}</span></Col>
      <Col>  <FcCheckmark /></Col>
    </Row>*/}
    
{/* <img className='p-1 ' src="https://images.tou.tv/v1/synps-src/show/perso/src_lacandidate_ott_program_v01.jpg?impolicy=ott&im=Resize=1200&quality=75" width="100%" height="45%"/>
*/}   
{((imageUrls.length > 0) && (testTech.TestTech.takeScreenShots) && (testTech.TestTech.takeSnapShots)) && 
<Form >
        <div key={`inline-type`} className="mb-3" style={{paddingTop:"5%"}}>
          <Form.Check
            inline
            label="SnapShots"
            name="ImageType"
            type='radio'
            id="snapshots"
            checked={displaySnapshots}
            onChange={handleChangeImages}
          />
          <Form.Check
            inline
            label="ScreenShots"
            name="ImageType"
            type='radio'
            id="screenshots"
            checked={!displaySnapshots}
            onChange={handleChangeImages}
          />
          
        </div>
    </Form>}

    <div  >
    <Carousel  activeIndex={Number(currentIndexImage)} onSelect={updateCurrentIndexImage} variant="dark">
      {imageUrls.map((imageUrl, indexImage) => (
        
          <Carousel.Item key={indexImage}>
            
                  <img onClick={handleModal} className='p-1 '  src={`${process.env.REACT_APP_APP_DOMAIN}/api${imageUrl}`} alt={`Image ${indexImage}`} width="100%" height="45%"/>

          </Carousel.Item>
    ))}
      </Carousel>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button 
        onClick={resendTest} 
        disabled={testTech.retakeTheTest || sent}
        >
          {lang.menu.resendTest}
        </Button>
        </div>
   </div>
    </div>) : (
      <div className='text-center' ><p>          {lang.menu.notyet}
      </p>
      <p>          {lang.menu.resendTest}
</p>
      </div>
    )
    
    }
    </div>
    </Col>
</Row>

            </Card.Body>
            <Card.Footer ref={footerRef}>
  <Row>
    <Col className="ml-6">
      <h4>{lang.menu.urrating}</h4>
      <p>{lang.menu.ratingRec}</p>
      <ReactStars
        count={5}
        onChange={ratingcandidateChanged}
        size={24}
        activeColor="#74188D"
      />
    </Col>
    <Col>
   <textarea id="myTextarea" className="form-control" placeholder="Add your private notes here (auto-saved) ...." onChange={handleContentChange}></textarea>
     </Col>
     
  </Row>
  <Button
      type="submit"
      className="btn btn-primary btn sweet-confirm"
      onClick={handleSubmitRating}
      disabled={!isContentFilled}
    >
      {lang.menu.submit}
    </Button>
    
</Card.Footer>

        </Card>
        {ratingsList && ratingsList.recruteurRateArray != null ? (
  <Card>
    <Card.Body className="ui mini comments">
  <h2>{lang.menu.commentRecruter}:</h2>

  <Comment.Group>
    {ratingsList &&
      ratingsList.contentArray &&
      ratingsList.contentArray.map((comment, index) => (
        <Comment key={index}>
          <Row  style={{
                  backgroundColor: '#8cd6c8', 
                  borderRadius: '10px',
                  padding: '10px' 
                }}>
     <Col xl={1}>
  <img
    src="https://react.semantic-ui.com/images/avatar/small/steve.jpg"
    style={{ width: '100%', height: '100%', borderRadius: '50%' }}
    alt="Avatar"
  />
</Col>


            <Col>
              <CommentContent>
                <div  style={{
                  backgroundColor: '#ffffff',
                  borderRadius: '15px',
                  padding: '10px'
                }}>
                <CommentAuthor as="a">
                  <strong>
                    {users.find(
                      (user) => user.uid === ratingsList.recruteurRateArray[index]
                    )?.FirstName}{' '}
                    {
                      users.find(
                        (user) => user.uid === ratingsList.recruteurRateArray[index]
                      )?.LastName || users.find(
                        (user) => user.uid === ratingsList.recruteurRateArray[index]
                      )?.email
                    }
                  </strong>
                </CommentAuthor>
                <CommentMetadata>
                  <span style={{ color: '#65676B', fontSize: '12px', marginLeft: '10px' }}>
                    {lang.menu.todayTime} 5:42PM
                  </span>
                </CommentMetadata>
                <CommentText style={{ marginTop: '5px', fontSize: '14px' }}>
                  {comment}
                </CommentText>
                </div>
                <CommentActions>
                  <span style={{ color: '#65676B', marginRight: '15px', cursor: 'pointer' }}>
                  <SlLike /> {"  "}
                  {lang.menu.like}
                  </span>
                  <span style={{ color: '#65676B', cursor: 'pointer' }}>
                  <RiReplyLine /> {"  "}

                    {lang.menu.Repondre}
                  </span>
                </CommentActions>
              </CommentContent>
            </Col>
          </Row>
          <br/>
        </Comment>
      ))}
  </Comment.Group>
</Card.Body>

  </Card>
) : null}

        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


        <Modal show={showModal} onHide={handleModal} centered>
        
        <Modal.Body>
        <Carousel  activeIndex={Number(currentIndexImage)} onSelect={updateCurrentIndexImage} >
      {imageUrls.map((imageUrl, indexImage) => (
        
          <Carousel.Item key={indexImage}>
            
                  <img  className='p-1 '  src={`${process.env.REACT_APP_APP_DOMAIN}/api${imageUrl}`} alt={`Image ${indexImage}`} width="100%" height="100%"/>

          </Carousel.Item>
    ))}
      </Carousel>
        </Modal.Body>
        
      </Modal>

      <Modal show={showModalInterview} onHide={handleCloseForm}>
  <Modal.Header closeButton>
    <Modal.Title>Planifier une réunion Google Meet</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handleSubmit}>
      {/* Sélectionner les recruteurs */}
      <Form.Group className="mb-4">
        <Form.Label style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Sélectionner les recruteurs
        </Form.Label>
        

        <Select
  isMulti
  placeholder="Select recruiter"
  value={recruiters
    .filter((recruiter) => selectedRecruteurs.includes(recruiter.uid))
    .map((recruiter) => ({
      value: recruiter.uid,
      label: recruiter.email,
    }))}
  onChange={handleRecruteurChange}
  options={recruiters.map((recruiter) => ({
    value: recruiter.uid,
    label: recruiter.email,
  }))}
  styles={{
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
      lineHeight: "40px",
      borderRadius: "0",
    }),
  }}
/>

    
      </Form.Group>

      {/* Date et Heure de la réunion */}
      <Form.Group className="mb-4">
        <Form.Label style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Date et Heure de la réunion
        </Form.Label>
        <Form.Control
          type="datetime-local"
          value={dateTime}
          onChange={(e) => setDateTime(e.target.value)}
          style={{
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ced4da',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            fontSize: '15px',
            color: '#495057',
          }}
        />
      </Form.Group>

      {/* Lien du Google Meet */}
      <Form.Group className="mb-4">
        <Form.Label style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Lien du Google Meet
        </Form.Label>
        <Form.Control
          type="url"
          placeholder="Entrez le lien Google Meet"
          value={meetLink}
          onChange={(e) => setMeetLink(e.target.value)}
          style={{
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ced4da',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            fontSize: '15px',
            color: '#495057',
          }}
        />
      </Form.Group>

      {/* Bouton d'enregistrement */}
      <div className="d-flex justify-content-center">
        <Button
          variant="primary"
          type="submit"
          style={{
            backgroundColor: '#6f42c1',
            borderColor: '#6f42c1',
            padding: '10px 20px',
            borderRadius: '25px',
            fontSize: '16px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          Enregistrer la réunion
        </Button>
      </div>
    </Form>
  </Modal.Body>
</Modal>

    </div>
  );
};

export default CandidateDetails;