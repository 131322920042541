import React, { useState, useEffect, useRef } from "react";
import "./technical.css";
import Landing from "./Components/Landing";
import {
  Col,
  Row,
  Accordion,
  Card,
  Badge,
  Table,
  Button,
} from "react-bootstrap";
import { BsArrowsFullscreen } from "react-icons/bs";
import data from "./data.json";
import {  useScreenshot } from "use-react-screenshot";
import axios from "axios";
import translations from "../AppsMenu/Candidate/mulitlingue";

import { setPlatform } from "@capacitor/core";

function Tech() {
  const token=localStorage.getItem("token");

  // get user id
  const userDetailsString = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(userDetailsString);
  const selectedCandidate = userDetails ? userDetails.localId : null;

  //test Type
  const testType= "Coding";
  
  const [topicDetails, setTopicDetails] = useState();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const pathname = window.location.pathname;
  const idtopic = pathname.split("/").pop();

  const [showTestPolicy, setShowTestPolicy] = useState(true);
  const [showAccessDenied, setShowAccessDenied] = useState(false);

  //permissions
  const [allowCopyPaste,setAllowCopyPaste]= useState(true);
  const [takeScreenShots,setTakeScreenShots]= useState(true);
  const [takeSnapShots,setTakeSnapShots]= useState(true);
  const [numberOfScreenShots, setNumberOfScreenShots] =useState();
  const [numberOfSnapShots, setNumberOfSnapShots] = useState();

  const [cameraOn, setCameraOn]= useState(false);

  //const [isItBoth, setIsItBoth]= useState(false);

  //get permissions of the test
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const testid  = localStorage.getItem("testId");

        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/technical/permissions/testtech/${testid}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        const permissions= response.data.permissionsTestTech;

        setAllowCopyPaste(permissions.allowCopyPaste);
        setTakeScreenShots(permissions.takeScreenShots);
        setTakeSnapShots(permissions.takeSnapShots);
        setNumberOfScreenShots(permissions.numberOfScreenShots);
        setNumberOfSnapShots(permissions.numberOfSnapShots);

       // setIsItBoth(permissions.type === "Both");

      } catch (error) {
        console.error(error);
      }
    };
 
    fetchPermissions();
  }, []);


  const [screenshot, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const [elementRef, setElementRef] = useState(null);
  const [ideRef, setIdeRef]= useState(null);

  // language
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  useEffect(() => {
    const fetchTopicDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/topic/getTopicById/${idtopic}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setTopicDetails(response.data);

        localStorage.setItem('questionsNbrCoding', response.data.Timer);
      } catch (error) {
        console.error(error);
      }
    };
 
    fetchTopicDetails();
  }, []);


// taking screenShots and send it to the backend
  const downloadScreen = () => {
    try{
      

      if (elementRef && takeScreenShots) {
      takeScreenshot(elementRef).then((imageData) => {
        const byteCharacters = atob(imageData.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/jpeg" });

        
        const userid  = selectedCandidate;
        const testid  = localStorage.getItem("testId");
        const jobid  = localStorage.getItem("jobRoleId");


        // Create FormData and append the blob
        const formData = new FormData();
        formData.append("screenshot", blob, `${Date.now()}`);
        

        // Make a POST request to your backend
        axios
      .post(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/screenshot/${userid}/${jobid}/${testid}/${testType}`, formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        console.log("backendRes " +JSON.stringify( res.data));
      })
      .catch((er) => console.log(er));
      });
    } else if(!elementRef){
      console.error("Ref is null");
    }}catch(error){
      console.log(error);
    }
  };

  //timer
  const testTime  = localStorage.getItem("questionsNbrCoding");

  useEffect(() => {
    if(numberOfScreenShots && numberOfScreenShots !== 0){
      downloadScreen();
      if(numberOfScreenShots > 1){
    const intervalId = setInterval(downloadScreen, (testTime/(numberOfScreenShots - 1))*1000);
    return () => clearInterval(intervalId);
    }}
  }, [elementRef, takeScreenShots, numberOfScreenShots]);

 /* 
  useEffect(() => {
    const foundTopic = data.find((topic) => topic.id === parseInt(idtopic, 10));

    if (foundTopic) {
      setTopicDetails(foundTopic);
      localStorage.setItem('questionsNbrCoding', foundTopic.Timer);
      console.log("foundTopic :", foundTopic);
    }
  }, [idtopic]);
  */

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);


  const enterFullScreen = (ref) => {
    const element = ref;
    
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  

  const handleFullScreenButtonClick = (ref) => {
    setIsFullScreen(true);
    enterFullScreen(ref);
  };



 const handleExit = () => {
  const jobid  = localStorage.getItem("jobRoleId");
    if(isFullScreen){
    exitFullScreen();
    setIsFullScreen(false);
    }
    saveFeaturesDetection();
    const redirectionPath = `/JobRoleTests/${jobid}`;
    
    window.location.href = redirectionPath;

  }; 



  const handleCancel2 = () => {
    setShowTestPolicy(false);
    handleExit();
  };

  
  useEffect(() => {
    if(!allowCopyPaste){
      const handlePaste = (event) => {
      event.preventDefault();
    };
    const handleCopy = (event) => {
      event.preventDefault();
    };

    const handleCut = (event) => {
      event.preventDefault();
    };

    window.addEventListener("paste", handlePaste);
    window.addEventListener("copy", handleCopy);
    window.addEventListener("cut", handleCut);
    return () => {
      window.removeEventListener("paste", handlePaste);
      window.removeEventListener("copy", handleCopy);
      window.removeEventListener("cut", handleCut);
    };
  }
  }, [allowCopyPaste]);


  async function getUserLocation() {
    
    return new Promise((resolve, reject) => {
      var latitude;
      var longitude;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    resolve({ latitude, longitude });
                },
                error => {
                    console.error('Error getting geolocation:', error);
                    resolve({ latitude, longitude });
                }
            );
        } else {
            console.error('Geolocation is not available');
            resolve({ latitude, longitude });
        }
    });
}

const saveDeviceFeatures = async ()=>{
  try{
    //devices features
    const deviceType = window.innerWidth< 480 ? true : false;
    const os= navigator.platform;
    

    const {latitude, longitude}= await getUserLocation();

    let city;
    let country;
    if(latitude && longitude)
      {
       

       const location = await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude='${latitude}'&longitude='${longitude}'&localityLanguage=en`)
      
       
           city = location.data.city;
           country = location.data.countryName;
   
      }

      const body= {city, country, deviceType, os};

        const userid  = selectedCandidate;
        const pathname = window.location.pathname;
        const testid = pathname.split("/").pop();
        const jobid  = localStorage.getItem("jobRoleId");
        const test_id  = localStorage.getItem("testId");

      const response= await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/device/${userid}/${jobid}/${test_id}/${testType}/${testid}`,{
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept': 'application/json',
        "Content-type": "application/json"
      },
      body: JSON.stringify(body)
    })
      
    

  
}catch(err){
  console.error(err);
}}


  // Initialize a boolean flag to track mouse position
  const [cursorInWindow, setCursorInWindow] = useState(true);
  const [entered, setEntered]= useState(false);
 useEffect(()=>{
    
    //Detect when the mouse leaves the window
      const handleMouseOut = (event) => {
    if (entered && (document.readyState === 'complete') && (!event.relatedTarget)) {
        console.log('Mouse left the window.');
        setCursorInWindow(false);
      }

      //to make sure to start detecting the mouse movement when he access the test
      if(!entered && elementRef){
        setEntered(true);
      }
    };

    window.addEventListener('mouseout', handleMouseOut);
    
    return () => {
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, [entered, elementRef]);



  //save mouse detection and camera status
  const saveFeaturesDetection = async()=>{
    try {
      const body ={cursorInWindow, cameraOn};

      const userid  = selectedCandidate;
      const pathname = window.location.pathname;
      const testid = pathname.split("/").pop();
      const jobid  = localStorage.getItem("jobRoleId");

      const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/featuresDetection/${userid}/${jobid}/${testid}/${testType}`,{
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          "Content-type": "application/json"
        },
       
        body: JSON.stringify(body)
      })
      
    } catch (error) {
      console.error(error);
    }
  }

  //take snapshots
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const takePicture = async () => {
    try {
      if(elementRef && takeSnapShots){
        
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraOn(true);
      
      videoRef.current.srcObject = stream;
      videoRef.current.play();

      videoRef.current.onloadedmetadata = async () => {
        
        const context = canvasRef.current.getContext('2d');

        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;

        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        const image = canvasRef.current.toDataURL('image/jpeg');
       

        // Convert the data URL to a Blob
        const blob = dataURLToBlob(image);

        // Send the image to the backend
        //sendImageToBackend(blob);

      const formData = new FormData();
      formData.append('snapshot', blob, `${Date.now()}`);
  
      const userid  = selectedCandidate;
      const testid  = localStorage.getItem("testId");
      const jobid  = localStorage.getItem("jobRoleId");
  
      await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/snapshot/${userid}/${jobid}/${testid}/${testType}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        console.log("backendRes " +JSON.stringify(res.data));
      })
      .catch((er) => console.log(er));
      
      //track.stop(); // Stop the camera track to prevent memory leaks
      // Stop all video tracks
      stream.getTracks().forEach(track => track.stop());
    }
  };
    } catch (error) {
      setCameraOn(false);
      console.error('Error taking picture: ', error);
    }
  };

  useEffect(() => {
  
    if(numberOfSnapShots && numberOfSnapShots !== 0){
      takePicture();
      if(numberOfSnapShots > 1){const interval = setInterval(() => {
    takePicture();
    
  }, (testTime/(numberOfSnapShots - 1))*1000);  
  return () => clearInterval(interval);
}
}
}, [elementRef ,takeSnapShots, numberOfSnapShots]);


  const handleAccessDenied = ()=>{
    setShowTestPolicy(false);
    setShowAccessDenied(true);
  }

  const [confirmTestPolicy, setConfirmTestPolicy]= useState(false);
  const checkAccess = async ()=>{
    try {

      const userid  = selectedCandidate;
      const pathname = window.location.pathname;
      const cod = pathname.split("/").pop();
      const jobid  = localStorage.getItem("jobRoleId");

      const testid  = localStorage.getItem("testId");


      const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/candidate/${userid}/${jobid}/${testid}/${testType}/${cod}`,{
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json"
        },
      })
      const jsonData = await response.json();
      
      if(jsonData.access){
        setShowTestPolicy(false);
        setConfirmTestPolicy(true);
        saveDeviceFeatures();
      }/*else if(!jsonData.access && isItBoth){
        setShowTestPolicy(false);
        setConfirmTestPolicy(true);
      }*/else{
        handleAccessDenied();
      }
      
    } catch (error) {     
    }
  }

  return (
    <div>
    
          {confirmTestPolicy &&
          <Card ref={(ref) => setElementRef(ref)}>
            <video ref={videoRef} style={{ display: 'none' }}  />
            <canvas ref={canvasRef} style={{ display: 'none' }} ></canvas>
            <Row>
              <Col>
                {" "}
                <span style={{ fontFamily: "Poppins", fontSize: "230%" }}>
                  {lang.menu.testTechnique}{" "}
                </span>
              </Col>
              <Col>
                <Button
                  onClick={()=>handleFullScreenButtonClick(ideRef)}
                  variant="primary badge-xl light"
                  style={{ marginLeft: "90%", marginBottom: "2%" }}
                >
                  <BsArrowsFullscreen />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Card style={{ fontFamily: "Poppins" }}>
                  <Card.Header className="d-block">
                    <Card.Title>
                      {topicDetails ? topicDetails.name : ""}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Accordion
                      className="accordion accordion-danger-solid"
                      defaultActiveKey="0"
                    >
                      <div className="accordion-item">
                        <span className="accordion-header-text">
                          <Badge
                            variant="primary badge-xl light"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {lang.menu.description} :
                          </Badge>
                          <p style={{ color: "black", textAlign: "left" }}>
                            {topicDetails ? topicDetails.description : ""}
                          </p>
                        </span>
                        <span className="accordion-header-text">
                          <Badge
                            variant="primary badge-xl light"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {lang.menu.exemple} :
                          </Badge>
                        </span>
                      </div>
                    </Accordion>
                    <Table
                      responsive
                      hover
                      className="header-border verticle-middle"
                      style={{ backgroundColor: "#e9f9fd" }}
                    >
                      <thead>
                        <th scope="col">{lang.menu.input}</th>
                        <th scope="col"> {lang.menu.expectedOutPut}</th>
                      </thead>
                      <tbody className="pt-4">
                        <tr>
                          <td>{topicDetails ? topicDetails.input1 : ""}</td>
                          <td>
                            {topicDetails ? topicDetails.expectedOutput1 : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{topicDetails ? topicDetails.input2 : ""}</td>
                          <td>
                            {topicDetails ? topicDetails.expectedOutput2 : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{topicDetails ? topicDetails.input3 : ""}</td>
                          <td>
                            {topicDetails ? topicDetails.expectedOutput3 : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={8} ref={(ref) => setIdeRef(ref)}>
              
                <Landing 
                  idTopic={topicDetails ? topicDetails.id : ""}
                  input1={topicDetails ? topicDetails.input1 : ""}
                  expectedOutput1={
                    topicDetails ? topicDetails.expectedOutput1 : ""
                  }
                  input2={topicDetails ? topicDetails.input2 : ""}
                  expectedOutput2={
                    topicDetails ? topicDetails.expectedOutput2 : ""
                  }
                  input3={topicDetails ? topicDetails.input3 : ""}
                  expectedOutput3={
                    topicDetails ? topicDetails.expectedOutput3 : ""
                  }
                  LanguageId={topicDetails ? topicDetails.LanguageId : ""}
                  Squelette={topicDetails ? topicDetails.Squelette : ""}
                  ExtentionCode={topicDetails ? topicDetails.ExtentionCode : ""}
                  ExitTest={handleExit}
                  allowCopyPaste={allowCopyPaste}
                />
           
              </Col>
            </Row>
          </Card >

}
       
     

      
{showTestPolicy && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h1>{lang.menu.TestPolicy}</h1>
          <ul>
            <li>
              {lang.menu.TestPolicyComment}
            </li>
          </ul>
          <Button variant="success" onClick={checkAccess}>{lang.menu.Confirm}</Button>
          <Button variant="danger" onClick={handleCancel2}>{lang.menu.Cancel}</Button>
        </div>
      )}

      
       {showAccessDenied && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h1 style={{ color: "#8CD6C8" }}>{lang.menu.AccessDenied} !</h1>
          <p>{lang.menu.AccessDeniedComment}</p>
          <Button variant="danger" onClick={()=>{setShowTestPolicy(true); setShowAccessDenied(false);}}>{lang.menu.Cancel}</Button>
        </div>
      )}
    </div>
  );
}

export default Tech;
