import React, { useState, useEffect,Fragment } from "react";
import axios from "axios";
import { Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import translations from"../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";

function SubRows({ jobsList }) {
  const [selectedLang, setSelectedLang] = useState(null);
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
      const storedLang = localStorage.getItem('selectedLang');
      if (storedLang) {
        const parsedLang = JSON.parse(storedLang);

        if (parsedLang.language === 'Français') {
          return translations.fr;
        }
      }
      return translations.en;
    });
  const [rows, setRows] = useState([]);
  const [testPassed, setTestPassed] = useState(0);
  
  useEffect(() => {
    console.log("testPassed : ",testPassed)
  }, [testPassed]);
  useEffect(() => {
    const fetchRows = async () => {
      const getTestPsychoName = async (testId) => {
        try {
     


          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestById/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data.test_name;
          setTestPassed(testPassed+1)

          return testName;
        } catch (error) {
          console.error(`Error fetching test psycho name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };
      const getTestTechName = async (testId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getTestById/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data.test_name;
          setTestPassed(testPassed+1)

          return testName;
        } catch (error) {
          console.error(`Error fetching test tech name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };
      const getTestCodingName = async (testId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getTopicById/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data;
          setTestPassed(testPassed+1)

          return testName;
        } catch (error) {
          console.error(`Error fetching test Coding name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };
      const getTestPerName = async (testId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/getTestPerById/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data[0].name;
          setTestPassed(testPassed+1)

          return testName;
        } catch (error) {
          console.error(`Error fetching test per name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };
      const formatNote = async (note) => {
        if (note === 'NULL') {
          return <span style={{ color: 'grey' }}>NULL</span>;
        }
      
        const parsedNote = JSON.parse(note);
        const { idtest, note: testNote } = parsedNote[0];
        const formattedNote = parseFloat(testNote).toFixed(2);
      
        return (
          <div>
            
            Test :  <Link to={`/TestPsychoTchniqueDetails/${idtest}`}>{await getTestPsychoName(idtest)}</Link>
            <div>{lang.menu.note} {formattedNote}</div>
          </div>
        );
      };
      
      const getCandAcceptStatus = (candAccept) => {
        switch (candAccept) {
          case 2:
            return (
              <Fragment>
                <i className="fa fa-circle text-success me-1"></i> {lang.menu.passUser}
              </Fragment>
            );
          case 1:
            return (
              <Fragment>
                <i className="fa fa-circle text-warning me-1"></i> {lang.menu.pendingUser}
              </Fragment>
            );
          case 0:
            return (
              <Fragment>
                <i className="fa fa-circle text-dark me-1"></i> {lang.menu.appliedUser}
              </Fragment>
            );
          default:
            return "";
        }
      };
      
          const formatNoteTech = async (note) => {
            if (note === 'NULL') {
              return <span style={{ color: 'grey' }}>NULL</span>;
            }
          
            const parsedNote = JSON.parse(note);
            const { idtest, noteTech: testNote } = parsedNote[0];
            const formattedNote = parseFloat(testNote).toFixed(2);
          
            return (
              <div>
                
                Test :  <Link to={`/TechQCMDetails/${idtest}`}>{await getTestTechName(idtest)}</Link>
                <div>{lang.menu.note} {formattedNote}</div>
              </div>
            );
          };
          const formatNoteCoding = async (note) => {
            if (note === 'NULL') {
              return <span style={{ color: 'grey' }}>NULL</span>;
            }
          
            const parsedNote = JSON.parse(note);
            const { idtest, noteCoding: testNote } = parsedNote[0];
           
          const topic=await getTestCodingName(idtest) 
            return (
              <div>
       
                Test :   <Link
                 
                        to={
                          topic.compiler === "oneCompiler"
                            ? `technical2/${topic.id}`
                            : `technical/${topic.id}`
                        }
                      
                        
                      >{topic.name}</Link>
                <div>{lang.menu.note} {testNote}</div>
              </div>
            );
          };
          
      const formatNotePer = async(notePer) => {
            if (notePer === 'NULL') {
              return (
                <span style={{ color: 'grey' }}>NULL</span>
              );
            }
          
            const parsedNotePer = JSON.parse(notePer);
            const { idtest, notePer: perData } = parsedNotePer[0];
            const testName = await getTestPerName(idtest);
            const perItems = Object.entries(perData).map(([key, value]) => (
              <div key={key}>
                <div>{key}: {value}</div>
              </div>
            ));
          
            return (
              <div>
                Test :  <Link to={`/page-test-candidate/${idtest}`}> {testName}</Link>
                {perItems}
              </div>
            );
          }; 
          
      try {
        const jobRows = JSON.parse(jobsList);

        const getJobName = async (jobId) => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobTitleById/${jobId}`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }});
            const jobName = response.data;
            return jobName;
          } catch (error) {
            console.error(`Error fetching job name for ID ${jobId}:`, error);
            return "Unknown Job";
          }
        };

        const rows = await Promise.all(jobRows.map(async (job, index) => {
          const jobName = await getJobName(job.id_jobrole);
          console.log("jobName :",jobName.JobTitle)
          const JobTitle=jobName.JobTitle;
          const Deadline=jobName.CloseDate;
          const formattedNote = await formatNote(job.note);
          const formattedNoteTech = await formatNoteTech(job.noteTech);
          const formattedNoteCoding = await formatNoteCoding(job.noteCoding);
          const formattedNotePer = await formatNotePer(job.notePer);
          function formatCalendarDate(date) {
            const options = {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            };
            return new Date(date).toLocaleDateString("en-US", options);
          }
          return (
            <tr key={job.id_jobrole}>
              <td style={{ width: "150px" }}><Link to={`/details/${job.id_jobrole}`}>{JobTitle}</Link></td>      
              <td style={{ width: "150px" }}>{formatCalendarDate(Deadline)}</td>    
              <td style={{ width: "100px" }}>{getCandAcceptStatus(job.candAccept)}</td>
              <td style={{ width: "150px" }}>{formattedNote}</td>
              <td style={{ width: "150px" }}>{formattedNoteTech}</td>
              <td style={{ width: "150px" }}>{formattedNoteCoding}</td>
              <td style={{ width: "200px" }}>{formattedNotePer}</td>
              <td style={{ width: "200px" }}>{formatCalendarDate(job.last_activity)}</td>
            </tr>
          );
        }));
        
        setRows(rows);
        
        setRows(rows);
      } catch (error) {
        console.error("Error parsing jobsList:", error);
        setRows([]);
      }
    };

    fetchRows();
  }, [jobsList]);

  return <>{rows}</>;
}

function CandidatesPerApp() {
  const [candidateAssessment, setCandidateAssessment] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCandidateAssessment(
            data.some(
              (permission) => permission.title === "candidate-assessment"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!candidateAssessment) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidateAssessment]);
  const [candidates, setCandidates] = useState([]);
  const [selectedLang, setSelectedLang] = useState(null);
  const [users, setUsers] = useState([]);
  const [lang, setLang] = useState(() => {
      const storedLang = localStorage.getItem('selectedLang');
      if (storedLang) {
        const parsedLang = JSON.parse(storedLang);

        if (parsedLang.language === 'Français') {
          return translations.fr;
        }
      }
      return translations.en;
    });
    const getUserCompanyID = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const compId = await getUserCompanyID();

        const userResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      setUsers(userResponse.data);
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAllCandidatesWithRepetitions/${compId}/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        console.log("repet : ",response.data.candidates)
        setCandidates(response.data.candidates);
        
      } catch (error) {
        console.error("Error fetching candidates:", error);
      }
    };

    fetchData();
  }, []);
  
  const handleEmailClick = (email) => {
    localStorage.setItem("emailSelected", JSON.stringify(email));
  };
  
  return (
    <> {candidateAssessment ? (  
    <Fragment>
            <PageTitle
        activeMenu={lang.menu.candPerApp}
        motherMenu={lang.menu.dashboard}
      />
    <Card>
    
      <Card.Body>
      <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
             
            <thead style={{ backgroundColor: "#8cd6c8"}}>
              <tr>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.email}</th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.PreselectedOffers}</th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.CompletedTestOffers}</th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.PendingTestOffers}</th>
              </tr>
            </thead>
            <tbody>
            {candidates.map((candidate) => (
  <tr key={candidate.id_candidate}>
    <td style={{fontSize: '14px'}}><a href={`RadarGlobal`} onClick={() => handleEmailClick(candidate)}>{candidate.email}</a></td>
    <td style={{fontSize: '14px'}}>{candidate.nbre_repetition}</td>

    <td style={{fontSize: '14px'}}>{candidate.nbre_repetition_done}</td>
    <td style={{fontSize: '14px'}}>{candidate.nbre_repetition_not_yet}</td>

  </tr>
))}

            </tbody>
          </table></div>
        </Card.Body>
      </Card>
    </Fragment>   ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
}

export default CandidatesPerApp;