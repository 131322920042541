import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import "./TestCandidate.css";
import translations from "../components/AppsMenu/Candidate/mulitlingue";

function TestCandidate() {
  
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);

      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const { id } = useParams();
  const [userId, setUserId] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const token=localStorage.getItem("token");

  const [testId, setTestId] = useState(id);

  const questionRefs = useRef([]); // Ref to store question elements

  useEffect(() => {
    fetchQuestions();
    fetchUserIdFromLocalStorage();
  }, []);

  async function fetchQuestions() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/page-test-candidate/` + id, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  }

  const history = useHistory();
  async function handleSubmit(event) {
    event.preventDefault();
  
    const unansweredQuestions = questions
      .map((question, index) => {
        if (!getSelectedAnswer(question.id)) {
          return index + 1; 
        }
        return null;
      })
      .filter((questionNum) => questionNum !== null); 
    
    if (unansweredQuestions.length > 0) {
      swal(
        lang.menu.Incomplete,
        `${lang.menu.incompleteText} : ${unansweredQuestions.join(", ")}`,
        "warning"
      );
      return; 
    }
    
  
    const jobRoleId = localStorage.getItem("jobRoleId");
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const idCandidate = userDetails ? userDetails.localId : null;
    const answerData = questions.map((question) => {
      const selectedAnswer = getSelectedAnswer(question.id);
      return {
        questionId: question.id,
        answer: selectedAnswer,
        score: selectedAnswer * question.coefficient,
        result: "result",
        jobRoleId: jobRoleId
      };
    });
  
    const formData = {
      testId,
      candidateId: userId,
      answersData: answerData
    };
    const scoresList = formData.answersData.map(answer => answer.score);
    const onlyScores = scoresList.map(scoreObject => {
      switch (scoreObject) {
          case 1:
              return -2;
          case 2:
              return -1;
          case 3:
              return 0;
          case 4:
              return 1;
          case 5:
              return 2;
          default:
              return scoreObject.score; 
      }
  });
  let notePer;

  if(parseInt(testId)===1){
    const response = await axios.post(
      `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/predict/16p`, 
      {
          features: onlyScores
      },
      {
          headers: {
              Authorization: `Bearer ${token}`,
          }
      }
  );
  notePer=response.data.personality_type.code
}else if(parseInt(testId)===2){
  const response = await axios.post(
    `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/predict/ocean`, 
    {
        features: onlyScores
    },
    {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
);
notePer=response.data

}  try {
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/personality-test/submit-answers`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      swal(
        lang.menu.answersubmited,
        lang.menu.answersubmitedDesc,
        lang.menu.success
      ).then(async () => {
          try {
            await axios.put(
              `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/candidatesjobtestPer/${idCandidate}/${jobRoleId}/${testId}`,
              { notePer },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                }
              }
            );
            console.log("PUT Request Successful");
            history.push(`/JobRoleTests/${jobRoleId}`);
          } catch (putError) {
            console.error("Error sending PUT request:", putError);
          }
       
      });
    } catch (error) {
      console.error("Error submitting answers:", error);
      swal(
        "Error",
        "There was an error submitting your answers. Please try again later.",
        "error"
      );
    }
  }
  
  
  function getSelectedAnswer(questionId, index) {
    const radioButtons = document.getElementsByName(questionId);
    for (let i = 0; i < radioButtons.length; i++) {
      if (radioButtons[i].checked) {
        return radioButtons[i].value;
      }
    }
    return null;
  }
  const questionClass = (index) => {
    if (index === currentQuestion) {
      return "question current-question";
    } else {
      return "question other-question";
    }
  };

  const fetchUserIdFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("userDetails");

    if (storedUserData) {
      try {
        const parsedUserData = JSON.parse(storedUserData);

        if (parsedUserData.email) {
          const userEmail = parsedUserData.email;
          setUserId(userEmail);
          console.log("Fetched user email:", userEmail);
        } else {
          console.log("User email not found in local storage.");
        }
      } catch (error) {
        console.error("Error parsing stored user data:", error);
      }
    } else {
      console.log("No stored user data found in local storage.");
    }
  };

  return (
    <>
      <h1>{lang.menu.perTest}</h1>
      <br></br> <br></br> <br></br>
      <form onSubmit={handleSubmit}>
  <div className="quiz">
    {questions.map((question, index) => (
      <div
        key={question.id}
        ref={(element) => (questionRefs.current[index] = element)}
        className={questionClass(index)}
        style={{
          background: index % 2 === 0 ? '#f0f0f0' : '#ffffff', 
          padding: '15px',
          borderRadius: '8px',
          marginBottom: '20px'
        }}
      >
   <div
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
    fontWeight: 'bold',
    fontSize: '200%',
    textAlign: 'center', 
  }}
>
  <span style={{ color: '#43DAC2', marginRight: '5px' }}>
    {index + 1}.
  </span>
  <span style={{ color: '#74188D' }}>
    {question.question}
  </span>
</div>
<br />

        <br />
        <ul>
          {question.answer_value.split(";").map((answer) => {
            const [value, description] = answer.trim().split("-");
            const radioClassName =
              question.answer_value.split(";").length === 3
                ? `radio-btn-${value + 5}`
                : `radio-btn-${value}`;
            return (
              <li key={value}>
                <label>
                  <input
                    type="radio"
                    className={radioClassName}
                    name={question.id}
                    value={value}
                    onClick={() => setCurrentQuestion(index + 1)}
                  />
                  <label className="label-font-left">{description}</label>
                </label>
              </li>
            );
          })}
        </ul>
        <br />
        <br />
        <hr />
      </div>
    ))}
  </div>
  <div className="text-center">
    <Button
      type="submit"
      style={{ fontSize: "16px", padding: "12px 24px" }}
    >
      {lang.menu.submit} <i className="fa fa-paper-plane me-2"></i>
    </Button>
  </div>
</form>

    </>
  );
}

export default TestCandidate;
