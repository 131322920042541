import React, { useState ,useEffect,Fragment,useCallback} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUsers } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import translations from "../../components/AppsMenu/Candidate/mulitlingue"
import { Link, useHistory  } from "react-router-dom";
import Editable from './Editable';
import { Button, Card, Row, Col, Modal, Form, FormGroup,Badge, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { signUp } from '../../../services/AuthService';
import { BsCalendarDate } from "react-icons/bs";
import axios from 'axios';
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import './company.css';
import { Icon } from "@iconify/react";
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/actions/AuthActions';
import { FaSortAmountUp } from "react-icons/fa";

const EXCHANGE_RATES = {
	EUR: 1.00,
	USD: 1.09,
	TND: 2.00,
	GBP: 0.85
  };
  const subscriptionLinks = {
    Free: "https://buy.stripe.com/test_cN2eYt5Gi4tl8LufZ5",
    "Solo Starter": "https://buy.stripe.com/test_6oE4jP7Oq8JBaTCbIJ",
    "Solo Pro": "https://buy.stripe.com/test_8wMaId4Ce9NF9Py7su",
    "Solo Premium": "https://buy.stripe.com/test_6oE7w10lY4tle5OeUX",
    "Entreprise Starter": "https://buy.stripe.com/test_28o4jP4Ce7Fx8Lu9AE",
    "Entreprise Pro": "https://buy.stripe.com/test_9AQbMhd8K8JBgdW7sx",
    "Entreprise Premium": "https://buy.stripe.com/test_14k6rX1q23phf9SbIO",
    "Pay as you go": "https://buy.stripe.com/test_bIYdUp0lYf7Z3ra144"
  };

const Company = () => {
	const [adminDash, setAdminDash] = useState([]);
	const token=localStorage.getItem("token");
	const [userConnect, setUserConnect] = useState(null);
	
	const [selectedCurrency, setSelectedCurrency] = useState("TND");
	const [selectedBilling, setSelectedBilling] = useState("monthly");

	const [enterpriseSubscriptions, setEnterpriseSubscriptions] = useState([]);
	useEffect(() => {
	  const fetchUserPermissions = async () => {
		try {
		  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		  setUserConnect(currentUser)
		  if (currentUser && currentUser.localId) {
			const userId = currentUser.localId;
			const response = await fetch(
			  `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
			  {
				headers: {
				  Authorization: `Bearer ${token}`,
				},
			  }
			);
			const data = await response.json();
			setAdminDash(
			  data.some(
				(permission) =>
				  permission.title === "view-company-dashoard"
			  )
			);
		  }
		} catch (error) {
		  console.error("Error fetching permissions details:", error);
		}
	  };
  
	  fetchUserPermissions();
	}, []);
	const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 
  
	useEffect(() => {
	  if (!adminDash) {
		setShowAccessDeniedModal(true); 
	  }
	}, [adminDash]);
	const [lang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
			const parsedLang = JSON.parse(storedLang);
			if (parsedLang.language === 'Français') {
				return translations.fr;
			}
		}
		return translations.en;
	});
	const [subscription, setSubscription] = useState([]);
	const [paymentControl, setPaymentControl] = useState(null);
	const [userData, setUserData] = useState(null);
	const [personnalityAIRecruiter, setPersonnalityAIRecruiter] = useState(null);
	const [personnalityAICompany, setPersonnalityAICompany] = useState(null);
	const [candidateVerif, setCandidateVerif] = useState(null);

	useEffect(() => {
	  const fetchSubscription = async () => {
		try {
		  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		  const userId = currentUser.localId;
		  const userResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
		  if (!userResponse.ok) {
			throw new Error(`Error fetching user details: ${userResponse.status}`);
		  }
		  const userData = await userResponse.json();
		  console.log("setUserData:",userData[0])
		  setUserData(userData[0])
		  if(currentUser.userRole===3 || currentUser.userRole===2){
			const responseSub = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getInvoiceByUserId/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				});
			  setSubscription(responseSub.data);
		  }
		
		} catch (error) {
		  console.error("Error fetching subscription data:", error);
		}
	  };
	
	  fetchSubscription();
	}, []);
	useEffect(() => {
		if (userConnect && userConnect.localId) {
			const fetchPaymentControl = async () => {
				try {
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/users/get-payment-control/${userConnect.localId}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setPaymentControl(responseSub.data.PaymentControl); 
					console.log('Subscription data:', responseSub.data);
				} catch (error) {
					console.error('Error fetching subscription data:', error);
				}
			};
			const fetchPersonnalityAIComp = async () => {
				try {
					const role=2
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/users/get-PersonnalityAI/${role}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setPersonnalityAICompany(responseSub.data.PersonnalityAI)
					//setPaymentControl(responseSub.data.PaymentControl); 
					console.log('get ai:', responseSub.data);
				} catch (error) {
					console.error('Error fetching PersonnalityAI comp data:', error);
				}
			};
			const fetchPersonnalityAIRec = async () => {
				try {
					const role=3
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/users/get-PersonnalityAI/${role}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setPersonnalityAIRecruiter(responseSub.data.PersonnalityAI)
					//setPaymentControl(responseSub.data.PaymentControl); 
					console.log('get ai:', responseSub.data);
				} catch (error) {
					console.error('Error fetching PersonnalityAI comp data:', error);
				}
			};
			const fetchCandidateControl = async () => {
				try {
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/companies/get-candidate-control`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					console.log("3:",responseSub.data)
					if(responseSub.data.candidateVerif===1)
						setCandidateVerif(true); 
					else
						setCandidateVerif(false); 	
				} catch (error) {
					console.error('Error fetching subscription data:', error);
				}
			};
			fetchCandidateControl()
			fetchPaymentControl();
			fetchPersonnalityAIComp()
			fetchPersonnalityAIRec()
		}
	}, [userConnect]); // Only run when userConnect changes
	
	const TableSub = ({ sub }) => {
		const calculatePercentage = (used, max) => {
		  if (max === 0) return 0; 
		  return (used / max) * 100;
		};
	  
		const getColor = (percentage) => {
			console.log(percentage)
		  if (percentage >= 100) {
			return 'red';
		  } else if (percentage >= 80) {
			return 'orange';
		  } else if (percentage >= 50) {
			return 'yellow';
		  } else {
			return 'green';
		  }
		};
	  
		const quotas = [
		  { name: 'Quota Extraction', max: sub.extractionNumber, used: company.quotaExtraction },
		  { name: 'Quota Job Role', max: sub.quotaJobRole, used: company.quotaJobRole },
		  { name: 'Quota Job Profile', max: sub.quotaJobprofile, used: company.quotaJobProfile },
		  { name: 'Quota Parcours job Test', max: sub.quotaParcours, used: company.quotaParcours },
		  { name: 'Quota Profiles', max: sub.user_profiles, used: company.quotaProfiles },
		];
	  
		return (
		  <>
			<div className="card-header pb-0 border-0 flex-wrap">
			  <h4 className="fs-20 mb-3">{lang.menu.subDetails}</h4>
			  <div className="d-flex">
			  <Button
                                          className="btn btn-primary me-2"
                                          onClick={(event) =>
                                            handleUpgradeNow(event)
                                          }
                                        ><FaSortAmountUp />

                                        {lang.menu.upgradeNow}
                                        </Button>
				
			  </div>
			</div>
			<div className="card-body">
			  <Row>
				<Col>
				  <strong><BsCalendarDate size={20} /> {lang.menu.closeDate}: </strong>
				  <span>{new Date(sub.period_end).toLocaleDateString()}</span>
				</Col>
			  </Row>
			  <table className="table display mb-3 dataTablesCard job-table table-responsive-s card-table dataTable no-footer">
				<thead>
				  <tr>
					<th></th>
					<th>{lang.menu.quotaMax}</th>
					<th>{lang.menu.quotaUsed}</th>
					<th>{lang.menu.usagePercentage}</th>
				  </tr>
				</thead>
				<tbody>
				  {quotas.map((quota, index) => {
					const percentage = calculatePercentage(quota.used, quota.max);
					const color = getColor(Math.round(percentage));
					return (
					  <tr key={index}>
						<td>{quota.name}</td>
						<td>{quota.max}</td>
						<td>{quota.used}</td>
						<td style={{ color: color }}>
						  {Math.round(percentage)}%
						</td>
					  </tr>
					);
				  })}
				</tbody>
			  </table>
			</div>
		  </>
		);
	  };
	
	const [editFormData, setEditFormData] = useState({
		userId: "",
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		country: "",
		jobTitle: "",
		phoneNumber: "",
		language: "",
		avatar: "",
		cover: "",
		signature: "",
		userRole: "",
		disabled: "",
		company: "",
		emailVerified: "",
		lastSignInTime: "",
		creationTime: "",
	})
	const fieldsToCheck = {
		'All': lang.menu.All,
		'FirstName': lang.menu.FirstName,
		'LastName': lang.menu.LastName,
		'email': lang.menu.Email,
		'disabled': lang.menu.Disabled,
		'companyName': lang.menu.Company,
		'userRole': lang.menu.Role,
		'lastSignInTime': lang.menu.lastSignInTime,
		'creationTime': lang.menu.creationTime,
	};
	const avatarClasses = ['empty-avatar-red', 'empty-avatar-green', 'empty-avatar-pimary', 'empty-avatar-yellow', 'empty-avatar-purple'];
	const [selectedFilterField, setSelectedFilterField] = useState('All');
	const [filterText, setFilterText] = useState("");
	const [addCard, setAddCard] = useState(false);

	const [company, setcompany] = useState({
		companyid: "",
		companyName: "",
		founderID: "",
		founder: "",
		numberOfUsers: "",
		comments: "",
		DurationQuestionQCM:"",
		DurationCoding:"",
		quotaExtraction:"",
		quotaJobRole:"",
		quotaJobProfile:"",
		quotaParcours:"",
		quotaProfiles:"",
		logo:"",
		resumeColors:"",
		sectionStatus:"",
		sectionsOrderCompany:""
	});
	const [resumeColors, setResumeColors] = useState({
		backgroundColor: '#fafafa',
		headerColor: '#43dac2',
		textColor: '#333'
	  });
	  const [sectionStatus, setSectionStatus] = useState({});
	  const [sectionsOrder, setSectionsOrder] = useState([]);

	const [user, setUser] = useState({
		uid: "",
		company: "",
		firstname: "",
		lastname: "",
		email: "",
		password: "",
		role: ""
	});

	const [users, setUsers] = useState([]);
	const [editContentId, setEditContentId] = useState(null);
	const [userRoles, setuserRoles] = useState([]);
	const [errors, setErrors] = useState({ firstname: '', lastname: '', email: '', role: '', password: '' });
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		const currentUser = JSON.parse(localStorage.getItem('userDetails'));

		if (currentUser && currentUser.localId) {
			const userId = currentUser.localId;
			fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/company/users/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				})
				.then(response => response.json())
				.then(data => {
					const convertedData = convertNumericFields(data);
					setUsers(convertedData);
				})
				.catch(error => {
					console.error('Error fetching user data:', error);
				});

			fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				})
				.then((response) => response.json())
				.then((data) => {
					console.log("data [0]",data[0])
					setcompany({
						companyid: data[0].companyid || "",
						companyName: data[0].companyName || "",
						founderID: userId,
						founder: data[0].founder || "",
						numberOfUsers: data[0].numberOfUsers || 0,
						comments: data[0].comments || "",
						DurationQuestionQCM: data[0].DurationQuestionQCM || "",
						DurationCoding: data[0].DurationCoding || "",
						quotaExtraction: data[0].quotaExtraction || 0,
						quotaJobRole: data[0].quotaJobRole || 0,
						quotaJobProfile: data[0].quotaJobProfile || 0,
						quotaParcours: data[0].quotaParcours || 0,
						quotaProfiles: data[0].quotaProfiles || 1,
						logo: data[0].logo || "",
						resumeColors: data[0].resumeColors || "",
						sectionStatus: data[0].sectionStatus || "",
						sectionsOrderCompany:data[0].sectionsOrderCompany || "",


					});
					console.log("data[0].sectionStatus ;",data[0].sectionStatus)
					setResumeColors(data[0].resumeColors);
					setSectionStatus(data[0].sectionStatus);
					setSectionsOrder(
						Object.keys(data[0].sectionStatus).reduce((acc, section) => {
						  acc[section] = data[0].sectionStatus[section]
							? data[0].sectionsOrderCompany?.[section] || Object.keys(data[0].sectionsOrderCompany).length + 1
							: null;
						  return acc;
						}, {})
					  );
					fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/roles/roles/${data[0].companyid}`, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  },
						})
						.then(response => response.json())
						.then(data => {
							setuserRoles(data);
						})
						.catch(error => {
							console.error('Error fetching user data:', error);
						});

				})
				.catch((error) => {
					console.error("Error fetching company details:", error);
				});
		}
	}, []);

	function convertNumericFields(users) {
		const convertedUsers = users.map((user) => {
			const newUser = { ...user };

			// Convert emailVerified
			if (newUser.emailVerified === 0) {
				newUser.emailVerified = 'False';
			} else if (newUser.emailVerified === 1) {
				newUser.emailVerified = 'True';
			}

			// Convert disabled
			if (newUser.disabled === 0) {
				newUser.disabled = 'False';
			} else if (newUser.disabled === 1) {
				newUser.disabled = 'True';
			}

			return newUser;
		});

		return convertedUsers;
	}

	const handleEditFormChange = async (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute('name');
		const fieldValue = event.target.value;
		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue;
		setEditFormData(newFormData);
	};

	const handleCancelClick = () => {
		setEditContentId(null);
	};

	const handleSubmitClick = async () => {
		try {
			// Send user data to backend for saving
			const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/updatefirebase`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(editFormData),
			});
			console.log("response ;",response)

			if (response.ok) {
				swal("", lang.menu.ProfileUpdate, "success").then(() => {
					window.location.reload();
				});
			}
			else if (response.status===400) {
				swal("Oops",lang.menu.emailExists, "error");
	}
			else {
				swal("Oops", lang.menu.ProfileUpdateError, "error");
			}
		} catch (error) {
			console.log("error ;",error)
			console.error("An error occurred:", error);
		}
	};

	const filteredUsers = users.filter((user) => {
		if (
			selectedFilterField === 'creationTime' ||
			selectedFilterField === 'lastSignInTime'
		) {
			const fieldValue = JSON.parse(user.metadata)[selectedFilterField];
			if (
				fieldValue !== null &&
				fieldValue !== undefined &&
				fieldValue.toLowerCase().includes(filterText.toLowerCase())
			) {
				return true;
			}
			return false;
		}
		else if (selectedFilterField !== 'All') {
			const fieldValue = user[selectedFilterField];
			if (fieldValue !== null && fieldValue !== undefined) {
				if (typeof fieldValue === 'number') {
					if (fieldValue.toString().includes(filterText)) {
						return true;
					}
				} else if (
					typeof fieldValue === 'string' &&
					fieldValue.toLowerCase().includes(filterText.toLowerCase())
				) {
					return true;
				} else if (typeof fieldValue === 'boolean') {
					if (fieldValue.toString() === filterText) {
						return true;
					}
				}
			}
			return false;
		} else {
			const fieldsToCheck = {
				'FirstName': lang.menu.FirstName,
				'LastName': lang.menu.LastName,
				'email': lang.menu.Email,
			
				'disabled': lang.menu.Disabled,
				'companyName': lang.menu.Company,
				'userRole': lang.menu.Role,
				'lastSignInTime': lang.menu.lastSignInTime,
				'creationTime': lang.menu.creationTime,
			};

			for (const field in fieldsToCheck) {
				let fieldValue = user[field];
				if (field === 'lastSignInTime' || field === 'creationTime') {
					fieldValue = JSON.parse(user.metadata)[field];
				}

				if (fieldValue !== null && fieldValue !== undefined) {
					if (typeof fieldValue === 'number') {
						if (fieldValue.toString().includes(filterText)) {
							return true;
						}
					} else if (
						typeof fieldValue === 'string' &&
						fieldValue.toLowerCase().includes(filterText.toLowerCase())
					) {
						return true;
					} else if (typeof fieldValue === 'boolean') {
						if (fieldValue.toString() === filterText) {
							return true;
						}
					}
				}
			}

			return false;
		}
	});

	const handleEditClick = (event, content) => {
		event.preventDefault();
		setEditContentId(content.uid);
		const formValues = {
			userId: content.uid,
			firstName: content.FirstName,
			lastName: content.LastName,
			email: content.email,
			password: content.Password,
			country: content.Country,
			jobTitle: content.JobTitleID,
			phoneNumber: content.PhoneNumber,
			language: content.Language,
			avatar: content.Avatar,
			cover: content.Cover,
			signature: content.EmailSignature,
			userRole: content.userRoleID ? content.userRoleID : 4,
			disabled: content.disabled === "True" ? 1 : 0,
			company: content.companyName,
			emailVerified: content.emailVerified,
			lastSignInTime: content.metadata ? JSON.parse(content.metadata).lastSignInTime : '',
			creationTime: content.metadata ? JSON.parse(content.metadata).creationTime : ''
		}
		setEditFormData(formValues);
	};

	const handleDeleteUser = async (event, content) => {
		event.preventDefault();
	  
		const userId = content.uid;
	  
		fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/deleteFromCompany/${userId}`, 
		  {
			method: 'DELETE',  
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  })
		  .then(response => response.json())
		  .then(data => {
			swal("", lang.menu.DeleteUserSuccess, "success").then(async() => {
				if(company.companyid!=1){
					await axios.put(
						`${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${company.companyid}`,
						{ fieldName:"quotaProfiles","incrementValue":-1  ,quota_max:subscription.user_profiles,quota_used:company.quotaProfiles}, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  },
						}
					  );
				}
				
			  const convertedData = convertNumericFields(data);
			  setUsers(convertedData);
			  window.location.reload();

			})
		  })
		  .catch(error => {
			console.error('Error deleting user:', error);
			swal("", lang.menu.DeleteUserError, "error");
		  });
	  }
	  

	const handleSave = async () => {
		// Send company data to backend for saving:
		const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/savecompanyDetails`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(company),
		});

		if (response.status === 500) {
			console.error("Server Error: Internal Server Error (500)");
			swal("Oops", lang.menu.CompanyExists, "error");
		} else {
			swal("", lang.menu.CompanyUpdate, "success")
		};
	};

	const handleAddFormSubmit = async () => {
		signUp(user.email, user.password)
			.then((response) => {
				user.uid = response.data.localId;
				user.company = company.companyid;
				fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/saveUserToCompany`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(user),
				}).then(() => {
					swal("", lang.menu.userAddSucc, "success").then(async() => {
						await axios.put(
							`${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${company.companyid}`,
							{ fieldName:"quotaProfiles","incrementValue":1  ,quota_max:subscription.user_profiles,quota_used:company.quotaProfiles}, 
							{
							  headers: {
								Authorization: `Bearer ${token}`,
							  },
							}
						  );
						window.location.reload();
					});
				})
			}).catch((error) => {
				if (error.response.status === 400)
					swal("Oops", lang.menu.emailExists, "error");
				else
					swal("Oops", lang.menu.ProfileUpdateError, "error");
			});

	};

	const handleAddUser = () => {
		const newUser = {
			firstname: "",
			lastname: "",
			email: "",
			password: "",
			role: "",
		};
		setUser(newUser);
		setAddCard(true)
	}


	function onSignUp(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errors };
		if (user.firstname === '') {
			errorObj.firstname = lang.menu.firstNameRequired;
			error = true;
		}
		else
			errorObj.firstname = ''

		if (user.lastname === '') {
			errorObj.lastname = lang.menu.lastNameRequired;
			error = true;
		}
		else
			errorObj.lastname = ''

		if (user.email === '') {
			errorObj.email = lang.menu.emailRequired;
			error = true;
		} else if (!validateEmail(user.email)) {
			errorObj.email = lang.menu.InvalidEmailFormat;
			error = true;
		}
		else
			errorObj.email = ''

		if (user.role === '') {
			errorObj.role = lang.menu.RoleRequired;
			error = true;
		}
		else
			errorObj.role = ''

		if (user.password === '') {
			errorObj.password = lang.menu.PasswordRequired;
			error = true;
		} else if (!validatePassword(user.password)) {
			errorObj.password = lang.menu.passWordComplexityReq;
			error = true;
		} else {
			errorObj.password = '';
		}
		setErrors(errorObj);
		if (error) return;
		handleAddFormSubmit()
	}

	const togglePasswordVisibility = () => {
		setShowPassword((prevState) => !prevState);
	};

	const validateEmail = (email) => {
		// Regular expression to validate email format
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const validatePassword = (password) => {
		// Regular expressions for password complexity requirements
		const uppercaseRegex = /[A-Z]/;
		const lowercaseRegex = /[a-z]/;
		const digitRegex = /[0-9]/;
		const specialCharRegex = /[^a-zA-Z0-9]/;

		return (
			password.length >= 8 &&
			uppercaseRegex.test(password) &&
			lowercaseRegex.test(password) &&
			digitRegex.test(password) &&
			specialCharRegex.test(password)
		);
	};

	const getPasswordStrengthColor = () => {
		let complexity = 0;

		if (user.password.length >= 8) {
			complexity++;
		}

		if (/[A-Z]/.test(user.password)) {
			complexity++;
		}

		if (/[a-z]/.test(user.password)) {
			complexity++;
		}

		if (/\d/.test(user.password)) {
			complexity++;
		}

		if (/[^a-zA-Z0-9]/.test(user.password)) {
			complexity++;
		}

		if (complexity === 5) {
			return 'bg-success';
		} else if (complexity >= 3) {
			return 'bg-warning';
		} else {
			return 'bg-danger';
		}
	};

	const getPasswordHintIcon = (condition) => {
		return condition ? <span className="hint-icon">✓</span> : <span className="hint-icon">X</span>;

	};




	const handleChangeControlPayment = async (enable) => {
		const token = localStorage.getItem("token");
		const currentUser = JSON.parse(localStorage.getItem("userDetails"));
	
		const parcour = {
			uid: userConnect.localId,
			enable: enable ? 1 : 0 		};
	console.log("parcour :",parcour)
		try {
			const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/update-payment-control`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(parcour),
			});
	
			const data = await response.json();
	
			if (response.ok) {
				console.log("PaymentControl updated successfully:", data.message);
				
				setPaymentControl(enable);
			} else {
				console.error("Error updating PaymentControl:", data.message);
			
			}
		} catch (error) {
			console.error("An error occurred while updating PaymentControl:", error);
		}
	};
	
	const handleChangeControlcandidateVerif = async (enable) => {
		const token = localStorage.getItem("token");
		const currentUser = JSON.parse(localStorage.getItem("userDetails"));
	
		const parcour = {
			uid: userConnect.localId,
			enable: enable ? 1 : 0 		};
	console.log("parcour :",parcour)
		try {
			const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/update-candidate-control`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(parcour),
			});
	
			const data = await response.json();
	
			if (response.ok) {
				console.log("CandidateControl updated successfully:", data.message);
				
				setCandidateVerif(enable);
			} else {
				console.error("Error updating CandidateControl:", data.message);
			
			}
		} catch (error) {
			console.error("An error occurred while updating CandidateControl:", error);
		}
	};


	const handleChangPersonnalityAI = async (enable,userRole) => {
		const token = localStorage.getItem("token");
	
		const persoAI = {
			uid: userConnect.localId,
			enable: enable ? 1 : 0 	,
			role:userRole	};
	console.log("persoAI :",persoAI)
		try {
			const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/update-PersonnalityAI`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(persoAI),
			});
	
			const data = await response.json();
	
			if (response.ok) {
				console.log("PersonnalityAI updated successfully:", data.message);
				if(userRole===2){
					setPersonnalityAICompany(enable);

				}else if(userRole===3){
					setPersonnalityAIRecruiter(enable);

				}
			} else {
				console.error("Error updating PersonnalityAI:", data.message);
			
			}
		} catch (error) {
			console.error("An error occurred while updating PersonnalityAI:", error);
		}
	};


//Logo
const handleSaveData = async (Data) => {
    try {
      await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/companies/saveCompanyLogo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Data), 
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  
  const handleLogoUpload = async (e) => {
	const logoFile = e.target.files[0];
	if (logoFile) {
	  try {
		const formData = new FormData();
		formData.append("logo", logoFile);
		formData.append("companyid", company.companyid);
  
		const response = await fetch(
		  `${process.env.REACT_APP_APP_DOMAIN}/api/companies/uploadLogo/${company.companyid}`,
		  {
			method: "POST",
			body: formData,
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		);
  console.log("response :",response)
		if (response.ok) {
		  const data = await response.json();
  
		  const newcomp = {
			companyid: company.companyid,
			logo: data.filename,
		  };
		  console.log("newcomp:", newcomp);
  
		  await handleSaveData(newcomp);
		  swal("", lang.menu.LogoUpdate, "success");
		  window.location.reload();
		} else {
		  swal("Oops", lang.menu.LogoUpdateError, "error");
		}
	  } catch (error) {
		console.error("An error occurred:", error);
	  }
	}
  };
  const isImage = (url) => {
	const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
	const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
	return imageExtensions.includes(extension);
  };
  
  const getImageUrlFromServer = (company) => {
	return `${process.env.REACT_APP_APP_DOMAIN}/api/companies/LogoComp/${company.companyid}/${company.logo}`;
  };
  
  const getImageUrl = (company) => {
	if (isImage(company.logo)) {
	  const imageUrl = getImageUrlFromServer(company);
	  return imageUrl;
	}
	return null;
  };
 

  const handleColorChange = (colorType, value) => {
    setResumeColors((prevColors) => ({
      ...prevColors,
      [colorType]: value
    }));
  };

  const handleOrderChange = (sectionName, newOrder) => {
    setSectionsOrder((prevOrder) => {
      const updatedOrder = { ...prevOrder };

      const oldOrder = updatedOrder[sectionName];
      updatedOrder[sectionName] = newOrder;

      Object.keys(updatedOrder).forEach((key) => {
        if (key !== sectionName && updatedOrder[key] !== null) {
          if (oldOrder < newOrder && updatedOrder[key] > oldOrder && updatedOrder[key] <= newOrder) {
            updatedOrder[key]--;
          } else if (oldOrder > newOrder && updatedOrder[key] < oldOrder && updatedOrder[key] >= newOrder) {
            updatedOrder[key]++;
          }
        }
      });

      return updatedOrder;
    });
  };

  const handleToggleSection = (sectionName) => {
    setSectionStatus((prevStatus) => {
      const newStatus = !prevStatus[sectionName];

      setSectionsOrder((prevOrder) => {
        const updatedOrder = { ...prevOrder };

        if (newStatus) {
          const maxOrder = Math.max(...Object.values(updatedOrder).filter((order) => order !== null));
          updatedOrder[sectionName] = maxOrder + 1;
        } else {
          const deactivatedOrder = updatedOrder[sectionName];
          updatedOrder[sectionName] = null;

          Object.keys(updatedOrder).forEach((key) => {
            if (updatedOrder[key] !== null && updatedOrder[key] > deactivatedOrder) {
              updatedOrder[key]--;
            }
          });
        }

        return updatedOrder;
      });

      return {
        ...prevStatus,
        [sectionName]: newStatus,
      };
    });
  };


  const handleSaveChangesColorSection = async () => {
    try {
		console.log("sectionsOrder :",sectionsOrder)

      await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateSectionsAndColor/${company.companyid}`,
        {
          resumeColors,
		  sectionStatus,
		  sectionsOrder: sectionsOrder,

        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
	  window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des modifications :", error);
      alert('Une erreur est survenue lors de la sauvegarde.');
    }
  };
  //upgrade subscription

  const calculateConvertedPrice = (price, currency) => {
    const rate = selectedBilling === "monthly" ? 1 : 12;
    return (parseFloat(price.slice(1)) * EXCHANGE_RATES[currency] * rate).toFixed(2);
  };

  const [featureselected, setFeatureselected] = useState({})
  const [features, setFeatures] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [closestSubscription, setClosestSubscription] = useState(null);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const showFeatures = async () => {
	try {
  

	  const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getAll`, 
				  {
					headers: {
					  Authorization: `Bearer ${token}`,
					}});
	  const featureData = response.data;

	  setFeatures(featureData);

	  const response2 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypeSub`, 
				  {
					headers: {
					  Authorization: `Bearer ${token}`,
					}});
	  const sub = response2.data;
	  setSubscriptionTypes(sub);
	  
	  
	  const featureOrder = [
		'Candidate Profiles',
		'Candidate extraction',
		'Create job profile',
		'Create job role',
		'Parcours'
	  ];
	  
	  const sortFeatures = (features) => {
		return features.sort((a, b) => {
		  return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
		});
	  };
	  
	  const soloSubs = {};
	  const enterpriseSubs = {};
	  
	  for (const [key, value] of Object.entries(sub)) {
		// Create a copy of the value array and sort it
		const sortedValue = [...value].sort((a, b) => {
		  return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
		});
	  
		
		if (key.startsWith("Entreprise") || key === "Free" || key === "Pay as you go") {
		  enterpriseSubs[key] = sortedValue;
		}
	  }
	  
	  setEnterpriseSubscriptions(enterpriseSubs);
	  const allSubscriptions = [];
	  const SUB=enterpriseSubs

	  Object.entries(SUB).forEach(([typeName, features]) => {
		  let priceType = "";
		  let idProduct = "";
		  let promoPercentage = "";
		  let isPromo = "";
		  features.forEach((feature) => {

			priceType = feature.type_monthly_price;
			idProduct = feature.type_id;
			isPromo = feature.type_isPromo;
			promoPercentage = feature.type_promoPercentage;
		  });
  
		  allSubscriptions.push({ typeName, features, priceType, idProduct ,isPromo,promoPercentage});
		});
		console.log("allSubscriptions:",allSubscriptions)
		setAllSubscriptions(allSubscriptions);


	  const featureSelectedInitial = featureData.reduce((acc, feat) => {
		acc[feat.name] = "";
		return acc;
	  }, {});


	  setFeatureselected(featureSelectedInitial);

	} catch (error) {
	  console.error("Error fetching features:", error);
	}
  };
  useEffect(() => {
   

    showFeatures();
  }, []);
  const handleCloseModal = () => {
    setClosestSubscription(null);
  };


  const ModalPayme = ({ subscriptionType,userEmail }) => {
	const [showModal, setShowModal] = useState(false);
	const [token, setToken] = useState("");
  
	const amountPayme= calculateConvertedPrice(subscriptionType.priceType, selectedCurrency)
	const amountPaymeAnnuel = selectedBilling === "monthly" ? (parseFloat(amountPayme) * 12).toFixed(2) : amountPayme;

	const [formData, setFormData] = useState({
	  amount: amountPayme,
	  note: `${subscriptionType.idProduct}`,
	  first_name: '',
	  last_name: '',
	  email: userEmail,
	  phone: '50719929',
	  order_id: `${subscriptionType.idProduct}`,
	   monthAnnuel:"Monthly"
	});
	
  
	
	const [gateway, setGatway] = useState(false)
	  const [formError, setFormError] = useState(null)
	  const [error, setError] = useState(null)
	  const [iframeURL, setIframeURL] = useState('')
	  const { t } = useTranslation()
	  const [phone, setPhone] = useState('50719929')
	  const [amount, setAmount] = useState(amountPayme)
	  const [email, setEmail] = useState(userEmail)
	  const [firstName, setFirstName] = useState("")
	  const [lastName, setLastName] = useState("")
      const [monthAnnuel, setmonthAnnuel] =useState("Monthly");
	  const handleChangemonthAnnuel = (event) => {
		const value = event.target.value;
		setmonthAnnuel(value); 
	  };
	  const handelClick = async () => {
		try {
		  console.log("phone:",phone)
			const res = await axios.get(
				`https://sandbox.paymee.tn/api/v1/accounts/${phone}/account-number`,
				{
					headers: {
						Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
					},
				}
			)
			console.log("res 14:",res.data)
  
			if (!res.data.status) {
				setFormError(res.data.message)
				setGatway(false)
			} else {
			  const formDataPayme = new FormData();
			  formDataPayme.append("email", formData.email);
			  formDataPayme.append("first_name", formData.first_name);
			  formDataPayme.append("last_name", formData.last_name);
  
   console.log("formDataPayme:",formDataPayme)
				const res2 = await axios.post(
					`https://sandbox.paymee.tn/api/v2/payments/create`,
					{
					  order_id:subscriptionType.idProduct,
						phone: phone,
						amount:  monthAnnuel === "Monthly" ? amount : amountPaymeAnnuel,
						note: subscriptionType.idProduct,
						first_name:firstName,
						last_name:lastName,
						email:email,
						return_url: "https://app.hirecue.com",
						cancel_url: "https://app.hirecue.com",
						webhook_url: "https://www.webhook_url.tn"
					  
					},
					{
						headers: {
							Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
						},
					}
				)
				console.log("res 15:",res2.data)
  
				if (res2.data.status) {
					setToken(res2.data.data.token)
					setIframeURL(
						`https://sandbox.paymee.tn/gateway/${res2.data.data.token}`
					)
  
					setGatway(true)
					setFormError(null)
  
				} else {
  
					setGatway(false)
					setFormError(res2.data.message)
				}
			}
		} catch (error) {
			console.log(error)
			setError(error)
			setGatway(false)
		}
	}
	const [paymentStatus, setPaymentStatus] = useState(null);
  
	const checkToken = useCallback(async () => {
	  if (!token) {
		return;
	  }
  
	  console.log("Checking token:", token);
  
	  try {
		const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/payMe/payments/${token}/check/v2`, {
		  headers: {
			"Authorization-Bearer": `Bearer ${token}`,
			"Authorization": `Token ${process.env.REACT_APP_PAYME_API_KEY}`
		  },
		});
  
		const { data } = response.data;
  
		if (data && data.payment_status) {
		  console.log("Payment completed successfully");
		  setPaymentStatus('completed');
		  setShowModal(false);
		  handleCloseModal()
		  const currentUser = JSON.parse(localStorage.getItem("userDetails"));

		  const subscriptionData = {
			userId: currentUser.localId,
			amount: data.amount,
			note: data.note,
		  };
		
		  try {
			const subscriptionDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/createSubscription`, {
			  method: "POST",
			  headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify(subscriptionData),
			});
		
			if (!subscriptionDone.ok) {
			  throw new Error('Failed to create subscription');
			}
		
			const dataSub = await subscriptionDone.json();
			const storedLang = localStorage.getItem("selectedLang");
			const parsedLang = JSON.parse(storedLang);
  
			const invoiceData = {
			  sub_id: dataSub.sub_id,
			  customer_id: dataSub.customer_id,
			  email: data.email,
			  received_amount: data.received_amount,
			  amount: data.amount, 
			  payment_status: data.payment_status,
			  firstname: data.firstname,
			  lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" ,
              currency:"TND",
			  monthAnnuel:monthAnnuel
			};
		
			const invoiceDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/invoice/createinvoice`, {
			  method: "POST",
			  headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify(invoiceData),
			});
		
			if (!invoiceDone.ok) {
			  throw new Error('Failed to create invoice');
			}
			showFeatures();
  
			console.log("Subscription and invoice creation completed successfully");
	 
		
		  } catch (error) {
			console.error("Error:", error);
		  }
		}
		else {
		  console.log("Payment not completed or unsuccessful");
		  setPaymentStatus('incomplete');
		}
	  } catch (error) {
		console.error("Error checking payment status:", error);
		setPaymentStatus('error');
	  }
	}, [token]);
  
	const handlePaymeeComplete = useCallback((event) => {
	  console.log('Paymee payment completed:', event.detail);
	  setPaymentStatus('completed');
	  setShowModal(false);
	  // Perform further actions like updating the UI or notifying the user
	}, []);
  
	useEffect(() => {
	  if (paymentStatus == 'completed') {
		console.log('Payment already completed, skipping check.');
							window.location.reload();

		return;
	  }
  
	  const intervalId = setInterval(checkToken, 1000);
  
	  window.addEventListener('paymee.complete', handlePaymeeComplete);
	  
	  return () => {
	   
		clearInterval(intervalId);
		window.removeEventListener('paymee.complete', handlePaymeeComplete);
		
	  };
	}, [checkToken, handlePaymeeComplete, paymentStatus]);
	
	useEffect(() => {
	  console.log("Hello",token)
		window.addEventListener(
			'message',
			function (event) {
				if (event.data.event_id === 'paymee.complete') {
					//Execute Step 3
					// window.location.replace()
					// https://sandbox.paymee.tn/api/v1/payments/{token}/check
					axios
						.get(
							`https://sandbox.paymee.tn/api/v2/payments/${token}/check`,
  
							{
								headers: {
									Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
								},
							}
						)
						.then((res) => {
						  console.log("res.data:",res.data)
							if (res.data.data && res.data.data.payment_status) {
								setIframeURL(
									'https://sandbox.paymee.tn/paylink/success'
								)
							} else {
								setIframeURL(
									'https://sandbox.paymee.tn/paylink/failure'
								)
							}
						})
						.catch((err) => {
							console.log(err)
							setError(err)
						})
				}
			},
			false
		)
	}, [token])
	return (
	  <>
		<Button color="primary" onClick={() => setShowModal(true)} className="btn-soft-primary rounded-pill">
		  {lang.menu.purchaseNow}<FaArrowRight />
		</Button>
		<Modal show={showModal} onHide={() => setShowModal(false)}>
		  <Modal.Header >
			<Modal.Title>{lang.menu.purchaseNow}</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>
		  <Card>
					  <Card.Header>
						  <h3>{t('Online payment')}</h3>
					  </Card.Header>
					  <Box overflow="auto">
						 
					  <Form>
	  {!gateway ? (
		  <Fragment>
			 <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.amount}</Form.Label>
				<Form.Control type="text" placeholder="Enter amount" name="amount" value={amountPayme}     onChange={(event) => {
					  setAmount(event.target.value.toString());
				  }} />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.email}</Form.Label>
				<Form.Control type="email" placeholder="Enter email" name="email" value={userEmail}     onChange={(event) => {
					  setEmail(event.target.value.toString());
				  }}  />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.firstName}</Form.Label>
				<Form.Control type="text" placeholder="Enter first_name" name="first_name" value={firstName}     onChange={(event) => {
					  setFirstName(event.target.value.toString());
				  }}  />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.lastName}</Form.Label>
				<Form.Control type="text" placeholder="Enter last_name" name="last_name" value={lastName}     onChange={(event) => {
					  setLastName(event.target.value.toString());
				  }}  />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount" hidden>
				<Form.Label>{lang.menu.phone}</Form.Label>
				<Form.Control type="text" placeholder="Enter phone" name="phone" value={formData.phone}  onChange={(event) => {
					  setPhone(event.target.value.toString());
				  }} />
			  </Form.Group>
  
  
			  <div className="mb-3">
      <label htmlFor="formFrequency" className="form-label">
        {lang.menu.frequency}
      </label>
      <select
        id="formFrequency" 
        name="frequency"
        value={monthAnnuel} 
        onChange={handleChangemonthAnnuel} 
        className="form-select"
      >
        <option value="Monthly"> {lang.menu.Mensuel}</option>
        <option value="Annuel"> {lang.menu.Annuel}</option>
      </select>
    </div>
  
			
			 
			  <br />
			  {formError && (
				  <p sx={{ color: 'red', my: 1 }}>
					  {formError}
				  </p>
			  )}
			  <Button onClick={handelClick}>
				  {t('Submit')}
			  </Button>
		  </Fragment>
	  ) : (
		  <iframe
			  title="gateway payment"
			  width="500px"
			  height="400px"
			  src={iframeURL}
		  />
	  )}
  </Form>
  
						
					  </Box>
				  </Card>
		  </Modal.Body>
		  <Modal.Footer>
			<Button variant="secondary" onClick={() => setShowModal(false)}>{lang.menu.retour }</Button>
		  </Modal.Footer>
		</Modal>
	  </>
	);
  };

  const PurchaseLink = ({ subscriptionType }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalLink, setModalLink] = useState("");
	const currentUser = JSON.parse(localStorage.getItem("userDetails"));
	const userEmail=currentUser.email
    const link = `${subscriptionLinks[subscriptionType.typeName]}?prefilled_email=${userEmail}`;
  
    const handlePurchaseClick = (link) => {
      if (selectedCurrency !== "TND") {
       // setModalLink(link);
       // setShowModal(true);
       window.location.href = link; 
        handleLogout()
      } else {
        //window.open(link, "_blank");
      }
    };
  
 
    return selectedCurrency === "TND" ? (
      <ModalPayme subscriptionType={subscriptionType} userEmail={userEmail} />
    ) : (
      <>
        <Button
          onClick={() => handlePurchaseClick(link)}
          className="btn-soft-primary rounded-pill"
        >
         {lang.menu.purchaseNow} <FaArrowRight />
        </Button>
        {/* <PurchaseModal show={showModal} onHide={() => setShowModal(false)} link={modalLink} /> */}
      </>
    );
  };
  const [showModalSub, setShowModalSub] = useState(false);
  const handleUpgradeNow = (event) => {
	setShowModalSub(true);  

      
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout(history));
  };
	return (
<> {adminDash ? (  

		<>
			<Modal className="modal fade" show={addCard} onHide={setAddCard} >
				<div className="" role="document">
					<div className="">
					
						<form onSubmit={onSignUp}>
							<div className="modal-header">
								<h4 className="modal-title fs-20">{lang.menu.AddUser}</h4>
								<button type="button" className="btn-close" onClick={() => setAddCard(false)} data-dismiss="modal"><span></span></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="row">
											<div className="col-6">
												<div className="form-group mb-3">
													<label className="mb-1">
														<strong>{lang.menu.firstName}</strong>
													</label>
													<input
														value={user.firstname}
														onChange={(e) =>
															setUser((prevUser) => ({
																...prevUser,
																firstname: e.target.value,
															}))
														}
														className="form-control"
														placeholder="Firstname"
													/>
													{errors.firstname && <div className='text-danger'>{errors.firstname}</div>}
												</div>
											</div>
											<div className="col-6">
												<div className="form-group mb-3">
													<label className="mb-1">
														<strong>{lang.menu.lastName}</strong>
													</label>
													<input
														value={user.lastname}
														onChange={(e) =>
															setUser((prevUser) => ({
																...prevUser,
																lastname: e.target.value,
															}))
														}
														className="form-control"
														placeholder="Lastname"
													/>
													{errors.lastname && <div className='text-danger'>{errors.lastname}</div>}
												</div>
											</div>
										</div>
										<div className="form-group mb-3">
											<label className="mb-1">
												<strong>{lang.menu.email}</strong>
											</label>
											<input
												value={user.email}
												onChange={(e) =>
													setUser((prevUser) => ({
														...prevUser,
														email: e.target.value,
													}))
												}
												className="form-control"
												placeholder="hello@example.com"
											/>
											{errors.email && <div className='text-danger'>{errors.email}</div>}
										</div>
										<div className="form-group mb-3">
											<label className="mb-1">
												<strong>{lang.menu.role}</strong>
											</label>
											<select
												name="role"
												value={user.role}
												className="form-control"
												onChange={(e) =>
													setUser((prevUser) => ({
														...prevUser,
														role: e.target.value,
													}))
												}
											>
												<option value="" disabled></option>
												{userRoles.map((role) => (
													<option key={role.roleid} value={role.roleid}>
														{role.roleName}
													</option>
												))}
											</select>
											{errors.role && <div className='text-danger'>{errors.role}</div>}
										</div>
										<div className="form-group mb-3">
											<label className="mb-1">
												<strong>{lang.menu.password}</strong>
											</label>
											<div className="password-input-container">
												<input
													value={user.password}
													onChange={(e) => {
														setUser((prevUser) => ({
															...prevUser,
															password: e.target.value,
														}))
														validatePassword(e.target.value);
													}}
													className="form-control"
													type={showPassword ? "text" : "password"}
													placeholder="Password"
												/>
												<span className="password-toggle-icon" onClick={togglePasswordVisibility}>
													{showPassword ? <FiEyeOff /> : <FiEye />}
												</span>
											</div>
											{errors.password && <div className='text-danger'>{errors.password}</div>}
										</div>
										<div className="progress mb-3">
											<div
												className={`progress-bar ${getPasswordStrengthColor()}`}
												role="progressbar"
												style={{ width: `${(user.password.length / 16) * 100}%` }}
												aria-valuenow={user.password.length}
												aria-valuemin="0"
												aria-valuemax="10"
											></div>
										</div>
										<div className={`password-hint ${Object.values(errors).some(error => error !== '') ? 'hint-error' : ''}`}>
											<ul>
												<li className={!user.password ? 'hint-not-typed' : (user.password.length >= 8 ? 'hint-typed' : '')}>
													{getPasswordHintIcon(user.password.length >= 8)} {lang.menu.isPasswordLengthValid}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/[A-Z]/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/[A-Z]/.test(user.password))} {lang.menu.isPasswordContainsUppercase}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/[a-z]/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/[a-z]/.test(user.password))}{lang.menu.isPasswordContainsLowercase}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/\d/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/\d/.test(user.password))} {lang.menu.isPasswordContainsDigit}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/[^a-zA-Z0-9]/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/[^a-zA-Z0-9]/.test(user.password))} {lang.menu.isPasswordContainsSpecialChar}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-primary">{lang.menu.Add}</button>
								<button type="button" onClick={() => setAddCard(false)} className="btn btn-danger">
									<i className="flaticon-delete-1"></i> {lang.menu.Discard}</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		
			<div className="col-12">
				<div className="card full-width">
					<div className="card-header">
						<h4 className="card-title"><FontAwesomeIcon icon={faBuilding} className="me-2" />{lang.menu.Company}</h4>
					</div>
		
					<div className="card-body">
					<div className="logo-container">
      {company.logo ? (
        <img
          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`}
          alt="Company Logo"
          className="company-logo"
        />
      ) : (
        <div className="placeholder-logo">No Logo</div>
      )}
      <label className="upload-button">
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoUpload}
          style={{ display: "none" }}
        />
        <span className="upload-text">   {lang.menu.LogoUpload}</span>
      </label>
    </div>
						<div className="basic-form">
							<form onSubmit={(e) => e.preventDefault()} className="p-3">
								<div className="row">
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.companyName}</label>
										<input
											id="companyName"
											type="text"
											className="form-control"
											value={company.companyName}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													companyName: e.target.value,
												}))
											}
										/>
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.founder}</label>
										<input
											disabled
											id="founder"
											className="form-control"
											value={company.founder}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													founder: e.target.value,
												}))
											}
										/>
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.numberOfUsers}</label>
										<input
											type="number"
											id="numberOfusers"
											min={0}
											disabled
											className="form-control"
											value={company.numberOfUsers}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													numberOfUsers: e.target.value,
												}))
											}
										/>
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding"></div>
									<div className="form-group mb-3 col-md-12 my-form-padding">
										<label className="my-label">{lang.menu.Comments}</label>
										<textarea
											id="comments"
											style={{ height: "150px" }}
											className="form-control"
											value={company.comments}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													comments: e.target.value,
												}))
											} />
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.QCMduration}</label>
										<input
											id="DurationQuestionQCM"
											type="number"
											className="form-control"
											value={company.DurationQuestionQCM}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													DurationQuestionQCM: e.target.value,
												}))
											}
										/>
									</div>
								
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.codingDuration}</label>
										<input
											id="DurationCoding"
											type="number"
											className="form-control"
											value={company.DurationCoding}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													DurationCoding: e.target.value,
												}))
											}
										/>
									</div>
								</div>
							</form>
						</div>
						<div className="form-group mb-3 col-md-12 my-button-padding">
							<button type="submit" className="btn btn-primary my-save-button" onClick={handleSave}>
								{lang.menu.SaveChanges}
							</button>
						</div>
					</div>
				</div>
				<div className="card">

				{subscription && company&&company.companyid!=1  ? (
							<>

							<TableSub sub={subscription} />
							</>
									
									) : (
								<></>
								)}</div>
								<div className="card">

{ userConnect&&userConnect.userRole===1 ? (
		  <>
          <div className="card-header pb-0 border-0 flex-wrap">
            <h4 className="fs-20 mb-3">{lang.menu.controlTitle}</h4>
          </div>
          <div className="card-body">
            <Row>
			<Col>
    <input
        type="radio"
        id="validationAdmin"
        name="paymentControl"
        value="1"
        checked={paymentControl === true} // If true, "Validation par administrateur" is checked
        onChange={() => handleChangeControlPayment(true)} // Update state and backend
    />
    <label htmlFor="validationAdmin">{lang.menu.controlAdmin}</label>
</Col>
<Col>
    <input
        type="radio"
        id="paymentCard"
        name="paymentControl"
        value="0"
        checked={paymentControl === false} // If false, "Paiement par carte" is checked
        onChange={() => handleChangeControlPayment(false)} // Update state and backend
    />
    <label htmlFor="paymentCard">{lang.menu.controlCard}</label>
</Col>

            </Row>



	
          </div>

		  <div className="card-header pb-0 border-0 flex-wrap">
            <h4 className="fs-20 mb-3">{lang.menu.controlCandidateTitle}</h4>
          </div>
          <div className="card-body">
		  <Row>
			<Col>
    <input
        type="radio"
        id="validationAdminCandidatetrue"
        name="validationAdminCandidate"
        value="1"
        checked={candidateVerif === true} // If true, "Validation par administrateur" is checked
        onChange={() => handleChangeControlcandidateVerif(true)} // Update state and backend
    />
    <label htmlFor="validationAdmin">{lang.menu.controlAdminCandidatetrue}</label>
</Col>
<Col>
    <input
        type="radio"
        id="validationAdminCandidatefalse"
        name="validationAdminCandidate"
        value="0"
        checked={candidateVerif === false} // If false, "sans validation admin" is checked
        onChange={() => handleChangeControlcandidateVerif(false)} // Update state and backend
    />
    <label htmlFor="paymentCard">{lang.menu.controlAdminCandidatefalse}</label>
</Col>

            </Row>
			</div>
	


        </>
					
					) : (
				<></>
				)}
				
				</div>
				
				
				
				
				
				
				
				
				{ userConnect&&userConnect.userRole===1 ? (
		  <Card className="mb-4">
		  <Card.Header className="pb-0 border-0">
			<h4 className="fs-20 mb-3">{lang.menu.aipersoSeetings}</h4>
		  </Card.Header>
		  <Card.Body>
			{/* Company Section */}
			<section className="mb-4">
			  <h5 className="fs-18">{lang.menu.accesCompTitle}</h5>
			  <p className="text-muted">{lang.menu.accesCompDesc}</p>
			  <Form>
				<FormGroup as={Row} className="align-items-center">
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAIyes"
					  name="PersonnalityAICompany"
					  label={lang.menu.Yes}
					  value="1"
					  checked={personnalityAICompany === true}
					  onChange={() => handleChangPersonnalityAI(true, 2)}
					/>
				  </Col>
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAINo"
					  name="PersonnalityAICompany"
					  label={lang.menu.No}
					  value="0"
					  checked={personnalityAICompany === false}
					  onChange={() => handleChangPersonnalityAI(false, 2)}
					/>
				  </Col>
				</FormGroup>
			  </Form>
			</section>
	
			{/* Recruiter Section */}
			<section>
			  <h5 className="fs-18">{lang.menu.accesRecTitle}</h5>
			  <p className="text-muted">{lang.menu.accesRecDesc}</p>
			  <Form>
				<FormGroup as={Row} className="align-items-center">
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAIYes2"
					  name="PersonnalityAIRecruiter"
					  label={lang.menu.Yes}
					  value="1"
					  checked={personnalityAIRecruiter === true}
					  onChange={() => handleChangPersonnalityAI(true, 3)}
					/>
				  </Col>
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAINo2"
					  name="PersonnalityAIRecruiter"
					  label={lang.menu.No}
					  value="0"
					  checked={personnalityAIRecruiter === false}
					  onChange={() => handleChangPersonnalityAI(false, 3)}
					/>
				  </Col>
				</FormGroup>
			  </Form>
			</section>
		  </Card.Body>
		</Card>
					
					) : (
				<>
				</>
				)}
				
				
				
				
				{ userConnect&&company ? (	
					<Card className="p-5 shadow border-0 rounded" style={{ backgroundColor: '#f9fafb' }}>
					<Card.Body>
					  <h2 className="mb-4 text-primary" style={{ fontWeight: 'bold' }}>{lang.menu.personCV}</h2>
			
					  <h4 className="text-secondary" style={{ fontWeight: '600' }}>{lang.menu.persoColor}</h4>
					  <Row className="my-4">
						<Col md={4}>
						  <Form.Group className="text-center">
							<Form.Label className="font-weight-bold">{lang.menu.headerColor}</Form.Label>
							<Form.Control
							  type="color"
							  value={resumeColors.headerColor}
							  onChange={(e) => handleColorChange('headerColor', e.target.value)}
							  style={{ cursor: 'pointer', height: '50px', borderRadius: '5px', width: '100%' }}
							/>
						  </Form.Group>
						</Col>
						<Col md={4}>
						  <Form.Group className="text-center">
							<Form.Label className="font-weight-bold">{lang.menu.textColor}</Form.Label>
							<Form.Control
							  type="color"
							  value={resumeColors.textColor}
							  onChange={(e) => handleColorChange('textColor', e.target.value)}
							  style={{ cursor: 'pointer', height: '50px', borderRadius: '5px', width: '100%' }}
							/>
						  </Form.Group>
						</Col>
						<Col md={4}>
						  <Form.Group className="text-center">
							<Form.Label className="font-weight-bold">{lang.menu.backgroundColor}</Form.Label>
							<Form.Control
							  type="color"
							  value={resumeColors.backgroundColor}
							  onChange={(e) => handleColorChange('backgroundColor', e.target.value)}
							  style={{ cursor: 'pointer', height: '50px', borderRadius: '5px', width: '100%' }}
							/>
						  </Form.Group>
						</Col>
					  </Row>
			
					  <div>
      <h4 className="text-secondary mt-5" style={{ fontWeight: '700', fontSize: '24px', textAlign: 'center', marginBottom: '20px' }}>
      {lang.menu.sectionManag}
      </h4>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
	  {Object.keys(sectionsOrder)
  .sort((a, b) => {
    const orderA = sectionsOrder[a] ?? Infinity;
    const orderB = sectionsOrder[b] ?? Infinity;
    return orderA - orderB;
  })
  .map((section) => {
    // Map des sections aux clés de traduction
    const sectionTranslationMap = {
      'Informations Générales': lang.menu.generalInfo,
      'Expérience Professionnelle': lang.menu.experPerso,
      'Formation': lang.menu.Education,
      'Compétences': lang.menu.skills,
      'Langues': lang.menu.Language,
      'Certificats': lang.menu.certificats,
      'Travail Bénévole': lang.menu.Volunteerings,
    };

    // Traduction basée sur le nom de la section
    const translatedSection = sectionTranslationMap[section] || section;

    return (
      <div
        key={section}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '15px 20px',
          margin: '10px 0',
          backgroundColor: sectionStatus[section] ? '#f9f9f9' : '#e9e9e9',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          opacity: sectionStatus[section] ? 1 : 0.6,
        }}
      >
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <label className="switch" style={{ marginRight: '15px' }}>
            <input
              type="checkbox"
              checked={sectionStatus[section]}
              onChange={() => handleToggleSection(section)}
            />
            <span className="slider round"></span>
          </label>

          <span style={{ fontSize: '18px', color: sectionStatus[section] ? '#333' : '#888' }}>
            {translatedSection} <small style={{ fontWeight: 'normal', fontStyle: 'italic' }}>({sectionStatus[section] ? 'Actif' : 'Inactif'})</small>
          </span>
        </div>

        <div style={{ flexShrink: 0, marginLeft: '10px' }}>
          <label style={{ fontSize: '14px', marginRight: '5px', color: '#555' }}>{lang.menu.ordre}:</label>
          <select
            value={sectionsOrder[section] ?? ''}
            onChange={(e) => handleOrderChange(section, parseInt(e.target.value))}
            disabled={!sectionStatus[section]}
            style={{
              padding: '5px 10px',
              fontSize: '14px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              cursor: sectionStatus[section] ? 'pointer' : 'not-allowed',
              backgroundColor: sectionStatus[section] ? '#fff' : '#f0f0f0',
            }}
          >
            <option value="" disabled>
              -- Select --
            </option>
            {[...Array(Object.keys(sectionsOrder).filter((section) => sectionStatus[section]).length)].map((_, idx) => (
              <option key={idx} value={idx + 1}>
                {idx + 1}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  })}


      </div>
    </div>
					  <div className="text-center mt-5">
						<Button
						  variant="success"
						  size="lg"
						  onClick={handleSaveChangesColorSection}
						  style={{
							backgroundColor: '#6a1b9a',
							borderColor: '#6a1b9a',
							fontWeight: '600',
							padding: '12px 24px',
							borderRadius: '8px',
						  }}
						>
						 {lang.menu.SaveChanges}
						</Button>
					  </div>
					</Card.Body>
				  </Card>
				) : (
				<></>
				)}			
				
				
				
				
				
				<div className="card full-width">
					<div className="card-header">
						<h4 className="card-title"><FontAwesomeIcon icon={faUsers} className="me-2" />{lang.menu.Users}</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
								<div className="d-flex align-items-center">
									<label><h4 className="me-2 mt-3">{lang.menu.SearchT}</h4></label>
									<input
										className="input-search form-control"
										value={filterText || ''}
										onChange={(e) => setFilterText(e.target.value)}
										style={{ width: '20%' }}
									/>
									<select
										className="ml-5 form-select input-search form-control"
										value={selectedFilterField}
										onChange={(e) => setSelectedFilterField(e.target.value)}
										style={{ width: '10%', marginLeft: '10px' }}
									>
										{Object.keys(fieldsToCheck).map((key) => (
											<option key={key} value={key}>
												{fieldsToCheck[key]}
											</option>
										))}
									</select>
									{company.numberOfUsers > filteredUsers.length ? (
										<button
											className="btn btn-primary shadow btn-xs sharp ms-auto"
											onClick={() => handleAddUser()}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : <></>}
								</div>


								<form>
									<table id="example" className="display w-100 dataTable">
										<thead>
											<tr>
												<th>#</th>
												<th>{lang.menu.firstName}</th>
												<th>{lang.menu.lastName}</th>
												<th>{lang.menu.email}</th>
												{/*<th>{lang.menu.EmailVerification}</th>
															<th>{lang.menu.EmailSignature}</th>
															<th>{lang.menu.Password}</th>
															<th>{lang.menu.Country}</th>
															<th>{lang.menu.MyJobtitle}</th>
															<th>{lang.menu.PhoneNumber}</th>
															<th>{lang.menu.language}</th>*/}
												<th>{lang.menu.Disabled}</th>
												<th>{lang.menu.company}</th>
												<th>{lang.menu.role}</th>
												<th>{lang.menu.lastSignInTime}</th>
												<th>{lang.menu.creationTime}</th>
											</tr>
										</thead>
										<tbody>
											{filteredUsers.map((content, index) => (
												<tr key={index}>
													{editContentId === content.uid ?
														(
															<Editable editFormData={editFormData} userRoles={userRoles}
																handleEditFormChange={handleEditFormChange}
																handleSubmitClick={handleSubmitClick}
																handleCancelClick={handleCancelClick} />
														) :
														(
															<>
																<td>
																	{
																		content.Avatar ? (
																			<img className="rounded-circle" width="43" src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`} alt="" />
																		) :
																			(
																				<div className={`empty-avatar rounded-circle ${['empty-avatar-red', 'empty-avatar-green', 'empty-avatar-pimary', 'empty-avatar-yellow', 'empty-avatar-purple'][Math.floor(Math.random() * avatarClasses.length)]}`}>{content.FirstName.charAt(0)}{content.LastName.charAt(0)}</div>
																			)
																	}
																</td>
																<td>{content.FirstName}</td>
																<td>{content.LastName}</td>
																<td><strong>{content.email}</strong></td>
																{/*<td>
																				{content.emailVerified === "True" ? (
																					<Badge variant="success">{content.emailVerified}</Badge>
																				) : (
																					<Badge variant="danger">{content.emailVerified}</Badge>
																				)}
																			</td>
																			 <td>{content.EmailSignature}</td> 
																			 <td>{content.Password}</td>
																			 <td><strong>{content.Country}</strong></td>
																			<td>{content.JobTitle}</td>
																			<td><strong>{content.PhoneNumber}</strong></td>
																			<td>{content.Language}</td> */}
																<td>
																	{content.disabled === "True" ? (
																		<Badge variant="success">{content.disabled}</Badge>
																	) : (
																		<Badge variant="danger">{content.disabled}</Badge>
																	)}
																</td>
																<td>{content.companyName}</td>
																<td>{content.userRole}</td>
																<td>{content.metadata ? JSON.parse(content.metadata).lastSignInTime : ''}</td>
																<td>{content.metadata ? JSON.parse(content.metadata).creationTime : ''}</td>
																<td>
																	<div className="d-flex">
																		<Link className="btn btn-secondary shadow btn-xs sharp me-2" to=""
																			onClick={(event) => handleEditClick(event, content)}
																		>
																			<i className="fas fa-pen"></i>
																		</Link>
																		{company.founderID !== content.uid ?
																			(
																				<Link className="btn btn-danger shadow btn-xs sharp me-2" to=""
																					onClick={(event) => handleDeleteUser(event, content)}
																				>
																					<i className="fa fa-trash"></i>
																				</Link>
																			) : (
																				<></>
																			)}
																	</div>
																</td>
															</>
														)
													}
												</tr>
											))}
										</tbody>
									</table>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={showModalSub} onHide={() => setShowModalSub(false)} dialogClassName="modal-100w">
  <Modal.Header>
    <Modal.Title>{lang.menu.Offress}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <div className="text-center mt-3">
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "EUR" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("EUR")}
            >
              EUR
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "USD" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("USD")}
            >
              USD
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "TND" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("TND")}
            >
              TND
            </button>
            <button
              className={`btn btn-outline-secondary ${
                selectedCurrency === "GBP" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("GBP")}
            >
              GBP
            </button>
          </div>
		  <Row>
  {allSubscriptions.map((subscription) => {
    const cardClass = 'pricing-box h-100 shadow-sm';
    return (
      <Col key={subscription.typeName} md={4}>
        <Card className={cardClass}>
          <Card.Body className="p-4 px-lg-5 position-relative">
            {/* Affichage de la promo */}
            {subscription.isPromo === 1 && (
              <div className="badge-promo bg-danger text-white position-absolute top-0 end-0 px-3 py-1 rounded-start">
                -{subscription.promoPercentage}%
              </div>
            )}
            <div className="pricing-name mt-4 pt-2 text-center">
              <h4 className="fs-18 text-primary">
                <div className="pricing-icon rounded-circle icons-md mx-auto mb-4">
                  <Icon icon="uim-telegram-alt" className="text-primary" />
                  {subscription.typeName === "Pay as you go"
                    ? "Customized offer"
                    : subscription.typeName}
                </div>
              </h4>

        
					  <h2 className="fw-semibold">
                      {calculateConvertedPrice(subscription.priceType, selectedCurrency)}
                        <small className="fs-16">{selectedCurrency} {selectedBilling === "monthly" ? "mo" : "yr"}</small>
                      </h2>
                   </div>
                 
                    <ul className="list-unstyled mt-3 mb-4">
                    {subscription.features
                      .filter((feature) => feature.feature_quota_max !== null)
                      .map((feature) => (
                        <li key={feature.feature_id} className="my-2">
                          <strong>{feature.feature_name}:</strong> {feature.feature_quota_max}
						  {	console.log("subscription 21:",subscription)
						}
                        </li>
                      ))}

                    </ul>
					<PurchaseLink subscriptionType={subscription} className="btn btn-primary btn-block" />




          </Card.Body>
        </Card>
      </Col>
    );
  })}
</Row>
      </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModalSub(false)}>
      {lang.menu.retour}
    </Button>
  </Modal.Footer>
</Modal>
		</>
		) : (
			<Modal show={showAccessDeniedModal} >
			<Modal.Header>
			  <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{lang.menu.YoudontHavePerm}
			</Modal.Body>
			<Modal.Footer>
		
			</Modal.Footer>
		  </Modal>
		 )}</>
	)
}
export default Company;
