import React, { useRef, useState,useEffect } from 'react';
import { Button, Card, Col, Row,Form,FormGroup } from "react-bootstrap";
import { FaUserCircle, FaBriefcase, FaGraduationCap } from "react-icons/fa";
import { GiSkills } from "react-icons/gi";
import html2pdf from "html2pdf.js";
import logo from "../../../images/logo-full.png";
import { IoLanguage } from "react-icons/io5";
import { PiCertificateLight } from "react-icons/pi";
import { MdOutlineVolunteerActivism } from "react-icons/md";
import axios from 'axios';
import Swal from "sweetalert2"; 
import translations from "../AppsMenu/Candidate/mulitlingue";

const CVPreview = ({ sections,backgroundColor,textColor,headerColor ,resumeId ,company }) => {
  console.log("sections 111:", sections);
  const cvRef = useRef();
  const token=localStorage.getItem("token");
  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
  const userId = currentUser.localId;
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
        const parsedLang = JSON.parse(storedLang);
        if (parsedLang.language === "Français") {
            return translations.fr;
        }
    }
    return translations.en;
});
  useEffect(() => {
    if (resumeId) {
      console.log("Affichage du CV avec l'ID :", resumeId);
    }
  }, [resumeId]);
  const [isAnonymous, setIsAnonymous] = useState(false);  
  const sectionNames = {
    'Informations Générales': lang.menu.generalInfo,
    'Expérience Professionnelle': lang.menu.experPerso,
    'Formation': lang.menu.Education,
    'Compétences': lang.menu.skills,
    'Langues': lang.menu.Language,
    'Certificats': lang.menu.certificats,
    'Travail Bénévole': lang.menu.Volunteerings,
  };
  const generatePDF = () => {
    const errors = validateFields();

    if (errors.length > 0) {
      const groupedErrors = errors.reduce((acc, error) => {
        const [section, field] = error.split('|'); 
        if (!acc[section]) acc[section] = [];
        acc[section].push(field);
        return acc;
      }, {});
    
      const tableHtml = `
    <table style="width: 100%; border-collapse: collapse; font-size: 14px; border: 1px solid #ddd;">
      <thead>
        <tr style="background: #f8f9fa;">
          <th style="padding: 8px; border: 1px solid #ddd;">Section</th>
          <th style="padding: 8px; border: 1px solid #ddd;">${lang.menu.champManq}</th>
        </tr>
      </thead>
      <tbody>
        ${Object.entries(groupedErrors).map(([section, fields]) => `
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">  ${sectionNames[section] || section}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">
              <ul style="margin: 0; padding-left: 20px; list-style-type: square;">
                ${fields.map(field => `<li>${field}</li>`).join('')}
              </ul>
            </td>
          </tr>
        `).join('')}
      </tbody>
    </table>
  `;

  // Afficher les erreurs dans SweetAlert
  Swal.fire({
    title: `<strong style="color: #d9534f;">⚠️ ${lang.menu.champManq}</strong>`,
    html: `
      <div style="text-align: left; font-size: 16px;">
        ${tableHtml}
      </div>
    `,
    icon: 'warning',
    confirmButtonText: lang.menu.corriger,
    confirmButtonColor: '#3085d6',
    width: '700px',
    customClass: {
      popup: 'shadow-lg rounded-lg',
    },
  });
      return;
    }
    
    const element = cvRef.current;
    element.style.fontFamily = "Arial, sans-serif";
    element.style.color = "#000"; 
    element.style.background = "#fff"; 
    const options = {
      margin: [0.2, 0, 0.5, 0],
      filename: "CV_Professionnel.pdf",
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    };

    const customElement = element.cloneNode(true);

    if (isAnonymous) {
      const personalInfoElements = customElement.querySelectorAll('.personal-info');
      personalInfoElements.forEach(el => {
        el.style.display = 'none';
      });
    }

    html2pdf().set(options).from(customElement).save();
  };

  
  
  const handleAnonymousToggle = () => {
    setIsAnonymous(!isAnonymous); 
  };


  const saveResume = async () => {
    try {
      const sectionsOrder = sections.map((section) => section.title);
      const resumeData = { uid: userId ,
                          headerColor,      
                          textColor,       
                          backgroundColor,
                          sectionsOrder };
      let logoFile = null;      
      sections.forEach((section) => {
        if (section.isEnabled) {
          switch (section.title) {
            case 'Informations Générales':
              resumeData.firstName = section.content.firstName;
              resumeData.lastName = section.content.lastName;
              resumeData.email = section.content.email;
              resumeData.phoneNumber = section.content.phoneNumber;
              resumeData.country = section.content.country;
              resumeData.github = section.content.github;
              resumeData.linkedIn = section.content.linkedIn;
              resumeData.description = section.content.description;
              if (section.content.photo) {
                logoFile = section.content.photo;
              }
              break;
            case 'Expérience Professionnelle':
              resumeData.experiences = section.content.map((exp) => ({
                company: exp.company,
                startDate: exp.startDate,
                endDate: exp.endDate,
                location: exp.location,
                post: exp.post,
              }));
              break;
            case 'Formation':
              resumeData.education = section.content.map((edu) => ({
                institution: edu.institution,
                startDate: edu.startDate,
                endDate: edu.endDate,
                degree: edu.degree,
                description: edu.description,
              }));
              break;
            case 'Langues':
              resumeData.languages = section.content.map((lang) => ({
                language: lang.language,
                level: lang.level,
              }));
              break;
            case 'Compétences':
              resumeData.skills = section.content;
              break;
            case 'Certificats':
              resumeData.certificates = section.content.map((cert) => ({
                title: cert.title,
                date: cert.date,
                issuedBy: cert.issuedBy,
              }));
              break;
            case 'Travail Bénévole':
              resumeData.volunteer = section.content.map((volunteer) => ({
                organization: volunteer.organization,
                startDate: volunteer.startDate,
                endDate: volunteer.endDate,
                role: volunteer.role,
              }));
              break;
            default:
              break;
          }
        }
      });
      if (logoFile) {
        resumeData.photo=logoFile;
      }
      if (resumeId) {
        await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/Resume/updateResume/${resumeId}/${userId}`, resumeData, 
          {
            headers: {
            Authorization: `Bearer ${token}`,
            }});
        Swal.fire({
          icon: 'success',
          title: 'CV mis à jour avec succès !',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        console.log("resumeData.",resumeData)
        const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/Resume/createResume`, resumeData, 
          {
            headers: {
            Authorization: `Bearer ${token}`,
            }});
        Swal.fire({
          icon: 'success',
          title: 'CV créé avec succès !',
          showConfirmButton: false,
          timer: 1500
        });      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: "Une erreur est survenue lors de la sauvegarde du CV.",
        showConfirmButton: false,
        timer: 1500
      })
      console.error("Erreur lors de la sauvegarde du CV :", error);
    }
  };
  
  const generalInfo = sections.find(section => section.title === 'Informations Générales');
  const locale = lang.language === 'Français' ? 'fr' : 'en';
  const formatDate = (date, locale) => {
    if (!date) return ''; 
    const [year, month] = date.split('-'); 
    return locale === 'fr' ? `${month}-${year}` : `${year}-${month}`;
  };

  const validateFields = () => {
    const errors = [];
  
    sections.forEach((section) => {
      if (section.isEnabled) {
        switch (section.title) {
          case 'Expérience Professionnelle':
            section.content.forEach((exp, index) => {
              if (!exp.company) errors.push(`Expérience Professionnelle|${lang.menu.company} (${lang.menu.Entry} ${index + 1})`);
              if (!exp.startDate) errors.push(`Expérience Professionnelle|${lang.menu.dateDebut}  (${lang.menu.Entry} ${index + 1})`);
              if (!exp.endDate) errors.push(`Expérience Professionnelle|${lang.menu.dateFin}  (${lang.menu.Entry} ${index + 1})`);
            });
            break;
  
          case 'Formation':
            section.content.forEach((edu, index) => {
              if (!edu.institution) errors.push(`Formation|${lang.menu.EdIn} (${lang.menu.Entry} ${index + 1})`);
              if (!edu.startDate) errors.push(`Formation|${lang.menu.dateDebut} (${lang.menu.Entry} ${index + 1})`);
              if (!edu.endDate) errors.push(`Formation|${lang.menu.dateFin}  (${lang.menu.Entry} ${index + 1})`);
              if (!edu.degree) errors.push(`Formation|${lang.menu.Degree}  (${lang.menu.Entry} ${index + 1})`);
            });
            break;
  
          case 'Certificats':
            section.content.forEach((cert, index) => {
              if (!cert.title) errors.push(`Certificats|${lang.menu.certifTitle}  (${lang.menu.Entry} ${index + 1})`);
            });
            break;
  
          case 'Travail Bénévole':
            section.content.forEach((volunteer, index) => {
              if (!volunteer.organization) errors.push(`Travail Bénévole|${lang.menu.Organization}  (${lang.menu.Entry} ${index + 1})`);
            });
            break;
  
          default:
            break;
        }
      }
    });
  
    return errors;
  };
  
  return (
    
    <div>
      

    <Card
      className="cv-preview border-0 p-3"
      style={{ maxWidth: "800px", margin: "auto" }}
    >
      <Form>
				<FormGroup as={Row} className="align-items-center">
				  <Col xs={6}>
					<Form.Check
					  type="radio"
					  id="normal"
					  name="mode"
					  label={lang.menu.modeNormal}
					  value="normal"
					   checked={isAnonymous === false}
     					 onChange={handleAnonymousToggle}
					/>
				  </Col>
				  <Col xs={6}>
					<Form.Check
					  type="radio"
					  id="anonymous"
					  name="mode"
					  label={lang.menu.modeAnonyme}
					  value="anonymous"
					  checked={isAnonymous === true}
      					onChange={handleAnonymousToggle}
					/>
				  </Col>
				</FormGroup>
			  </Form>

      <div
        ref={cvRef}
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          minHeight: "297mm", 
          padding: "5px",
          boxSizing: "border-box",
        }}
      >
          {generalInfo && generalInfo.isEnabled && (
          <Card.Header
          style={{
            borderBottom: '2px solid #555',
            paddingBottom: '10px',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: headerColor,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {company.logo ? (
              <img
                src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`}
                alt={`${company.companyName} Logo`}
                style={{
                  width: '50px',
                  height: '50px',
                  marginBottom: '5px', 
                }}
              />
            ) : (
              <img
                src={logo}
                alt="Logo de l'entreprise"
                style={{
                  width: '50px',
                  height: 'auto',
                  marginBottom: '5px', 
                }}
              />
            )}
            {company && company.companyName && (
              <p style={{ fontSize: '16px', color: headerColor, margin: '0' }}>
                {company.companyName}
              </p>
            )}
          </div>
        
          <div style={{ textAlign: 'right' }}>
            <h1 style={{ fontSize: '24px', color: headerColor, margin: '0' }}>
            {isAnonymous 
  ? `${generalInfo.content.firstName[0]}${generalInfo.content.lastName[0]}` 
  : `${generalInfo.content.firstName} ${generalInfo.content.lastName}`}

            </h1>
            <p style={{ fontSize: '14px', color: '#888', margin: '0' }}>
            {!isAnonymous && `${generalInfo.content.email} | ${generalInfo.content.phoneNumber}`}
            </p>
          </div>
        </Card.Header>
        
          
          
          )}
          <Card.Body>
          {generalInfo && generalInfo.isEnabled && (
            <div
            className="experience-display"
            dangerouslySetInnerHTML={{
              __html: generalInfo.content.description.replace(/\n/g, '<br />'),
            }}
          ></div>
          
              )}
            {sections
              .filter(section => section.isEnabled && section.isVisible)
              .map((section) => (
                <div
                key={section.id}
                className="cv-section"
                style={{
                  marginBottom: "20px",
                  pageBreakInside: "avoid",
                }}
              >
                {section.isEnabled && (!isAnonymous || section.title !== 'Informations Générales') ? (
                  <>
                    <h4
                      style={{
                        backgroundColor: '#f0f0f0',
                        padding: '8px',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {!isAnonymous && section.title === 'Informations Générales' && (
                        <FaUserCircle style={{ marginRight: '8px' }} />
                      )}
                      {section.title === 'Expérience Professionnelle' && (
                        <FaBriefcase style={{ marginRight: '8px' }} />
                      )}
                      {section.title === 'Formation' && (
                        <FaGraduationCap style={{ marginRight: '8px' }} />
                      )}
                      {section.title === 'Compétences' && (
                        <GiSkills style={{ marginRight: '8px' }} />
                      )}
                      {section.title === 'Langues' && (
                        <IoLanguage style={{ marginRight: '8px' }} />
                      )}
                      {section.title === 'Certificats' && (
                        <PiCertificateLight style={{ marginRight: '8px' }} />
                      )}
                      {section.title === 'Travail Bénévole' && (
                        <MdOutlineVolunteerActivism style={{ marginRight: '8px' }} />
                      )}
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '18px',
                          color: headerColor,
                        }}
                      >
                        {
                          {
                            'Informations Générales': lang.menu.generalInfo,
                            'Expérience Professionnelle': lang.menu.experPerso,
                            'Formation': lang.menu.Education,
                            'Compétences': lang.menu.skills,
                            'Langues': lang.menu.Language,
                            'Certificats': lang.menu.certificats,
                            'Travail Bénévole': lang.menu.Volunteerings,
                          }[section.title] || section.title
                        }
                      </span>
                    </h4>

                    <div style={{ padding: '10px', backgroundColor: '#fafafa', borderRadius: '5px' }}>
                    {!isAnonymous && section.title === 'Informations Générales' && (
                      <div className="info-details" style={{ fontSize: '14px', lineHeight: '1.6' }}>
                        {section.content.photo && (
                          <img
                            src={section.content.photo}
                            alt="Profil de user"
                            style={{ width: '100px', height: '100px', borderRadius: '50%', float: 'right', marginLeft: '15px' }}
                          />
                        )}
                        <p style={{  color: textColor }}><strong style={{  color: headerColor }}>{lang.menu.firstName}:</strong> {section.content.firstName}</p>
                        <p style={{  color: textColor }}><strong style={{  color: headerColor }}>{lang.menu.lastName}:</strong> {section.content.lastName}</p>
                        <p style={{  color: textColor }} ><strong style={{  color: headerColor }}>{lang.menu.country}:</strong> {section.content.country}</p>
                        <p style={{  color: textColor }}><strong style={{  color: headerColor }}>{lang.menu.email}:</strong> {section.content.email}</p>
                        <p style={{  color: textColor }}><strong style={{  color: headerColor }}>{lang.menu.GitHub}:</strong> <a href={section.content.github} target="_blank" rel="noopener noreferrer">{section.content.github}</a></p>
                        <p style={{  color: textColor }}><strong style={{  color: headerColor }}>{lang.menu.LinkedIn}:</strong> <a href={section.content.linkedIn} target="_blank" rel="noopener noreferrer">{section.content.linkedIn}</a></p>
                        <p style={{  color: textColor }}><strong style={{  color: headerColor }}>{lang.menu.phone}:</strong> {section.content.phoneNumber}</p>

                      </div>
                    )}
                 {section.title === "Expérience Professionnelle" &&
  section.content.map((exp) => (
    <div
      key={exp.id}
      style={{
        padding: "8px",
        margin: "8px 0",
        border: "1px solid #ddd",
        borderRadius: "5px",
        pageBreakInside: "avoid", 
        overflow: "hidden",
      }}
    >
      <strong style={{ color: headerColor }}>{exp.company}</strong> (
        {formatDate(exp.startDate, locale)} - {formatDate(exp.endDate, locale)})
      <br />
      <em style={{ fontSize: "14px", color: "#555" }}>{exp.location}</em>
      <br />
      <div
        className="experience-display"
        style={{
          wordBreak: "break-word", 
          whiteSpace: "pre-wrap", 
          lineHeight: "1.5", 
          pageBreakInside: "avoid", 
        }}
        dangerouslySetInnerHTML={{
          __html: exp.post.replace(/\n/g, "<br />"), 
        }}
      ></div>
    </div>
  ))}

                    {section.title === 'Formation' && section.content.map((edu) => (
                      <div key={edu.id} style={{ padding: '8px', margin: '8px 0', border: '1px solid #ddd', borderRadius: '5px',pageBreakInside: "avoid" }}>
                        <strong style={{ color: headerColor }}>{edu.institution}</strong> ({edu.startDate} - {edu.endDate})<br />
                        <em style={{ fontSize: '14px', color: '#555' }}>{edu.degree}</em><br />
                        <p style={{ fontSize: '14px', lineHeight: '1.5' }}>{edu.description}</p>
                      </div>
                    ))}
                          {section.title === 'Langues' && section.content.map((lang) => (
                        <div key={lang.id} style={{ padding: '8px', margin: '8px 0', border: '1px solid #ddd', borderRadius: '5px',pageBreakInside: "avoid" }}>
                          <strong style={{ color: headerColor }}>{lang.language}</strong> - <em style={{ color: '#555' }}>{lang.level}</em>
                        </div>
                      ))}
                    {section.title === 'Compétences' && (
                      <ul className="skills-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', listStyle: 'none', padding: 0,pageBreakInside: "avoid" }}>
                        {section.content.map((skill, index) => (
                          <li key={index} style={{ backgroundColor: headerColor, color: '#fff', padding: '5px 10px', borderRadius: '15px', fontSize: '14px' }}>{skill}</li>
                        ))}
                      </ul>
                    )}  
                       {section.title === 'Certificats' && section.content.map((cert) => (
                       
                        <div key={cert.id} style={{ padding: '8px', marginBottom: "10px", border: '1px solid #ddd', borderRadius: '5px',pageBreakInside: "avoid" }}>
                          <strong style={{ color: headerColor }}>{cert.title}</strong> ({cert.date})<br />
                          <em style={{ fontSize: '14px', color: '#555' }}>{cert.issuedBy}</em>
                        </div>
                      ))}
                         {section.title === 'Travail Bénévole' && section.content.map((volunteer) => (
                        <div key={volunteer.id} style={{ padding: '8px', margin: '8px 0', border: '1px solid #ddd', borderRadius: '5px' ,pageBreakInside: "avoid"}}>
                          <strong style={{ color: headerColor }}>{volunteer.organization}</strong> ({volunteer.startDate} - {volunteer.endDate})<br />
                          <em style={{ fontSize: '14px', color: '#555' }}>{volunteer.role}</em><br />
                        </div>
                      ))}
                    
                                      </div>
                  </>
                ) : (

                  <p className="text-muted"></p>
                )}
              </div>
            ))}
          </Card.Body>
          <footer
            style={{
              pageBreakInside: "avoid",
              position: "relative",
              textAlign: "center",
              padding: "10px",
              backgroundColor: backgroundColor,
              fontSize: "12px",
              borderTop: "1px solid #ddd",
              marginTop: "20px",
            }}
          >
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  }}>
         {company.logo ? (
                <img
                  src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`}
                  alt={`${company.companyName} Logo`}
                  style={{ width: '50px', height: '50px', marginBottom: '10px' }} 
                  rounded
                />
              ) : (
                <img src={logo} alt="Logo Hirecue" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />

              )}
    <div style={{ display: 'flex', gap: '15px', fontSize: '14px' }}>
    {company && company.founder && (
      <a href={`mailto:${company.founder}`} style={{
        color: headerColor,
        textDecoration: 'none',
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: backgroundColor,
        transition: 'background-color 0.3s ease',
      }}
      onHover={{ backgroundColor: backgroundColor }}>
     {company.founder}
      </a>)}
      <a href="https://hirecue.com" target="_blank" rel="noopener noreferrer" style={{
        color: headerColor,
        textDecoration: 'none',
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: backgroundColor,
        transition: 'background-color 0.3s ease',
      }}
      onHover={{ backgroundColor: backgroundColor }}>
        hirecue.com
      </a>
    </div>
  </div>
</footer>

        </div>
        <Row>
          <Col>
          <Button onClick={generatePDF} variant="primary" className="mt-3 generate-pdf-button">{lang.menu.genererPDF}</Button>
          </Col>
          <Col> <Button onClick={saveResume} variant="success" className="mt-3 save-resume-button">
          {lang.menu.SaveChanges}
        </Button></Col>
      
        </Row>
      </Card>
    </div>
  );
};

export default CVPreview;
