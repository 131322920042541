import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Button, Badge,Image  } from "react-bootstrap";
import {
  FiDollarSign,
  FiTrendingUp,
  FiMapPin,
  FiClock,
  FiBook,
  FiCheckCircle,
} from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";
import { GiSkills } from "react-icons/gi";
import { BsCheckCircle, BsAward, BsBookmarkHeart } from "react-icons/bs";
import { BiUserCheck, BiUserCircle } from "react-icons/bi";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import translations from "../AppsMenu/Candidate/mulitlingue";

const JobRoleDetails = () => {
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState(null);
  const token=localStorage.getItem("token");

  const [companies, setCompanies] = useState([]);
  const pathname = window.location.pathname;
  const [isCandidateExists, setIsCandidateExists] = useState(false);
  const jobId = pathname.split("/").pop();
  const [isFavorite, setIsFavorite] = useState(false);
  const history = useHistory();
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };
  const [userRole, setuserRole] = useState([]);
  const checkCandidateExists = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      if (currentUser && currentUser.localId) {
        const userId = currentUser.localId;
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/checkCandidateJobExists/${userId}/${jobId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setIsCandidateExists(response.data.message === "Candidate job exists.");
      }
    } catch (error) {
      console.error("Error checking candidate existence:", error);
    }
  };
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
          );
          const data = await response.json();
          console.log("data:")
          setuserRole(data);
          const response1 = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const dataCmp = await response1.json();
          console.log("dataCmp : ",dataCmp)
          
          setCompanies(dataCmp);
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
    checkCandidateExists();
  }, [jobId]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/read/${jobId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((response) => {
        setJob(response.data[0]);
        const filteredComp = companies.filter(job => job.companyid === response.data[0].companyid);
            console.log('filteredComp :',filteredComp[0])
        setCompany(filteredComp[0])
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des détails de l'emploi:",
          error
        );
      });
  }, [companies]);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  const handleApplyForJob = () => {
    const userId = userRole[0].email;
    localStorage.setItem("appliedJob", job.JobTitle);
    localStorage.setItem("appliedJobId", job.id);

        history.push("/cv-upload");
    
  };
  if (!job) {
    return <div>{lang.menu.loading}</div>;
  }
  
  function formatCalendarDate(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
  
    const locale = lang.language === "Français" ? "fr-FR" : "en-US";
    
    return new Date(date).toLocaleDateString(locale, options);
  }
  

  return (
    <Container style={{ fontFamily: "Poppins" }}>
      <Row>
        <Card>
          <Card.Header>
          <Col xl={5}>
            <Card.Title style={{ fontWeight: "bold" }}>
              {job.JobTitle}
            </Card.Title>
            </Col>
            {company && (
    <Col xl={3}>
      {company.logo && (
        <Image
        src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`}
          alt={`${company.companyName} Logo`}
          style={{ width: "50px", height: "50px", marginLeft: "10px" }}
          rounded
        />
      )}
      {" "}
      <span style={{ fontSize: "120%", color: "#000", fontWeight: "700" }}>
        {lang.menu.company}:
      </span>{" "}
      {company.companyName}
      
    </Col>
  )}
            <div className="d-flex align-items-center">
              <Button
                className="me-2 btn-outline-primary btn-rounded"
                onClick={handleApplyForJob}
                disabled={isCandidateExists}
                style={{ border: "none"}}
              >
                <BsCheckCircle />{" "}
                {isCandidateExists ? lang.menu.Applied : lang.menu.notApplied}
              </Button>
              <button
                onClick={toggleFavorite}
                style={{ border: "none", background: "none" }}
              >
                <BsBookmarkHeart
                  color={isFavorite ? "red" : "gray"}
                  size={32}
                />
              </button>
            </div>
            
          </Card.Header>
          
       
          <Card.Body>
      
            <Card.Text>
              {job.JobType === "Full Time" && (
                <Badge variant="primary ">{lang.menu.fullTime}</Badge>
              )}
              {job.JobType === "Part Time" && (
                <Badge variant="warning">{lang.menu.partTime}</Badge>
              )}
              {job.JobType === "FreeLance" && (
                <Badge variant="danger">{lang.menu.FreeLance}</Badge>
              )}
            </Card.Text>
            <Row>
              <Col>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <FiDollarSign /> {lang.menu.OfferedSalary}:
                </span>{" "}
                {job.OfferedSalary}
              </Col>
              <Col>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <FiTrendingUp /> {lang.menu.note}:
                </span>{" "}
                {job.Score}
              </Col>
              <Col>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <FiCheckCircle /> {lang.menu.status}:
                </span>{" "}
               <Badge variant="success">{job.Status}</Badge> 
              </Col>
              <Col>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <FiMapPin /> {lang.menu.country}:
                </span>{" "}
                {job.Country}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title style={{ fontWeight: "bold" }}>
              {lang.menu.jobDesc}
              </Card.Title>
              <Card.Text
      style={{
        cursor: 'pointer',
        display: isExpanded ? 'block' : '-webkit-box',
        WebkitLineClamp: isExpanded ? 'none' : 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      onClick={toggleDescription}
    >
      {job.JobDescription}
    </Card.Text>
          <br/>
              <div>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <GiSkills style={{ fontSize: "180%"}} /> {lang.menu.skills}: <br/>
                </span>
                <div style={{textIndent:"8%"}}>
                  {job.skills
                    .replace("[", "")
                    .replace("]", "")
                    .split("),")
                    .map((skill, index) => {
                      const skillParts = skill
                        .replace("('", "")
                        .replace("')", "")
                        .split("',");
                      return (
                        <Badge variant="warning" style={{margin:"1%"}}>
                          <span style={{textAlign:"center"}} key={index}>{skillParts[0]}</span>
                        </Badge>
                      );
                    })}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{backgroundColor:"#8cd6c8ba"}}>
            <Card.Body>
              <div>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <FiClock style={{ fontSize: "180%"}} /> {lang.menu.postDate}:<br/>
                </span>{" "}
                <p style={{textIndent:"8%"}}>{formatCalendarDate(job.PostedDate)}</p>
              </div>
              <div>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <FiClock style={{ fontSize: "180%"}} /> {lang.menu.CloseDate}: <br/>
                </span>{" "}
                <p style={{textIndent:"8%"}}>{formatCalendarDate(job.CloseDate)}</p>
              </div>
              <div>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <BiUserCheck style={{ fontSize: "180%"}} /> {lang.menu.experience}:<br/>
                </span>{" "}
                <p style={{textIndent:"8%"}}>
  {job.Experience === "intern" && lang.menu.intern}
  {job.Experience === "Junior" && lang.menu.Junior}
  {job.Experience === "10 Years or more" && lang.menu.y10Yearsormore}
  {job.Experience === "5 Years or more" && lang.menu.y5Yearsormore}
  {job.Experience === "2 Years or more" && lang.menu.y2Yearsormore}
</p>

              </div>
              <div>
                <span style={{ fontSize: "120%", color: "#000", fontWeight:"700" }}>
                  <FiBook style={{ fontSize: "180%"}}/> {lang.menu.TypeOfQualification}:<br/>
                </span>{" "}
               <p style={{textIndent:"8%"}}> {job.TypeOfQualification}</p>
              </div>
         
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default JobRoleDetails;
