import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import axios from 'axios';
import CVPreview from './CVPreview';
import SkillsSelector from './SkillsSelector';
import './CVEditor.css';
import CVicon from "../Dashboard/onboarding/upload_cv.png";
import translations from "../AppsMenu/Candidate/mulitlingue";
import FileInput from "../Dashboard/onboarding/file-input";

const CVEditor = ({ resumeId }) => {
  const [headerColor, setHeaderColor] = useState('#43dac2');
  const [textColor, setTextColor] = useState('#333');
  const [backgroundColor, setBackgroundColor] = useState('#fafafa');
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [company, setCompany] = useState("");
  const token=localStorage.getItem("token");
  const [sections, setSections] = useState([]);
  const [colorsPermission, setColorsPermission]= useState(false);
  const [languages, setLanguages] = useState([]);
  const [user, setUser] = useState("");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const languageLevels = [lang.menu.Beginner, lang.menu.Intermediate, lang.menu.Advanced];

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        localStorage.removeItem('GeneralInfo');
        localStorage.removeItem('Education');
        localStorage.removeItem('Experiences');
        localStorage.removeItem('TechnicalSkills');
        localStorage.removeItem('SoftSkills');
  
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setUser(currentUser)
        const userId = currentUser.localId;
  
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedCompany = response.data[0];
  
        setCompany(fetchedCompany);
  
        const response2 = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/skills/getAll`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedSkills = response2.data;
        setSkillsOptions(fetchedSkills);
  
        const response3 = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/languages/getAll`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedLanguage = response3.data;
        setLanguages(fetchedLanguage);
  
        const orderedSections = [
          { id: '1', title: 'Informations Générales', content: { firstName: '', lastName: '', country: '', email: '', github: '', linkedIn: '', phoneNumber: '', photo: null, description: '' }, isEnabled: fetchedCompany.sectionStatus['Informations Générales'], isVisible: true  },
          { id: '2', title: 'Expérience Professionnelle', content: [{ id: '2-1', company: '', startDate: '', endDate: '', location: '', post: '' }], isEnabled: fetchedCompany.sectionStatus['Expérience Professionnelle'] , isVisible: true },
          { id: '3', title: 'Formation', content: [{ id: '3-1', institution: '', degree: '', startDate: '', endDate: '', description: '' }], isEnabled: fetchedCompany.sectionStatus['Formation'] , isVisible: true },
          { id: '4', title: 'Compétences', content: [], isEnabled: fetchedCompany.sectionStatus['Compétences'], isVisible: true },
          { id: '5', title: 'Langues', content: [{ id: '5-1', language: '', level: '' }], isEnabled: fetchedCompany.sectionStatus['Langues'], isVisible: true  },
          { id: '6', title: 'Certificats', content: [{ id: '6-1', title: '', issuedBy: '', date: '' }], isEnabled: fetchedCompany.sectionStatus['Certificats'] , isVisible: true },
          { id: '7', title: 'Travail Bénévole', content: [{ id: '7-1', organization: '', role: '', startDate: '', endDate: '' }], isEnabled: fetchedCompany.sectionStatus['Travail Bénévole'], isVisible: true  },
        ];
  
        const sectionsOrder = fetchedCompany.sectionsOrderCompany;
  
        const validSections = orderedSections.filter(section => sectionsOrder[section.title] !== null);
  
        const ordered = validSections.sort((a, b) => {
          const orderA = sectionsOrder[a.title];
          const orderB = sectionsOrder[b.title];
          return orderA - orderB;
        });
        setSections(ordered);
  
        setHeaderColor(fetchedCompany.resumeColors.headerColor);
        setBackgroundColor(fetchedCompany.resumeColors.backgroundColor);
        setTextColor(fetchedCompany.resumeColors.textColor);
  
      } catch (error) {
        console.error("Erreur lors de la récupération de l'entreprise :", error);
      }
    };
    fetchCompany();
  }, [token]);
  
  

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setColorsPermission(
            data.some((permission) => permission.title === "resume-color")
          );
        } 
      }catch (error) {
          console.error("Error fetching permissions details:", error);
        }
        };

          fetchUserPermissions();
        }, []); 


  const handleUploadCV = async (event) => {
    event.preventDefault();
    setShowUploadPopup(true);
  };
  useEffect(() => {
    if (resumeId && company) {
      const fetchResumeData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Resume/findOne/${resumeId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          const data = response.data;
          const fetchedSectionsOrder = data.sectionsOrder;
          const fetchedSectionsOrdercomp = company.sectionsOrderCompany;

          const fetchedSections = [
            {
              id: '1',
              title: 'Informations Générales',
              content: {
                firstName: data.firstName || '',
                lastName: data.lastName || '',
                country: data.country || '',
                email: data.email || '',
                github: data.github || '',
                linkedIn: data.linkedIn || '',
                phoneNumber: data.phoneNumber || '',
                photo: data.photo ? `${process.env.REACT_APP_APP_DOMAIN}/api/images/userResume/${data.uid}/${data.photo}` : null,
                description: data.description || '',
              },
              isEnabled: company.sectionStatus['Informations Générales'],
              isVisible:company.sectionStatus['Informations Générales'],
            },
            {
              id: '2',
              title: 'Expérience Professionnelle',
              content: (data.experiences || []).map((exp, index) => ({
                id: `2-${index + 1}`,
                company: exp.company || '',
                startDate: exp.startDate || '',
                endDate: exp.endDate || '',
                location: exp.location || '',
                post: exp.post || '',
              })),
              isEnabled: company.sectionStatus['Expérience Professionnelle'],
              isVisible:company.sectionStatus['Expérience Professionnelle'],

            },
            {
              id: '3',
              title: 'Formation',
              content: (data.education || []).map((edu, index) => ({
                id: `3-${index + 1}`,
                institution: edu.institution || '',
                degree: edu.degree || '',
                startDate: edu.startDate || '',
                endDate: edu.endDate || '',
                description: edu.description || '',
              })),
              isEnabled: company.sectionStatus['Formation'],
            },
            {
              id: '4',
              title: 'Compétences',
              content: data.skills || [],
              isEnabled: company.sectionStatus['Compétences'],
              isVisible:company.sectionStatus['Compétences'],

            },
            {
              id: '5',
              title: 'Langues',
              content: (data.languages || []).map((lang, index) => ({
                id: `5-${index + 1}`,
                language: lang.language || '',
                level: lang.level || '',
              })),
              isEnabled: company.sectionStatus['Langues'],
              isVisible:company.sectionStatus['Langues'],

            },
            {
              id: '6',
              title: 'Certificats',
              content: (data.certificates || []).map((cert, index) => ({
                id: `6-${index + 1}`,
                title: cert.title || '',
                issuedBy: cert.issuedBy || '',
                date: cert.date || '',
              })),
              isEnabled: company.sectionStatus['Certificats'],
              isVisible:company.sectionStatus['Certificats'],

            },
            {
              id: '7',
              title: 'Travail Bénévole',
              content: (data.volunteer || []).map((vol, index) => ({
                id: `7-${index + 1}`,
                organization: vol.organization || '',
                role: vol.role || '',
                startDate: vol.startDate || '',
                endDate: vol.endDate || '',
              })),
              isEnabled: company.sectionStatus['Travail Bénévole'],
              isVisible:company.sectionStatus['Travail Bénévole'],

            },
          ];

          fetchedSections.sort((a, b) => {
            const orderA = fetchedSectionsOrdercomp[a.title] !== null ? fetchedSectionsOrdercomp[a.title] : Infinity;
            const orderB = fetchedSectionsOrdercomp[b.title] !== null ? fetchedSectionsOrdercomp[b.title] : Infinity;
            return orderA - orderB;
          });
          setSections(fetchedSections);
          setSelectedSkills(data.skills ? data.skills.map(skill => ({ value: skill, label: skill })) : []);
          setHeaderColor(data.headerColor || '#43dac2');
          setTextColor(data.textColor || '#333');
          setBackgroundColor(data.backgroundColor || '#fafafa');
        } catch (error) {
          console.error("Erreur lors du chargement du CV :", error);
        }
      };
      
      fetchResumeData();
    }
  }, [resumeId,company,skillsOptions,languages]);
  useEffect(() => {
    const populateFieldsFromLocalStorage = () => {
      const storedGeneralInfo = JSON.parse(localStorage.getItem('GeneralInfo'));
      const storedEducation = JSON.parse(localStorage.getItem('Education'));
      const storedExperiences = JSON.parse(localStorage.getItem('Experiences'));
      const storedSkills = JSON.parse(localStorage.getItem('TechnicalSkills'));
      const storedSoftSkills = JSON.parse(localStorage.getItem('SoftSkills'));
  
      if (storedGeneralInfo || storedEducation || storedExperiences || storedSkills || storedSoftSkills) {
        const newSections = [...sections];
  
        // Populate General Info
        if (storedGeneralInfo) {
          newSections[0].content = {
            ...newSections[0].content,
            firstName: storedGeneralInfo.FirstName || '',
            lastName: storedGeneralInfo.LastName || '',
            country: storedGeneralInfo.country[0] || '',
            email: storedGeneralInfo.email || '',
            github: storedGeneralInfo.github || '',
            linkedIn: storedGeneralInfo.linkedIn || '',
            phoneNumber: storedGeneralInfo.phoneNumber || '',
          };
        }
  
        // Populate Education
        if (storedEducation && newSections[2]) {
          newSections[2].content = storedEducation.map((edu, index) => ({
            id: `3-${index + 1}`,
            institution: edu.institution || '',
            degree: edu.degree || '',
            startDate: edu.startDate || '',
            endDate: edu.endDate || '',
            description: edu.description || '',
          }));
        }
  
        // Populate Experiences
        if (storedExperiences && newSections[1]) {
          newSections[1].content = storedExperiences.map((exp, index) => ({
            id: `2-${index + 1}`,
            company: exp.company || '',
            post: exp.post || '',
            location: exp.location || '',
            startDate: exp.startDate || '',
            endDate: exp.endDate || '',
          }));
        }
  
        // Populate Skills
        if (storedSkills && newSections[3]) {
          newSections[3].content = storedSkills.selectedSkills || [];

          const skillll=newSections[3].content ? newSections[3].content.map(skill => ({ value: skill, label: skill })): []
          setSelectedSkills(skillll);

        }
  
        // Populate Soft Skills (Languages)
        if (storedSoftSkills && newSections[4]) {
          newSections[4].content = storedSoftSkills.selectedLanguages.map((lang, index) => ({
            id: `5-${index + 1}`,
            language: lang,
            level: storedSoftSkills.proficiencyLevels[lang] || 'Débutant',
          }));
        }
        console.log("new sections:",newSections)
        setSections(newSections);
      }
    };
  
    populateFieldsFromLocalStorage();
  }, [showUploadPopup]); 
  
  const onDragEnd = (result) => {
    if (!result.destination) return;
    if(user.userRole===1|| user.userRole === 2){
      const reorderedSections = Array.from(sections);
      const [removed] = reorderedSections.splice(result.source.index, 1);
      reorderedSections.splice(result.destination.index, 0, removed);
      setSections(reorderedSections);
    }
    
  };

  const handleGeneralInfoChange = (field, value) => {
    const newSections = [...sections];
    newSections[0].content[field] = value;
    setSections(newSections);
  };

  const handleExperienceChange = (index, field, value, expIndex) => {
    const newSections = [...sections];
    newSections[index].content[expIndex][field] = value;
    setSections(newSections);
  };

  const handleAddExperience = (index) => {
    const newSections = [...sections];
    newSections[index].content.push({ id: `${index + 2}-${newSections[index].content.length + 1}`, company: '', startDate: '', endDate: '', location: '', post: '' });
    setSections(newSections);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newSections = [...sections];
        newSections[0].content.photo = reader.result; 
        setSections(newSections);
      };
      reader.readAsDataURL(file); 
    }
  };
  

  const handleAddEducation = (index) => {
    const newSections = [...sections];
    newSections[index].content.push({ id: `${index + 3}-${newSections[index].content.length + 1}`, institution: '', degree: '', startDate: '', endDate: '', description: '' });
    setSections(newSections);
  };

  const handleEducationChange = (index, field, value, eduIndex) => {
    const newSections = [...sections];
    newSections[index].content[eduIndex][field] = value;
    setSections(newSections);
  };

  const handleToggleSection = (index) => {
    // Empêche la désactivation de la section "Informations Générales"
    if (sections[index].title === 'Informations Générales') return;
  
    const newSections = [...sections];
    newSections[index].isEnabled = !newSections[index].isEnabled;
    setSections(newSections);
  };

  const handleAddLanguage = (index) => {
    const newSections = [...sections];
    newSections[index].content.push({ id: `${index + 5}-${newSections[index].content.length + 1}`, language: '', level: '' });
    setSections(newSections);
  };

  const handleLanguageChange = (index, field, value, langIndex) => {
    const newSections = [...sections];
    newSections[index].content[langIndex][field] = value;
    setSections(newSections);
  };

  const handleAddCertificate = (index) => {
    const newSections = [...sections];
    newSections[index].content.push({ id: `${index + 6}-${newSections[index].content.length + 1}`, title: '', issuedBy: '', date: '' });
    setSections(newSections);
  };

  const handleAddVolunteerWork = (index) => {
    const newSections = [...sections];
    newSections[index].content.push({ id: `${index + 7}-${newSections[index].content.length + 1}`, organization: '', role: '', startDate: '', endDate: '' });
    setSections(newSections);
  };

  const handleCertificateChange = (index, field, value, certIndex) => {
    const newSections = [...sections];
    newSections[index].content[certIndex][field] = value;
    setSections(newSections);
  };

  const handleVolunteerChange = (index, field, value, volIndex) => {
    const newSections = [...sections];
    newSections[index].content[volIndex][field] = value;
    setSections(newSections);
  };

  const upRefreshData = () => {
    setRefreshData(refreshData + 1);
  };
  const handleClose = () => {
    setShowUploadPopup(false);
    
 
  };
  const handleRemoveItem = (sectionIndex, itemIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].content.splice(itemIndex, 1); 
    setSections(newSections);
  };
  const handleSetVisibility = (index, isVisible) => {
    const newSections = [...sections];
    newSections[index].isVisible = isVisible;
    setSections(newSections);
  };
  
  return (
    <div style={{ display: 'flex', padding: '20px', backgroundColor: '#f5f5f5' }}>
   
      <Row>
        <Col xl={6}>
          <Card>
            <DragDropContext onDragEnd={user && (user.userRole === 1 || user.userRole === 2) ? onDragEnd : null}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="editor">
                       <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <h3 style={{ margin: '0 10px', fontSize: '18px', fontWeight: '500', color: '#333' }}>
          {lang.menu.oucv}
        </h3>
        <img
          src={CVicon}
          alt="Import CV Icon"
          style={{
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            marginLeft: '8px',
            transition: 'transform 0.2s',
          }}
          onClick={handleUploadCV}
          title="Importer un CV pour extraire les informations automatiquement"
        />
      </div> 
      {colorsPermission ? (
                    <div className="color-picker-section" style={{ marginBottom: '20px' }}>
                      <h5>{lang.menu.persoColor}</h5>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>{lang.menu.headerColor}</Form.Label>
                            <Form.Control type="color" value={headerColor} onChange={(e) => setHeaderColor(e.target.value)} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>{lang.menu.textColor}</Form.Label>
                            <Form.Control type="color" value={textColor} onChange={(e) => setTextColor(e.target.value)} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>{lang.menu.backgroundColor}</Form.Label>
                            <Form.Control type="color" value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
              
      ):(<></>)}
          
                    {sections.map((section, index) => (
  <Draggable key={section.id} draggableId={section.id} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="section draggable-section"
      >
        {section.isEnabled && (
          <>
     <Row>
  <Col>
    {/* Interrupteur pour rendre visible */}
    <Form.Check
      type="switch"
      id={`make-visible-section-${index}`}
      label= {lang.menu.RendreVisible}
      checked={section.isVisible}
      onChange={() => handleSetVisibility(index, true)} 
    />
  </Col>
  <Col>
    <Form.Check
      type="switch"
      id={`make-hidden-section-${index}`}
      label= {lang.menu.Masquer}
      checked={!section.isVisible}
      onChange={() => handleSetVisibility(index, false)} 
    />
  </Col>
</Row>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          > 
         
            <h4 style={{ margin: 0 }}>  {
                                {
                                  'Informations Générales': lang.menu.generalInfo,
                                  'Expérience Professionnelle': lang.menu.experPerso,
                                  'Formation': lang.menu.Education,
                                  'Compétences': lang.menu.skills,
                                  'Langues': lang.menu.Language,
                                  'Certificats': lang.menu.certificats,
                                  'Travail Bénévole': lang.menu.Volunteerings,
                                }[section.title] || section.title 
                              }</h4>
          
            {user && (user.userRole === 1 || user.userRole === 2) && (
    <span
      style={{
        cursor: 'grab', 
        fontSize: '14px',
        color: '#999',
      }}
    >
      <i className="fas fa-arrows-alt"></i> {lang.menu.glisser}
    </span>
  )}
          </div>
          <br/></>
        )}
        {section.isEnabled && (
          <>
    {section.isEnabled ? (
                            <>
                              {section.title === 'Informations Générales' ? (
                                <div className="general-info">
                                       <textarea 
                                    value={section.content.description}
                                    onChange={(e) => handleGeneralInfoChange('description', e.target.value)}
                                    placeholder={lang.menu.description}
                                    className="info-input"
                                    maxLength={4500}
                                    required
                                  />
                                   <input
                                      type="file"
                                      accept="image/*"
                                      onChange={handlePhotoChange}
                                      className="info-input"
                                    />
                                    {section.content.photo && (
                                      <img
                                        src={section.content.photo}
                                        alt="Profil"
                                        style={{ width: '100px', height: '100px', borderRadius: '50%', margin: '10px 0' }}
                                      />
                                    )}
                                  <input
                                    type="text"
                                    value={section.content.firstName}
                                    onChange={(e) => handleGeneralInfoChange('firstName', e.target.value)}
                                    placeholder={lang.menu.firstName}
                                    className="info-input"
                                    required
                                  />
                                  <input
                                    type="text"
                                    value={section.content.lastName}
                                    onChange={(e) => handleGeneralInfoChange('lastName', e.target.value)}
                                    placeholder={lang.menu.lastName}
                                    className="info-input"
                                    required
                                  />
                                  <input
                                    type="text"
                                    value={section.content.country}
                                    onChange={(e) => handleGeneralInfoChange('country', e.target.value)}
                                    placeholder={lang.menu.country}
                                    className="info-input"
                                  />
                                  <input
                                    type="email"
                                    value={section.content.email}
                                    onChange={(e) => handleGeneralInfoChange('email', e.target.value)}
                                    placeholder={lang.menu.email}
                                    className="info-input"
                                    required
                                  />
                                  <input
                                    type="text"
                                    value={section.content.github}
                                    onChange={(e) => handleGeneralInfoChange('github', e.target.value)}
                                    placeholder={lang.menu.GitHub}
                                    className="info-input"
                                  />
                                  <input
                                    type="text"
                                    value={section.content.linkedIn}
                                    onChange={(e) => handleGeneralInfoChange('linkedIn', e.target.value)}
                                    placeholder={lang.menu.LinkedIn}
                                    className="info-input"
                                  />
                                  <input
                                    type="text"
                                    value={section.content.phoneNumber}
                                    onChange={(e) => handleGeneralInfoChange('phoneNumber', e.target.value)}
                                    placeholder={lang.menu.phone}
                                    className="info-input"
                                  />
                               
                                </div>
                              ) : section.title === 'Expérience Professionnelle' ? (
                                <>
                                  {section.content.map((exp, expIndex) => (
                                    <div key={expIndex} className="experience-entry">
                                        <button
                                      onClick={() => handleRemoveItem(index, expIndex)}
                                      style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        background: 'none',
                                        border: 'none',
                                        color: 'f01111', 
                                        fontSize: '24px', 
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        lineHeight: '1'
                                      }}
                                      aria-label="Supprimer"
                                    >
                                      ✕
                                    </button>
                              
                                      <input
                                        type="text"
                                        value={exp.company}
                                        onChange={(e) => handleExperienceChange(index, 'company', e.target.value, expIndex)}
                                        placeholder={lang.menu.company}
                                        className="experience-input"
                                        required
                                      />
                                      <input
                                        type="text"
                                        value={exp.startDate}
                                        onChange={(e) => handleExperienceChange(index, 'startDate', e.target.value, expIndex)}
                                        placeholder={lang.menu.dateDebut}
                                        className="experience-input"
                                        required
                                      />
                                      <input
                                        type="text"
                                        value={exp.endDate}
                                        onChange={(e) => handleExperienceChange(index, 'endDate', e.target.value, expIndex)}
                                        placeholder={lang.menu.dateFin}
                                        className="experience-input"
                                        required
                                      />
                                      <input
                                        type="text"
                                        value={exp.location}
                                        onChange={(e) => handleExperienceChange(index, 'location', e.target.value, expIndex)}
                                        placeholder={lang.menu.locality}
                                        className="experience-input"
                                      />
                                      <textarea
                                        value={exp.post}
                                        onChange={(e) => handleExperienceChange(index, 'post', e.target.value, expIndex)}
                                        placeholder={lang.menu.description}
                                        className="experience-input"
                                      />
                                      <br/>
                                 
                                    </div>
                                  ))}
                                  <Button variant='success ' onClick={() => handleAddExperience(index)}> {lang.menu.ajouExp}</Button>
                                </>
                              ) : section.title === 'Formation' ? (
                                <>
                                  {section.content.map((edu, eduIndex) => (
                                    <div key={eduIndex} className="education-entry">
                                       <button
                                      onClick={() => handleRemoveItem(index, eduIndex)}
                                      style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        background: 'none',
                                        border: 'none',
                                        color: 'f01111', 
                                        fontSize: '24px', 
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        lineHeight: '1'
                                      }}
                                      aria-label="Supprimer"
                                    >
                                      ✕
                                    </button>
                                      <input
                                        type="text"
                                        value={edu.institution}
                                        onChange={(e) => handleEducationChange(index, 'institution', e.target.value, eduIndex)}
                                        placeholder={lang.menu.EdIn}
                                        className="education-input"
                                        required
                                      />
                                      <input
                                        type="text"
                                        value={edu.degree}
                                        onChange={(e) => handleEducationChange(index, 'degree', e.target.value, eduIndex)}
                                        placeholder={lang.menu.Degree}
                                        className="education-input"
                                        required
                                      />
                                      <input
                                        type="text"
                                        value={edu.startDate}
                                        onChange={(e) => handleEducationChange(index, 'startDate', e.target.value, eduIndex)}
                                        placeholder={lang.menu.dateDebut}
                                        className="education-input"
                                        required
                                      />
                                      <input
                                        type="text"
                                        value={edu.endDate}
                                        onChange={(e) => handleEducationChange(index, 'endDate', e.target.value, eduIndex)}
                                        placeholder={lang.menu.dateFin}
                                        className="education-input"
                                        required
                                      />
                                      <input
                                        type="text"
                                        value={edu.description}
                                        onChange={(e) => handleEducationChange(index, 'description', e.target.value, eduIndex)}
                                        placeholder={lang.menu.description}
                                        className="education-input"
                                      />
                                    </div>
                                  ))}
                                  <Button variant='success ' onClick={() => handleAddEducation(index)}>{lang.menu.ajouform}</Button>
                                </>
                              )  : section.title === 'Langues' ? (
                                <>
                                 {section.content.map((langu, langIndex) => (
      <div key={langIndex} className="language-entry">
         <button
                                      onClick={() => handleRemoveItem(index, langIndex)}
                                      style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        background: 'none',
                                        border: 'none',
                                        color: 'f01111', 
                                        fontSize: '24px', 
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        lineHeight: '1'
                                      }}
                                      aria-label="Supprimer"
                                    >
                                      ✕
                                    </button>

<select
                                          value={langu.language}
                                          onChange={(e) => handleLanguageChange(index, 'language', e.target.value, langIndex)}
                                          className="language-input"
                                        >
                                          <option value="">{lang.menu.salu}</option>
                                          {languages.map((language) => (
                                            <option key={language} value={language}>
                                              {language}
                                            </option>
                                          ))}
                                        </select>
        <select
          value={langu.level}
          onChange={(e) => handleLanguageChange(index, 'level', e.target.value, langIndex)}
          className="language-input"
        >
          <option value="">{lang.menu.level}</option>
          {languageLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </select>
      </div>
    ))}
                                  <Button variant='success' onClick={() => handleAddLanguage(index)}>{lang.menu.ajoulang}</Button>
                                </>
                              ): section.title === 'Compétences' ? (
                                skillsOptions && skillsOptions.length > 0 ? (
                                  <SkillsSelector 
                                    skills={skillsOptions} 
                                    selectedSkills={selectedSkills} 
                                    setSkills={(newSkills) => {
                                      const updatedSections = [...sections];
                                      updatedSections[3].content = newSkills.map(skill => skill.value); 
                                      setSections(updatedSections);
                                      setSelectedSkills(newSkills);
                                    }} 
                                  />
                                ) : null 
                              ) : section.title === 'Certificats' ? (
                                <>
                                  {
                                section.content.map((cert, certIndex) => (
                                  <div key={certIndex} className="cert-entry">
                                 
                                     <button
                                      onClick={() => handleRemoveItem(index, certIndex)}
                                      style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        background: 'none',
                                        border: 'none',
                                        color: 'f01111', 
                                        fontSize: '24px', 
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        lineHeight: '1'
                                      }}
                                      aria-label="Supprimer"
                                    >
                                      ✕
                                    </button>
                                    <input
                                      type="text"
                                      value={cert.title}
                                      onChange={(e) => handleCertificateChange(index, 'title', e.target.value, certIndex)}
                                      placeholder={lang.menu.certifTitle}
                                      className="info-input"
                                      required
                                    />
                                    <input
                                      type="text"
                                      value={cert.issuedBy}
                                      onChange={(e) => handleCertificateChange(index, 'issuedBy', e.target.value, certIndex)}
                                      placeholder={lang.menu.certifDeliv}
                                      className="info-input"
                                    />
                                    <input
                                      type="text"
                                      value={cert.date}
                                      onChange={(e) => handleCertificateChange(index, 'date', e.target.value, certIndex)}
                                      placeholder="Date"
                                      className="info-input"
                                    />
                                  </div>
                                ))}                                   
                                 <Button variant='success '  onClick={() => handleAddCertificate(index)}>{lang.menu.ajouCert}</Button>
                                </>
                              ) : section.title === 'Travail Bénévole' ? (
                                <>
                                  {
                                section.content.map((vol, volIndex) => (
                                  <div key={vol.id} className='vol-entry'>
                                     <button
                                      onClick={() => handleRemoveItem(index, volIndex)}
                                      style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        background: 'none',
                                        border: 'none',
                                        color: 'f01111', 
                                        fontSize: '24px', 
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        lineHeight: '1'
                                      }}
                                      aria-label="Supprimer"
                                    >
                                      ✕
                                    </button>
                                    <input
                                      type="text"
                                      value={vol.organization}
                                      onChange={(e) => handleVolunteerChange(index, 'organization', e.target.value, volIndex)}
                                      placeholder={lang.menu.Organization}
                                      className="info-input"
                                      required
                                    />
                                    <input
                                      type="text"
                                      value={vol.role}
                                      onChange={(e) => handleVolunteerChange(index, 'role', e.target.value, volIndex)}
                                      placeholder={lang.menu.role}
                                      className="info-input"
                                    />
                                    <input
                                      type="text"
                                      value={vol.startDate}
                                      onChange={(e) => handleVolunteerChange(index, 'startDate', e.target.value, volIndex)}
                                      placeholder={lang.menu.dateDebut}
                                      className="info-input"
                                    />
                                    <input
                                      type="text"
                                      value={vol.endDate}
                                      onChange={(e) => handleVolunteerChange(index, 'endDate', e.target.value, volIndex)}
                                      placeholder={lang.menu.dateFin}
                                      className="info-input"
                                    />
                                  </div>
                                ))}
                          <Button variant='success ' onClick={() => handleAddVolunteerWork(index)}>{lang.menu.ajouVoln}</Button>

                                </>
                              ) : null}
                            </>
                          ) : null}          </>
        )}
      </div>
    )}
  </Draggable>
))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Card>
        </Col>
        <Col xl={6}>
          <CVPreview sections={sections} backgroundColor={backgroundColor} textColor={textColor} headerColor={headerColor}  resumeId={resumeId} company={company} />
        </Col>
      </Row>
      {showUploadPopup && (
        <>
          <div className="overlay" onClick={handleClose}></div>

          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              width: "40%",
              minWidth: "180px",

              maxWidth: "100%",
              textAlign: "center",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: "8px",
              boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
              zIndex: 999,
            }}
          >
            <FileInput
              lang={lang}
              upRefreshData={upRefreshData}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CVEditor;
