import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card ,Modal,ListGroup,Button,Badge} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";
import translations from "../../AppsMenu/Candidate/mulitlingue";
import { FaBrain, FaCheckCircle, FaCheck } from "react-icons/fa";

function TechQCMDetails() {
  const [testDetails, setTestDetails] = useState();
  const token=localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null); 
  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const pathname = window.location.pathname;
  const idtest = pathname.split("/").pop();
  const history = useHistory();
  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };

  const getImageUrlFromServer = (imageName, Name, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/images/${Name}/${id}/${imageName}`;
  };

  const getImageUrl = (imageRef, id, skillName) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, skillName, id);
      return imageUrl;
    }
    return null;
  };

  const renderContent = (content, id, skillName) => {
    const imageUrl = getImageUrl(content, id, skillName);

    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " />
        </div>
      );
    } else {
      return <span style={{ fontSize: "12px" }}>{content}</span>;
    }
  };

  useEffect(() => {
    const fetchTestDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getTestWithQuestionsById/${idtest}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        console.log("tests", response.data);
        setTestDetails(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTestDetails();
  }, []);
  const renderContentImg = (content, id,skill_name) => {
    const imageUrl = getImageUrl(content, id,skill_name);
  
    const handleImageLoad = (e) => {
      const img = e.target;
      const { naturalWidth, naturalHeight } = img;
  
      // Si l'image est plus grande que 100x90, on redimensionne
      if (naturalWidth >100 || naturalHeight > 90) {
        const aspectRatio = naturalWidth / naturalHeight;
  
        // Ajuster la taille tout en conservant les proportions
        if (aspectRatio > 1) {
          // Image plus large que haute
          img.style.width = "500px";
          img.style.height = `${500 / aspectRatio}px`;
        } else {
          // Image plus haute que large
          img.style.width = `${400 * aspectRatio}px`;
          img.style.height = "400px";
        }
      }
    };
  
    if (imageUrl) {
      return (
        <>
        <div className="frame center">
          {/* On utilise onLoad pour contrôler la taille après le chargement */}
          <img
            src={imageUrl}
            alt=" "
            onLoad={handleImageLoad}
            style={{ cursor: "pointer",maxWidth: "100%", maxHeight: "100%" }}
            onClick={() => openModal(imageUrl)} 
          />
        </div>
        <Modal show={showModal} onHide={closeModal} centered size="l">
          <Modal.Body className="p-0">
            <img
              src={modalImageUrl}
              alt=" "
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Modal.Body>
        </Modal>

        </>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleBonneReponseClick = (question) => {
    setSelectedQuestion(question);
  };
  const getBonneReponseLabel = (question) => {
    switch (question.BonneReponse) {
      case "choix1":
        return `${lang.menu.choix1}`;
      case "choix2":
        return `${lang.menu.choix2}`;
      case "choix3":
        return `${lang.menu.choix3}`;
      case "choix4":
        return `${lang.menu.choix4}`;
      default:
        return ""; 
    }
  };
  const getLevelLabel = (question) => {
    switch (question.level) {
      case "Beginner":
        return <Badge variant="success light" style={{ fontSize: '14px' }}>{lang.menu.Beginner}</Badge>;
      case "Intermediate":
        return  <Badge variant="warning light" style={{ fontSize: '14px' }}>{lang.menu.Intermediate}</Badge>;
      case "Advanced":
        return     <Badge variant="danger light" style={{ fontSize: '14px' }}>{lang.menu.Advanced}</Badge>;
      default:
        return ""; 
    }
  };
  return (
    <div style={{ fontFamily: "Poppins" }}>
     
      {testDetails && (
        <div>
          <h2 className="text-center my-4">{testDetails.test_name}</h2>
          <p className="text-muted text-center mb-5">{testDetails.description}</p>
          
          {testDetails.skills.map((skill) => (
            <Card key={skill.skill_id} className="mb-4 shadow-sm">
              <Card.Header   className="text-white" 
            style={{ 
              backgroundColor: "#74188D", 
              fontWeight: "bold", 
              fontSize: "1.2rem",
              textTransform: "uppercase" 
            }}>
                
                <h4 className="mb-0"><FaBrain className="mr-2" />{skill.skill_name}</h4>
              </Card.Header>

              <Card.Body>
                {skill.questions.map((question, index) => (
                  <Card key={question.id} className="mb-3">
                    <Card.Header className="d-flex align-items-center">
                     {getLevelLabel(question)}
                      <h5 className="mb-0">{lang.menu.Question} {index + 1}</h5>
                    </Card.Header>

                    <Card.Body>
                    <Card.Title
              className="mb-4"
              style={{
                color: "#333",
                textAlign: "left",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >{question.question}</Card.Title>

                      {question.imageRef && 
                        renderContentImg(
                          question.imageRef,
                          question.id,
                          skill.skill_name
                        )}

                      <p className="mt-3">{lang.menu.choix} :</p>

                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          1. {renderContent(question.choix1, question.id, skill.skill_name)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          2. {renderContent(question.choix2, question.id, skill.skill_name)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          3. {renderContent(question.choix3, question.id, skill.skill_name)}
                        </ListGroup.Item>
                        {question.choix4 && (
                          <ListGroup.Item>
                            4. {renderContent(question.choix4, question.id, skill.skill_name)}
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                      <div className="d-flex align-items-center">
              <Button
                variant="success"
                onClick={() => handleBonneReponseClick(question)}
                className="me-3"
              >
                <FaCheckCircle className="me-2" /> {lang.menu.BonneReponse}
              </Button>
              {selectedQuestion === question && (
            <strong style={{ color: "#74188D", fontSize: "1.3rem" }}>
              {getBonneReponseLabel(question)}
            </strong>
          )}
            </div>
                    </Card.Body>
                  </Card>
                ))}
              </Card.Body>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}

export default TechQCMDetails;
