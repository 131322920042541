import React, { useState, useEffect } from "react";
import { gapi } from "gapi-script";
import Select from "react-select";
import swal from "sweetalert";
import Button from "react-bootstrap/Button";
import axios from 'axios';

const GOOGLE_CLIENT_ID = "1087878034865-o9kvh6r3d53dl2c8a4kak2lqbbcth3er.apps.googleusercontent.com";
const API_KEY = "AIzaSyCohyaFKqNEr5Y_bpaii57UVnoLmUSuz1A";
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

const MeetScheduler = () => {
  const [selectedRecruteurs, setSelectedRecruteurs] = useState([]);
  const [dateTime, setDateTime] = useState("");
  const token = localStorage.getItem("token");
  const [recruiters, setRecruiters] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const getUserCompanyID = async (userId) => {
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };

  useEffect(async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      if (currentUser && currentUser.localId) {
        const userId = currentUser.localId;
        const compId = await getUserCompanyID(userId);

        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const allUsers = response.data;

        const filterRecruiter = allUsers.filter(user => user.companyID === compId && (user.userRoleID === 3 || user.userRoleID === 2 || user.userRoleID === 1));
        const filterCandidates = allUsers.filter(user => user.companyID === compId && (user.userRoleID === 4 || user.userRoleID === 5));

        setRecruiters(filterRecruiter);
        setCandidates(filterCandidates);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const initClient = () => {
      gapi.load("client:auth2", () => {
        gapi.client.init({
          apiKey: API_KEY,
          clientId: GOOGLE_CLIENT_ID,
          scope: SCOPES,
        });
      });
    };
    initClient();
  }, []);

  const scheduleMeet = async () => {
    try {
      const authInstance = gapi.auth2.getAuthInstance();
      if (!authInstance.isSignedIn.get()) {
        await authInstance.signIn();
      }

      const startTime = new Date(dateTime).toISOString();
      const endTime = new Date(new Date(dateTime).getTime() + 60 * 60 * 1000).toISOString();

      const event = {
        summary: `Entretien avec ${selectedCandidate ? selectedCandidate.FirstName : ""} ${selectedCandidate ? selectedCandidate.LastName : ""}`,
        start: { dateTime: startTime, timeZone: "UTC" },
        end: { dateTime: endTime, timeZone: "UTC" },
        attendees: [
          { email: selectedCandidate ? selectedCandidate.email : "" },
          ...selectedRecruteurs.map((email) => ({ email })),
        ],
        conferenceData: {
          createRequest: {
            requestId: "123456",
            conferenceSolutionKey: { type: "hangoutsMeet" },
          },
        },
      };

      const response = await gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
        conferenceDataVersion: 1,
      });

      const meetLink = response.result.hangoutLink;
      swal("Succès", `Lien généré : ${meetLink}`, "success");
    } catch (error) {
      console.error("Error scheduling meet:", error);
      swal("Erreur", "Impossible de planifier la réunion", "error");
    }
  };

  const handleCandidateChange = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      const candidate = candidates.find(c => c.email === selectedOption[0].value);
      setSelectedCandidate(candidate);
    }
  };

  return (
    <div>
      <h2>Planifier une réunion Google Meet</h2>

      <Select
        className={`form-control`}
        isMulti
        options={recruiters.map((recruiter) => ({
          value: recruiter.email,
          label: `${recruiter.email}`
        }))}
        onChange={(options) => setSelectedRecruteurs(options.map((option) => option.value))}
        placeholder="Sélectionnez les recruteurs"
      />
<br/>
      <Select
        className={`form-control`}
        options={candidates.map((cand) => ({
          value: cand.email,
          label: `${cand.email}`
        }))}
        onChange={handleCandidateChange}
        placeholder="Sélectionnez le candidat"
      />
<br/>
      <input
        className={`form-control`}
        type="datetime-local"
        value={dateTime}
        onChange={(e) => setDateTime(e.target.value)}
        style={{ display: "block", margin: "10px 0" }}
      />

      <Button variant="primary" type="submit" onClick={scheduleMeet}>Planifier la réunion</Button>
    </div>
  );
};

export default MeetScheduler;
