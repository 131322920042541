import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Modal, Badge,Form } from 'react-bootstrap';
import "./jobProfile.css"; // Import the CSS file
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import translations from "../AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle"
import { AiOutlinePieChart } from "react-icons/ai";

function JobProfileList() {
  const [viewJobs, setViewJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [quotaMax, setQuotaMax] = useState("");
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setViewJobs(
            data.some((permission) => permission.title === "view-job")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false); 

  useEffect(() => {
    if (!viewJobs) {
      setShowAccessDeniedModal(true); 
    }
  }, [viewJobs]);

  const [user, setUser] = useState("");
	const [compId, setCompId] = useState("");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const [isDisable, setIsDisable] = useState("");

  useEffect(() => {
		const fetchData = async () => {
		  try {
			const currentUser = JSON.parse(localStorage.getItem('userDetails'));
			const userId = currentUser.localId;
	  
			const userResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
			if (!userResponse.ok) {
			  throw new Error(`Error fetching user details: ${userResponse.status}`);
			}
			const userData = await userResponse.json();
	  
			setUser({
			  userId: userId,
			  firstName: userData[0].FirstName || "",
			  lastName: userData[0].LastName || "",
			  email: userData[0].email || "",
			  password: userData[0].Password || "",
			  country: userData[0].Country || "",
			  jobTitle: userData[0].JobTitle || "",
			  phoneNumber: userData[0].PhoneNumber || "",
			  language: userData[0].Language || "",
			  avatar: userData[0].Avatar || "",
			  cover: userData[0].Cover || "",
			  signature: userData[0].EmailSignature || "",
			  role: userData[0].userRole || "",
			  disabled: userData[0].disabled,
			  roleName: userData[0].roleName || "",
			  compId: userData[0].companyID || ""			});
	  
			setCompId(userData[0].companyID)
      if(userData[0].companyID!==1 ){

          const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${userData[0].companyID}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const subscriptionData = await subscriptionResponse.json();
          console.log("comp id sub:", subscriptionData);
          if(subscriptionResponse.status===200){
            setQuotaMax(subscriptionData[2])
            const isDisabled = subscriptionData[2].numberquota_max <=  subscriptionData[2].quotaJobProfile;
            setIsDisable(isDisabled)
          }
    }
    if(userData[0].companyID===1 && userData[0].userRole===3){

      const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const subscriptionData = await subscriptionResponse.json();
      console.log("comp id sub:", subscriptionData);
      if(subscriptionResponse.status===200){
        setQuotaMax(subscriptionData[2])
        const isDisabled = subscriptionData[2].numberquota_max <=  subscriptionData[2].quotaJobProfile;
        setIsDisable(isDisabled)
      }
}
		  } catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, []);
  const [profiles, setProfiles] = useState([]);

  async function fetchProfiles() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getAllProfiles`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
console.log("user : ",user)
let filteredJobsProf;
console.log("profil : ",response.data)

if (user.compId!=1) {
   filteredJobsProf = response.data.filter((job) => job.companyid === compId);
   console.log("profil 1: ",response.data)

}
else{
  if(user.role==1){

    filteredJobsProf = response.data;

    console.log("profil 2 : ",response.data)

  }else if(user.role==3){
    filteredJobsProf = response.data.filter(job =>job.companyid === compId && job.creator_uid===user.userId );
    console.log("profil 3: ",response.data)

}}
      setProfiles(filteredJobsProf);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  }

  useEffect(() => {
    if(user!=""){
      fetchProfiles();

    }
  }, [compId,user]);
  const filterprofilesByTitle = () => {
    return profiles.filter(job => job.title.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleDelete = (id) => {
    swal({
      title: lang.menu.confirmCancelTitle,
      text: lang.menu.confirmcancelTetxt,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/profile/delete/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res);
  
            if (res.status === 200) {
              swal("Poof! Profile has been deleted!", {
                icon: "success",
              });
              fetchProfiles(); 
            } else if (res.status === 400 && res.data.Message) {
              swal(lang.menu.deleteNotAllowed, lang.menu.deleteNotAllowedText, "warning");
            }
          })
          .catch((err) => {
            console.error(err);
  
            // Vérifier s'il y a un message d'erreur spécifique dans la réponse du serveur
            if (err.response && err.response.status === 400 && err.response.data.Message) {
              swal(lang.menu.deleteNotAllowed, lang.menu.deleteNotAllowedText, "warning");
            } else {
              swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
            }
          });
      } else {
        // L'utilisateur a annulé l'action de suppression
        swal(lang.menu.deleteCancel, {
          icon: "info",
        });
      }
    });
  };
  
  return (
    <>
    {viewJobs ? (
    <div className="job-profile-container">
       <PageTitle
        activeMenu={lang.menu.JobProfileList}
        motherMenu={lang.menu.jobs}
        pageContent="Candidate"
      />
      <br />
      <Row className="mb-3">
        <Col xs={7}>
          <Form.Group controlId="formBasicSearch">
            <Form.Control
              type="text"
              placeholder={lang.menu.serachJob}
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form.Group>
        </Col>
        <Col xs={1} className="d-flex offset-md-1">
    {quotaMax ? (
      <Badge className="d-flex align-items-center justify-content-center badge-rounded" 
      variant="outline-danger"      style={{ color: quotaMax.quotaJobProfile >=  quotaMax.numberquota_max ? 'red' : '#74188D' }}>
        <AiOutlinePieChart size={20} />{' '}
        {lang.menu.quotaUsed} : {quotaMax.quotaJobProfile}/{quotaMax.numberquota_max}
      </Badge>
    ) : null}
  </Col>
  <Col xs={2} className="d-flex align-items-end justify-content-end offset-md-1">
  {compId && compId!==1 ? (
              <Link
              to={isDisable ? "#" : "/job-profile"}
              className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
              onClick={e => isDisable && e.preventDefault()}
              >
            <i className="fas fa-plus"></i> {lang.menu.AddProfiles}
          </Link>
        ):

        compId && compId===1 && user &&user.userRole===3  ? (
          <Link
          to={isDisable ? "#" : "/job-profile"}
          className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
          onClick={e => isDisable && e.preventDefault()}
          >
        <i className="fas fa-plus"></i> {lang.menu.AddProfiles}
      </Link>)
        
        :(
        <Link to="/job-profile" className="btn btn-primary test-list-button">
            <i className="fas fa-plus"></i> {lang.menu.AddProfiles}
          </Link>)}
        </Col>
      </Row>
      <Container>
      <Row>
      {filterprofilesByTitle().map(profile => (
      
          <Col key={profile.id} xs={12} sm={6} md={4}>
            <Card>
              <Card.Body style={{ fontFamily: "Poppins" }}>
                <Card.Title style={{ fontSize: "180%",fontWeight:"700", color: "#000",textAlign:"center" }}>{profile.title}</Card.Title>
                <Card.Text style={{
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}}>
  {profile.description}
</Card.Text>
                <br/>
                <Row>
                  <Col> <p>
                <strong>{lang.menu.RequiredQualifications2}:</strong><br/>
                {profile.requiredQualifications
                  .replace(/[\[\]']+/g, "")
                  .split(/\), /)
                  .map((qualification, index) => {
                    const [skill, count] = qualification.split(", ");
                    const cleanedSkill = skill.replace(/[\(\)]/g, "").trim();
                    const cleanCoefficient = count.replace(/[\(\)]/g, "");
                    return (
                      <Badge key={index} className="badge-success" style={{margin:"1%"}}>
                        {cleanedSkill}({cleanCoefficient})
                      </Badge>
                    );
                  })}
              </p></Col>
                </Row>
                <Row>
                  <Col><p><strong>{lang.menu.language}:</strong>  {JSON.parse(profile.languages).join(", ")}</p></Col>
                </Row>
                <Row style={{textAlign:"center"}}>
                  <Col> <button
                  style={{ border: "none" }}
                  onClick={() => handleDelete(profile.id)}
                >
                  <i className="fas fa-trash-alt fa-lg"></i>
                </button></Col>
                <Col>
                <Link
                  to={`/update-job-profile/${profile.id}`}
                  className="test-details-link"
                >
                  <i className="fas fa-pencil-alt fa-lg"></i>
                </Link></Col>
                <Col><Link to={`/post-job`} className="test-details-link">
                  <i className="fas fa-arrow-circle-right fa-lg"></i>
                </Link></Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
     
    </div>
       ) : (
        <Modal show={showAccessDeniedModal} >
        <Modal.Header>
          <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You don't have permission to view this page.
        </Modal.Body>
        <Modal.Footer>
    
        </Modal.Footer>
      </Modal>
     )}
     </>
  );
}

export default JobProfileList;
