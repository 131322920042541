import React,{useState,useContext, useEffect,Fragment,useCallback} from 'react';
import {Link,useHistory} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Dropdown,Badge,Row,Col,Button, Card, Modal,Form} from 'react-bootstrap';
import { MdMarkEmailRead } from "react-icons/md";
import axios from 'axios';
import translations from"../AppsMenu/Candidate/mulitlingue";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import VacanyTab from '../Jobick/Home/VacanyTab';
import CanvasChartTab from '../Jobick/Home/CanvasChartTab';
import FeaturedCompanies from '../Jobick/Home/FeaturedCompanies';
import RecentActivity from '../Jobick/Home/RecentActivity';
import HomeSlider from '../Jobick/Home/HomeSlider';
import { BsCalendarDate } from "react-icons/bs";
import { FaSortAmountUp } from "react-icons/fa";

import { Icon } from "@iconify/react";
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/actions/AuthActions';
//Images
import pic1 from './../../../images/profile/pic1.jpg';

//Components
const NewCustomers1 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers1"), 1000)
);
const NewCustomers2 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers2"), 1000)
);
const NewCustomers3 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers3"), 1000)
);
const NewCustomers4 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers4"), 1000)
);
const ApexPieChart = loadable(() =>
	pMinDelay(import("../Jobick/Home/ApexPieChart"), 1000)
);

const EXCHANGE_RATES = {
	EUR: 1.00,
	USD: 1.09,
	TND: 2.00,
	GBP: 0.85
  };
  const subscriptionLinks = {
    Free: "https://buy.stripe.com/test_cN2eYt5Gi4tl8LufZ5",
    "Solo Starter": "https://buy.stripe.com/test_6oE4jP7Oq8JBaTCbIJ",
    "Solo Pro": "https://buy.stripe.com/test_8wMaId4Ce9NF9Py7su",
    "Solo Premium": "https://buy.stripe.com/test_6oE7w10lY4tle5OeUX",
    "Entreprise Starter": "https://buy.stripe.com/test_28o4jP4Ce7Fx8Lu9AE",
    "Entreprise Pro": "https://buy.stripe.com/test_9AQbMhd8K8JBgdW7sx",
    "Entreprise Premium": "https://buy.stripe.com/test_14k6rX1q23phf9SbIO",
    "Pay as you go": "https://buy.stripe.com/test_bIYdUp0lYf7Z3ra144"
  };

const DashboardDark = () => {	
	const [UserLogPer, setUserLogPer] = useState([]);
	const [candidates, setCandidates] = useState([]);
	const token=localStorage.getItem("token");

useEffect(() => {
    const fetchUserPermissions = async () => {
        try {
            const currentUser = JSON.parse(localStorage.getItem("userDetails"));
            if (currentUser && currentUser.localId) {
                const userId = currentUser.localId;
                const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
                const data = await response.json();
                const hasPermission = data.some((permission) => permission.title === "view-user-logs");
				setUserLogPer(hasPermission)
                if (hasPermission) {
                    //loadUserLogs();
					setLoading(false);

                } else {
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error("Error fetching permissions details:", error);
            setLoading(false);
        }
    };

    fetchUserPermissions();
}, []);

const [subscription, setSubscription] = useState([]);
const [user, setUser] = useState("");

const [quota, setQuota] = useState({});  
useEffect(() => {
    const fetchQuota = async () => {
        try {
		
			
			  
				if(user.compId!=1){
					const responsecomp = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companieByid/${user.compId}`, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  }});
					setQuota(responsecomp.data || {});  
				}
				else if(user.compId===1 && user.role===3){
					const responseRec = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/getRecruiterSub/${user.userId}`, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  }});
					setQuota(responseRec.data || {});  
			}
			  
		
        } catch (error) {
            console.error("Error fetching quota data:", error);
            setQuota({});  // Set quota to an empty object on error
        }
    };

    if (user.compId) {  
        fetchQuota();
    }
}, [user]);
  useEffect(() => {
	const fetchSubscription = async () => {
	  try {
		const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		const userId = currentUser.localId;
		if(currentUser.userRole===2 || currentUser.userRole===3){
			const responseSub = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getInvoiceByUserId/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			setSubscription(responseSub.data);
		  
				}
	  } catch (error) {
		console.error("Error fetching subscription data:", error);
	  }
	};
  
	fetchSubscription();
  }, [user]);
	const { changeBackground, background } = useContext(ThemeContext);
	const [compId, setCompId] = useState("");
	const [jobs, setJobs] = useState([]);
	const [jobsApp, setJobsApp] = useState([]);
	const [allApplicantNb, setAllApplicantNb] = useState("");
	const [loading, setLoading] = useState(true);
	const [profileViewed, setProfileViewed] = useState("");
	const [candidateAccepted, setCandidateAccepted] = useState("");
	const [skillsTop3, setSkillsTop3] = useState([]);
	const [skillsJobTop4, setSkillsJobTop4] = useState([]);
	useEffect(() => {
		const fetchcandidaates = async () => {
		  try {
			const responseSub = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/getCandidates`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			setCandidates(responseSub.data.candidates);
		  } catch (error) {
			console.error("Error fetching candidates data:", error);
		  }
		};
	  
		fetchcandidaates();
	  }, []);
	const [lang] = useState(() => {
		const storedLang = localStorage.getItem("selectedLang");
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === "Français") {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
	const avatarClasses = [
		"empty-avatar-red",
		"empty-avatar-green",
		"empty-avatar-pimary",
		"empty-avatar-yellow",
		"empty-avatar-purple",
	  ];
	const randomAvatarClass =
	avatarClasses[Math.floor(Math.random() * avatarClasses.length)];
	useEffect(() => {
	  changeBackground({ value: "dark", label: "Dark" });

	}, []); 
	


	const TableSub = ({ sub }) => {
		return (
			<>
			<div className="card-header pb-0 border-0 flex-wrap">
			<h4 className="fs-20 mb-3">{lang.menu.subDetails}</h4>
			<div className="d-flex">
			<Button
			variant="danger light"
                                          className=" me-2"
                                          onClick={(event) =>
                                            handleUpgradeNow(event)
                                          }
                                        >
                                      <FaSortAmountUp />

									  {lang.menu.upgradeNow}
                                        </Button>
			</div>
			</div>
			<div className="card-body"> 
			  <Row >
				<Col>
				  <strong><BsCalendarDate size={20}/>{lang.menu.closeDate} </strong>
				  <span>{new Date(sub.period_end).toLocaleDateString()}</span>
				</Col>
			
			  </Row>
			  <table className="table display mb-3 dataTablesCard job-table table-responsive-s card-table dataTable no-footer">
				<thead>
				  <tr>
					<th></th>
					<th>{lang.menu.quotaMax}</th>
					<th>{lang.menu.quotaUsed}</th>
				  </tr>
				</thead>
				<tbody>
				  <tr>
					<td>{lang.menu.quotaExtraction}</td>
					<td>{sub.extractionNumber}</td>
					<td>{quota[0].quotaExtraction}</td>
				  </tr>
				  <tr>
					<td>{lang.menu.quotaJobRole}</td>
					<td>{sub.quotaJobRole}</td>
					<td>{quota[0].quotaJobRole}</td>
	
				  </tr>
				  <tr>
					<td>{lang.menu.quotaJobProfile}</td>
					<td>{sub.quotaJobprofile}</td>
					<td>{quota[0].quotaJobProfile}</td>
	
				  </tr>
				  <tr>
					<td>{lang.menu.quotaParcoursJobTest}</td>
					<td>{sub.quotaParcours}</td>
					<td>{quota[0].quotaParcours}</td>
	
				  </tr>
				  <tr>
					<td>{lang.menu.quotaProfiles}</td>
					<td>{sub.user_profiles}</td>
					<td>{quota[0].quotaProfiles}</td>
	
				  </tr>
				</tbody>
			  </table>
			  <Modal show={showModalSub} onHide={() => setShowModalSub(false)} dialogClassName="modal-100w">
  <Modal.Header>
    <Modal.Title>{lang.menu.Offress}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <div className="text-center mt-3">
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "EUR" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("EUR")}
            >
              EUR
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "USD" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("USD")}
            >
              USD
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "TND" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("TND")}
            >
              TND
            </button>
            <button
              className={`btn btn-outline-secondary ${
                selectedCurrency === "GBP" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("GBP")}
            >
              GBP
            </button>
          </div>
          <Row>
  {allSubscriptions.map((subscription) => {
    const cardClass = 'pricing-box h-100 shadow-sm';
    return (
      <Col key={subscription.typeName} md={4}>
        <Card className={cardClass}>
          <Card.Body className="p-4 px-lg-5 position-relative">
            {/* Affichage de la promo */}
            {subscription.isPromo === 1 && (
              <div className="badge-promo bg-danger text-white position-absolute top-0 end-0 px-3 py-1 rounded-start">
                -{subscription.promoPercentage}%
              </div>
            )}
            <div className="pricing-name mt-4 pt-2 text-center">
              <h4 className="fs-18 text-primary">
                <div className="pricing-icon rounded-circle icons-md mx-auto mb-4">
                  <Icon icon="uim-telegram-alt" className="text-primary" />
                  {subscription.typeName === "Pay as you go"
                    ? "Customized offer"
                    : subscription.typeName}
                </div>
              </h4>

        
					  <h2 className="fw-semibold">
                      {calculateConvertedPrice(subscription.priceType, selectedCurrency)}
                        <small className="fs-16">{selectedCurrency} {selectedBilling === "monthly" ? "mo" : "yr"}</small>
                      </h2>
                   </div>
                 
                    <ul className="list-unstyled mt-3 mb-4">
                    {subscription.features
                      .filter((feature) => feature.feature_quota_max !== null)
                      .map((feature) => (
                        <li key={feature.feature_id} className="my-2">
                          <strong>{feature.feature_name}:</strong> {feature.feature_quota_max}
						  {	console.log("subscription 21:",subscription)
						}
                        </li>
                      ))}

                    </ul>
					<PurchaseLink subscriptionType={subscription} className="btn btn-primary btn-block" />




          </Card.Body>
        </Card>
      </Col>
    );
  })}
</Row>
      </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModalSub(false)}>
      {lang.menu.retour}
    </Button>
  </Modal.Footer>
</Modal>
		 </div>
		  </>
		);
	};

	const [control3, setControl3] = useState('Newest');
	useEffect(() => {
		const fetchData = async () => {
		  try {
			const currentUser = JSON.parse(localStorage.getItem('userDetails'));
			const userId = currentUser.localId;
	  
			// Requête pour récupérer les détails de l'utilisateur
			const userResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
			if (!userResponse.ok) {
			  throw new Error(`Error fetching user details: ${userResponse.status}`);
			}
			const userData = await userResponse.json();
			
			// Mise à jour de l'état de l'utilisateur
			setUser({
			  userId: userId,
			  firstName: userData[0].FirstName || "",
			  lastName: userData[0].LastName || "",
			  email: userData[0].email || "",
			  password: userData[0].Password || "",
			  country: userData[0].Country || "",
			  jobTitle: userData[0].JobTitle || "",
			  phoneNumber: userData[0].PhoneNumber || "",
			  language: userData[0].Language || "",
			  avatar: userData[0].Avatar || "",
			  cover: userData[0].Cover || "",
			  signature: userData[0].EmailSignature || "",
			  role: userData[0].userRole || "",
			  disabled: userData[0].disabled,
			  roleName: userData[0].roleName || "",
			  compId: userData[0].companyID || ""			});
	  
			setCompId(userData[0].companyID)
			const compId=userData[0].companyID
			const userResponseComp = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getTotalCvInfoByCompanyId/${compId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
				if (!userResponseComp.ok) {
					throw new Error(`Error fetching CV info: ${userResponseComp.status}`);
				}
				const cvPerComp = await userResponseComp.json();
				setProfileViewed(cvPerComp.totalCvInfo)
				const userResponseCandAccept = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAcceptedCandidates/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
				if (!userResponseCandAccept.ok) {
					throw new Error(`Error fetching CV info: ${userResponseCandAccept.status}`);
				}
				const candAccept = await userResponseCandAccept.json();
				setCandidateAccepted(candAccept.candidateAccepted)
				const userResponseSkillsTop = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getSkillsTopCandidate/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
				if (!userResponseSkillsTop.ok) {
					throw new Error(`Error fetching CV info: ${userResponseSkillsTop.status}`);
				}
				const skillsTopCand = await userResponseSkillsTop.json();
				if (Object.keys(skillsTopCand.resultat).length !== 0) {
					const skillsData = skillsTopCand.resultat;
					const totalSkillsCount = Object.values(skillsData).reduce((acc, curr) => acc + curr, 0);
				  
					const skillsWithPercentage = Object.fromEntries(
					  Object.entries(skillsData).map(([skill, count]) => {
						const percentage = (count * 100) / totalSkillsCount;
						return [skill, percentage];
					  })
					);
				  
					setSkillsTop3(skillsWithPercentage);
					}
						  //	jobs Top 4
						  const userResponseSkillsTopJob = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getSkillsTopJob/${compId}`, 
							{
							  headers: {
								Authorization: `Bearer ${token}`,
							  }});
						  if (!userResponseSkillsTopJob.ok) {
							  throw new Error(`Error fetching CV info: ${userResponseSkillsTopJob.status}`);
						  }
						  const skillsTopJobs = await userResponseSkillsTopJob.json();
						  if (Object.keys(skillsTopJobs.resultat).length !== 0) {
							  const skillsData = skillsTopJobs.resultat;
							  const totalSkillsCount = Object.values(skillsData).reduce((acc, curr) => acc + curr, 0);
							
							  const skillsWithPercentage = Object.fromEntries(
								Object.entries(skillsData).map(([skill, count]) => {
								  const percentage = (count * 100) / totalSkillsCount;
								  return [skill, percentage];
								})
							  );
							
							  setSkillsJobTop4(skillsWithPercentage); 
		  
		  
		  
							}
		  } catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, []);

	  useEffect(() => {
		if (compId === "") {
		  return;
		}
	  
		const fetchData = async () => {
		  try {
			const jobsResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/JobRole/getLatestJobsByCompanyId/${compId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			if (!jobsResponse.ok) {
			  if (jobsResponse.status === 404) {
				throw new Error("No jobs found for the specified company ID");
			  } else {
				throw new Error(`Error fetching jobs: ${jobsResponse.status}`);
			  }
			}
			const jobsData = await jobsResponse.json();
			const jobsWithCandidates = await Promise.all(jobsData.map(async (job) => {

			 
			  const candidates1= candidates.filter(
				(candidate) => candidate.jobrole_id === job.id
			  );
			  return { ...job, candidateLength: candidates1.length };
			}));
			const filteredJobs = jobsWithCandidates.filter(job => job.Status==="Active" && job.archived===0);

			setJobs(filteredJobs);
	  
		  } catch (error) {
			console.error(error);
		  }
		};
	  
		fetchData();
	  }, [compId]);
	  useEffect(() => {
		const fetchData = async () => {
		  try {
			const response = await axios.get(
			  `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findAllApplicant/${compId}`,
			  {
				headers: {
				  Authorization: `Bearer ${token}`,
				},
			  }
			);
	  
			if (response.status === 200) {
			  const allApplicants = Array.isArray(response.data) ? response.data.length : 0; // Gestion des cas où data est vide ou non une liste
			  setAllApplicantNb(allApplicants);
			} else {
			  console.warn(`Unexpected response status: ${response.status}`);
			  setAllApplicantNb(""); // Aucun applicant trouvé
			}
		  } catch (error) {
			console.error("Error fetching applicants:", error.message);
			setAllApplicantNb(""); // Défaut en cas d'erreur
		  }
		};
	  
		fetchData();
	  }, [compId, token]);
	  useEffect(() => {
		if (compId === "") {
			return;
		  }
		const fetchData = async () => {
		try {
			// Requête pour récupérer tous les emplois
			const response3 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			if (response3.status !== 200) {
			  throw new Error(`Error fetching all jobs: ${response3.status}`);
			}
			let filteredJobs = response3.data;
	  
			if (compId!=1) {
				filteredJobs = response3.data.filter((job) => job.companyid === compId);
			  }
			  else{
				  filteredJobs = response3.data;
			  }
	  
			let updatedJobs = [];
			await Promise.all(filteredJobs.map(async (job) => {
				const candidates1= candidates.filter(
					(candidate) => candidate.jobrole_id === job.id
				  );
			  const JobDetails = await getJobDetails(job.id);
			  const candidatesList = JobDetails.map(candidate => ({
				candidat: candidate.id_candidate,
				status: candidate.candAccept,
				lastActivity: candidate.last_activity || null,
			  }));
			  const candLength = candidates1.length;
			  job.candLength = candLength;
			  job.candidatesList = candidatesList;
			  updatedJobs.push(job);
			}));
	  
			setJobsApp(updatedJobs);
		} catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, [compId]);

	  
	  
		

	  const getJobDetails = async (jobId) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobRoleDetailsForCandidates/${jobId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
		  const candidates = response.data;
		  return candidates;
		} catch (error) {
		  console.error(`Error fetching job skills for ID ${jobId}:`, error);
		  return [];
		}
	  };

//upgrade now

const [selectedCurrency, setSelectedCurrency] = useState("TND");
const [selectedBilling, setSelectedBilling] = useState("monthly");

const [enterpriseSubscriptions, setEnterpriseSubscriptions] = useState([]);

const calculateConvertedPrice = (price, currency) => {
    const rate = selectedBilling === "monthly" ? 1 : 12;
    return (parseFloat(price.slice(1)) * EXCHANGE_RATES[currency] * rate).toFixed(2);
  };

  const [featureselected, setFeatureselected] = useState({})
  const [features, setFeatures] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [closestSubscription, setClosestSubscription] = useState(null);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [soloSubscriptions, setSoloSubscriptions] = useState([]);
  const showFeatures = async () => {
	try {
  

	  const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getAll`, 
				  {
					headers: {
					  Authorization: `Bearer ${token}`,
					}});
	  const featureData = response.data;

	  setFeatures(featureData);

	  const response2 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypeSub`, 
				  {
					headers: {
					  Authorization: `Bearer ${token}`,
					}});
	  const sub = response2.data;
	  setSubscriptionTypes(sub);
	  
	  
	  const featureOrder = [
		'Candidate Profiles',
		'Candidate extraction',
		'Create job profile',
		'Create job role',
		'Parcours'
	  ];
	  
	  const sortFeatures = (features) => {
		return features.sort((a, b) => {
		  return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
		});
	  };
	  
	  const soloSubs = {};
	  const enterpriseSubs = {};
	  
	  for (const [key, value] of Object.entries(sub)) {
		// Create a copy of the value array and sort it
		const sortedValue = [...value].sort((a, b) => {
		  return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
		});
	  
		
		if (key.startsWith("Solo") || key === "Free" || key === "Pay as you go") {
		  soloSubs[key] = sortedValue;
		}
		if (key.startsWith("Entreprise") || key === "Free" || key === "Pay as you go") {
		  enterpriseSubs[key] = sortedValue;
		}
	  }
	  setSoloSubscriptions(soloSubs);

	  setEnterpriseSubscriptions(enterpriseSubs);
	  const allSubscriptions = [];
	  let SUB= {};
	  if(user.role === 3){
		  SUB=soloSubs
	  }else if (user.role === 2){
		  SUB=enterpriseSubs

	  }

	  Object.entries(SUB).forEach(([typeName, features]) => {
		let priceType = "";
		let idProduct = "";
		let promoPercentage = "";
		let isPromo = "";
		features.forEach((feature) => {

		  priceType = feature.type_monthly_price;
		  idProduct = feature.type_id;
		  isPromo = feature.type_isPromo;
		  promoPercentage = feature.type_promoPercentage;
		});

		allSubscriptions.push({ typeName, features, priceType, idProduct ,isPromo,promoPercentage});
	  });
		setAllSubscriptions(allSubscriptions);


	  const featureSelectedInitial = featureData.reduce((acc, feat) => {
		acc[feat.name] = "";
		return acc;
	  }, {});


	  setFeatureselected(featureSelectedInitial);

	} catch (error) {
	  console.error("Error fetching features:", error);
	}
  };
  useEffect(() => {
   
	if(user.role === 2 || user.role === 3 ){
    showFeatures();}
  }, [user]);
  const handleCloseModal = () => {
    setClosestSubscription(null);
  };


  const ModalPayme = ({ subscriptionType,userEmail }) => {
	const [showModal, setShowModal] = useState(false);
	const [token, setToken] = useState("");
  
	const amountPayme= calculateConvertedPrice(subscriptionType.priceType, selectedCurrency)
	const amountPaymeAnnuel = selectedBilling === "monthly" ? (parseFloat(amountPayme) * 12).toFixed(2) : amountPayme;

	const [formData, setFormData] = useState({
	  amount: amountPayme,
	  note: `${subscriptionType.idProduct}`,
	  first_name: '',
	  last_name: '',
	  email: userEmail,
	  phone: '50719929',
	  order_id: `${subscriptionType.idProduct}`,
	   monthAnnuel:"Monthly"
	});
	
  
	
	const [gateway, setGatway] = useState(false)
	  const [formError, setFormError] = useState(null)
	  const [error, setError] = useState(null)
	  const [iframeURL, setIframeURL] = useState('')
	  const { t } = useTranslation()
	  const [phone, setPhone] = useState('50719929')
	  const [amount, setAmount] = useState(amountPayme)
	  const [email, setEmail] = useState(userEmail)
	  const [firstName, setFirstName] = useState("")
	  const [lastName, setLastName] = useState("")
      const [monthAnnuel, setmonthAnnuel] =useState("Monthly");
	  const handleChangemonthAnnuel = (event) => {
		const value = event.target.value;
		setmonthAnnuel(value); 
	  };
	  const handelClick = async () => {
		try {
		  console.log("phone:",phone)
			const res = await axios.get(
				`https://sandbox.paymee.tn/api/v1/accounts/${phone}/account-number`,
				{
					headers: {
						Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
					},
				}
			)
			console.log("res 14:",res.data)
  
			if (!res.data.status) {
				setFormError(res.data.message)
				setGatway(false)
			} else {
			  const formDataPayme = new FormData();
			  formDataPayme.append("email", formData.email);
			  formDataPayme.append("first_name", formData.first_name);
			  formDataPayme.append("last_name", formData.last_name);
  
   console.log("formDataPayme:",formDataPayme)
				const res2 = await axios.post(
					`https://sandbox.paymee.tn/api/v2/payments/create`,
					{
					  order_id:subscriptionType.idProduct,
						phone: phone,
						amount:  monthAnnuel === "Monthly" ? amount : amountPaymeAnnuel,
						note: subscriptionType.idProduct,
						first_name:firstName,
						last_name:lastName,
						email:email,
						return_url: "https://app.hirecue.com",
						cancel_url: "https://app.hirecue.com",
						webhook_url: "https://www.webhook_url.tn"
					  
					},
					{
						headers: {
							Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
						},
					}
				)
				console.log("res 15:",res2.data)
  
				if (res2.data.status) {
					setToken(res2.data.data.token)
					setIframeURL(
						`https://sandbox.paymee.tn/gateway/${res2.data.data.token}`
					)
  
					setGatway(true)
					setFormError(null)
  
				} else {
  
					setGatway(false)
					setFormError(res2.data.message)
				}
			}
		} catch (error) {
			console.log(error)
			setError(error)
			setGatway(false)
		}
	}
	const [paymentStatus, setPaymentStatus] = useState(null);
  
	const checkToken = useCallback(async () => {
	  if (!token) {
		return;
	  }
  
	  console.log("Checking token:", token);
  
	  try {
		const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/payMe/payments/${token}/check/v2`, {
		  headers: {
			"Authorization-Bearer": `Bearer ${token}`,
			"Authorization": `Token ${process.env.REACT_APP_PAYME_API_KEY}`
		  },
		});
  
		const { data } = response.data;
  
		if (data && data.payment_status) {
		  console.log("Payment completed successfully");
		  setPaymentStatus('completed');
		  setShowModal(false);
		  handleCloseModal()
		  const currentUser = JSON.parse(localStorage.getItem("userDetails"));

		  const subscriptionData = {
			userId: currentUser.localId,
			amount: data.amount,
			note: data.note,
		  };
		
		  try {
			const subscriptionDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/createSubscription`, {
			  method: "POST",
			  headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify(subscriptionData),
			});
		
			if (!subscriptionDone.ok) {
			  throw new Error('Failed to create subscription');
			}
		
			const dataSub = await subscriptionDone.json();
			const storedLang = localStorage.getItem("selectedLang");
			const parsedLang = JSON.parse(storedLang);
  
			const invoiceData = {
			  sub_id: dataSub.sub_id,
			  customer_id: dataSub.customer_id,
			  email: data.email,
			  received_amount: data.received_amount,
			  amount: data.amount, 
			  payment_status: data.payment_status,
			  firstname: data.firstname,
			  lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" ,
            monthAnnuel:monthAnnuel,
            currency:"TND"
			};
		
			const invoiceDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/invoice/createinvoice`, {
			  method: "POST",
			  headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify(invoiceData),
			});
		
			if (!invoiceDone.ok) {
			  throw new Error('Failed to create invoice');
			}
			showFeatures();
  
			console.log("Subscription and invoice creation completed successfully");
	 
		
		  } catch (error) {
			console.error("Error:", error);
		  }
		}
		else {
		  console.log("Payment not completed or unsuccessful");
		  setPaymentStatus('incomplete');
		}
	  } catch (error) {
		console.error("Error checking payment status:", error);
		setPaymentStatus('error');
	  }
	}, [token]);
  
	const handlePaymeeComplete = useCallback((event) => {
	  console.log('Paymee payment completed:', event.detail);
	  setPaymentStatus('completed');
	  setShowModal(false);
	  // Perform further actions like updating the UI or notifying the user
	}, []);
  
	useEffect(() => {
	  if (paymentStatus == 'completed') {
		console.log('Payment already completed, skipping check.');
		handleLogout()
		return;
	  }
  
	  const intervalId = setInterval(checkToken, 1000);
  
	  window.addEventListener('paymee.complete', handlePaymeeComplete);
	  
	  return () => {
	   
		clearInterval(intervalId);
		window.removeEventListener('paymee.complete', handlePaymeeComplete);
		
	  };
	}, [checkToken, handlePaymeeComplete, paymentStatus]);
	
	useEffect(() => {
	  console.log("Hello",token)
		window.addEventListener(
			'message',
			function (event) {
				if (event.data.event_id === 'paymee.complete') {
					//Execute Step 3
					// window.location.replace()
					// https://sandbox.paymee.tn/api/v1/payments/{token}/check
					axios
						.get(
							`https://sandbox.paymee.tn/api/v2/payments/${token}/check`,
  
							{
								headers: {
									Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
								},
							}
						)
						.then((res) => {
						  console.log("res.data:",res.data)
							if (res.data.data && res.data.data.payment_status) {
								setIframeURL(
									'https://sandbox.paymee.tn/paylink/success'
								)
							} else {
								setIframeURL(
									'https://sandbox.paymee.tn/paylink/failure'
								)
							}
						})
						.catch((err) => {
							console.log(err)
							setError(err)
						})
				}
			},
			false
		)
	}, [token])
	return (
	  <>
		<Button color="primary" onClick={() => setShowModal(true)} className="btn-soft-primary rounded-pill">
		  {lang.menu.purchaseNow}<FaArrowRight />
		</Button>
		<Modal show={showModal} onHide={() => setShowModal(false)}>
		  <Modal.Header >
			<Modal.Title>{lang.menu.purchaseNow}</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>
		  <Card>
					  <Card.Header>
						  <h3>{t('Online payment')}</h3>
					  </Card.Header>
					  <Box overflow="auto">
						 
					  <Form>
	  {!gateway ? (
		  <Fragment>
			 <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.amount}</Form.Label>
				<Form.Control type="text" placeholder="Enter amount" name="amount" value={amountPayme}     onChange={(event) => {
					  setAmount(event.target.value.toString());
				  }} />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.email}</Form.Label>
				<Form.Control type="email" placeholder="Enter email" name="email" value={userEmail}     onChange={(event) => {
					  setEmail(event.target.value.toString());
				  }}  />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.firstName}</Form.Label>
				<Form.Control type="text" placeholder="Enter first_name" name="first_name" value={firstName}     onChange={(event) => {
					  setFirstName(event.target.value.toString());
				  }}  />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount">
				<Form.Label>{lang.menu.lastName}</Form.Label>
				<Form.Control type="text" placeholder="Enter last_name" name="last_name" value={lastName}     onChange={(event) => {
					  setLastName(event.target.value.toString());
				  }}  />
			  </Form.Group>
			  <Form.Group className="mb-3" controlId="formAmount" hidden>
				<Form.Label>{lang.menu.phone}</Form.Label>
				<Form.Control type="text" placeholder="Enter phone" name="phone" value={formData.phone}  onChange={(event) => {
					  setPhone(event.target.value.toString());
				  }} />
			  </Form.Group>
  
			  <div className="mb-3">
      <label htmlFor="formFrequency" className="form-label">
        {lang.menu.frequency}
      </label>
      <select
        id="formFrequency" 
        name="frequency"
        value={monthAnnuel} 
        onChange={handleChangemonthAnnuel} 
        className="form-select"
      >
        <option value="Monthly"> {lang.menu.Mensuel}</option>
        <option value="Annuel"> {lang.menu.Annuel}</option>
      </select>
    </div>
  
  
			
			 
			  <br />
			  {formError && (
				  <p sx={{ color: 'red', my: 1 }}>
					  {formError}
				  </p>
			  )}
			  <Button onClick={handelClick}>
				  {t('Submit')}
			  </Button>
		  </Fragment>
	  ) : (
		  <iframe
			  title="gateway payment"
			  width="500px"
			  height="400px"
			  src={iframeURL}
		  />
	  )}
  </Form>
  
						
					  </Box>
				  </Card>
		  </Modal.Body>
		  <Modal.Footer>
			<Button variant="secondary" onClick={() => setShowModal(false)}>{lang.menu.retour }</Button>
		  </Modal.Footer>
		</Modal>
	  </>
	);
  };

  const PurchaseLink = ({ subscriptionType }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalLink, setModalLink] = useState("");
	const currentUser = JSON.parse(localStorage.getItem("userDetails"));
	const userEmail=currentUser.email
    const link = `${subscriptionLinks[subscriptionType.typeName]}?prefilled_email=${userEmail}`;
  
    const handlePurchaseClick = (link) => {
      if (selectedCurrency !== "TND") {
       // setModalLink(link);
       // setShowModal(true);
       window.location.href = link; 
        handleLogout()
      } else {
        //window.open(link, "_blank");
      }
    };
  
 
    return selectedCurrency === "TND" ? (
      <ModalPayme subscriptionType={subscriptionType} userEmail={userEmail} />
    ) : (
      <>
        <Button
          onClick={() => handlePurchaseClick(link)}
          className="btn-soft-primary rounded-pill"
        >
         {lang.menu.purchaseNow} <FaArrowRight />
        </Button>
        {/* <PurchaseModal show={showModal} onHide={() => setShowModal(false)} link={modalLink} /> */}
      </>
    );
  };
  const [showModalSub, setShowModalSub] = useState(false);
  const handleUpgradeNow = (event) => {
	setShowModalSub(true);  

      
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout(history));
  };










	return(    
		<>
			<div className="row">
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row separate-row">
										<div className="col-sm-6">
											<div className="job-icon pb-4 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{allApplicantNb}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.assessmentsSchedules}</span>
												</div>	
												<div id="NewCustomers">
													<NewCustomers1 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{allApplicantNb}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.applicationSent}</span>
												</div>	
												<div id="NewCustomers1">
													<NewCustomers2 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{profileViewed}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.profileViewed}</span>
												</div>	
												<div id="NewCustomers2">
													<NewCustomers3 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{candidateAccepted}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.acceptedCandidates}</span>
												</div>	
												<div id="NewCustomers3">
													<NewCustomers4 />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{skillsJobTop4 && skillsJobTop4.length!=0 ? (
						<div className="col-xl-12">
  <div className="card">
    <div className="card-body">
      <h4 className="fs-20 mb-3">{lang.menu.skillsForJobs}</h4>
      <div className="row">
        {Object.entries(skillsJobTop4)
          .slice(0, 4)
          .map(([skill, value]) => (
            <div className="col-xl-6 col-sm-6" key={skill}>
              <div className="progress default-progress">
                <div className="progress-bar bg-green progress-animated" style={{ width: `${value}%`, height: "13px" }} role="progressbar">
                  <span className="sr-only">{value.toFixed(2)}% Complete</span>
                </div>
              </div>
              <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
                <span className="fs-14 font-w500">{skill}</span>
                <span className="fs-16"><span className="text-black pe-2"></span>{value.toFixed(2)}%</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
</div>
):(<></> )}
					{/* 	<div className="col-xl-12">
							<VacanyTab jobsApp={jobsApp}/>
						</div>
						<div className="col-xl-12">
							<CanvasChartTab  />
						</div>
						<div className="col-xl-12">
							<FeaturedCompanies />
						</div> */}
						{jobs.length > 0 ? (
						<div className="card">
								<div className="card-header pb-0 border-0 flex-wrap">
									<h4 className="fs-20 mb-3">{lang.menu.availableJobsForYou}</h4>
									<div className="d-flex">	
										<Dropdown className="default-select bgl-primary rounded" >
											<Dropdown.Toggle  as="div" className="btn btn-sm  text-primary d-flex align-items-center i-false" >
												{control3}
												<i className="fas fa-angle-down text-primary scale5 ms-3"></i>
												
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
												<Dropdown.Item className=" text-primary"  onClick={()=>setControl3("Newest")}>
													Newest
												</Dropdown.Item>
												<Dropdown.Item className=" text-primary" onClick={()=>setControl3("Oldest")}>
													Oldest
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										<Dropdown className="custom-dropdown mb-0">
											<Dropdown.Toggle as="button" className="btn sharp tp-btn dark-btn i-false">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu-right">
												<Dropdown.Item>{lang.menu.details}</Dropdown.Item>
												<Dropdown.Item className="text-danger">{lang.menu.cancel}</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>	
								</div>
								<div className="card-body">
								<div className="owl-carousel owl-carousel owl-loaded front-view-slider">
										{jobs.length > 0 ? (
										<HomeSlider jobs={jobs} />
										) : (
										<p>{lang.menu.noJobs}</p>
										)}
									</div>
								</div>
							</div>
					):(<></> )}
					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row ">
										<div className="col-xl-8 col-xxl-7 col-sm-7">
											<div className="update-profile d-flex">
											{user.avatar ? (
              <img
                className="rounded-circle"
                src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${user.avatar}`}
                alt=""
				style={{ width: '50%', height: '50%' }}
              />
            ) : (
              <div
                className={`profile-pic-empty empty-avatar rounded-circle ${randomAvatarClass}`}
				style={{ width: '300%', height: '300%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '300%' }} 
              >
                {user.firstName}
				
              </div>
            )}
												<div className="ms-4">
													<h3 className="fs-24 font-w400 mb-0">{user.firstName}{" "} {user.lastName}</h3>
													<span className="text-primary d-block mb-4">{user.roleName}</span>
													
												</div>
												
											</div>
										

										</div>
										
										<div className="col-xl-4 col-xxl-5 col-sm-5 sm-mt-auto mt-3">
											<Link to={"/profile"} className="btn btn-primary btn-rounded">{lang.menu.updateProfile}</Link>
										</div>
										
									</div>
									<div className="col-xl-8 ">
											<span
								style={{ padding:"5%",display: 'flex', alignItems: 'left', justifyContent: 'left', fontSize: '150%' }} 

											><MdMarkEmailRead size={30} /> {' '}{user.email}</span>
										</div>

								</div>
							</div>
						</div>
						
{skillsTop3 && skillsTop3.length!=0 ? (
						<div className="col-xl-12">
						<div className="card">
								<div className="card-body">
						<div className="row mt-4 align-items-center">
  <h4 className="fs-20 mb-3">{lang.menu.skillsForCandidates}</h4>
  <div className="col-xl-6 col-sm-6">
  {Object.entries(skillsTop3)
  .slice(0, 3)
  .map(([skill, value]) => (
    <React.Fragment key={skill}>
      <div className="progress default-progress">
        <div className="progress-bar bg-green progress-animated" style={{ width: `${value}%`, height: "13px" }} role="progressbar">
          <span className="sr-only">{value.toFixed(2)}% Complete</span>
        </div>
      </div>
      <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
        <span className="fs-14 font-w500">{skill}</span>
        <span className="fs-16"><span className="text-black pe-2"></span>{value.toFixed(2)}%</span>
      </div>
    </React.Fragment>
  ))}

  </div>
  <div className="col-xl-6 col-sm-6">
    <div id="pieChart1">
      <ApexPieChart skillsTop3={skillsTop3} />
    </div>
  </div>
</div>
</div>
							</div>
						</div>	


):(<></> )}
						<div className="col-xl-12">
						<div className="card">

						{subscription && quota && quota.length > 0 && user && user.compId === 1 && user.role === 3 ? (
    <TableSub sub={subscription} />
  ) : subscription && quota && quota.length > 0 && user && user.compId !== 1&& (user.role === 2 || user.role === 3)? (
    <TableSub sub={subscription} />
  ) : (
    <>
    </>
  )}
</div>
						</div>
				


					</div>
				</div>
		
			</div>	
		</>
	)
}
export default DashboardDark;