import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card,Modal,Form,Row,Col } from "react-bootstrap";
import { Link ,useHistory } from "react-router-dom";
import translations from"../AppsMenu/Candidate/mulitlingue";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { MdOutlineAccessTime } from "react-icons/md";
import { TbMessageQuestion } from "react-icons/tb";

function TestPsychotechniqueList() {
  const [listPsychoPer, setListPsychoPer] = useState([]);
  const history = useHistory();
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setListPsychoPer(
            data.some((permission) => permission.title === "list-psycho-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!listPsychoPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [listPsychoPer]);
  const [tests, setTests] = useState([]);
  const [deletetest,setDeletetest] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const filterTestByTitle = () => {
    return tests.filter(test => test.test_name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setDeletetest(
            data.some((permission) => permission.title === "delete-psycho-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [createTest,setCreateTest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCreateTest(
            data.some((permission) => permission.title === "create-psycho-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);
    useEffect(() => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
    
      const getUserCompanyID = async () => {
        const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
        const data = await responseC.json();
        console.log("data :",data)

        const compId = data[0].companyID;
        return compId;
      };
    
      getUserCompanyID().then((compId) => {
        axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getAllTests`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }})
          .then(response => {
            console.log("response.data. :",response.data)
            if (compId!=1) {
              const filteredTests = response.data.filter(test => test.companyID === compId || test.companyID === null );
              setTests(filteredTests);
            } else {
              setTests(response.data);
            }
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des tests:', error);
          });
      });
    }, []);
    
  const handleDelete = (testId) => {
    swal({
      title:lang.menu.deleteTitle ,
      text: lang.menu.deleteText,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
    if (testId) {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/deleteTest/${testId}/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(response => {
          console.log(response.data); 
          if(response.data.message ==="Impossible de supprimer ce test car il a été utilisé."){
            swal(lang.menu.suppImpo, {
              icon: "error",
            });
          }else{
            swal(lang.menu.deleteSuccessTest, {
              icon: "success",
            });
          setTests(prevTests => prevTests.filter(test => test.test_id !== testId));}
        
        })
        .catch(error => {
          console.error(error); 
        });
    }}else {
      // User canceled the delete action
      swal(lang.menu.testDeletMsgCancel, {
        icon: "info",
      });
    }
  });
  };
  
  
  useEffect(() => {
    const deleteButtons = document.querySelectorAll(".deleteButton");
    
    deleteButtons.forEach(deleteButton => {
      deleteButton.addEventListener('click', () => handleDelete(deleteButton.dataset.testId));
    });
    
    return () => {
      deleteButtons.forEach(deleteButton => {
        deleteButton.removeEventListener('click', () => handleDelete(deleteButton.dataset.testId));
      });
    };
  }, []);
  
  const handleIconClick = (timePerQuestion,test_id) => {
    history.push(`/Test-Psychotechnique-For-Recruter/${test_id}`);

    localStorage.setItem('timePerQuestionPsycho', timePerQuestion);
  };
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const dataCmp = await response.json();
        setCompanies(dataCmp);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);
  const getCompanyLogo = (companyId) => {
    const validCompanyId = companyId || 1;
  
    const company = companies.find((comp) => comp.companyid === validCompanyId);
  
    console.log("company :", company);
  
    let pathImage = company
      ? `${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`
      : null;
  
    return pathImage;
  };
  

  const renderTests = () => {
    const groupedTests = filterTestByTitle().reduce((result, test) => {
      const {
        test_id,
        test_name,
        test_description,
        category_id,
        category_description,
        companyID,
        timePerQuestion,
        nbre_question_globale,
      } = test;
  
      if (!result[test_id]) {
        result[test_id] = {
          test_id,
          test_name,
          test_description,
          categories: [],
          companyID,
          timePerQuestion,
          nbre_question_globale,
        };
      }
  
      result[test_id].categories.push({
        id: category_id,
        test_name,
        description: category_description,
      });
  
      return result;
    }, {});
  
    return (
      <Row className="tests-grid" style={{ gap: "20px 0" }}>
        {Object.values(groupedTests).map((group) => {
          const {
            test_id,
            test_name,
            test_description,
            timePerQuestion,
            nbre_question_globale,
            companyID,
          } = group;
          const temps_global_de_test = nbre_question_globale * timePerQuestion;
          const minutes = Math.floor(temps_global_de_test / 60); 
          const secondes = temps_global_de_test % 60;
          // Limiter la description à 150 caractères
          const truncatedDescription =
            test_description.length > 150
              ? `${test_description.slice(0, 150)}...`
              : test_description;
  
          return (
            <Col key={test_id} xl={6}>
              <Card style={{ fontFamily: "Poppins" }}>
                <Card.Header>
                  <Card.Title>{test_name}</Card.Title>
                  {getCompanyLogo(companyID) ? (
                    <img
                      src={getCompanyLogo(companyID)}
                      alt="Company Logo"
                      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                    />
                  ) : (
                    <i className="fas fa-building" style={{ fontSize: "24px", color: "#ccc" }}></i>
                  )}
                </Card.Header>
                <Card.Body>
                  <Card.Text>{truncatedDescription}</Card.Text>
                  <div
                    className="badge-container"
                    style={{ display: "flex", gap: "10px", marginTop: "10px" }}
                  >
<span
  className="badge"
  style={{
    background: "#43DAC2",
    padding: "5px 10px",
    borderRadius: "12px",
    fontSize: "0.9em",
  }}
>
  <MdOutlineAccessTime />
  {minutes > 0 && `${minutes} min `}
  {secondes > 0 && `${secondes} s`}
</span>


                    <span
                      className="badge"
                      style={{
                        background: "#43DAC2",
                        padding: "5px 10px",
                        borderRadius: "12px",
                        fontSize: "0.9em",
                      }}
                    >
                      <TbMessageQuestion /> {` ${nbre_question_globale} questions`}
                    </span>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className="test-card-icons">
                    <Link to={`/TestPsychoTchniqueDetails/${test_id}`}>
                      <i className="fas fa-eye test-details-icon fa-lg"></i>
                    </Link>
                    <Link to={`/UpdateTestPsychotechnique/${test_id}`} >
                      <i className="fas fa-edit fa-lg"></i>
                    </Link>
                    <Link to={`/Test-Psychotechnique-For-Recruter/${test_id}`}>
                      <i
                        className="fa fa-play fa-lg"
                        onClick={() => handleIconClick(timePerQuestion, test_id)}
                      ></i>
                    </Link>
                    {deletetest ? (
                      <button
                        style={{ border: "none" }}
                        onClick={() => handleDelete(test_id)}
                        disabled={group.companyID === null}
                      >
                        <i className="fas fa-trash-alt fa-lg"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };
  
  

  return (
    <> {listPsychoPer ? (  
    <div>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{lang.menu.testPsychoTechniqueList}</h4>
        
      </div>
      <div>
     
      <Row>
        <Col xs={8}>
        <Form.Group controlId="formBasicSearch">
      <Form.Control

          type="text"
          placeholder={lang.menu.serachJob}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
        </Col>
        <Col xs={4} className="d-flex align-items-end justify-content-end">
        { createTest ? ( <Link to="/TestPsychotechnique" className="btn btn-primary">
          <i className="fas fa-plus me-2"></i>{lang.menu.newTest}
        </Link>) : <></>}</Col>
      </Row>
      <br/>
        {renderTests()}</div>
    </div> ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
}

export default TestPsychotechniqueList;

