import React, { useEffect, useState } from "react";
import axios from "axios";
import { Badge, Card, Form,Row,Col, Button, Modal , Navbar, ProgressBar} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { useTimer } from "react-timer-hook";
import "../../PsychoTechnicalTest/test.css";
import translations from"../../AppsMenu/Candidate/mulitlingue";
import {  useScreenshot } from "use-react-screenshot";

import logo from "./../../../../images/logo-full.png"
import ReactApexChart from "react-apexcharts";

function TechQCMForRecruter() {
  const storedQuestion = localStorage.getItem("questionsNbr");
  const timePerQuestion= localStorage.getItem("timePerQuestion");
  const [testDetails, setTestDetails] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [result, setResult] = useState({});
  const [score, setScore] = useState(0);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [categoryCorrectCounts, setCategoryCorrectCounts] = useState();
  const [timeInSeconds, setTimeInSeconds] = useState(storedQuestion*timePerQuestion); 
  const token=localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null); 
  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //test type
  const testType= "QCM";



  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
      if (testDetails && testDetails.question_globale) {
        setNbrQuesPerCat(testDetails.test_questionNbre);
  
      }
    }, [testDetails]);
    const {
      seconds,
      minutes,
      hours,
    } = useTimer({ expiryTimestamp: new Date().getTime() + timeInSeconds * 1000 });
  
   useEffect(() => {
      if (seconds === 0 && minutes === 0 && hours === 0) {
        setTimerExpired(true);
      }
    }, [seconds, minutes, hours]);
  
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);
  const pathname = window.location.pathname;
  const [nbreQuest, setNbreQuest] = useState();
  const idtest = pathname.split("/").pop();
  const history = useHistory();
  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };
  const [nbrQuesPerCat, setNbrQuesPerCat] = useState(0);


  const getImageUrlFromServer = (imageName, Name, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/images/${Name}/${id}/${imageName}`;
  };

  const getImageUrl = (imageRef, id, Name) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, Name, id);
      return imageUrl;
    }
    return null;
  };

  const renderContent = (content, id, Name) => {
    const imageUrl = getImageUrl(content, id, Name);

    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " width={150} height={100}/>
        </div>
      );
    } else {
      return <span style={{ fontSize: "14px" }}>{content}</span>;
    }
  };
  const renderContentRef = (content, id,skill_name) => {
    const imageUrl = getImageUrl(content, id,skill_name);
  
    const handleImageLoad = (e) => {
      const img = e.target;
      const { naturalWidth, naturalHeight } = img;
  
      // Si l'image est plus grande que 100x90, on redimensionne
      if (naturalWidth >100 || naturalHeight > 90) {
        const aspectRatio = naturalWidth / naturalHeight;
  
        // Ajuster la taille tout en conservant les proportions
        if (aspectRatio > 1) {
          // Image plus large que haute
          img.style.width = "500px";
          img.style.height = `${500 / aspectRatio}px`;
        } else {
          // Image plus haute que large
          img.style.width = `${400 * aspectRatio}px`;
          img.style.height = "400px";
        }
      }
    };
  
    if (imageUrl) {
      return (
        <>
        <div className="frame center">
          {/* On utilise onLoad pour contrôler la taille après le chargement */}
          <img
            src={imageUrl}
            alt=" "
            onLoad={handleImageLoad}
            style={{ cursor: "pointer",maxWidth: "100%", maxHeight: "100%" }}
            onClick={() => openModal(imageUrl)}           />
        </div>
        <Modal show={showModal} onHide={closeModal} centered size="l">
          <Modal.Body className="p-0">
            <img
              src={modalImageUrl}
              alt=" "
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Modal.Body>
        </Modal>
        </>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  useEffect(() => {
    const fetchNbreQuestion = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/count/${idtest}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setNbreQuest(response.data.nbreQuestion);
        console.log('1 : ',response.data.nbreQuestion)
      } catch (error) {
        console.error(error);
      }
    };

    fetchNbreQuestion();
  }, []);
  const handleAnswerChange = (questionId, answer) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answer,
    }));
    console.log("selectedAnswers", selectedAnswers, "selectedAnswers length", Object.keys(selectedAnswers).length);
  };

  




  const [categories, setCategories] = useState();
  const [currentQuestions, setCurrentQuestions] = useState();
  const [currentIndexCategory, setCurrentIndexCategory] = useState(0);
  const [currentIndexQuestion, setCurrentIndexQuestion] = useState(0);


  useEffect(() => {
    const fetchTestDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getTestWithQuestionsById/${idtest}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        console.log("testDetails", response.data);
        setTestDetails(response.data);
        setCategories(response.data.skills.map(skill => skill.skill_name));
        setCurrentQuestions(response.data.skills[currentIndexCategory].questions);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTestDetails();
  }, []);

  //anti cheating mesures
  // get user id
  const userDetailsString = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(userDetailsString);
  const selectedCandidate = userDetails ? userDetails.localId : null;


  //permissions
  const [allowCopyPaste,setAllowCopyPaste]= useState(true);
  const [takeScreenShots,setTakeScreenShots]= useState(true);
  const [takeSnapShots,setTakeSnapShots]= useState(true);
  const [numberOfScreenShots, setNumberOfScreenShots] =useState();
  const [numberOfSnapShots, setNumberOfSnapShots] = useState();

  const [cameraOn, setCameraOn]= useState(false);

  const [elementRef, setElementRef] = useState(null);

  //timer
  const testTime  = timeInSeconds;

  //get permissions of the test
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const testid  = localStorage.getItem("testId");

        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/technical/permissions/testtech/${testid}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        const permissions= response.data.permissionsTestTech;
        //console.log("allow copy paste", permissions.allowCopyPaste, "screenshots", permissions.takeScreenShots, "snapshots", permissions.takeSnapShots);
        setAllowCopyPaste(permissions.allowCopyPaste);
        setTakeScreenShots(permissions.takeScreenShots);
        setTakeSnapShots(permissions.takeSnapShots);
        setNumberOfScreenShots(permissions.numberOfScreenShots);
        setNumberOfSnapShots(permissions.numberOfSnapShots);

      } catch (error) {
        console.error(error);
      }
    };
 
    fetchPermissions();
  }, []);

  //screenshots
  const [screenshot, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  // taking screenShots and send it to the backend
  const downloadScreen = () => {
    try{
      if (elementRef && takeScreenShots) {
      takeScreenshot(elementRef).then((imageData) => {
        if(imageData){
        

        const byteCharacters = atob(imageData.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/jpeg" });

        
        const userid  = selectedCandidate;
        const testid  = localStorage.getItem("testId");
        const jobid  = localStorage.getItem("jobRoleId");


        // Create FormData and append the blob
        const formData = new FormData();
        formData.append("screenshot", blob, `${Date.now()}`);
        
        //console.log("formData : " +formData);

        // Make a POST request to your backend
        axios
      .post(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/screenshot/${userid}/${jobid}/${testid}/${testType}`, formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        console.log("backendRes " +JSON.stringify( res.data));
      })
      .catch((er) => console.log(er));
      }});
    } else if(!elementRef){
      console.error("Ref is null");
    }}catch(error){
      console.error(error);
    }
  };

  useEffect(() => {
    if(numberOfScreenShots && numberOfScreenShots !== 0){
    const intervalId = setInterval(downloadScreen, (testTime /(numberOfScreenShots+1))*1000);
    return () => clearInterval(intervalId);}
  }, [elementRef, takeScreenShots, numberOfScreenShots]);


 
  // Initialize a boolean flag to track mouse position
  const [cursorInWindow, setCursorInWindow] = useState(true);
  const [entered, setEntered]= useState(false);
  useEffect(()=>{
    
    //Detect when the mouse leaves the window
      const handleMouseOut = (event) => {
    if (entered && (document.readyState === 'complete') && (!event.relatedTarget)) {
        console.log('Mouse left the window.');
        setCursorInWindow(false);
      }

      //to make sure to start detecting the mouse movement when he access the test
      if(!entered && elementRef){
        setEntered(true);
      }
    };

    window.addEventListener('mouseout', handleMouseOut);
    
    return () => {
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, [entered, elementRef]);



  // copy paste
  useEffect(() => {
    if(!allowCopyPaste){
      const handlePaste = (event) => {
      event.preventDefault();
    };
    const handleCopy = (event) => {
      event.preventDefault();
    };

    const handleCut = (event) => {
      event.preventDefault();
    };

    window.addEventListener("paste", handlePaste);
    window.addEventListener("copy", handleCopy);
    window.addEventListener("cut", handleCut);
    return () => {
      window.removeEventListener("paste", handlePaste);
      window.removeEventListener("copy", handleCopy);
      window.removeEventListener("cut", handleCut);
    };
  }
  }, [allowCopyPaste]);

  //take snapshots
  const takePicture = async () => {
    try {
      if(elementRef && takeSnapShots){
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraOn(true);
      const track = mediaStream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(track);

      const blob = await imageCapture.takePhoto(); // Take a photo
      const formData = new FormData();
      formData.append('snapshot', blob, `${Date.now()}`);
  

      
      track.stop(); // Stop the camera track to prevent memory leaks
    }
    } catch (error) {
      setCameraOn(false);
      console.error('Error taking picture: ', error);
    }
  };

  useEffect(() => {
   
    if(numberOfSnapShots && numberOfSnapShots !== 0){
      const interval = setInterval(() => {
    takePicture();
  }, (testTime/(numberOfSnapShots+1))*1000);  
  return () => clearInterval(interval);}

}, [elementRef ,takeSnapShots, cameraOn, numberOfSnapShots]);


  


//show submit button
const [submit, setSubmit]= useState(false);
//progressBar length
const [testProgress, setTestProgress]= useState(0);

 const getTheNextValue = () => {
  try {
    
    if(currentQuestions){
      console.log("length", currentQuestions.length, "currentIndexCategory", currentIndexCategory, "currentIndexQuestion", currentIndexQuestion);
      setTestProgress(testProgress + 1);
      if((currentIndexQuestion == (currentQuestions.length -2)) && (currentIndexCategory == (categories.length -1) )){
        setSubmit(true);
      }
      if (currentIndexQuestion < currentQuestions.length - 1) {
       setCurrentIndexQuestion(currentIndexQuestion + 1);
     } else if (currentIndexCategory < categories.length - 1) {
       setCurrentIndexCategory(currentIndexCategory + 1);
       setCurrentQuestions(testDetails.skills[currentIndexCategory+1].questions);
       setCurrentIndexQuestion(0);
     } 
    }
  } catch (error) {
    console.log(error);
  }
   //const categories = testDetails.skills.map(skill => skill.skill_name);
   //const currentQuestions = testDetails.skills[currentIndexCategory].questions;

   
 };


 useEffect(()=>{
  getTheNextValue();
 },[testDetails])
 

 const [previous, setPrevious]= useState(false);
 const getPreviousValue = () => {
  try {
    setTestProgress(testProgress - 1);
	  	    setSubmit(false)

    if (currentQuestions) {
      if (currentIndexQuestion > 0) {
        if(currentIndexCategory === 0 && currentIndexQuestion === 1){
          setPrevious(false);
        }
        setCurrentIndexQuestion(currentIndexQuestion - 1);
      } else if (currentIndexCategory > 0) {
        
        setCurrentIndexCategory(currentIndexCategory - 1);
        const prevCategoryQuestions =
          testDetails.skills[currentIndexCategory - 1].questions;
        setCurrentQuestions(prevCategoryQuestions);
        setCurrentIndexQuestion(prevCategoryQuestions.length - 1);
      }else{
        setPrevious(false);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
const getLevelLabel = (question) => {
  switch (question.level) {
    case "Beginner":
      return `${lang.menu.Beginner}`;
    case "Intermediate":
      return `${lang.menu.Intermediate}`;
    case "Advanced":
      return `${lang.menu.Advanced}`;
    default:
      return ""; 
  }
};

  return (

    <div style={{  width:"100vw", height:"100vh",margin: "0",padding: "0"}}>
  <Navbar  >

      
        <Navbar.Brand ><img className="brand-title" src={logo}/></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="timer">
            <span id="hours">{hours}</span>:<span id="minutes">{minutes}</span>:<span id="seconds">{seconds}</span>
          </div>
        </Navbar.Collapse>
       
     

  </Navbar> 


    <div ref={(ref) => setElementRef(ref)} style={{backgroundColor:"#D9D9D9",display: "flex", justifyContent: "center", alignItems:"center", flexDirection: "column",  overflowY: "scroll", height:"88%" }}>
    

      {(testDetails && currentQuestions) && (
        <>

        <Card style={{backgroundColor:"white", border: "2px solid white", textAlign:"center", width: "96%", marginTop: "1%", marginBottom:"1%" }}>
          
          <Card.Header>
            <h2 style={{textAlign: "left", color: "#8CD6C8"}}>{testDetails.skills[currentIndexCategory].skill_name} ({getLevelLabel(currentQuestions[currentIndexQuestion])}
          )</h2>
            <h2 style={{ color: "purple"}}>{testDetails.test_name}</h2>
            
          </Card.Header>
        
        <Card.Body style={{backgroundColor:"white"}}>

        <Row style={{width: "92%", justifyContent: "between", height:"100%"}}>
          
        <Col className="col-8" style={{textAlign: "left", position: "relative"}}>
        
            <h5><strong>{currentIndexQuestion+1 + "." + currentQuestions[currentIndexQuestion].question}</strong>
            </h5>
            <div className="center">
                        {currentQuestions[currentIndexQuestion].imageRef &&
                          renderContentRef(
                            currentQuestions[currentIndexQuestion].imageRef,
                            currentQuestions[currentIndexQuestion].id,
                            testDetails.skills[currentIndexCategory].skill_name
                          )}
                      </div>
            
      <Form.Group style={{textAlign:"left", display: "inline-block", marginTop: "5%"}}>
           
         <Form.Check
                           // type="radio"
                            id={`answer1_${currentQuestions[currentIndexQuestion].id}`}
                            name={`answer_${currentQuestions[currentIndexQuestion].id}`}
                            className="choice"
                          
                           style={{ marginRight: '10px', marginLeft:'0' }}
                          >
                            <Form.Check.Input 
                            type="radio" 
                            isValid 
                            checked={selectedAnswers[currentQuestions[currentIndexQuestion].id] === "choix1"}
                            onChange={() =>{
                              handleAnswerChange(currentQuestions[currentIndexQuestion].id, "choix1");
                              }
                            }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>A. {renderContent(
                            currentQuestions[currentIndexQuestion].choix1,
                            currentQuestions[currentIndexQuestion].id,
                            categories[currentIndexCategory]
                          )}
                          </span>
                            </Form.Check.Label>
                          
                          </Form.Check>
                          <Form.Check
                            type="radio"
                            id={`answer2_${currentQuestions[currentIndexQuestion].id}`}
                            name={`answer_${currentQuestions[currentIndexQuestion].id}`}
                            className="choice"
                           
                           style={{  marginRight: '10px', marginLeft:'0' }}
                          >
                            <Form.Check.Input 
                            type="radio" 
                            isValid
                            checked={selectedAnswers[currentQuestions[currentIndexQuestion].id] === "choix2"}
                            onChange={() =>
                              handleAnswerChange(currentQuestions[currentIndexQuestion].id, "choix2")
                            }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>
                            B. {renderContent(
                            currentQuestions[currentIndexQuestion].choix2,
                            currentQuestions[currentIndexQuestion].id,
                            categories[currentIndexCategory]
                          )}
                          
                          </span>
                          </Form.Check.Label>
                          </Form.Check>
                          <Form.Check
                            type="radio"
                            id={`answer3_${currentQuestions[currentIndexQuestion].id}`}
                            name={`answer_${currentQuestions[currentIndexQuestion].id}`}
                            className="choice"
                           
                           style={{ marginRight: '10px', marginLeft:'0' }}
                          >
                            <Form.Check.Input 
                            type="radio" 
                            isValid
                            checked={selectedAnswers[currentQuestions[currentIndexQuestion].id] === "choix3"}
                            onChange={() =>
                              handleAnswerChange(currentQuestions[currentIndexQuestion].id, "choix3")
                            }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>
                           C. {renderContent(
                            currentQuestions[currentIndexQuestion].choix3,
                            currentQuestions[currentIndexQuestion].id,
                            categories[currentIndexCategory]
                          )}
                          </span>
                          </Form.Check.Label>
                          </Form.Check>
                          {currentQuestions[currentIndexQuestion].choix4 && (
                            <>
                              <Form.Check
                                type="radio"
                                id={`answer4_${currentQuestions[currentIndexQuestion].id}`}
                                name={`answer_${currentQuestions[currentIndexQuestion].id}`}
                                className="choice"
                                
                            
                                style={{ marginRight: '10px', marginLeft:'0' }}
                              >
                                <Form.Check.Input 
                                type="radio" 
                                isValid
                                checked={
                                  selectedAnswers[currentQuestions[currentIndexQuestion].id] === "choix4"
                                }
                                onChange={() =>
                                  handleAnswerChange(currentQuestions[currentIndexQuestion].id, "choix4")
                                }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>
                            D. {renderContent(
                                currentQuestions[currentIndexQuestion].choix4,
                                currentQuestions[currentIndexQuestion].id,
                                categories[currentIndexCategory]
                              )}
                             </span>
                              </Form.Check.Label>
                              </Form.Check>
                            </>
                          )}
          </Form.Group> 
          
          <div style={{position: "absolute", bottom:"0", right: "0"}}> 
          {previous && <Button 
              variant="success"
            onClick={getPreviousValue}
            disabled={isButtonDisabled || timerExpired}
            >{lang.menu.previous}
            </Button>}
            
          {!submit ? 
          <Button 
          onClick={()=>{
            getTheNextValue();
            setPrevious(true);
          
          }}
            disabled={isButtonDisabled || timerExpired}
            >{lang.menu.next}</Button>
        : <button
        type="submit"
        className="btn btn-outline-primary btn-rounded"
        disabled={isButtonDisabled || timerExpired}
        onClick={()=>{history.goBack();getTheNextValue();}}
        variant="success"


      >
      {lang.menu.submit}
      </button>}
      </div>
         
        </Col>

        <Col className="col-4" style={{ display: "flex", flexDirection: "column", justifyContent:"space-between"}}>

        {Object.keys(result).length !== 0 &&  <div>
        <div className="row mt-4 align-items-center">
                      <div id="currentScore">
												<ReactApexChart 
                        options={{
                          dataLabels:{
                            enabled: false
                          },
                          stroke: {
                            width: 0,
                          },
                          colors:['#8cd6c8', '#74188D' ],
                          labels: ['Correct Answers', 'Wrong Answers']
                        }}
                        
                        series={[score, storedQuestion - score]}
                        type="donut"
                        height={250}
                        />
											</div>

                      {testDetails.skills.map(skill =>
                        <div>
                        <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">{skill.skill_name}</span>
												<span className="fs-16"><span className="text-black pe-2"></span>{categoryCorrectCounts[skill.skill_name] +"/" + skill.questions.length}</span>
											</div>
											
                      </div>
                      )}
                      </div>
									
                  </div>}

        
        </Col>
        </Row>
        </Card.Body>

        <Card.Footer style={{backgroundColor:"white"}}>

        <ProgressBar style={{height:"80%", border: "2px solid #8CD6C8"}} now={(testProgress <= storedQuestion ? testProgress : storedQuestion) * 100 / storedQuestion} variant="success" label={(testProgress <= storedQuestion ? testProgress : storedQuestion) + "/" + storedQuestion}></ProgressBar>

        </Card.Footer>

        </Card>
       

        </>
                              )}     




    </div>
    </div>
  );
}

export default TechQCMForRecruter;