import { React, useState, useEffect,Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import Editable from "./Editable";
import CompanyEditable from "./CompanyEditable";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import { Badge } from "react-bootstrap";
import { Card, Tabs, Tab, Nav, Modal ,Form,Button,Row,Col,OverlayTrigger,Tooltip} from "react-bootstrap";
import axios from 'axios';
import { Icon } from "@iconify/react";
import { FaArrowRight } from 'react-icons/fa';
import { LuLoader } from "react-icons/lu";
import { MdHelpOutline } from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from "react-paginate";

const Admin_dashboard = () => {
  const [adminDash, setAdminDash] = useState([]);
  const token=localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setAdminDash(
            data.some(
              (permission) =>
                permission.title === "view-admin-dashoard"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!adminDash) {
      setShowAccessDeniedModal(true); 
    }
  }, [adminDash]);
  const avatarClasses = [
    "empty-avatar-red",
    "empty-avatar-green",
    "empty-avatar-pimary",
    "empty-avatar-yellow",
    "empty-avatar-purple",
  ];
  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [userPend, setUserPend] = useState(null);
  const [subscriptionsUser, setSubscriptionsUser] = useState([]);
  const [subscriptionTypesIni, setSubscriptionTypesIni] = useState([]);

  const { activeTab } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        setCompanies(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Fetch role data from the API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/roles/roles`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        setUserRoles(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Fetch user data from the API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        const convertedData = convertNumericFields(data);
        setUsers(convertedData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  //Get all Subscriptions
  const getStatusBadge = (status) => {
    switch (status) {
        case "paid":
            return <Badge variant="success">{lang.menu.paid}</Badge>;
        case "unpaid":
            return <Badge variant="warning">{lang.menu.unpaid}</Badge>;
        case "overdue":
            return <Badge variant="danger">{lang.menu.overdue}</Badge>;
        default:
            return <Badge variant="secondary">{status}</Badge>;
    }
};
const formatCurrency = (currency) => {
  return currency.toUpperCase(); 
};
  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/findAllWithDetails`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        console.log("data :",data)

        const convertedData = convertNumericFields(data);
        console.log("convertedData :",convertedData)
        setSubscriptionsUser(convertedData)
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  useEffect(async() => {
    try {
    const response2 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const sub = response2.data;
    console.log("sub :",sub)
    setSubscriptionTypesIni(sub);
  } catch (error) {
      console.error("Error fetching permissions details:", error);
    }
  }, []);
  const [editableRow, setEditableRow] = useState(null); 
  const [editedData, setEditedData] = useState({});
  const handleInputChange = (e, field, id_subscription) => {
    const value = e.target.value; // Lit directement la valeur
    setEditedData((prevData) => ({
      ...prevData,
      [id_subscription]: {
        ...prevData[id_subscription],
        [field]: field === "isPromo" ? value === "true" : value, // Conversion correcte pour isPromo
      },
    }));
  };
  
  

  // Gérer le clic sur "Edit"
  const handleEdit = (id) => {
    setEditableRow(id);
    const rowData = subscriptionTypesIni.find((sub) => sub.id === id);
    setEditedData({ [id]: { ...rowData } });
  };

  // Gérer l'enregistrement des modifications
  const handleSave = async (id_subscription) => {
    const updatedData = editedData[id_subscription]; // Données modifiées pour cet abonnement
    if (!updatedData) return;
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/updatesubscriptionType/${id_subscription}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedData), // Données au format JSON
        }
      );
  
      if (response.ok) {
        // Mise à jour réussie, mettre à jour la liste localement
        const updatedSubscriptions = subscriptionTypesIni.map((sub) =>
          sub.id === id_subscription ? { ...sub, ...updatedData } : sub
        );
        setSubscriptionTypesIni(updatedSubscriptions);
        setEditableRow(null); // Quitter le mode édition
        alert("Subscription updated successfully!");
      } else {
        console.error("Failed to update subscription");
        alert("Failed to update subscription. Please try again.");
      }
    } catch (error) {
      console.error("Error while updating subscription:", error);
      alert("An error occurred. Please try again.");
    }
  };
  

  // Gérer l'annulation des modifications
  const handleCancel = () => {
    setEditableRow(null);
    setEditedData({});
  };
  const handlesynchro = async () => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/synchro`)
      .then((response) => response.json())
      .then((data) => {
        swal("", lang.menu.SynchronizeUsers, "success").then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const handleDeleteUser = async (event, content) => {
    event.preventDefault();

    const userId = content.uid;

    const response = await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/users/delete/${userId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );

    const data = await response.json();

    if (response.status === 500) {
      if (data.error.code === "ER_ROW_IS_REFERENCED_2")
        swal("", lang.menu.DeleteUserWithCompanyError, "error");
      else swal("", lang.menu.DeleteUserError, "error");
    } else {
      swal("", lang.menu.DeleteUserSuccess, "success").then(() => {
        const convertedData = convertNumericFields(data);
        setUsers(convertedData);
      });
    }
  };

  const handleRejectUser = async (event, content) => {
    event.preventDefault();

    const userId = content.uid;

    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/reject/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }, 
  )
      .then(
        swal("", lang.menu.RejectedusersSuccess, "success").then(() => {
          window.location.reload();
        })
      )
      .catch((error) => {
        console.error("Error rejected user:", error);
        swal("", lang.menu.RejectedusersError, "error");
      });
  };
  const handleAcceptUserBefore = (event,content) => {
    console.log("content:",content)
    event.preventDefault();
    if(content.userRoleID!==2 && content.userRoleID!==3 )  {
      handleAcceptUser(event,content)
    }else{

      
      setUserPend(content)
    setShowModal(true);  

    }
      
  };
  const handleAcceptUser = async (event, content) => {
    event.preventDefault();

    const userId = content.uid;
    const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);

    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/accept/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment: "",  
        lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" 

      }),
    })
      .then(
        swal("", lang.menu.AcceptedusersSuccess, "success").then(() => {
          window.location.reload();
        })
      )
      .catch((error) => {
        console.error("Error accepted user:", error);
        swal("", lang.menu.AccepedusersError, "error");
      });
  };

  function convertNumericFields(users) {
    const convertedUsers = users.map((user) => {
      const newUser = { ...user };

      // Convert emailVerified
      if (newUser.emailVerified === 0) {
        newUser.emailVerified = "False";
      } else if (newUser.emailVerified === 1) {
        newUser.emailVerified = "True";
      }

      // Convert disabled
      if (newUser.disabled === 0) {
        newUser.disabled = "False";
      } else if (newUser.disabled === 1) {
        newUser.disabled = "True";
      }

      return newUser;
    });

    return convertedUsers;
  }
  const fieldsToCheck = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    /*'emailVerified': lang.menu.EmailVerification,
					'Country': lang.menu.Country,
				'JobTitle': lang.menu.MyJobtitle,
				'PhoneNumber': lang.menu.PhoneNumber,
				'Language': lang.menu.Language, */
    disabled: lang.menu.Disabled,
    companyName: lang.menu.Company,
    userRole: lang.menu.Role,
    lastSignInTime: lang.menu.lastSignInTime,
    creationTime: lang.menu.creationTime,
  };

  const fieldsToCheckPending = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    disabled: lang.menu.Disabled,
    companyName: lang.menu.Company,
    userRole: lang.menu.Role,
  };

  const fieldsToCheckRejected = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    disabled: lang.menu.Disabled,
    companyName: lang.menu.Company,
    userRole: lang.menu.Role,
  };

  const fieldsToCheckCreated = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    emailVerified: lang.menu.EmailVerification,
    disabled: lang.menu.Disabled,
  };

  const fieldsToCheckCompany = {
    All: lang.menu.All,
    companyName: lang.menu.companyName,
    founder: lang.menu.founder,
    numberOfUsers: lang.menu.numberOfUsers,
    comments: lang.menu.Comments,
  };
  const fieldsToCheckSubscription = {
    Type: lang.menu.subscription,
    user_email: lang.menu.email,
    status: lang.menu.status,
  };
  
  const [selectedFilterField, setSelectedFilterField] = useState("All");
  const [selectedFilterFieldPending, setSelectedFilterFieldPending] =
    useState("All");
  const [selectedFilterFieldRejected, setSelectedFilterFieldRejected] =
    useState("All");
  const [selectedFilterFieldCreated, setSelectedFilterFieldCreated] =
    useState("All");
  const [selectedFilterFieldCompany, setSelectedFilterFieldCompany] =
    useState("All");
    const [selectedFilterFieldSubscription, setSelectedFilterFieldSubscription] =
    useState("All");
  const [filterText, setFilterText] = useState("");
  const [filterTextPending, setFilterTextPending] = useState("");
  const [filterTextRejected, setFilterTextRejected] = useState("");
  const [filterTextCreated, setFilterTextCreated] = useState("");
  const [filterTextCompany, setFilterTextCompany] = useState("");
  const [filterTextsubscription, setFilterTextsubscription] = useState("");

  const filteredUsers = users
    .filter((user) => user.adminVerified === 1)
    .filter((user) => {
      if (
        selectedFilterField === "creationTime" ||
        selectedFilterField === "lastSignInTime"
      ) {
        const fieldValue = JSON.parse(user.metadata)[selectedFilterField];
        if (
          fieldValue !== null &&
          fieldValue !== undefined &&
          fieldValue.toLowerCase().includes(filterText.toLowerCase())
        ) {
          return true;
        }
        return false;
      } else if (selectedFilterField !== "All") {
        const fieldValue = user[selectedFilterField];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterText)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterText.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterText) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          /*'emailVerified': lang.menu.EmailVerification,
									'Country': lang.menu.Country,
								'JobTitle': lang.menu.MyJobtitle,
								'PhoneNumber': lang.menu.PhoneNumber,
								'Language': lang.menu.Language, */
          disabled: lang.menu.Disabled,
          companyName: lang.menu.Company,
          userRole: lang.menu.Role,
          lastSignInTime: lang.menu.lastSignInTime,
          creationTime: lang.menu.creationTime,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (field === "lastSignInTime" || field === "creationTime") {
            fieldValue = JSON.parse(user.metadata)[field];
          }

          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterText)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue.toLowerCase().includes(filterText.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterText) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersPending = users
    .filter((user) => user.adminVerified === 0 && user.userRole)
    .filter((user) => {
      if (selectedFilterFieldPending !== "All") {
        const fieldValue = user[selectedFilterFieldPending];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextPending)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextPending.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextPending) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          disabled: lang.menu.Disabled,
          companyName: lang.menu.Company,
          userRole: lang.menu.Role,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterTextPending)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue.toLowerCase().includes(filterTextPending.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterTextPending) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersRejected = users
    .filter((user) => user.adminVerified === 2)
    .filter((user) => {
      if (selectedFilterFieldRejected !== "All") {
        const fieldValue = user[selectedFilterFieldRejected];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextRejected)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextRejected.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextRejected) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          disabled: lang.menu.Disabled,
          companyName: lang.menu.Company,
          userRole: lang.menu.Role,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterTextRejected)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue
                .toLowerCase()
                .includes(filterTextRejected.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterTextRejected) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersCreated = users
    .filter((user) => !user.userRole)
    .filter((user) => {
      if (selectedFilterFieldCreated !== "All") {
        const fieldValue = user[selectedFilterFieldCreated];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextCreated)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextCreated.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextCreated) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          emailVerified: lang.menu.EmailVerification,
          disabled: lang.menu.Disabled,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterTextCreated)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue.toLowerCase().includes(filterTextCreated.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterTextCreated) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersCompany = companies.filter((user) => {
    if (selectedFilterFieldCompany !== "All") {
      const fieldValue = user[selectedFilterFieldCompany];
      if (fieldValue !== null && fieldValue !== undefined) {
        if (typeof fieldValue === "number") {
          if (fieldValue.toString().includes(filterTextCompany)) {
            return true;
          }
        } else if (
          typeof fieldValue === "string" &&
          fieldValue.toLowerCase().includes(filterTextCompany.toLowerCase())
        ) {
          return true;
        } else if (typeof fieldValue === "boolean") {
          if (fieldValue.toString() === filterTextCompany) {
            return true;
          }
        }
      }
      return false;
    } else {
      const fieldsToCheck = {
        companyName: lang.menu.companyName,
        founder: lang.menu.founder,
        numberOfUsers: lang.menu.numberOfUsers,
        comments: lang.menu.Comments,
      };

      for (const field in fieldsToCheck) {
        let fieldValue = user[field];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextCompany)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextCompany.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextCompany) {
              return true;
            }
          }
        }
      }

      return false;
    }
  });


  const filteredSubscription = subscriptionsUser.filter((sub) => {
    
    if (selectedFilterFieldSubscription !== "All") {
      const fieldValue = sub[selectedFilterFieldSubscription];
      if (fieldValue !== null && fieldValue !== undefined) {
        
        if (typeof fieldValue === "number") {
          if (fieldValue.toString().includes(filterTextsubscription)) {
            return true;
          }
        } else if (
          typeof fieldValue === "string" &&
          fieldValue.toLowerCase().includes(filterTextsubscription.toLowerCase())
        ) {
          return true;
        } else if (typeof fieldValue === "boolean") {
          if (fieldValue.toString() === filterTextsubscription) {
            return true;
          }
        }
      }
      return false;
    } else {
      const fieldsToCheck = {
        
        Type: lang.menu.subscription,
        user_email: lang.menu.email,
        status: lang.menu.status,
      };
      

      for (const field in fieldsToCheck) {
        let fieldValue = sub[field];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextsubscription)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextsubscription.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextsubscription) {
              return true;
            }
          }
        }
      }

      return false;
    }
  });
  const itemsPerPage = process.env.REACT_APP_PAGINATION;
  const offset = currentPage * itemsPerPage;
  const paginatedData = filteredSubscription.slice(offset, offset + itemsPerPage); 
  const pageCount = Math.ceil(filteredSubscription.length / itemsPerPage); 

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const [editContentId, setEditContentId] = useState(null);
  const [editContentCompanyId, setEditContentCompanyId] = useState(null);

  const handleEditCompanyClick = (event, content) => {
    event.preventDefault();
    setEditContentCompanyId(content.companyid);
    const formValues = {
      companyid: content.companyid,
      companyName: content.companyName,
      numberOfUsers: content.numberOfUsers,
      comments: content.comments,
      founder: content.founder,
    };
    setEditCompanyData(formValues);
  };

  const handleEditClick = (event, content) => {
    event.preventDefault();
    setEditContentId(content.uid);
    const formValues = {
      userId: content.uid,
      firstName: content.FirstName,
      lastName: content.LastName,
      email: content.email,
      password: content.Password,
      country: content.Country,
      jobTitle: content.JobTitleID,
      phoneNumber: content.PhoneNumber,
      language: content.Language,
      avatar: content.Avatar,
      cover: content.Cover,
      signature: content.EmailSignature,
      userRole: content.userRoleID ? content.userRoleID : 4,
      disabled: content.disabled === "True" ? 1 : 0,
      company: content.companyName,
      emailVerified: content.emailVerified,
      lastSignInTime: content.metadata
        ? JSON.parse(content.metadata).lastSignInTime
        : "",
      creationTime: content.metadata
        ? JSON.parse(content.metadata).creationTime
        : "",
    };
    setEditFormData(formValues);
  };

  const [editCompanyData, setEditCompanyData] = useState({
    companyid: "",
    companyName: "",
    numberOfUsers: "",
    comments: "",
    founder: "",
  });

  const [editFormData, setEditFormData] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: "",
    jobTitle: "",
    phoneNumber: "",
    language: "",
    avatar: "",
    cover: "",
    signature: "",
    userRole: "",
    disabled: "",
    company: "",
    emailVerified: "",
    lastSignInTime: "",
    creationTime: "",
  });

  //update data function
  const handleEditFormChange = async (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleEditFormCompanyChange = async (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editCompanyData };
    newFormData[fieldName] = fieldValue;
    setEditCompanyData(newFormData);
  };

  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };

  const handleCancelCompanyClick = () => {
    setEditContentCompanyId(null);
  };

  const handleSubmitClick = async () => {
    try {
      // Send user data to backend for saving
      const response = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/updatefirebase`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editFormData),
        }
      );

      if (response.ok) {
        swal("", lang.menu.ProfileUpdate, "success").then(() => {
          window.location.reload();
        });
      } else {
        swal("Oops", lang.menu.ProfileUpdateError, "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteCompany = async (event, content) => {
    event.preventDefault();

    const companyid = content.companyid;

    const response = await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/delete/${companyid}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );

    const data = await response.json();

    if (response.status === 500) {
      if (data.error.code === "ER_ROW_IS_REFERENCED_2")
        swal("", lang.menu.CompShoudlBeEmpty, "error");
      else swal("", lang.menu.DeleteCompanyError, "error");
    } else {
      swal("", lang.menu.DeleteCompanySuccess, "success").then(() => {
        setCompanies(data);
      });
    }
  };

  const handleSubmitCompanyClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/savecompanyDetailsDET`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editCompanyData),
        }
      );

      if (response.status === 500) {
        console.error("Server Error: Internal Server Error (500)");
        swal("Oops", lang.menu.CompanyExists, "error");
      } else {
        const data = await response.json();
        swal("", lang.menu.CompanyUpdate, "success").then(() => {
          setCompanies(data);
          setEditContentCompanyId(null);
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  //Modal box
  const [showCompany, setShowCompany] = useState(false);

  const [companyDetails, setcompanyDetails] = useState({
    companyName: "",
    founderID: "",
    numberOfUsers: "",
    comments: "",
  });

  const handleShowCompany = async (event, content) => {
    event.preventDefault();
    setShowCompany(true);
    const companyDetails = {
      companyName: content.companyName,
      founderID: content.founderID,
      numberOfUsers: content.numberOfUsers,
      comments: content.comments,
    };
    setcompanyDetails(companyDetails);
  };





  const [selectedCurrency, setSelectedCurrency] = useState("TND");
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [enterpriseSubscriptions, setEnterpriseSubscriptions] = useState([]);
  const [soloSubscriptions, setSoloSubscriptions] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [featureselected, setFeatureselected] = useState({});
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const showFeatures = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const featureData = response.data;
  
        setFeatures(featureData);
  
        const response2 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypeSub`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sub = response2.data;
        setSubscriptionTypes(sub);
  
        const featureOrder = [
          'Candidate Profiles',
          'Candidate extraction',
          'Create job profile',
          'Create job role',
          'Parcours',
        ];
  
        const soloSubs = {};
        const enterpriseSubs = {};
  
        for (const [key, value] of Object.entries(sub)) {
          // Create a copy of the value array and sort it
          const sortedValue = [...value].sort((a, b) => {
            return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
          });
  
          if (key.startsWith("Solo") || key === "Free" || key === "Pay as you go") {
            soloSubs[key] = sortedValue;
          }
          if (key.startsWith("Entreprise") || key === "Free" || key === "Pay as you go") {
            enterpriseSubs[key] = sortedValue;
          }
        }
  
        setSoloSubscriptions(soloSubs);
        setEnterpriseSubscriptions(enterpriseSubs);
        const allSubscriptions = [];
        console.log('userPend1:', userPend);
        if(userPend!=null)
{
  let SUB=null
  if(userPend.userRoleID===2){

    SUB=enterpriseSubs
  }else{
    SUB=soloSubs

  }
      
        Object.entries(SUB).forEach(([typeName, features]) => {
          let priceType = "";
          let idProduct = "";
          features.forEach((feature) => {

            priceType = feature.type_monthly_price;
            idProduct = feature.type_id;
          });
  
          allSubscriptions.push({ typeName, features, priceType, idProduct });
        });
  
        setAllSubscriptions(allSubscriptions);
  
        const featureSelectedInitial = featureData.reduce((acc, feat) => {
          acc[feat.name] = "";
          return acc;
        }, {});
  
        setFeatureselected(featureSelectedInitial);}
      } catch (error) {
        console.error("Error fetching features:", error);
      }
    };
  
    showFeatures();
  }, [userPend]);


  const [subType, setSubType] = useState(null);

  const handlePurchaseType = (sub) => {
    setShowPurchaseForm(true)
    setSubType(sub)

  }
  const [showPurchaseForm, setShowPurchaseForm] = useState(false);
const [subscriptionPrice, setSubscriptionPrice] = useState('');
const [comment, setComment] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [monthAnnuel, setmonthAnnuel] =useState("Monthly");
const handleChangemonthAnnuel = (event) => {
  const value = event.target.value;
  setmonthAnnuel(value); 
};
const handlePurchaseSubmit = async() => {

  const subscriptionData = {
    userId: userPend.uid,
    amount: subscriptionPrice,
    note: subType.idProduct,
  };
  setIsLoading(true); 
    const subscriptionDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/createSubscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(subscriptionData),
    });

    if (!subscriptionDone.ok) {
      throw new Error('Failed to create subscription');
    }

    const dataSub = await subscriptionDone.json();
    const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);

    const invoiceData = {
      sub_id: dataSub.sub_id,
      customer_id: dataSub.customer_id,
      email: userPend.email,
      received_amount: subscriptionPrice,
      amount: subscriptionPrice, 
      payment_status:true,
      firstname: userPend.FirstName,
      currency:selectedCurrency,
      lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" ,
      monthAnnuel:monthAnnuel
    };

    const invoiceDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/invoice/createinvoice`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(invoiceData),
    });

    if (!invoiceDone.ok) {
      throw new Error('Failed to create invoice');
    }
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/accept/${userPend.uid}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment: comment,
        lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" 
      }),
    })
      .then(
        
        swal("", lang.menu.AcceptedusersSuccess, "success").then(() => {
          window.location.reload();
        })
      )
      .catch((error) => {
        setIsLoading(false)
        console.error("Error accepted user:", error);
        swal("", lang.menu.AccepedusersError, "error");
      });
  setShowPurchaseForm(false); 
  setShowModal(false)
};

const handlePriceChange = (e) => {
  let value = e.target.value;

  const regex = /^\d*\.?\d{0,2}$/;

  if (regex.test(value)) {
    if (value !== '') {
      value = parseFloat(value).toFixed(2);
    }
    console.log("value .",value)

    setSubscriptionPrice(value);
  }
};


//features tab
const [subscriptionfeaturespertypes, setSubscriptionfeaturespertypes] = useState([]);
const [subscriptionFeature, setSubscriptionFeature] = useState([]);
const [activeView, setActiveView] = useState("entreprise"); 
const [editableFeatureRow, setEditableFeatureRow] = useState(null);
const [editedFeatureData, setEditedFeatureData] = useState({});
const [editableFeature, setEditableFeature] = useState(null);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypes`);
      setSubscriptionfeaturespertypes(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  fetchData();
}, []);
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getByCategories`);
      setSubscriptionFeature(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  fetchData();
}, []);
const filteredSubscriptionType = subscriptionTypesIni.filter(subscription => {
  if (activeView === "solo") {
    return subscription.Type.toLowerCase().includes("solo") || subscription.Type.toLowerCase() === "free" || subscription.Type.toLowerCase() === "pay as you go";
  } else if (activeView === "entreprise") {
    return subscription.Type.toLowerCase().includes("entreprise") || subscription.Type.toLowerCase() === "free" || subscription.Type.toLowerCase() === "pay as you go";
  }
  return false;
});
const getFeatureQuotaMax = (featureId, typeId) => {
  const feature = subscriptionfeaturespertypes.find(
    (f) => f.feature_id === featureId && f.type_id === typeId
  );
  return feature ? feature.feature_quota_max : null;
};

const handleFeatureInputChange = (e, featureId, typeId) => {
  setEditedFeatureData((prev) => ({
    ...prev,
    [featureId]: {
      ...prev[featureId],
      [typeId]: e.target.value,
    },
  }));
};

const handleEditFeature = (featureId) => {
  if (editableFeature === featureId) {
    // Sauvegarder les données (vous pouvez appeler une API ici)
    saveFeatureData(editedFeatureData[featureId]);
    setEditableFeature(null);
  } else {
    setEditableFeature(featureId);
    // Préremplir les données existantes si nécessaire
    setEditedFeatureData((prev) => ({
      ...prev,
      [featureId]: {},
    }));
  }
};

const saveFeatureData = (featureData) => {
  // Appel API ou mise à jour des données en local
  console.log("Sauvegarde des données :", featureData);
};
const handleUpdateFeatureSub = async (subscriptionId, featureId, numberquota_max) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/updatesubscriptionfeaturespertypes/${subscriptionId}/${featureId}`,
      {
      
        numberquota_max,
      },	{
        headers: {
        Authorization: `Bearer ${token}`,
        }});
  
    console.log("Mise à jour réussie :", response.data);
    // Mettre à jour localement les données après la réussite
    setSubscriptionfeaturespertypes((prev) =>
      prev.map((item) =>
        item.type_id === subscriptionId && item.feature_id === featureId
          ? { ...item, feature_quota_max: numberquota_max }
          : item
      )
    );
  } catch (error) {
    console.error("Erreur lors de la mise à jour :", error);
  }
};

const handleFeatureInputBlur = (featureId, typeId) => {
  const newValue = editedFeatureData[featureId]?.[typeId];
  if (newValue !== undefined) {
    handleUpdateFeatureSub(typeId, featureId, newValue);
  }
  setEditableFeature(null);
};

const handleKeyPress = (e, featureId, typeId) => {
  if (e.key === "Enter") {
    handleFeatureInputBlur(featureId, typeId);
  }
};

const handleCancelFeature = () => {
  setEditableFeature(null);
  setEditedFeatureData({});
};

  return (


    <> {adminDash ? (  

    <>
      <Modal className="modal fade" show={showCompany} onHide={setShowCompany}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">{lang.menu.company}</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowCompany(false)}
                  data-dismiss="modal"
                >
                  <span></span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        {lang.menu.companyName}
                      </label>
                      <input
                        type="text"
                        id="companyName"
                        maxLength={16}
                        className="form-control"
                        value={companyDetails.companyName}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        {lang.menu.numberOfUsers}
                      </label>
                      <input
                        type="number" 
                        id="numberOfUsers"
                        className="form-control"
                        value={companyDetails.numberOfUsers}
                        min={0}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        {lang.menu.Comments}
                      </label>
                      <textarea
                        id="comments"
                        maxLength={255}
                        style={{ height: "150px" }}
                        className="form-control"
                        value={companyDetails.comments}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Card>
        <Card.Body>
          <div className="custom-tab-1 my-tab-size">
            <Tab.Container defaultActiveKey={activeTab ? activeTab : "users"}>
              <Nav as="ul" className="nav-tabs">
                <Nav.Item as="li">
                  <Nav.Link eventKey="users">
                    <i className={`la la-users me-2`} />
                    {lang.menu.Users}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Pendingusers">
                    <i className={`la la-user me-2`} />
                    {lang.menu.Pendingusers}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Rejectedusers">
                    <i className={`la la-user-times me-2`} />
                    {lang.menu.Rejectedusers}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Createdusers">
                    <i className={`la la-plus-circle me-2`} />
                    {lang.menu.Createdusers}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="companies">
                    <i className={`la la-building me-2`} />
                    {lang.menu.companies}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="subscriptions">
                  <i className={`la la-money me-2`} />                    {lang.menu.subscription}
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="users">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterText || ""}
                          onChange={(e) => setFilterText(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterField}
                          onChange={(e) =>
                            setSelectedFilterField(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheck).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheck[key]}
                            </option>
                          ))}
                        </select>
                        <button
                          type="submit"
                          className="btn btn-primary my-synchro-button ms-auto"
                          onClick={handlesynchro}
                        >
                          {lang.menu.Synchronize}
                        </button>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              {/*<th>{lang.menu.EmailVerification}</th>
															<th>{lang.menu.EmailSignature}</th>
															<th>{lang.menu.Password}</th>
															<th>{lang.menu.Country}</th>
															<th>{lang.menu.MyJobtitle}</th>
															<th>{lang.menu.PhoneNumber}</th>
															<th>{lang.menu.language}</th>*/}
                              <th>{lang.menu.Disabled}</th>
                              <th>{lang.menu.company}</th>
                              <th>{lang.menu.role}</th>
                              <th>{lang.menu.lastSignInTime}</th>
                              <th>{lang.menu.creationTime}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    userRoles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    {/*<td>
																				{content.emailVerified === "True" ? (
																					<Badge variant="success">{content.emailVerified}</Badge>
																				) : (
																					<Badge variant="danger">{content.emailVerified}</Badge>
																				)}
																			</td>
																			 <td>{content.EmailSignature}</td> 
																			 <td>{content.Password}</td>
																			 <td><strong>{content.Country}</strong></td>
																			<td>{content.JobTitle}</td>
																			<td><strong>{content.PhoneNumber}</strong></td>
																			<td>{content.Language}</td> */}
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>{content.companyName}</td>
                                    <td>{content.userRole}</td>
                                    <td>
                                      {content.metadata
                                        ? JSON.parse(content.metadata)
                                            .lastSignInTime
                                        : ""}
                                    </td>
                                    <td>
                                      {content.metadata
                                        ? JSON.parse(content.metadata)
                                            .creationTime
                                        : ""}
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-secondary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleEditClick(event, content)
                                          }
                                        >
                                          <i className="fas fa-pen"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleDeleteUser(event, content)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link>
                                        {content.companyName ? (
                                          <Link
                                            className="btn btn-primary shadow btn-xs sharp me-2"
                                            to=""
                                            onClick={(event) =>
                                              handleShowCompany(event, content)
                                            }
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Pendingusers">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextPending || ""}
                          onChange={(e) => setFilterTextPending(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldPending}
                          onChange={(e) =>
                            setSelectedFilterFieldPending(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckPending).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckPending[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              <th>{lang.menu.Disabled}</th>
                              <th>{lang.menu.company}</th>
                              <th>{lang.menu.role}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersPending.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    roles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>{content.companyName}</td>
                                    <td>{content.userRole}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-primary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleAcceptUserBefore(event, content)
                                          }
                                        >
                                          <i className="fa fa-plus"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleRejectUser(event, content)
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </Link>
                                        {content.companyName ? (
                                          <Link
                                            className="btn btn-primary shadow btn-xs sharp me-2"
                                            to=""
                                            onClick={(event) =>
                                              handleShowCompany(event, content)
                                            }
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Rejectedusers">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextRejected || ""}
                          onChange={(e) =>
                            setFilterTextRejected(e.target.value)
                          }
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldRejected}
                          onChange={(e) =>
                            setSelectedFilterFieldRejected(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckRejected).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckRejected[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              <th>{lang.menu.Disabled}</th>
                              <th>{lang.menu.company}</th>
                              <th>{lang.menu.role}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersRejected.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    userRoles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>{" "}
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>{content.companyName}</td>
                                    <td>{content.userRole}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-primary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleAcceptUserBefore(event, content)
                                          }
                                        >
                                          <i className="fa fa-plus"></i>
                                        </Link>
                                        {content.companyName ? (
                                          <Link
                                            className="btn btn-primary shadow btn-xs sharp me-2"
                                            to=""
                                            onClick={(event) =>
                                              handleShowCompany(event, content)
                                            }
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Createdusers">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextCreated || ""}
                          onChange={(e) => setFilterTextCreated(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldCreated}
                          onChange={(e) =>
                            setSelectedFilterFieldCreated(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckCreated).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckCreated[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              <th>{lang.menu.EmailVerification}</th>
                              <th>{lang.menu.Disabled}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersCreated.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    userRoles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    <td>
                                      {content.emailVerified === "True" ? (
                                        <Badge variant="success">
                                          {content.emailVerified}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.emailVerified}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleDeleteUser(event, content)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="companies">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextCompany || ""}
                          onChange={(e) => setFilterTextCompany(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldCompany}
                          onChange={(e) =>
                            setSelectedFilterFieldCompany(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckCompany).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckCompany[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.companyName}</th>
                              <th>{lang.menu.founder}</th>
                              <th>{lang.menu.numberOfUsers}</th>
                              <th>{lang.menu.Comments}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersCompany.map((content, index) => (
                              <tr key={index}>
                                {editContentCompanyId === content.companyid ? (
                                  <CompanyEditable
                                    editCompanyData={editCompanyData}
                                    handleEditFormChange={
                                      handleEditFormCompanyChange
                                    }
                                    handleSubmitClick={handleSubmitCompanyClick}
                                    handleCancelClick={handleCancelCompanyClick}
                                  />
                                ) : (
                                  <>
                                    <td>{content.companyid}</td>
                                    <td>{content.companyName}</td>
                                    <td>{content.founder}</td>
                                    <td>
                                      <strong>{content.numberOfUsers}</strong>
                                    </td>
                                    <td>{content.comments}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-secondary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleEditCompanyClick(
                                              event,
                                              content
                                            )
                                          }
                                        >
                                          <i className="fas fa-pen"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleDeleteCompany(event, content)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="subscriptions">
      <Tabs defaultActiveKey="allSubscriptions" className="mb-3">
        {/* All Subscriptions */}
        <Tab eventKey="allSubscriptions" title="All Subscriptions">
      <div className="w-100 table-responsive">
        <div id="example_wrapper" className="dataTables_wrapper">
          {/* Barre de recherche */}
          <div className="d-flex align-items-center">
            <label>
              <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
            </label>
            <input
              className="input-search form-control"
              value={filterTextsubscription || ""}
              onChange={(e) => setFilterTextsubscription(e.target.value)}
              style={{ width: "20%" }}
            />
            <select
              className="ml-5 form-select input-search form-control"
              value={selectedFilterFieldSubscription}
              onChange={(e) => setSelectedFilterFieldSubscription(e.target.value)}
              style={{ width: "10%", marginLeft: "10px" }}
            >
              {Object.keys(fieldsToCheckSubscription).map((key) => (
                <option key={key} value={key}>
                  {fieldsToCheckSubscription[key]}
                </option>
              ))}
            </select>
          </div>

          {/* Tableau */}
          <table id="example" className="display w-100 dataTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>{lang.menu.subscription}</th>
                <th>{lang.menu.email}</th>
                <th>{lang.menu.amountDue}</th>
                <th>{lang.menu.status}</th>
                <th>{lang.menu.dateDebut}</th>
                <th>{lang.menu.periodEnd}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((content, index) => (
                <tr key={index}>
                  <td>{content.sub_id}</td>
                  <td>{content.Type}</td>
                  <td>{content.user_email}</td>
                  <td>
                    <strong>
                      {content.invoice?.amount_paid} {formatCurrency(content.invoice?.currency)}
                    </strong>
                  </td>
                  <td>{getStatusBadge(content.invoice?.status)}</td>
                  <td>{new Date(content.invoice?.period_start).toLocaleDateString()}</td>
                  <td>{new Date(content.invoice?.period_end).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <ReactPaginate
            previousLabel={lang.menu.previous}
            nextLabel={lang.menu.next}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
          />
        </div>
      </div>
    </Tab>

        {/* Subscription Per User */}
        <Tab eventKey="subType" title="Subscription Type">
  <div className="w-100 table-responsive">
    <h5>Subscription Types</h5>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Type</th>
          <th>Monthly Price</th>
  
          <th>isPromo</th>
          <th>Promo Percentage</th>
          <th>  {lang.menu.actions}</th>
        </tr>
      </thead>
      <tbody>
        {subscriptionTypesIni.map((sub) => (
          <tr key={sub.id}>
    
            {editableRow === sub.id ? (
              <>
                <td>
                  <input
                    type="text"
                    value={editedData[sub.id]?.Type || ""}
                    onChange={(e) => handleInputChange(e, "Type", sub.id)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={editedData[sub.id]?.MonthlyPrice || ""}
                    onChange={(e) =>
                      handleInputChange(e, "MonthlyPrice", sub.id)
                    }
                  />
                </td>
              
                <td>
  <select
    value={editedData[sub.id]?.isPromo ? "true" : "false"}
    onChange={(e) => handleInputChange(e, "isPromo", sub.id)} 
  >
    <option value="false">False</option>
    <option value="true">True</option>
  </select>
</td>


                <td>
                  <input
                    type="number"
                    step="0.1"
                    value={editedData[sub.id]?.promoPercentage || ""}
                    onChange={(e) =>
                      handleInputChange(e, "promoPercentage", sub.id)
                    }
                    disabled={!editedData[sub.id]?.isPromo} 
                  />
                </td>
                <td>
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => handleSave(sub.id)}
                  >
                    {lang.menu.SaveChanges}
                  </button>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={handleCancel}
                  >
                      {lang.menu.cancel}
                  </button>
                </td>
              </>
            ) : (
              <>
                <td>{sub.Type}</td>
                <td>{sub.MonthlyPrice}</td>
                <td>      {sub.isPromo ? (
                    <FontAwesomeIcon icon={faCheckCircle} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} color="red" />
                  )}</td>
                <td>
                  {sub.isPromo && sub.promoPercentage !== null
                    ? `${sub.promoPercentage}%`
                    : "N/A"}
                </td>
                <td>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleEdit(sub.id)}
                  >
                      {lang.menu.Update}
                  </button>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</Tab>


<Tab eventKey="SubscriptionFeatures" title="Subscription Features ">
  <div className="feature-section">
    {/* Boutons de bascule pour afficher les types */}
    
    <div className="text-center mt-3">
  <label className={`lunar-solar-switch ${activeView === "entreprise" ? "active" : ""}`}>
    <input
      type="checkbox"
      className="visually-hidden"
      checked={activeView === "entreprise"}
      onChange={() => setActiveView(activeView === "entreprise" ? "solo" : "entreprise")}
    />
    <span className="lunar-solar-label">
      {activeView === "solo" ? lang.menu.Solo : lang.menu.company}
    </span>
  </label>
</div>



    {/* Table des fonctionnalités */}
    <div className="feature-table-wrapper responsive-table">
  <table className="feature-table">
    <thead>
      <tr>
        <th className="feature-category"> {lang.menu.Feature}</th>
        {filteredSubscriptionType.map((subscription) => (
          <th className="plan-header" key={subscription.id}>
            {subscription.Type === "Pay as you go" ? "Customized Offer" : subscription.Type}
            <br />
            <span className="plan-availability">Available</span>
          </th>
        ))}
        <th>{lang.menu.actions}</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(subscriptionFeature).map((category) => (
        <Fragment key={category}>
          {/* Ligne de catégorie */}
          <tr className="category-row">
            <td colSpan={filteredSubscriptionType.length + 2}>
              <b>{category}</b>
            </td>
          </tr>
          {/* Détails des fonctionnalités */}
          {subscriptionFeature[category].map((feature) => (
            <tr key={feature.id}>
              <td className="feature-name">
                {feature.name}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{feature.description}</Tooltip>}
                >
                  <span style={{ cursor: "pointer" }}>
                    <MdHelpOutline />
                  </span>
                </OverlayTrigger>
              </td>
              {filteredSubscriptionType.map((subscription) => (
  <td key={subscription.id}>
    {editableFeature === `${feature.id}-${subscription.id}` ? (
      <input
        type="number"
        value={
          editedFeatureData[feature.id]?.[subscription.id] ||
          getFeatureQuotaMax(feature.id, subscription.id) ||
          ""
        }
        onChange={(e) =>
          handleFeatureInputChange(e, feature.id, subscription.id)
        }
        onBlur={() => handleFeatureInputBlur(feature.id, subscription.id)}
        onKeyDown={(e) => handleKeyPress(e, feature.id, subscription.id)}
        className="form-control"
        style={{ maxWidth: "100px" }}
        autoFocus
      />
    ) : (
      <span
        onClick={() =>
          setEditableFeature(`${feature.id}-${subscription.id}`)
        }
        style={{ cursor: "pointer" }}
      >
        {
          subscriptionfeaturespertypes.find(
            (subType) =>
              subType.feature_id === feature.id &&
              subType.type_id === subscription.id
          )?.feature_quota_max || "-"
        }
      </span>
    )}
  </td>
))}
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleEditFeature(feature.id)}
                >
                  {editableFeature === feature.id ? "Save" : "Edit"}
                </button>
         {/*        <button
                  className="btn btn-secondary btn-sm"
                  onClick={handleCancelFeature}
                >
                  {lang.menu.cancel}
                </button> */}
              </td>
            </tr>
          ))}
        </Fragment>
      ))}
    </tbody>
  </table>

  <style jsx>{`
  .lunar-solar-switch {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 40px;
  background-color: #74188D;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.lunar-solar-switch::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 75px;
  height: 36px;
  background-color: #dd8ff3;
  border-radius: 18px;
  transition: left 0.3s ease;
}

.lunar-solar-switch.active {
  background-color: #74188D;
}

.lunar-solar-switch.active::before {
  left: 75px;
}

.lunar-solar-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  right: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: white;
}

    .feature-table-wrapper {
      background: #f9f9f9;
      padding: 20px;
      margin-top: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      overflow-x: auto;
    }

    .feature-table {
      width: 100%;
      min-width: 600px; /* Taille minimale pour éviter l’écrasement */
      border-collapse: collapse;
    }

    .feature-table th,
    .feature-table td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      white-space: nowrap; /* Empêche les textes longs de casser la mise en page */
    }

    .category-row {
      background: #f1f3f9;
      font-size: 14px;
      font-weight: bold;
    }

    .plan-header {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }

    .feature-name {
      font-size: 14px;
      font-weight: 500;
      color: #333;
      }

    @media (max-width: 768px) {
      .feature-table-wrapper {
        padding: 10px;
      }

      .feature-table th,
      .feature-table td {
        padding: 8px;
        font-size: 12px;
      }

      .feature-table th {
        white-space: normal; /* Permet d’ajuster le texte */
      }
       
    }
  `}</style>
</div>

  </div>
</Tab>

      </Tabs>
    </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-100w">
  <Modal.Header>
    <Modal.Title>{lang.menu.Offress}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
     
        <Row>
          {allSubscriptions.map((subscription) => {
            const cardClass = 'pricing-box h-100 shadow-sm';
            return (
              <Col key={subscription.typeName} md={4}>
                <Card className={cardClass}>
                  <Card.Body className="p-4 px-lg-5">
                    <div className="pricing-name mt-4 pt-2 text-center">
                      <h4 className="fs-18 text-primary">
                        <div className="pricing-icon rounded-circle icons-md mx-auto mb-4">
                          <Icon icon="uim-telegram-alt" className="text-primary" />
                          {subscription.typeName}
                        </div>
                      </h4>
                    
                    </div>
                 
                    <ul className="list-unstyled mt-3 mb-4">
                    {subscription.features
                      .filter((feature) => feature.feature_quota_max !== null)
                      .map((feature) => (
                        <li key={feature.feature_id} className="my-2">
                          <strong>{feature.feature_name}:</strong> {feature.feature_quota_max}
                        </li>
                      ))}

                    </ul>
                    <Button
  className="btn btn-primary btn-block"
  onClick={() => handlePurchaseType(subscription)}
>
  {lang.menu.purchaseNow} <FaArrowRight />
</Button>


                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      {lang.menu.retour}
    </Button>
  </Modal.Footer>
</Modal>
<Modal show={showPurchaseForm} onHide={() => setShowPurchaseForm(false)}>
  <Modal.Header>
    <Modal.Title>{lang.menu.purchaseNow}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
      <Form>
      <Form.Label>{lang.menu.amount}</Form.Label>
      <Row>
          <Col xs={9}>
            {/* Largeur plus grande pour l'entrée de prix */}
            <Form.Group controlId="subscriptionPrice">
              <Form.Control
                type="text"
                placeholder="00.00"
                value={subscriptionPrice}
                onChange={handlePriceChange}
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            {/* Largeur plus petite pour la sélection de la devise */}
            <Form.Group controlId="currency">
              <Form.Control
                as="select"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                <option value="GBP">GBP</option>
                <option value="TND">TND</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>


 
        <Form.Group controlId="comment">
          <Form.Label>      {lang.menu.comment}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>

        <div className="mb-3">
      <label htmlFor="formFrequency" className="form-label">
        {lang.menu.frequency}
      </label>
      <select
        id="formFrequency" 
        name="frequency"
        value={monthAnnuel} 
        onChange={handleChangemonthAnnuel} 
        className="form-select"
      >
        <option value="Monthly"> {lang.menu.Mensuel}</option>
        <option value="Annuel"> {lang.menu.Annuel}</option>
      </select>
    </div>

      </Form>
    </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowPurchaseForm(false)}>
      {lang.menu.cancel}
    </Button>
    <Button variant="primary" onClick={handlePurchaseSubmit}>
    {isLoading ? <LuLoader /> : lang.menu.submit}


    </Button>
  </Modal.Footer>
</Modal>

    </>) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};
export default Admin_dashboard;
