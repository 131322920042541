import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select'; // Import react-select
import translations from "../AppsMenu/Candidate/mulitlingue";

const InterviewList = () => {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const token = localStorage.getItem("token");
  const [jobRoles, setJobRoles] = useState([]);
  const [jobTitles, setJobTitles] = useState({});
  const [recruiters, setRecruiters] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(null); // To store the selected interview
  const [selectedRecruiters, setSelectedRecruiters] = useState([]); // To store the selected recruiters
  const [showModal, setShowModal] = useState(false); // To control modal visibility

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });

  // Fetch interviews from the API
  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/interview/FindAllinterviews`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInterviews(response.data);
      } catch (err) {
        setError('Erreur lors de la récupération des entretiens.');
      } finally {
        setLoading(false);
      }
    };
    fetchInterviews();
  }, [token]);

  // Fetch job roles from the API
  useEffect(() => {
    const fetchJobRoles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const jobs = response.data;
        setJobRoles(jobs);

        const titles = jobs.reduce((acc, job) => {
          acc[job.id] = job.JobTitle;
          return acc;
        }, {});
        setJobTitles(titles);
      } catch (err) {
        setError('Erreur lors de la récupération des rôles de travail.');
      }
    };
    fetchJobRoles();
  }, [token]);

  if (loading) {
    return <div className="loading"><Spinner animation="border" variant="primary" /> Chargement...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const locale = lang.language === 'Français' ? 'fr-FR' : 'en-US';

  // Handle update button click to show modal and fetch recruiter details
  const handleUpdate = async (interview) => {
    setSelectedInterview(interview); // Set the selected interview to show in the modal
    const responsejob = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/read/${interview.jobrole_id}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }});
    const jobDetails = responsejob.data[0];
    const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }});
    const allUsers = response.data;
    const filterRecruiter = allUsers.filter(user => user.companyID === jobDetails.companyid && (user.userRoleID === 3 || user.userRoleID === 2 || user.userRoleID === 1));
    setRecruiters(filterRecruiter);

    // Set the selected recruiters from the interview
    console.log("interview.recruiters  :",interview.recruiters )
    const interviewRecruiters = interview.recruiters ? interview.recruiters.map(recruiter => recruiter.uid) : [];
    console.log("interviewRecruiters  :",interviewRecruiters )

    setSelectedRecruiters(interviewRecruiters); 

    setShowModal(true); 
  };

  const handleRecruiterChange = (selectedOptions) => {
    setSelectedRecruiters(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedInterview(null);
    setSelectedRecruiters([]);
  };
  const handleSaveChanges = async () => {
    try {
      const { id } = selectedInterview; 
      const { date_time, status, comments, lien } = selectedInterview; 

      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/interview/Updateinterviews/${id}`,
        {
          candidate_id: selectedInterview.candidate_id,
          recruiter_ids: selectedRecruiters,
          date_time,
          jobrole_id: selectedInterview.jobrole_id,
          status,
          comments,
          lien,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle success
      console.log(response.data);
      setShowModal(false);
      setInterviews(prevInterviews => 
        prevInterviews.map(interview => 
          interview.id === id ? { ...interview, ...response.data.interview } : interview
        )
      ); 
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'entretien :", error);
      setError("Erreur lors de la mise à jour de l'entretien.");
    }
  };
  return (
    <div className="interview-list-container">
      <h2>Liste des Entretiens</h2>
      {interviews.length === 0 ? (
        <p>Aucun entretien trouvé.</p>
      ) : (
        <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
          <thead style={{ backgroundColor: "#8cd6c8" }}>
            <tr>
              <th>{lang.menu.JobRoleName}</th>
              <th>{lang.menu.Candidate}</th>
              <th>{lang.menu.Recruiter}s</th>
              <th>link</th>
              <th>{lang.menu.DateTime}</th>
              <th>{lang.menu.status}</th>
              <th>{lang.menu.comment}</th>
              <th>{lang.menu.actions}</th>
            </tr>
          </thead>
          <tbody>
            {interviews.map((interview) => (
              <tr key={interview.id}>
                <td>{jobTitles[interview.jobrole_id] || 'Non spécifié'}</td>
                <td>{interview.candidate?.email || 'Non spécifié'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {interview.recruiters && interview.recruiters.length > 0 ? (
                    interview.recruiters.map((recruiter) => (
                      <Badge key={recruiter.uid} pill variant="warning light" style={{ marginRight: '5px' }}>
                        {recruiter.email}
                      </Badge>
                    ))
                  ) : (
                    'Non spécifié'
                  )}
                </td>
                <td>
  <a href={interview.lien} target="_blank" rel="noopener noreferrer">
    {interview.lien.length > 20 ? interview.lien.substring(0, 20) + '...' : interview.lien}
  </a>
</td>
                <td>
                  {new Date(interview.date_time).toLocaleString(locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </td>
                <td>{interview.status}</td>
                <td>{interview.comments || 'Aucun'}</td>
                <td>
                  <Button variant="primary" onClick={() => handleUpdate(interview)}>  <i class="fas fa-pencil-alt"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal for updating interview */}
      {selectedInterview && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{lang.menu.UpdateInterview}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formJobRole">
                <Form.Label>{lang.menu.JobRoleName}</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={jobTitles[selectedInterview.jobrole_id] || ''}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="formCandidateEmail">
                <Form.Label>{lang.menu.Candidate}</Form.Label>
                <Form.Control
                  type="email"
                  defaultValue={selectedInterview.candidate?.email || ''}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="formLink">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedInterview.lien || ''}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="formDateTime">
                <Form.Label>{lang.menu.DateTime}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  onChange={(e) => setSelectedInterview({ ...selectedInterview, date_time: e.target.value })}

                  defaultValue={new Date(selectedInterview.date_time).toISOString().slice(0, 16)}
                />
              </Form.Group>
              <Form.Group controlId="formStatus">
                <Form.Label>{lang.menu.status}</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={selectedInterview.status || ''}
                  onChange={(e) => setSelectedInterview({ ...selectedInterview, status: e.target.value })}

                />
              </Form.Group>
              <Form.Group controlId="formComments">
                <Form.Label>{lang.menu.comment}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  defaultValue={selectedInterview.comments || ''}
                  onChange={(e) => setSelectedInterview({ ...selectedInterview, comments: e.target.value })}

                />
              </Form.Group>
              <Form.Group controlId="formRecruiter">
                <Form.Label>{lang.menu.Recruiter}s</Form.Label>
                <Select
                  isMulti
                  value={recruiters
                    .filter((recruiter) => selectedRecruiters.includes(recruiter.uid))
                    .map((recruiter) => ({
                      value: recruiter.uid,
                      label: recruiter.email,
                    }))}
                  onChange={handleRecruiterChange}
                  options={recruiters.map((recruiter) => ({
                    value: recruiter.uid,
                    label: recruiter.email,
                  }))}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "40px",
                      lineHeight: "40px",
                      borderRadius: "0",
                    }),
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
   
            <Button variant="primary" onClick={handleSaveChanges}>
              {lang.menu.SaveChanges}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default InterviewList;
