import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./PersonalityTestDetails.css";
import { RiArrowGoBackFill } from "react-icons/ri";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function PersonalityTestDetails() {
  const { id } = useParams();
  const [questions, setquestions] = useState([]);
  const history = useHistory();
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/` + id, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
        )
      .then((res) => {
        console.log(res);
        setquestions(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="personality-test-details">
      <h1 className="test-details-title">{questions[0]?.name}</h1>
    
      <div className="test-details-table-container">
        <table className="table">
          <thead style={{ backgroundColor: "#8cd6c8"}}>
            <tr >
              <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.Question}</th>
              <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.Coefficient}</th>
              <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.trait}</th>
              <th style={{fontSize: '14px', fontWeight: 'bold'}}>{lang.menu.evaluation}</th>
            </tr>
          </thead>
          <tbody>
            {questions?.map((tableData, index) => (
              <tr key={index}>
                <td style={{fontSize: '14px'}}>{tableData.question}</td>
                <td style={{fontSize: '14px'}} >{tableData.coefficient}</td>
                <td style={{fontSize: '14px'}} >{tableData.trait}</td>
                <td style={{fontSize: '14px'}}>{tableData.answer_value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PersonalityTestDetails;
