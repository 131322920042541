import React, { useEffect, useState } from "react";

import Select from "react-select";
import data from "../data";
import { Button,Form, Toast } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import { useHistory } from "react-router-dom";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateAssignmentForm() {
  const [comapanyId, setComapanyId] = useState([]);
  const token=localStorage.getItem("token");

var comp=0;
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const history = useHistory();

  const [height, setHeight] = useState("150px");
  function handleChangeStyle(e) {
    e.preventDefault();
    setHeight("300px");
    let newHeight = e.target.scrollHeight + "px";
    setHeight(newHeight);
  }

  //Style Select
  const selectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "15px",
      boxShadow: "none",
      border: "none",
      "&:hover": {
        border: "none",
      },
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#333",
      "&:hover": {},
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "15px",
      marginTop: "-1px",
      boxShadow: "none",
      border: "none",
      "&:before": {
        display: "none",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#555",
    }),
  };
 
  const [assignment, setAssignment] = useState({
    name: "", 
    description: "",
    difficulty: "Beginner",
    duration: "",
    repository: "",
  });
 
 /* useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await response.json();
            console.log("data.companyID : ",data[0].companyID)
            comp=data[0].companyID;
            console.log("comp: ",comp)
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };
  
    fetchUserPermissions();
  }, [comp]);
*/

const validateURL = (str) => {
  const pattern = new RegExp(
      '^https://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|' +
      '(?:%[0-9a-fA-F][0-9a-fA-F]))+$'
  );
  return pattern.test(str);
};

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("assignment.duration:",assignment.duration)
    if (!assignment.difficulty) {
      swal(lang.menu.difficultyError, {
        icon: "error",
      });
      return;
    }
    if(parseInt(assignment.duration) > process.env.REACT_APP_TEMPORARY_USER_DURATION || parseInt(assignment.duration) <1){
      swal(lang.menu.durationError, {
        icon: "error",
      });
        return;
      
    }
    if(assignment.repository && !validateURL(assignment.repository)){
      swal(lang.menu.urlError, {
        icon: "error",
      });
      return;
    }
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      .then((response) => {
        if (response.data && response.data[0] && response.data[0].companyID) {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          axios
            .post(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/${userId}`, assignment, 
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            .then((res) => {
              console.log("assignment sent" + JSON.stringify(assignment));
              swal(lang.menu.assignmentSuccess, {
                icon: "success",
              });
              history.push(`/assignment-list`);
            })
            .catch((err) => {
              swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching company ID:", error);
      });
  };
  
  

  
  
  return (
    <>
      <h1>{lang.menu.CreateNewAssignment} </h1>
      <br />
      <div className="max-widht">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h4 className="left-align-container smallscreen">
                    {lang.menu.AssignmentName}
                  </h4>

                  <div className="input-group ">
                    <input
                      required
                      type="text"
                      className={`form-control `}
                      name="name"
                      placeholder=" Title"
                      maxLength={60}
                      onChange={(e) =>
                        setAssignment({ ...assignment, [e.target.name]: e.target.value })
                      }
                    />
                  </div>
                  <br />
                  <h4 className="left-align-container smallscreen">
                    {lang.menu.description}
                  </h4>
                  <div>
                    <textarea
                      required
                      style={{ height: height }}
                      name="description"
                      maxLength={1000}
                      onChange={(e) =>
                        setAssignment({ ...assignment, [e.target.name]: e.target.value })
                      }
                      type="text"
                      className={`form-control`}
                      placeholder=" Assignment Description"
                    />
                  </div>
                  <br />

                  <h4 className="left-align-container smallscreen">
                    {lang.menu.Repository}
                    <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>{lang.menu.repoMsg}</p>
              </div>
                      </div>
                  </h4>

                  <div className="input-group ">
                    <input
                      type="text"
                      className={`form-control `}
                      name="repository"
                      maxLength={250}
                      placeholder={lang.menu.Repository}
                      onChange={(e) =>
                        setAssignment({ ...assignment, [e.target.name]: e.target.value })
                      }
                    />
                  </div>
                  <br />
                  <div className="select-container" >
                  <div className="select-wrapper-right">
                  <h4 className="left-align-container smallscreen">
                    {lang.menu.assignmentDuration}
                    <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>{lang.menu.dureeMsg}</p>
              </div>
                      </div>
                  </h4>

                  <Form.Control
                        type="number"
                        name="duration"
                        required
                        placeholder={lang.menu.assignmentDuration}
                        onChange={(e) =>
                            setAssignment({ ...assignment, [e.target.name]: e.target.value })}
                             min="1"
                       />
                            

                  
                    </div>


                    <div className="select-wrapper-left" >
                      <h4 className="smallscreen">
                        {lang.menu.Difficulty}
                      </h4>
                      <Form.Control
                        as="select"
                        name="difficulty"
                        required
                        placeholder= {lang.menu.Difficulty}
                        onChange={(e) =>
                            setAssignment({ ...assignment, [e.target.name]: e.target.value })}
                        >
                            
                            <option value="Beginner">{lang.menu.Beginner}</option>
                            <option value="Intermediate">{lang.menu.Intermediate}</option>
                            <option value="Advanced">{lang.menu.Advanced}</option>
                        </Form.Control>

                    </div>
                  </div>

                  <br />
            
                <div className="card-footer border-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      className="btn btn-primary btn sweet-confirm"
                    >
                      {lang.menu.PostNow}
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default CreateAssignmentForm;