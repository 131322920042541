/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import { MdPsychology } from "react-icons/md";
import { LuBrainCircuit } from "react-icons/lu";
import { FaRegHandshake } from "react-icons/fa6";
import { PiBuildingOfficeBold } from "react-icons/pi";
import { MdOutlineDashboard } from "react-icons/md";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);

      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  
  const [candidatePerSend, setCandidatePerSend] = useState(false);

  const [candidateExt, setCandidateExt] = useState(false);
  const [candidatePer, setCandidatePer] = useState(false);
  const [candidateListRef, setCandidateListRef] = useState(false);
  const [candidateEval, setCandidateEval] = useState(false);
  const [candidateAssessment, setCandidateAssessment] = useState(false);
  const [categoryPer, setCategoryPer] = useState(false);
  const [importPsychoPer, setImportPsychoPer] = useState(false);
  const [listPsychoPer, setListPsychoPer] = useState(false);
  const [psychoResultPer, setPsychoResultPer] = useState(false);
  const [skillPer, setSkillPer] = useState(false);
  const [importTechPer, setImportTechPer] = useState(false);
  const [listTechPer, setListTechPer] = useState(false);
  const [techResultPer, setTechResultPer] = useState(false);
  const [topicsList, setTopicsList] = useState(false);
  const [skillDash, setSkillDash] = useState(false);

  
  const [PersonalityTestRES, setPersonalityTestRES] = useState(false);

  const [sendPersonalityTest, setSendPersonalityTest] = useState(false);
  const [jobRoleList, setJobRoleList] = useState(false);
  const [jobTest, setJobTest] = useState(false);
  const [codingResultJob, setCodingResultJob] = useState(false);
  const [techList, setTechList] = useState(false);

  /***SideBar */
  const [candidateViewSide, setCandidateViewSide] = useState(false);
  const [psychoViewSide, setPsychoViewSide] = useState(false);
  const [techViewSide, setTechViewSide] = useState(false);
  const [codingViewSide, setCodingViewSide] = useState(false);
  const [techQCMViewSide, setTechQCMViewSide] = useState(false);
  const [viewAssignment, setViewAssignment] = useState(false);
  const [viewPersonalityTest, setviewPersonalityTest] = useState(false);
  const [viewJobs, setViewJobs] = useState(false);
  const [userResume, setUserResume] = useState(false);

  const [showSideBar, setShowSideBar]= useState(false);

  //assignment
  const [assignmentList, setAssignmentList]= useState(false);
  const [assignmentResult, setAssignmentResult]= useState(false);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setViewJobs(
            data.some((permission) => permission.title === "view-job")
          );
   setCandidatePerSend(
            data.some((permission) => permission.title === "send-cv")
          );
 setCandidateExt(
            data.some((permission) => permission.title === "cvs-extraction")
          );
  setCandidatePer(
            data.some((permission) => permission.title === "candidate-list")
          );
setCandidateListRef(
            data.some((permission) => permission.title === "list-ref")
          );
 setCandidateEval(
            data.some((permission) => permission.title === "candidate-eval")
          );
 setCandidateAssessment(
            data.some(
              (permission) => permission.title === "candidate-assessment"
            )
          );
    setCategoryPer(
            data.some((permission) => permission.title === "list-categories")
          );
 setImportPsychoPer(
            data.some(
              (permission) => permission.title === "import-questions-testPsycho"
            )
          );
   setListPsychoPer(
            data.some((permission) => permission.title === "list-psycho-test")
          );
setPsychoResultPer(
            data.some(
              (permission) => permission.title === "result-psycho-tests"
            )
          );

    setSkillPer(
            data.some((permission) => permission.title === "list-skills")
          );
setImportTechPer(
            data.some(
              (permission) => permission.title === "import-questions-testTech"
            )
          );
setListTechPer(
            data.some((permission) => permission.title === "list-tech-test")
          );
 setTechResultPer(
            data.some((permission) => permission.title === "result-tech-tests")
          );

 setTopicsList(
            data.some((permission) => permission.title === "topics")
          );

setviewPersonalityTest(
            data.some(
              (permission) => permission.title === "view-personality-test"
            )
          );
          setPersonalityTestRES(
            data.some(
              (permission) => permission.title === "view-personality-test-result"
            )
          );
   setSendPersonalityTest(
            data.some(
              (permission) =>
                permission.title === "send-personality-test-to-candidate"
            )
          );
  setJobRoleList(
            data.some((permission) => permission.title === "job-role-list")
          );
 setJobTest(
            data.some((permission) => permission.title === "jobrole-list-test")
          );
setCodingResultJob(
            data.some((permission) => permission.title === "list-test-tech-coding")
          );
setTechList(
            data.some((permission) => permission.title === "tech-list")
          );
setCandidateViewSide(
            data.some((permission) => permission.title === "view-candidates")
          );
setPsychoViewSide(
            data.some((permission) => permission.title === "view-PsychoTechnical-test")
          );
setTechViewSide(
            data.some((permission) => permission.title === "view-technical-test")
          );
setCodingViewSide(
            data.some((permission) => permission.title === "view-coding-test")
          );
 setTechQCMViewSide(
            data.some((permission) => permission.title === "view-technical-QCM-test")
          );

          setViewAssignment(
            data.some((permission) => permission.title === "view-assignment")
          );

          setAssignmentList(
            data.some((permission) => permission.title === "assignment-list")
          );

          setAssignmentResult(
            data.some((permission) => permission.title === "view-assignment-result")
          );
          setUserResume(
            data.some((permission) => permission.title === "user-resume")
          );
          setSkillDash(
            data.some((permission) => permission.title === "skill-dashoard")
          );

          setShowSideBar(true);
          
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []); 



  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    closeMenuToggle,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  //let scrollPosition = useScrollPosition();
  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow === hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  //console.log("path window", path);
  path = path.split("/");
  path = path[path.length - 1];
  //console.log("path wi", path);

  /// Active menu
  let deshBoard = [
      "",
      "RadarGlobal",
      "CandidateList",
      "ListAllCandidates",
      "CandidateEval",
      "UserLogs",
      "CandidatesPerApp",
      "JobAssessment",
      "dashboard-dark",
      "search-jobs",
      "applications",
      "my-profile",
      "statistics",
      "companies",
      "task",
    ],
    job = [
      "apply-job",
      "job-list",
      "job-view",
      "job-application",

      "new-job",
      "user-profile",
      "post-job",
      "Jobs-test",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = ["redux-form", "redux-wizard", "todo"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    personalityTests = [
      "personality-test",
      "new-test",
      "peronality-test-result",
      "test-list",
    ],
    candidates = [
      "CvUpload",

      "CVsDataExtraction",

      "ChatGPT",
      "CandidatProfile",
      "UserResumes"
    ],
    testPsychoTechnique = [
      "ListCategories",
      "libraryPsychoMCQQuestions",
      "TestPsychotechniqueList",
      "Your-Psycho-Technical-tests",
      "RadarPsycho",
    ],
    testTechniqueQCM = [
      "ListSkills",
      "libraryTechniqueMCQQuestions",
      "TestTechniqueList",
      "Your-Technical-tests",
      "RadarTech",
      "skillDash"
    ],
    Coding = ["Topics", "ListTestTechCoding"],
    TestTechnique = ["TechList", "Coding", "testTechniqueQCM"],
    assignmentTests = [
      "assignment-list",
      "create-assignment",
      "assignment-result",

    
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      {showSideBar && 
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
        {candidatePer ? (
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
            <MdOutlineDashboard  size={70} color="black" />

              <span className="nav-text">{lang.menu.dashboard}</span>
            </Link>
            <ul>
            
              {candidatePer ? (
                <li>
                  <Link
                    className={`${path === "CandidateList" ? "mm-active" : ""}`}
                    to="/CandidateList"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.candidateList}
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {candidateListRef ? (
                <li>
                  <Link
                    className={`${
                      path === "List-All-Candidates" ? "mm-active" : ""
                    }`}
                    to="/ListAllCandidates"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.listRef}
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {candidateEval ? (
                <li>
                  <Link
                    className={`${path === "CandidateEval" ? "mm-active" : ""}`}
                    to="/CandidateEval"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.evaluateCandidate}
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {/* userlogs */}
                {/* {candidateAssessment ? (
                <li>
                  <Link
                    className={`${path === "UserLogs" ? "mm-active" : ""}`}
                    to="/UserLogs"
                    onClick={closeMenuToggle}
                  >
                    User Logs
                  </Link>
                </li>
              ) : (
                <></>

              )} */}
                  {candidateAssessment ? (
                <li>
                  <Link
                    className={`${path === "CandidatesPerApp" ? "mm-active" : ""}`}
                    to="/CandidatesPerApp"
                    onClick={closeMenuToggle}
                  >
{lang.menu.candPerApp}
                  </Link>
                </li>
              ) : (
                <></>
              )}
  {candidateAssessment ? (
                <li>
                  <Link
                    className={`${path === "JobAssessment" ? "mm-active" : ""}`}
                    to="/JobAssessment"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.jobAssessment}
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {/** 
              <li>
                <Link
                  className={`${path === "search-jobs" ? "mm-active" : ""}`}
                  to="/search-jobs"
                  onClick={closeMenuToggle}
                >
                  {" "}
                  Jobs
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "applications" ? "mm-active" : ""}`}
                  to="/applications" onClick={closeMenuToggle}
                >
                  {" "}
                  Applications
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "my-profile" ? "mm-active" : ""}`}
                  to="/my-profile" onClick={closeMenuToggle}
                >
                  {" "}
                  My Profile
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "statistics" ? "mm-active" : ""}`}
                  to="/statistics" onClick={closeMenuToggle}
                >
                  Statistics
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "companies" ? "mm-active" : ""}`}
                  to="/companies" onClick={closeMenuToggle}
                >
                  Companies
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "task" ? "mm-active" : ""}`}
                  to="/task" onClick={closeMenuToggle}
                >
                  Task
                </Link>
              </li>
              */}
            </ul>
          </li>) : (
            <></>
          )}
       {viewPersonalityTest ? (
            <li
              className={`${
                personalityTests.includes(path) ? "mm-active" : ""
              }`}
            >
              <Link className="has-arrow" to="#">
              <MdPsychology size={70} color="black" />

                <span className="nav-text">{lang.menu.perTest}</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "personality-test" ? "mm-active" : ""
                    }`}
                    to="/personality-test"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.perTest}
                  </Link>
                </li>
                {PersonalityTestRES ? ( <li>
                  <Link
                    className={`${
                      path === "peronality-test-result" ? "mm-active" : ""
                    }`}
                    to="/peronality-test-result"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.personalityTestRes}
                  </Link>
                </li>) : (
            <></>
          )}
              </ul>
            </li>
          ) : (
            <></>
          )}
          {psychoViewSide ? (
          <li
            className={`${
              testPsychoTechnique.includes(path) ? "mm-active" : ""
            }`}
          >
            <Link className="has-arrow ai-icon" to="#">
            <LuBrainCircuit size={50} color="black" />
            <span className="nav-text">{lang.menu.PsychoTechnical}</span>
            </Link>
            <ul>
              {categoryPer ? (
                <li>
                  <Link
                    className={`${
                      path === "ListCategories" ? "mm-active" : ""
                    }`}
                    to="/ListCategories"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.listCat}
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {importPsychoPer ? (
                <li>
                  <Link
                    className={`${path === "libraryPsychoMCQQuestions" ? "mm-active" : ""}`}
                    to="/library-Psycho-MCQ-Questions"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.libraryPsychoMCQQuestions}
                  </Link>
                </li>
              ) : (
                <></>
              )}
         
              {listPsychoPer ? (
                <li>
                  <Link
                    className={`${
                      path === "TestPsychotechniqueList" ? "mm-active" : ""
                    }`}
                    to="/TestPsychotechniqueList"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.testPsychoTechniqueList}
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {psychoResultPer ? (
                <li>
                  <Link
                    className={`${path === "RadarPsycho" ? "mm-active" : ""}`}
                    to="/RadarPsycho"
                    onClick={closeMenuToggle}
                  >
                       {lang.menu.psychoResult}
                       </Link>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </li> ) : (
            <></>
          )}
          {techViewSide ? (
          <li className={`${TestTechnique.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-007-bulleye"></i>
              <span className="nav-text">{lang.menu.testTechnique}</span>
            </Link>
            <ul>
          
            {codingViewSide ? (
              <li className={`${Coding.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {lang.menu.coding}
                </Link>
                <ul>
                  {topicsList ? (
                    <li>
                      <Link
                        className={`${
                          path === "TopicsByCandidate" ? "mm-active" : ""
                        }`}
                        to="/TopicsByCandidate"
                        onClick={closeMenuToggle}
                      >
                        {lang.menu.listTopic}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                   {codingResultJob ? (    <li>
                    {" "}
                 <Link
                      className={`${
                        path === "ListTestTechCoding" ? "mm-active" : ""
                      }`}
                      to="/ListTestTechCoding"
                      onClick={closeMenuToggle}
                    >
                      {lang.menu.codingRes}
                    </Link>
                  </li>) : (
                    <></>
                  )}
                </ul>
              </li> ) : (
            <></>
          )}
{techQCMViewSide ? (
              <li
                className={`${
                  testTechniqueQCM.includes(path) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  {lang.menu.testTechniqueMCQ}
                </Link>
                <ul>
                  {skillPer ? (
                    <li>
                      <Link
                        className={`${
                          path === "ListSkills" ? "mm-active" : ""
                        }`}
                        to="/ListSkills"
                        onClick={closeMenuToggle}
                      >
                        {lang.menu.listSkill}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}  
                   {skillDash ? (
                    <li>
                      <Link
                        className={`${
                          path === "skillDash" ? "mm-active" : ""
                        }`}
                        to="/skillsDashboard"
                        onClick={closeMenuToggle}
                      >
                        {lang.menu.skillDash}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {importTechPer ? (
                    <li>
                      <Link
                        className={`${
                          path === "libraryTechniqueMCQQuestions" ? "mm-active" : ""
                        }`}
                        to="/library-Technique-MCQ-Questions"
                        onClick={closeMenuToggle}
                      >
                        {lang.menu.libraryTechMCQQuestions}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
               
                  {listTechPer ? (
                    <li>
                      <Link
                        className={`${
                          path === "TestTechniqueList" ? "mm-active" : ""
                        }`}
                        to="/TechQCMList"
                        onClick={closeMenuToggle}
                      >
                        {lang.menu.testTechniqueMCQList}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {techResultPer ? (
                    <li>
                      <Link
                        className={`${path === "RadarTech" ? "mm-active" : ""}`}
                        to="/RadarTech"
                        onClick={closeMenuToggle}
                      >
                       {lang.menu.techResult}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>) : (
            <></>
          )}
            {techList ? ( <li>
                <Link
                  className={`${path === "TechList" ? "mm-active" : ""}`}
                  to="/TechList"
                  onClick={closeMenuToggle}
                >
                                          {lang.menu.testTechniqueList}

                </Link>
              </li>) : (
            <></>
          )}
            </ul>
          </li>
 ) : (
  <></>
)}
       {viewAssignment ? (
            <li
              className={`${
                assignmentTests.includes(path) ? "mm-active" : ""
              }`}
            >
              <Link className="has-arrow" to="#">
                <i className="flaticon-381-folder-8"></i>
                <span className="nav-text">{lang.menu.Assignment}</span>
              </Link>
              <ul>
                {assignmentList ? 
                (<li>
                  <Link
                    className={`${
                      path === "assignment-list" ? "mm-active" : ""
                    }`}
                    to="/assignment-list"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.AssignmentList}
                  </Link>
                </li>): (
                    <></>
                  )}
                {assignmentResult ? (<li>
                  <Link
                    className={`${
                      path === "assignment-result" ? "mm-active" : ""
                    }`}
                    to="/assignment-result"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.AssignmentResult}
                  </Link>
                </li>): (
                    <></>
                  )}
                
              </ul>
            </li>
          ) : (
            <></>
          )}
            <li className={`${job.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
              <PiBuildingOfficeBold size={60} color="black" />

              <span className="nav-text">{lang.menu.jobs}</span>
              </Link>
              <ul>
              {viewJobs ? ( <li>
                  <Link
                    className={`${path === "job-list" ? "mm-active" : ""}`}
                    to="/job-list"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.jobRole}
                  </Link>
                </li>   ) : (
            <></>
          )}
                {viewJobs ? (<li>
                  <Link
                    className={`${path === "job-profile" ? "mm-active" : ""}`}
                    to="/job-profile-list"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.JobProfile}
                  </Link>
                </li>) : (
            <></>
          )}
                {jobRoleList ? (
                    <li>
                  <Link
                    className={`${path === "job-role-list" ? "mm-active" : ""}`}
                    to="/job-role-list"
                    onClick={closeMenuToggle}
                  >
                   {lang.menu.listJob}
                  </Link>
                </li>  ) : (
            <></>
          )}
                {jobTest ? ( <li>
                  <Link
                    className={`${path === "Jobs-test" ? "mm-active" : ""}`}
                    to="/JobRoleListTest"
                    onClick={closeMenuToggle}
                  >
                       {lang.menu.preselecteOffre}
                  </Link>
                </li> ) : (
            <></>
          )}
             {sendPersonalityTest ? (
                  <li>
                    <Link
                      className={`${
                        path === "personality-test" ? "mm-active" : ""
                      }`}
                      to="/Send-Job-Email"
                      onClick={closeMenuToggle}
                    >
                      {lang.menu.sendPersonalityTest}
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </li>
       
          {/* 
              <li className={`${job.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <i className="flaticon-093-waving"></i>
                <span className="nav-text">{lang.menu.jobs}</span>

              </Link>
              <ul>
                {/*<li>
                  <Link
                    className={`${path === "post-job" ? "mm-active" : ""}`}
                    to="/post-job"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.applyJob}
                  </Link>
                </li> 
                <li>
                  <Link
                    className={`${path === "job-list" ? "mm-active" : ""}`}
                    to="/job-list"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.jobList}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "job-view" ? "mm-active" : ""}`}
                    to="/job-view"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.jobView}
                    
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "job-application" ? "mm-active" : ""}`}
                    to="/job-application"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.jobApp}
                  
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "apply-job" ? "mm-active" : ""}`}
                    to="/apply-job"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.applyJob}
                  </Link>
                </li>
                
                <li>
                  <Link
                    className={`${path === "new-job" ? "mm-active" : ""}`}
                    to="/new-job"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.newJob}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "user-profile" ? "mm-active" : ""}`}
                    to="/user-profile"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.userProfile}
                  </Link>
                </li>

              </ul>
            </li> */}

{candidateViewSide ? (
          <li className={`${candidates.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
            <FaRegHandshake size={60} color="black"  />
            <span className="nav-text">{lang.menu.candidates}</span>
            </Link>
            <ul>
         {/*      {candidatePerSend ? (
                <li>
                  <Link
                    className={`${path === "CvUpload" ? "mm-active" : ""}`}
                    to="/cv-upload"
                    onClick={closeMenuToggle}
                  >
                    {lang.menu.uploadCv}
                  </Link>
                </li>
              ) : (
                <></>
              )} */}
  {userResume ? (
                <li>
                  <Link
                    className={`${path === "UserResumes" ? "mm-active" : ""}`}
                    to="/UserResumes"
                    onClick={closeMenuToggle}
                  >
                   User Resumes
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {candidateExt ? (
                <li>
                  <Link
                    className={`${
                      path === "CVsDataExtraction" ? "mm-active" : ""
                    }`}
                    to="/Cv-Extraction-Forum"
                    onClick={closeMenuToggle}
                  >
                    {" "}
                    {lang.menu.cvDex}
                  </Link>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </li>  ) : (
                  <></>
                )}


 {/* 
   
         <li className={`${app.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-050-info"></i>
              <span className="nav-text">Apps</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "app-profile" ? "mm-active" : ""}`}
                  to="/app-profile"
                >
                  {lang.menu.profile}
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "post-details" ? "mm-active" : ""}`}
                  to="/post-details"
                >
                  {lang.menu.postDetails}
                </Link>
              </li>
              <li className={`${email.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="#">
                  Email
                </Link>
                <ul className={`${email.includes(path) ? "mm-show" : ""}`}>
                  <li>
                    <Link
                      className={`${
                        path === "email-compose" ? "mm-active" : ""
                      }`}
                      to="/email-compose"
                    >
                      Compose
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === "email-inbox" ? "mm-active" : ""}`}
                      to="/email-inbox"
                    >
                      Inbox
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === "email-read" ? "mm-active" : ""}`}
                      to="/email-read"
                    >
                      Read
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                  to="/app-calender"
                >
                  Calendar
                </Link>
              </li>
              <li className={`${shop.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="#">
                  Shop
                </Link>
                <ul className={`${shop.includes(path) ? "mm-show" : ""}`}>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-grid" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-grid"
                    >
                      Product Grid
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-list" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-list"
                    >
                      Product List
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-detail" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-detail"
                    >
                      Product Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-product-order" ? "mm-active" : ""
                      }`}
                      to="/ecom-product-order"
                    >
                      Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-checkout" ? "mm-active" : ""
                      }`}
                      to="/ecom-checkout"
                    >
                      Checkout
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-invoice" ? "mm-active" : ""
                      }`}
                      to="/ecom-invoice"
                    >
                      Invoice
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "ecom-customers" ? "mm-active" : ""
                      }`}
                      to="/ecom-customers"
                    >
                      Customers
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Charts</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "chart-rechart" ? "mm-active" : ""}`}
                  to="/chart-rechart"
                >
                  RechartJs
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-chartjs" ? "mm-active" : ""}`}
                  to="/chart-chartjs"
                >
                  Chartjs
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-chartist" ? "mm-active" : ""}`}
                  to="/chart-chartist"
                >
                  Chartist
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-sparkline" ? "mm-active" : ""}`}
                  to="/chart-sparkline"
                >
                  Sparkline
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "chart-apexchart" ? "mm-active" : ""}`}
                  to="/chart-apexchart"
                >
                  Apexchart
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <span className="nav-text">Bootstrap</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "ui-accordion" ? "mm-active" : ""}`}
                  to="/ui-accordion"
                >
                  Accordion
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-alert" ? "mm-active" : ""}`}
                  to="/ui-alert"
                >
                  Alert
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-badge" ? "mm-active" : ""}`}
                  to="/ui-badge"
                >
                  Badge
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-button" ? "mm-active" : ""}`}
                  to="/ui-button"
                >
                  Button
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-modal" ? "mm-active" : ""}`}
                  to="/ui-modal"
                >
                  Modal
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-button-group" ? "mm-active" : ""}`}
                  to="/ui-button-group"
                >
                  Button Group
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-list-group" ? "mm-active" : ""}`}
                  to="/ui-list-group"
                >
                  List Group
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-card" ? "mm-active" : ""}`}
                  to="/ui-card"
                >
                  Cards
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-carousel" ? "mm-active" : ""}`}
                  to="/ui-carousel"
                >
                  Carousel
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-dropdown" ? "mm-active" : ""}`}
                  to="/ui-dropdown"
                >
                  Dropdown
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-popover" ? "mm-active" : ""}`}
                  to="/ui-popover"
                >
                  Popover
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-progressbar" ? "mm-active" : ""}`}
                  to="/ui-progressbar"
                >
                  Progressbar
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-tab" ? "mm-active" : ""}`}
                  to="/ui-tab"
                >
                  Tab
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-typography" ? "mm-active" : ""}`}
                  to="/ui-typography"
                >
                  Typography
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-pagination" ? "mm-active" : ""}`}
                  to="/ui-pagination"
                >
                  Pagination
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "ui-grid" ? "mm-active" : ""}`}
                  to="/ui-grid"
                >
                  Grid
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <span className="nav-text">Plugins</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "uc-select2" ? "mm-active" : ""}`}
                  to="/uc-select2"
                >
                  Select 2
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-noui-slider" ? "mm-active" : ""}`}
                  to="/uc-noui-slider"
                >
                  Noui Slider
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-sweetalert" ? "mm-active" : ""}`}
                  to="/uc-sweetalert"
                >
                  Sweet Alert
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-toastr" ? "mm-active" : ""}`}
                  to="/uc-toastr"
                >
                  Toastr
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "map-jqvmap" ? "mm-active" : ""}`}
                  to="/map-jqvmap"
                >
                  Jqv Map
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "uc-lightgallery" ? "mm-active" : ""}`}
                  to="/uc-lightgallery"
                >
                  Light Gallery
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${redux.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-087-stop"></i>
              <span className="nav-text">Redux</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "todo" ? "mm-active" : ""}`}
                  to="/todo"
                >
                  Todo
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "redux-form" ? "mm-active" : ""}`}
                  to="/redux-form"
                >
                  Redux Form
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "redux-wizard" ? "mm-active" : ""}`}
                  to="/redux-wizard"
                >
                  Redux Wizard
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <Link to="widget-basic" className="ai-icon">
              <i className="flaticon-013-checkmark"></i>
              <span className="nav-text">Widget</span>
            </Link>
          </li>
          <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-072-printer"></i>
              <span className="nav-text forms">Forms</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "form-element" ? "mm-active" : ""}`}
                  to="/form-element"
                >
                  Form Elements
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "form-wizard" ? "mm-active" : ""}`}
                  to="/form-wizard"
                >
                  Wizard
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "form-editor-summernote" ? "mm-active" : ""
                  }`}
                  to="/form-editor-summernote"
                >
                  Summernote
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "form-pickers" ? "mm-active" : ""}`}
                  to="/form-pickers"
                >
                  Pickers
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "form-validation-jquery" ? "mm-active" : ""
                  }`}
                  to="/form-validation-jquery"
                >
                  Form Validate
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${table.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-043-menu"></i>
              <span className="nav-text">Table</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "table-filtering" ? "mm-active" : ""}`}
                  to="/table-filtering"
                >
                  Table Filtering
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "table-sorting" ? "mm-active" : ""}`}
                  to="/table-sorting"
                >
                  Table Sorting
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "table-bootstrap-basic" ? "mm-active" : ""
                  }`}
                  to="/table-bootstrap-basic"
                >
                  Bootstrap
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "table-datatable-basic" ? "mm-active" : ""
                  }`}
                  to="/table-datatable-basic"
                >
                  Datatable
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-022-copy"></i>
              <span className="nav-text">Pages</span>
            </Link>
            <ul>
              <li className={`${error.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="#">
                  Error
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${
                        path === "page-error-400" ? "mm-active" : ""
                      }`}
                      to="/page-error-400"
                    >
                      Error 400
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "page-error-403" ? "mm-active" : ""
                      }`}
                      to="/page-error-403"
                    >
                      Error 403
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "page-error-404" ? "mm-active" : ""
                      }`}
                      to="/page-error-404"
                    >
                      Error 404
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "page-error-500" ? "mm-active" : ""
                      }`}
                      to="/page-error-500"
                    >
                      Error 500
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "page-error-503" ? "mm-active" : ""
                      }`}
                      to="/page-error-503"
                    >
                      Error 503
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className={`${
                    path === "page-lock-screen" ? "mm-active" : ""
                  }`}
                  to="/page-lock-screen"
                >
                  Lock Screen
                </Link>
              </li>
            </ul>
          </li>  */}
        </MM>
      </PerfectScrollbar>
}
    </div>
  );
};

export default SideBar;
