import React, { useEffect, useState } from 'react';
import { Card, Button, Spinner, Col, Row, Container } from 'react-bootstrap';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import './UserResumes.css';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaEye } from 'react-icons/fa';
import swal from 'sweetalert';
import translations from "../AppsMenu/Candidate/mulitlingue";

const UserResumes = () => {
  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
  const userId = currentUser.localId;
  const [resumes, setResumes] = useState([]);
  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const fetchResumes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/Resume/findByuser/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setResumes(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des CV :", error);
      } finally {
        setLoading(false);
      }
    };
    fetchResumes();
  }, [userId, token]);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCompany(response.data[0]);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'entreprise :", error);
      }
    };
    fetchCompany();
  }, [userId, token]);

  const handleViewResume = (resumeId) => {
    history.push(`/Resume?resumeId=${resumeId}`);
  };

  const handleDeleteResume = async (resumeId) => {
    swal({
      title: "Êtes-vous sûr ?",
      text: "Voulez-vous vraiment supprimer ce CV ? Cette action est irréversible !",
      icon: "warning",
      buttons: ["Annuler", "Supprimer"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_APP_DOMAIN}/api/Resume/deleteResume/${resumeId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setResumes(resumes.filter((resume) => resume.id !== resumeId));
          swal("Le CV a été supprimé avec succès !", { icon: "success" });
        } catch (error) {
          console.error("Erreur lors de la suppression du CV :", error);
          swal("Une erreur est survenue !", { icon: "error" });
        }
      }
    });
  };

  return (
    <Container className="user-resumes">
      {/* Première section - Designs récents */}
      <div className="recent-designs">
        <h3>{lang.menu.designRec}</h3>
        <Link to="/Resume" className="btn btn-primary me-3 btn-sm">
          <i className="fas fa-plus me-2"></i>
          {lang.menu.creeCv}
        </Link>
        <br /><br /><br />
        {loading ? (
          <Spinner animation="border" />
        ) : resumes.length === 0 ? (
          <p>{lang.menu.aucunCv}</p>
        ) : (
          <div className="resumes-grid">
            {resumes.map((resume) => (
              <Card key={resume.id} className="resume-card">
                <Card.Header className="resume-header">
                  {resume.photo ? (
                    <img
                      src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/userResume/${userId}/${resume.photo}`}
                      alt={`${resume.firstName} ${resume.lastName}`}
                      className="resume-photo"
                    />
                  ) : (
                    <div className="resume-placeholder-photo"></div>
                  )}
                  <div className="resume-info">
                    <h5>{resume.firstName} {resume.lastName}</h5>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                  {lang.menu.creeLe} {new Date(resume.createdAt).toLocaleDateString()}
                  </Card.Text>
                  <br />
                  <Row>
                    <Col>
                      <Button variant="primary" onClick={() => handleViewResume(resume.id)}>
                      <i class="fas fa-pencil-alt"></i>
                      </Button>
                    </Col>
                    <Col>
                      <Button variant="danger light" className="ms-2" onClick={() => handleDeleteResume(resume.id)}>
                        <RiDeleteBin6Line />
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </div>
        )}
      </div>

     
    </Container>
  );
};

export default UserResumes;
