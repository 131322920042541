import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";
import translations from "../AppsMenu/Candidate/mulitlingue";

function TestPsychoTchniqueDetails() {
  const [testDetails, setTestDetails] = useState();
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const pathname = window.location.pathname;
  const idtest = pathname.split("/").pop();
  const history = useHistory();
  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };

  const getImageUrlFromServer = (imageName, categoryName, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/question1/images/${categoryName}/${id}/${imageName}`;
  };

  const getImageUrl = (imageRef, id, categorieName) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, categorieName, id);
      return imageUrl;
    }
    return null;
  };

  const renderContent = (content, id, categorieName) => {
    const imageUrl = getImageUrl(content, id, categorieName);

    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " />
        </div>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };

  useEffect(() => {
    const fetchTestDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestWithQuestionsById/${idtest}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setTestDetails(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTestDetails();
  }, []);

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h2 className="fs-20 font-w800  me-auto"></h2>
      
      </div>
      {testDetails && (
        <div>
          <h2>{testDetails.test_name}</h2>
          <p>{testDetails.description}</p>
          {testDetails.categories.map((category) => (
            <div key={category.category_id}>
              <h4>{category.category_name}</h4>
              {category.questions.map((question, index) => (
                <div key={question.id}>
                  <p>{`${index + 1}. ${question.question}`}</p>
                  {question.imageRef &&
                    renderContent(
                      question.imageRef,
                      question.id,
                      category.category_name
                    )}
                  <p>{lang.menu.choix} :</p>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    <li style={{ marginRight: "60px", fontSize: "14px" }}>
                      1.{" "}
                      {renderContent(
                        question.choix1,
                        question.id,
                        category.category_name
                      )}
                    </li>
                    <li style={{ marginRight: "60px", fontSize: "14px" }}>
                      2.{" "}
                      {renderContent(
                        question.choix2,
                        question.id,
                        category.category_name
                      )}
                    </li>
                    <li style={{ marginRight: "60px", fontSize: "14px" }}>
                      3.{" "}
                      {renderContent(
                        question.choix3,
                        question.id,
                        category.category_name
                      )}
                    </li>
                    {question.choix4 && (
                      <li style={{ marginRight: "60px", fontSize: "14px" }}>
                        4.{" "}
                        {renderContent(
                          question.choix4,
                          question.id,
                          category.category_name
                        )}
                      </li>
                    )}
                  </ul>
                  <p>
                    {lang.menu.BonneReponse} : {question.BonneReponse}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TestPsychoTchniqueDetails;
