import React, { useState, useEffect } from 'react';
import pic1 from "./../../../images/profile/small/pic1.jpg";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Badge ,Col,Row,Button,Modal} from "react-bootstrap";
import { BsGithub } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import axios from "axios";
import { ImFilePdf } from "react-icons/im";

const UserProfileCandidate = () => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const [profileData, setProfileData] = useState({
    name: '',
    phone: '',
    email: '',
    speciality: '',
    language: '',
    country: '',
    skills: '',
    experience: '',
    education: '',
    github: '',
    linkedin: '',
    certificates: '',
    prequalified: '',
    status: '',
    filePath:'',
  });
  const token=localStorage.getItem("token");

  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);

      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const pathname = window.location.pathname;
    const userId = pathname.split("/").pop();

    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/findOneCnadidate/${userId}`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }})
      .then(response => response.json())
      .then(data => {
        setProfileData({
          name: data.name,
          phone: data.phone,
          email: data.email,
          speciality: data.speciality,
          language: data.language,
          country: data.country,
          skills: data.skills,
          experience: data.experience,
          education: data.education,
          github: data.github,
          linkedin: data.linkedIn,
          certificates: data.certif,
          prequalified: data.score,
          status: data.status,
          filePath: data.filePath,
        });
        console.log("data : ",data)
      })

      .catch(error => console.error('Erreur lors de la récupération des données du candidat:', error));
  }, []); 


  const avatarClasses = [
    "empty-avatar-red",
    "empty-avatar-green",
    "empty-avatar-pimary",
    "empty-avatar-yellow",
    "empty-avatar-purple",
  ];
  const randomAvatarClass =
  avatarClasses[Math.floor(Math.random() * avatarClasses.length)];
  const formatSkills = (skillsString) => {
    try {
      const skillsData = JSON.parse(skillsString);
      console.log("skillsData : ", skillsData);
  
      return Object.entries(skillsData).map(([skill, { occurence, total_value}]) => (
        <Badge key={skill} variant='info' className="me-2">
          {skill}: {occurence}
        </Badge>
      ));
    } catch (error) {
      console.error('Error parsing skills data:', error);
      return null;
    }
  };
  const processData = (prData) => {
    const prLevels = [];

    let startPos = prData.indexOf("'");
    let endPos;

    while (startPos !== -1) {
      endPos = prData.indexOf("'", startPos + 1);
      if (endPos !== -1) {
        prLevels.push(prData.substring(startPos + 1, endPos));
        startPos = prData.indexOf("'", endPos + 1);
      } else {
        break;
      }
    }

    return prLevels;
  };
  const formatExperience = (expString) => {
    try {
      const expData = JSON.parse(expString);
      return Object.entries(expData)
        .map(([nbr, { exp }]) => `${nbr},${exp}`)
        .join("\n");
    } catch (error) {
      console.error("Error parsing exp data:", error);
      return "";
    }
  };
  const getStatusBadge = (status) => {
    switch (status) {
      case 'accepted':
        return <Badge variant="success light">{lang.menu.accepted}</Badge>;
      case 'pending':
        return <Badge variant="warning light">{lang.menu.pending}</Badge>;
      case 'refused':
        return <Badge variant="danger light">{lang.menu.refused}</Badge>;
      default:
        return <Badge variant="secondary light">{lang.menu.unknown}</Badge>;
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSaveChanges = () => {
    const pathname = window.location.pathname;
    const userId = pathname.split("/").pop();
    const updatedInfo = {
      name: profileData.name,
      phone: profileData.phone,
      email: profileData.email,
      speciality: profileData.speciality,
      language: profileData.language,
      country: profileData.country,
      skills: profileData.skills,
      experience: profileData.experience,
      education: profileData.education,
      github: profileData.github,
      linkedin: profileData.linkedin,
      certificates: profileData.certificates,
      score: profileData.prequalified,
      status: profileData.status,
      
    };

    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/updateInfoForUser`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, updatedInfo }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('User info updated successfully:', data);
    })
    .catch(error => {
      console.error('Error updating user info:', error);
    });
  };
  const getPdf = async (filePath) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/get_pdf`, {
        params: {
          filePath: filePath,
        },
        responseType: 'blob', 
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');  
      return 'Success';
    } catch (error) {
      console.error('Error fetching PDF:', error);
      setShowErrorModal(true);
      return 'Error fetching PDF';
    }
  };
  return (
    <div className="col-12">
   
            <div className="card full-width">
          <div className="card-header">
            <Col xl={9}>
            <Row>
            <Col xl={2}>
            <h4 className="card-title"> 
                <FontAwesomeIcon icon={faUser} className="me-2" />
              {lang.menu.Profile} {" "} : </h4>
            
             </Col>
             <Col>
             {getStatusBadge(profileData.status)}</Col>
            
     
            </Row>
            
        
            </Col>
            
            <Col xl={1}>
            <Button
   variant="primary-Sshade"
    onClick={() => getPdf(profileData.filePath)} 
  >
    <ImFilePdf size={30}/>

  </Button>
            </Col>
            <Col xl={1}>
            <a
  href={profileData.github ? 
        (profileData.github.startsWith("http") ? profileData.github : `https://${profileData.github}`) 
        : "#"}
  target={profileData.github ? "_blank" : undefined}
  rel="noopener noreferrer"
  className={profileData.github ? "" : "disabled"}
  onClick={(e) => !profileData.github && e.preventDefault()}
>
  <BsGithub size={30} />
</a>

                      </Col> 
                      <Col xl={1}>
                      <a
  href={profileData.linkedin ? 
        (profileData.linkedin.startsWith("http") ? profileData.linkedin : `https://${profileData.linkedin}`) 
        : "#"}
  target="_blank"
  rel="noopener noreferrer"
  className={profileData.linkedin ? "" : "disabled"}
>
                      <SiLinkedin size={30} color='blue'/></a>
                      </Col>
                      
          </div>
          <div className="card-body">
      <form>
  
        <Row>
          <Col xl={6}>
          <label>
          {lang.menu.name}:
          <input type="text" name="name" className="form-control" value={profileData.name} onChange={handleInputChange}  />
        </label>
          </Col>
          <Col xl={6}>
          <label>
          {lang.menu.email}:
          <input type="text" name="email" className="form-control" value={profileData.email} onChange={handleInputChange}  />
        </label>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
          <label>
          {lang.menu.PhoneNumber}:
          <input type="text" name="phone" className="form-control" value={profileData.phone} onChange={handleInputChange}  />
        </label></Col>
          <Col xl={6}>
          <label>
          {lang.menu.language}:
          <input type="text" name="language" className="form-control" value={processData(profileData.language).join(", ")} onChange={handleInputChange} />
        </label>

          </Col>
          </Row>

          <Row>
          <Col xl={6}>
          <label>
          {lang.menu.country}:
          <input type="text" name="country" className="form-control" value={profileData.country} onChange={handleInputChange}  />
        </label>
          </Col>
          <Col xl={6}>
          <label>
          {lang.menu.speciality}:
          <input type="text" name="speciality" className="form-control" value={profileData.speciality} onChange={handleInputChange}  />
        </label>
          </Col>
          </Row>
          <Row>
          <Col xl={6}>
          <label>
          LinkedIn:
          <input type="text" name="linkedIn" className="form-control" value={profileData.linkedin} onChange={handleInputChange}  />
        </label>
          </Col>
          <Col xl={6}>
          <label>
          Github:
          <input type="text" name="github" className="form-control" value={profileData.github} onChange={handleInputChange}  />
        </label>
          </Col>
          </Row>
          <Row>
         
          <label>
          {lang.menu.skills}:
          <br/>
          <div className="d-flex">{formatSkills(profileData.skills)}</div>
        </label>
       
        
          </Row>
          <Row>
          <label>
          {lang.menu.experience}:
          <input type="textarea" name="experience" className="form-control" value={profileData.experience} onChange={handleInputChange}  />
        </label>

          </Row>
          <Row>
         
          <label>
          {lang.menu.education}:
          <input type="text" name="education" className="form-control" value={processData(profileData.education).join(", ")} onChange={handleInputChange}  />
        </label>
          
        
          </Row>
          <Row>
          <label>
          {lang.menu.certificats}:
          <input type="textarea" name="certificates"  className="form-control" value={profileData.certificates} onChange={handleInputChange}  />
        </label>

          </Row>
          <Row>
          <Col xl={6}>
          <label>
          {lang.menu.Prequalified}:
          <input type="text" name="prequalified" className="form-control" value={profileData.prequalified} onChange={handleInputChange}  />
        </label>
          </Col>
          <Col xl={6}>
  <label>
    {lang.menu.status}:
    <select name="status" className="form-control" value={profileData.status} onChange={handleInputChange}>
      <option value="refused">Refused</option>
      <option value="pending">Pending</option>
      <option value="accepted">Accepted</option>
    </select>
  </label>
</Col>

          </Row>

        
      
  

     

        <button type="submit" className="btn btn-primary my-save-button" onClick={handleSaveChanges}>{lang.menu.SaveChanges}</button>
      </form>
      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
  <Modal.Header closeButton>
    <Modal.Title>Error</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    The CV has been deleted after one month of being saved in our system.
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={handleCloseErrorModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
      </div>
      </div>
    </div>
  );
};

export default UserProfileCandidate;