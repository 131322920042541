

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import { Dropdown, Row, Modal, Pagination,Button ,Form,Card,Col} from 'react-bootstrap';

import translations from"../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";
import { Link,useHistory } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import {

  FiClock
} from "react-icons/fi";
import { BsFiletypePdf } from "react-icons/bs";
import { IoIosStarHalf } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";
import * as XLSX from 'xlsx';
import { RiFileExcel2Line } from "react-icons/ri";
import { VscRunAll } from "react-icons/vsc";

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { MdOutlineEmail } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

const JobDetails = () => {
  const token=localStorage.getItem("token");

    const [content, setContent] = useState('');
    const [testPassed, setTestPassed] = useState(0);
    const [jobName, setJobName] = useState("");
    const [deadline, setDeadline] = useState("");
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [showFeedModal, setShowFeedModal] = useState(false);

    const [stars, setStars] = useState(0);
    const [isContentFilled, setIsContentFilled] = useState(false);
    const pathname = window.location.pathname;
    const jobId = pathname.split("/").pop();
    const handleCloseInviteModal = () => setShowInviteModal(false);
    const handleCloseRatingModal = () => setShowRatingModal(false);
    const handleCloseFeedBack = () => setShowFeedModal(false);

  const handleShowInviteModal = () => setShowInviteModal(true);
  const handleShowRatingModal = () => setShowRatingModal(true);
  const handleViewFeedBack = () => setShowFeedModal(true);
  const [ratings, setRatings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(3);
  const [searchTerm, setSearchTerm] = useState("");
  const [ratingsSum, setRatingsSum] = useState(0);


  const handleExcelDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(document.querySelector('.table'));
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'table.xlsx');
  };
    const history = useHistory();
    const getTestPerDetails = async (testId,candidateEmail) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/test-view/${candidateEmail}/${testId}/${jobId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
            const { scores, testName } = response.data;
            const onlyScores = scores.map(scoreObject => {
              switch (scoreObject.score) {
                  case 1:
                      return -2;
                  case 2:
                      return -1;
                  case 3:
                      return 0;
                  case 4:
                      return 1;
                  case 5:
                      return 2;
                  default:
                      return scoreObject.score; 
              }
          });
          let resultDescription
          if(testId===1){
            const response = await axios.post(
              `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/predict/16p`, 
              {
                  features: onlyScores
              },
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );          
           resultDescription =response.data.personality_type.code

          }
          else if(testId===2)
            {
            const response = await axios.post(
              `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/predict/ocean`, 
              {
                  features: onlyScores
              },
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );
 resultDescription = `
  Agreeableness: ${response.data.Agreeableness}
  Conscientiousness: ${response.data.Conscientiousness}
  Extraversion: ${response.data.Extraversion}
  Neuroticism: ${response.data.Neuroticism}
  Openness: ${response.data.Openness}
`
          }
        return resultDescription ;
      } catch (error) {
        console.error(`Error fetching test per name for ID ${testId}:`, error);
        return "Unknown Test";
      }
    };
    const handleCandidateClick = (candidate) => {
      const FirstName = users.find(user => user.uid === candidate.id_candidate)?.FirstName || 'N/A';
      const LastName = users.find(user => user.uid === candidate.id_candidate)?.LastName || 'N/A';
      const email = users.find(user => user.uid === candidate.id_candidate)?.email || 'N/A';
      const notePer = candidate.notePer[0] || 0; 
      const ratingJobSum = ratingsSum; 
      if (notePer != 0) {
          const extendedCandidate = { ...candidate, FirstName, LastName, email, jobName,ratingJobSum };
  
          localStorage.setItem("candidateDetails", JSON.stringify(extendedCandidate));
          history.push(`/candidat/${candidate.id_candidate}`);
      } else {
        const extendedCandidate = { ...candidate, FirstName, LastName, email, jobName ,ratingJobSum};
  
        localStorage.setItem("candidateDetails", JSON.stringify(extendedCandidate));
        history.push(`/candidat/${candidate.id_candidate}`);
      }
  };
 
  const [lang, setLang] = useState(() => {
      const storedLang = localStorage.getItem('selectedLang');
      if (storedLang) {
        const parsedLang = JSON.parse(storedLang);

        if (parsedLang.language === 'Français') {
          return translations.fr;
        }
      }
      return translations.en;
    });
    const [candidates, setCandidates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

  
  
    useEffect(() => {
      const fetchJobName = async () => {
        
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobTitleById/${jobId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
          let jobName = response.data.JobTitle;
          let dealine = response.data.CloseDate;
          setDeadline(dealine)
          setJobName(jobName)
      }
      const fetchData = async () => {
        try {
          const userResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
        setUsers(userResponse.data);
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobRoleDetailsForCandidates/${jobId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const updatedCandidates = await Promise.all(response.data.map(async (candidate) => {
            let emailUser = userResponse.data.find(user => user.uid === candidate.id_candidate)?.email;

            const updatedNote = await Promise.all(JSON.parse(candidate.note || '[]').map(async (test) => {
              const testName = await getTestPsychoName(test.idtest);
              return { ...test, testName };
            }));
            const updatedNoteTech = await Promise.all(JSON.parse(candidate.noteTech || '[]').map(async (test) => {
              const testName = await getTestTechName(test.idtest);
              return { ...test, testName };
            }));
            const updatedNoteCoding = await Promise.all(JSON.parse(candidate.noteCoding || '[]').map(async (test) => {
              const testName = await getTestCodingName(test.idtest);
              return { ...test, testName };
            }));

            const updatedNotePer = await Promise.all(JSON.parse(candidate.notePer || '[]').map(async (test) => {
              const testName = await getTestPerName(test.idtest);

              return { ...test, testName };
            }));
            let Personnality=null
            if (candidate.notePer?.length > 0) {
              const notePerArray = JSON.parse(candidate.notePer);
                if(parseInt(notePerArray[0].idtest)===2)
               Personnality = `
                                Agreeableness: ${notePerArray[0].notePer.Agreeableness}
                                Conscientiousness: ${notePerArray[0].notePer.Conscientiousness}
                                Extraversion: ${notePerArray[0].notePer.Extraversion}
                                Neuroticism: ${notePerArray[0].notePer.Neuroticism}
                                Openness: ${notePerArray[0].notePer.Openness}
                              `
            
            
                              else if (parseInt(notePerArray[0].idtest)===1){
                                Personnality = `${notePerArray[0].notePer}`
                              }
                            }
            return { ...candidate, note: updatedNote, noteTech: updatedNoteTech, noteCoding: updatedNoteCoding , notePer: updatedNotePer,Personnality   };
          }));
          setCandidates(updatedCandidates);
       
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
          
          const userResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
        setUsers(userResponse.data);
        }
      };
      fetchJobName()
      fetchData();
    }, []);
    

    const getTestPsychoName = async (testId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestById/${testId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const testName = response.data.test_name;
        setTestPassed(testPassed+1)
        return testName;
      } catch (error) {
        console.error(`Error fetching test psycho name for ID ${testId}:`, error);
        return "Unknown Test";
      }
    };
    const getCandAcceptStatus = (candAccept) => {
      switch (candAccept) {
        case 2:
          return (
            <div style={{fontSize: 'smaller'}}>
              <i className="fa fa-circle text-success me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i> {lang.menu.passUser}
            </div>
          );
        case 1:
          return (
            <div style={{fontSize: 'smaller'}}>
            <i className="fa fa-circle text-warning me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i><span > {lang.menu.pendingUser}</span>
          </div>
          
          );
        case 0:
          return (
            <div style={{fontSize: 'smaller'}}>
              <i className="fa fa-circle text-dark me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i> {lang.menu.appliedUser}
            </div>
          );
        default:
          return "";
      }
    };
    
    const getTestTechName = async (testId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getTestById/${testId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const testName = response.data.test_name;
        setTestPassed(testPassed+1)

        return testName;
      } catch (error) {
        console.error(`Error fetching test tech name for ID ${testId}:`, error);
        return "Unknown Test";
      }
    };
    const getTestCodingName = async (testId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getTopicById/${testId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const testName = response.data.name; 
        setTestPassed(testPassed + 1);
    
        return testName;
      } catch (error) {
        console.error(`Error fetching test Coding name for ID ${testId}:`, error);
        return "Unknown Test";
      }
    };
    
    const getTestPerName = async (testId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/getTestPerById/${testId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const testName = response.data[0].name;
        setTestPassed(testPassed + 1);
    
        return testName;
      } catch (error) {
        console.error(`Error fetching test per name for ID ${testId}:`, error);
        return "Unknown Test";
      }
    };
    function formatCalendarDate(date1) {
      const dateObj = new Date(date1);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-GB', options);
      return formattedDate;
    }
 
    /********************************************************** */
    const sendEmail = (e) => {
      e.preventDefault();
      try {
        const baseUrl = `${process.env.REACT_APP_APP_DOMAIN}/api`;
    
        // Step 1: Check if the user with the provided email exists
        const getUserByEmailUrl = `${baseUrl}/users/local/getUserByEmail/${email}`;
    
        axios
          .get(getUserByEmailUrl, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }})
          .then((userExistsResponse) => {
    
           
              if (userExistsResponse.status === 200) {
                    const createCandidateUrl = `${baseUrl}/jobrole/create-candidate/${email}/${jobId}`;
                    axios
                      .post(createCandidateUrl, {},
                        {
                          headers: {
                          Authorization: `Bearer ${token}`,
                          }})
                      .then((createCandidateResponse) => {
    
                        if (createCandidateResponse.status === 200) {
                          createCandidateAndSendEmail(email,jobId);
                          alert("Candidate created successfully!");
                          
                        } else {
                          alert("Failed to create candidate.");
                        }
                      })
                      .catch((error) => {
                        console.error("Error creating candidate:", error);
                        alert("Error creating candidate. Please try again later.");
                      });
                  }else {
                    console.error("Error checking selectedJob:");
                  }
                })

          .catch((error) => {
            console.error("Error checking user existence:", error.response || error);
    
            if (error.response && error.response.status === 500) {
              const createTemporaryUserUrl = `${baseUrl}/users/local/createTemporaryUser/${email}`;
              axios
                .post(createTemporaryUserUrl, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }})
                .then((createTemporaryUserResponse) => {
                  const passwordTempo=createTemporaryUserResponse.data.password;
                  if (createTemporaryUserResponse.status===201) {
                    
                            const createCandidateUrl = `${baseUrl}/jobrole/create-candidate/${email}/${jobId}`;
                            axios
                              .post(createCandidateUrl, {},
                                {
                                  headers: {
                                  Authorization: `Bearer ${token}`,
                                  }})
                              .then((createCandidateResponse) => {
            
                                if (createCandidateResponse.status === 200) {
                                  createTempoUserAndSendEmail(email,jobId,passwordTempo);
                                  alert("Candidate created successfully!");
                                  
                                } else {
                                  alert("Failed to create candidate.");
                                }
                              })
                              .catch((error) => {
                                console.error("Error creating candidate:", error);
                                alert("Error creating candidate. Please try again later.");
                              });
                          } else {
                            alert("Failed to create temporary user.");
                          }})
                          .catch((error) => {
                            console.error("Error fetching job skills:", error);
                            alert("Error fetching job skills. Please try again later.");
                          });
                      }
                    })
      } catch (error) {
        console.error("Error sending email:", error);
        alert("Error sending email. Please try again later.");
      }
    };
    const sendRating = (e) => {}
    
    const sendEmailToCandidate = async (email, jobId) => {
      try {
        const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);
        const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmail`, {
          email: email,
          jobId: jobId,
          lang:parsedLang.language

        }, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
    
        if (response.status === 200) {
        } else {
          console.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
    const createCandidateAndSendEmail = async (email, jobId) => {
 
      try {
        await sendEmailToCandidate(email, jobId);
    
        
      } catch (error) {
        console.error('Error creating candidate:', error);
        }
    };    
    //Tempo User Email
    const sendEmailToTempoUser = async (email, jobId,password) => {
      try {
        const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);
        const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmailTempo`, {
          email: email,
          jobId: jobId,
          password:password,
          lang:parsedLang.language
        });
    
        if (response.status === 200) {
          console.log('Email sent successfully');
        } else {
          console.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
    const createTempoUserAndSendEmail = async (email, jobId,password) => {
 
      try {
        await sendEmailToTempoUser(email, jobId,password);
    
      } catch (error) {
        console.error('Error creating candidate:', error);
      }
    };   
const [email, setEmail] = useState("");
const handleSubmitRating = async () => {
  try {
    if (!isContentFilled) return;
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
    const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/Rating/createrating`, {
      id_Job: jobId,
      id_user: userId,
      content: content,
      stars: stars
    }, 
    {
      headers: {
      Authorization: `Bearer ${token}`,
      }});  
 
    swal("Rating submitted successfully", {
      icon: "success",
    });
    //handleCloseRatingModal()
  }
  catch{
    swal('Failed to submit the form', {
      icon: "error",
    });
  }
}
const ratingJobChanged = (newRating) => {
  setStars(newRating)
};
const handleContentChange = (e) => {
  setContent(e.target.value);
  setIsContentFilled(!!e.target.value);  
};
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Rating/getAll`, {
        params: {
          id_Job: jobId
        },
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setRatings(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Rating/calculateWeightedSum/${jobId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}, {
   
      });
      setRatingsSum(response.data.weightedSum);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
const showTest = async (id)=>{
  try {
   

    const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/candidateJob/test/${id}/${jobId}`,{
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json"
      }
    })
    console.log("ressss :",response.data)

    window.location.reload();
  } catch (error) {
    console.log(error);
  }
}



///Pagination
const indexOfLastLog = currentPage * logsPerPage;
const indexOfFirstLog = indexOfLastLog - logsPerPage;
const currentRate = ratings
  .filter((log) =>
    Object.values(log)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  )
  .slice(indexOfFirstLog, indexOfLastLog);

const pageNumbers = [];
for (let i = 1; i <= Math.ceil(ratings.length / logsPerPage); i++) {
  pageNumbers.push(i);
}
const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
      <div>
             {jobName ? (
            <>         
        
        <Card>
        <Card.Body>
            <Row>
  <Col xl={1}>
        <button
            className="back-button"
            onClick={() => history.goBack()}
          style={{height:"100%",width:"20%"}}
          >
            <span style={{ marginRight: "5px" }}>
            <IoChevronBack />

            </span>
          </button></Col>
        <Col xl={4}><strong style={{ fontSize: '180%' }}>{jobName}</strong>
        <br/>
        <span>
        <FiClock size={17}  />  {formatCalendarDate(deadline)}
        </span>
        </Col>
      
        <Col xl={1} className="ms-auto">
      
  <Dropdown>
                  <Dropdown.Toggle
                    className="nav-link i-false c-pointer"
                    as="a"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  
                  ><i  className="flaticon-032-ellipsis"></i></Dropdown.Toggle>
                  
                   <Dropdown.Menu className="profile-dropdown">
                   <DropdownItem><Button
                            style={{backgroundColor:"transparent",border:"0",color:"black"}}
                            onClick={() => handleShowInviteModal()}
                          ><MdOutlineEmail/>{"  "}Invite
                          </Button>
                          </DropdownItem>
                   <DropdownItem>
                   <Button
                            style={{backgroundColor:"transparent",border:"0",color:"black"}}
                            onClick={() => handleExcelDownload()}
                          ><RiFileExcel2Line />{"  "}Download results Excel</Button> 
</DropdownItem>
                      <DropdownItem>
                      <Button
                            style={{backgroundColor:"transparent",border:"0",color:"black"}}
                            onClick={() => handleShowRatingModal()}>
                                                
                      <IoIosStarHalf />  {"  "}Leave test feedback</Button>
                      </DropdownItem>
                      <DropdownItem>
                        <Button
                            style={{backgroundColor:"transparent",border:"0",color:"black"}}
                            onClick={() => handleViewFeedBack()}
                          ><VscFeedback />{"  "}View feedback</Button> 
                      </DropdownItem>

                    </Dropdown.Menu></Dropdown>
  </Col>
      </Row>
            
         
            
          </Card.Body>
        </Card>
        
          </>
            ) : (<></>)}

        <div>
  
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
             
              <thead style={{ backgroundColor: "#8cd6c8"}}>
                <tr>
                  <th>{lang.menu.candidates} </th>
                  <th>{lang.menu.lastActivity}</th>
                  <th>{lang.menu.hiringStage}</th>
                  <th>{lang.menu.perTest}</th>
                  <th>{lang.menu.testPsychoTechnique}</th>
                  <th>{lang.menu.testTechnique}</th>
                 
                  <th>{lang.menu.rating}</th>
                  <th>resend test</th>
                </tr>
              </thead>
              <tbody >
              {candidates.map(candidate => {
    let average = 0;


    if (candidate.stars) {
      const starsArray = JSON.parse(candidate.stars);
      const sum = starsArray.reduce((acc, curr) => acc + curr, 0);
      average = sum / starsArray.length;
  

    }

  return (
                  <tr key={candidate.id_candidate} >
                    <td>
  {users.find(user => user.uid === candidate.id_candidate)?.FirstName ? (
    <button onClick={() => handleCandidateClick(candidate)}   style={{ backgroundColor: 'transparent', border: '0'  }}
    >
     <strong> {users.find(user => user.uid === candidate.id_candidate)?.FirstName} {users.find(user => user.uid === candidate.id_candidate)?.LastName || 'N/A'}</strong>
    </button>
  ) : (
    <button onClick={() => handleCandidateClick(candidate)} style={{ backgroundColor: 'transparent', border: '0'  }}>
      <strong>{users.find(user => user.uid === candidate.id_candidate)?.email || 'N/A'}</strong>
      </button>
  )}
</td>

                    <td>
                    {formatCalendarDate(candidate.last_activity)}</td>
                    <td>{getCandAcceptStatus(candidate.candAccept)}</td>


                    <td>
  {candidate.notePer ? (
    candidate.notePer.map((test) => (
      <div key={test.idtest}>
       
       {/*  <div>
          {Object.entries(test.notePer).map(([trait, value]) => (
            <div key={trait}>
              {trait}: {value}
            </div>
          ))}
        </div> */}
        <div>
          <p>{candidate.Personnality}</p>  
          
 
          
        </div>
      </div>
    ))
  ) : (
    <></>
  )}
</td>


<td className='text-center'>

 {candidate.note ? (
    candidate.note.map((techPsycho) => {
      const codingTest = techPsycho.note;
      const totalTechNote = (codingTest*100)/10;


      return (
        <div key={techPsycho.idtest}>
          <p>{totalTechNote.toFixed(2)} %</p>
        </div>
      );
    })
  ) : (
    <></>  )}



</td>

<td className='text-center'>
  {candidate.noteTech.length>0 || candidate.noteCoding.length>0 ? (
    candidate.noteTech.length>0 && candidate.noteCoding.length>0 ? (
      candidate.noteTech.map((techTest, index) => {
        const codingTest = candidate.noteCoding[index];
        const codingNote = parseFloat(codingTest.noteCoding);
        const totalTechNote = ((techTest.noteTech + codingNote) / 2) * 100 / 10;


        return (
          <div key={techTest.idtest}>
            <p>{totalTechNote.toFixed(2)} %</p>
          </div>
        );
      })
    ) : (
      candidate.noteTech.length>0 ? (
        candidate.noteTech.map((techTest) => {
          const totalTechNote = (parseFloat(techTest.noteTech) * 100 / 10); 
          return (
            <div key={techTest.idtest}>
              <p>{totalTechNote.toFixed(2)} %</p>
            </div>
          );
        })
      ) : (
        candidate.noteCoding.map((codingTest) => {
          const codingNote = parseFloat(codingTest.noteCoding);
          const totalTechNote = (codingNote * 100 / 10);
          return (
            <div key={codingTest.idtest}>
              <p>{totalTechNote.toFixed(2)} %</p>
            </div>
          );
        })
      )
    )
  ) : (
    <></>
  )}
</td>

<td >
<ReactStars
    count={5}
    value={average}
    size={24}
    activeColor="#74188D"
    edit={false}
  /></td>
  <td>

  <Button variant='success light'  onClick={()=>{showTest(candidate.id_candidate)}}> <VscRunAll size={16} /> </Button>

  </td>


                  </tr>
  )
  })}
              </tbody>
     </table></div>
          )}
        </div>
        <Modal show={showInviteModal} onHide={handleCloseInviteModal}>
  <Modal.Header closeButton>
    <Modal.Title>Invite candidates</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <form onSubmit={sendEmail}>
  <Form.Label><strong>{lang.menu.email} <span style={{ color: 'red' }}>*</span>  :</strong></Form.Label>
                  <div className="input-group ">
                    <input
                      required
                      type="text"
                      className={`form-control `}
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <br/>
                  <Row>
                  <Col xl={8}></Col>
                  <Col>
    <Button
      type="submit"
      className="btn btn-primary btn sweet-confirm"
    >
      Send 
    </Button></Col>
  </Row>

      </form>           
  </Modal.Body>
 
</Modal>


{/* Rating */}
<Modal show={showRatingModal} onHide={handleCloseRatingModal}>
  <Modal.Header closeButton>
    <Modal.Title>Leave test feedback</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <form onSubmit={sendRating}>
    <Row>
    <Form.Label><strong>{lang.menu.urrating} <span style={{ color: 'red' }}>*</span>  :</strong></Form.Label>
      <Col xl={8}>
      <div className="input-group ">
      <textarea id="content" className="form-control" 
                      placeholder="Add your private notes here (auto-saved) ...."
                      onChange={handleContentChange}
                      required
                    />
                  </div>
  </Col>
                  <Col xl={4}>
                    <ReactStars
    count={5}
    onChange={ratingJobChanged}
    size={24}
    activeColor="#74188D" 
  />
                  </Col>
                  </Row>
                  <br/>
                  <Row>  
                  <Col xl={8}></Col>
                  <Col>
    <Button
      type="submit"
      className="btn btn-primary btn sweet-confirm"
      onClick={handleSubmitRating}
      disabled={!isContentFilled}
    >
      {lang.menu.submit}
    </Button></Col>
  </Row>

      </form>            
  </Modal.Body>
 
</Modal>

{/* view Feedback*/}
<Modal show={showFeedModal} onHide={handleCloseFeedBack} dialogClassName="custom-modal-dialog">
  <Modal.Header closeButton>
    <Modal.Title>View feedback</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="table-responsive">
      <table className="table display mb-3 dataTablesCard job-table table-responsive-md card-table dataTable no-footer">
        <thead>
          <tr>
            <th>Recruter</th>
            <th>Content</th>
            <th>Stars</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {currentRate.map(rating => (
            <tr key={rating.id}>
              <td>{users.find(user => user.uid === rating.id_user)?.FirstName} {users.find(user => user.uid === rating.id_user)?.LastName || 'N/A'}</td>
              <td>{rating.content}</td>
              <td><ReactStars
    count={5}
    value={rating.stars}
    size={24}
    activeColor="#74188D"
    edit={false}
  /></td>
              <td>{formatCalendarDate(rating.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Pagination className="justify-content-end">
          <Pagination.Prev
            onClick={() => setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))}
          />
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => paginate(number)}
            >
              {number}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() =>
              setCurrentPage((prevPage) =>
                prevPage < Math.ceil(ratings.length / logsPerPage) ? prevPage + 1 : prevPage
              )
            }
          />
        </Pagination>
  </Modal.Body>
</Modal>

<ToastContainer position="top-right" autoClose={3000} hideProgressBar />

      </div>
    );
  };
  
  export default JobDetails;