import React, { useState } from 'react';
import Select from 'react-select';

const SkillsSelector = ({ skills, selectedSkills, setSkills }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (selectedOptions) => {
    const selectedSkills = selectedOptions.map(option => option.value);
    setSkills(selectedOptions);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      setSkills((prev) => [...prev, { value: inputValue, label: inputValue }]);
      setInputValue(''); 
    }
  };

  return (
    <div>
      <Select
        isMulti
        options={skills.map(skill => ({ value: skill.value, label: skill.label }))}
        value={selectedSkills}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder="Select skills..."
        styles={{
          control: (provided) => ({
            ...provided,
            marginBottom: '10px',
          }),
        }}
      />
    </div>
  );
};

export default SkillsSelector;
