import React, { useEffect, useState } from 'react';
import axios from 'axios';
import translations from "../AppsMenu/Candidate/mulitlingue";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Spinner,
    Badge,
} from "react-bootstrap";
import { FaEye, FaFilePdf } from 'react-icons/fa';
import jsPDF from 'jspdf';
import logo from "../../../images/logo-full.png";
import signature from "../../../images/signatureHiba.png";

const SubscriptionAndInvoices = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token");
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [company, setCompany] = useState("");

    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem("selectedLang");
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);
          if (parsedLang.language === "Français") {
            return translations.fr;
          }
        }
        return translations.en;
    });

   
    useEffect(async() => {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userId = currentUser.localId;
  
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedCompany = response.data[0];
  
        setCompany(fetchedCompany);
    },[])
    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;

        const fetchInvoiceData = async () => {
            try {
                const response2 = await axios.get(
                    `${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log("data2:",response2.data)
                setSubscriptionTypes(response2.data);

                const response = await axios.get(
                    `${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getUserSubscriptionsWithInvoices/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log("data:",response.data)
                setSubscriptions(response.data);
                
            } catch (error) {
                console.error('Failed to fetch invoice data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceData();
    }, []);
    const getSubscriptionTypeName = (typeId) => {
        const type = subscriptionTypes.find((subType) => subType.id === typeId);
        return type ? type.Type : lang.menu.unknownType;
    };
    
    const getStatusBadge = (status) => {
        switch (status) {
            case "paid":
                return <Badge variant="success">{lang.menu.paid}</Badge>;
            case "unpaid":
                return <Badge variant="warning">{lang.menu.unpaid}</Badge>;
            case "overdue":
                return <Badge variant="danger">{lang.menu.overdue}</Badge>;
            default:
                return <Badge variant="secondary">{status}</Badge>;
        }
    };


    const formatCurrency = (currency) => {
        return currency.toUpperCase(); 
    };
    const groupBySubscriptionType = (subscriptions) => {
        const groupedSubscriptions = {};
        console.log("subsc :",subscriptions)
    
        subscriptions.forEach((sub) => {
            const typeId = sub.subscription.Type;
            console.log("typeId :",typeId)

            if (!groupedSubscriptions[typeId]) {
                groupedSubscriptions[typeId] = {
                    typeId: typeId,
                    typeName: getSubscriptionTypeName(typeId),
                    subscriptions: [],
                };
            }
            groupedSubscriptions[typeId].subscriptions.push(sub);
        });
    
        return Object.values(groupedSubscriptions);
    };

    const generateInvoiceContractPDF = (subscription, invoice) => {
        const doc = new jsPDF();
    
        // Hauteur totale de la page
        const pageHeight = doc.internal.pageSize.height;
    
        // Logo 
        const logoUrl = logo;
        const logoWidth = 30;
        const logoHeight = 20;
        const logoX = 20;
        const logoY = 10;
        doc.addImage(logoUrl, 'PNG', logoX, logoY, logoWidth, logoHeight);
    
        // Déterminez la langue et ajustez le contenu
        const isFrench = lang === translations.fr;
    
        // Titre
        const titleX = logoX + logoWidth + 30;
        const titleY = logoY + logoHeight / 2 + 2;
        doc.setFont("helvetica", "bold");
        doc.setFontSize(22);
        doc.setTextColor(116, 24, 141);
        doc.text(isFrench ? "Contrat d'abonnement" : "Subscription contract", titleX, titleY);
    
        // Séparation
        doc.setLineWidth(0.5);
        doc.setDrawColor(100, 100, 100);
        doc.line(20, logoY + logoHeight + 5, 190, logoY + logoHeight + 5);
    
        // Informations de la facture
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(40, 40, 90);
        doc.text(isFrench ? "Détails du paiement" : "Payment details", 20, 50);
    
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0);
        doc.text(`${isFrench ? "Nom du client" : "Customer Name"} : ${invoice.customer_name}`, 20, 70);
        doc.text(`${isFrench ? "Type d'abonnement" : "Subscription Type"} : ${getSubscriptionTypeName(subscription.subscription.Type)}`, 20, 80);
        doc.text(`${isFrench ? "ID de paiement" : "Payment ID"} : ${invoice.invoice_id}`, 20, 90);
        doc.text(
            `${isFrench ? "Montant dû" : "Amount Due"} : ${invoice.amount_due} ${formatCurrency(invoice.currency)}`,
            20,
            100
        );
        doc.text(
            `${isFrench ? "Statut du paiement" : "Payment status"} : ${invoice.status === "paid" ? (isFrench ? "Payée" : "Paid") : (isFrench ? "Non payée" : "Unpaid")}`,
            20,
            110
        );
    
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0);
        doc.text(`${isFrench ? "Date du paiement" : "Payment Date"} : ${new Date(invoice.period_start).toLocaleDateString()}`, 20, 120);
        doc.text(`${isFrench ? "Période de paiement jusqu'au" : "Payment Period Until"} : ${new Date(invoice.period_end).toLocaleDateString()}`, 20, 130);
    
        doc.setFontSize(11);
        doc.setFont("helvetica", "italic");
        doc.setTextColor(60, 60, 60);
        doc.text(
            isFrench
                ? "Ce document représente un contrat pour les services fournis sous l'abonnement indiqué et précise le montant dû pour la période spécifiée."
                : "This document represents a contract for the services provided under the specified subscription and outlines the amount due for the specified period.",
            20,
            150,
            { maxWidth: 170 }
        );
    
        // Signatures
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(isFrench ? "Signature du client :" : "Customer Signature:", 40, 170);
        doc.line(40, 180, 80, 180);
    
        doc.text(isFrench ? "Signature de l'entreprise :" : "Company Signature:", 120, 170);
        doc.line(120, 180, 170, 180);
    
        // Image de signature
        const signatureWidth = 40;
        const signatureHeight = 15;
        const signatureX = 120;
        const signatureY = 175;
        doc.addImage(signature, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);
    
        // Pied de page
        const footerY = pageHeight - 30; 
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(100, 100, 100);
        doc.text(
            isFrench
                ? "Pour toute question concernant cette paiement, veuillez nous contacter à : contact@hirecue.com"
                : "For any questions regarding this Payment, please contact us at: contact@hirecue.com",
            20,
            footerY - 10,
            { maxWidth: 170 }
        );
    
        doc.line(20, footerY - 5, 190, footerY - 5);
    
        doc.setFontSize(9);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(150, 150, 150);
        doc.text(
            isFrench
                ? "Hirecue - 14 D, Rue Emir Abdelkader, Tunis 1002, Tunisie - Numéro de téléphone :0021646516905 /0033689393094 "
                : "Hirecue - 14 D, Rue Emir Abdelkader, Tunis 1002, Tunisia - Phone number:0021646516905 /0033689393094 ",
            20,
            footerY,
            { maxWidth: 170 }
        );
    
        doc.save(`${isFrench ? "Contrat_Paiement" : "Payment_Contract"}_${invoice.invoice_id}.pdf`);
    };
    
    
    return (
        <div className="container">
            <Row className="align-items-center mb-4">
                <Col>
                    <h2>{lang.menu.subscriptions}</h2>
                </Col>
             
            </Row>

            {loading ? (
    <div className="text-center">
        <Spinner animation="border" variant="primary" />
    </div>
) : subscriptions.length === 0 ? (
    <div className="text-center">
        <h5>{lang.menu.noSubscriptions}</h5>
    </div>
) : (
    groupBySubscriptionType(subscriptions).map((group, groupIndex) => (
        <Card key={groupIndex} className="mb-4 shadow-sm">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <h5>Type: {group.typeName}</h5>
            </Card.Header>

            <Card.Body>
    <Table striped bordered hover responsive>
        <thead>
            <tr>
                <th>{lang.menu.invoiceId}</th>
                <th>{lang.menu.customerName}</th>
                <th>{lang.menu.amountDue}</th>
                <th>{lang.menu.status}</th>
                <th>{lang.menu.periodEnd}</th>
                <th>{lang.menu.actions}</th>
            </tr>
        </thead>
        <tbody>
            {group.subscriptions.map((sub, subIndex) =>
                sub.invoices.map((invoice, invoiceIndex) => (
                    <tr key={`${subIndex}-${invoiceIndex}`}>
                        <td>{invoice.invoice_id}</td>
                        <td>{invoice.customer_name}</td>
                        <td>
                            {invoice.amount_due} {formatCurrency(invoice.currency)}
                        </td>
                        <td>{getStatusBadge(invoice.status)}</td>
                        <td>{new Date(invoice.period_end).toLocaleDateString()}</td>
                        <td className="text-center">
                           
                            <Button
                                variant="outline-dark"
                                size="sm"
                                onClick={() => generateInvoiceContractPDF(sub, invoice)}
                            >
                                <FaFilePdf /> {lang.menu.downloadPDF}
                            </Button>
                        </td>
                    </tr>
                ))
            )}
        </tbody>
    </Table>
</Card.Body>

        </Card>
    ))
)}

        </div>
    );
};

export default SubscriptionAndInvoices;
