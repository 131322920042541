import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button,Col,Row } from "react-bootstrap";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import translations from "../AppsMenu/Candidate/mulitlingue";
import Select from "react-select";
import data from "../data";
import swal from "sweetalert";
import Swal from "sweetalert2";

function UpdateJobProfile() {
  const token=localStorage.getItem("token");

  const { id } = useParams();
  const history = useHistory();
  const [persoTest,setPersoTest] = useState([]);
  const [technicalTest,setTechnicalTest] = useState([]);
  const [psychoTest,setPsychoTest] = useState([]);
  const [psycho,setPsycho] = useState("");
  const [perso,setPerso ]= useState("");
  const [selectedTech, setSelectedTech] = useState(technicalTest);
  const [allTests,setAllTests] = useState([]);
  const [testsTechDetails,setTestsTechDetails] = useState([]);
  const [testsPsychoDetails,setTestsPsychoDetails] = useState([]);
  const [testsPersoDetails,setTestsPersoDetails] = useState([]);

  useEffect(() => {
    
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getAllTests`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        
        // Filter tests by type
        const technicalTests = res.data.filter(
          (test) => test.test_source === "TechnicalTest"
        );
        setAllTests(res.data)
        setTechnicalTest(technicalTests)


        const personalityTests = res.data.filter(
          (test) => test.test_source === "PersonalityTest"
        );
        setPersoTest(personalityTests)

        const psychoTests = res.data.filter(
          (test) => test.test_source === "Psychoest"
        );
        setPsychoTest(psychoTests)

        // Map them to options
        const updatedTestsOptions = [
          {
            label: "-----------Technical Test-------------",
            options: technicalTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Technical Test",
              test_source: test.test_source,
            })),
          },
          {
            label: "-----------Personality Test-----------",
            options: personalityTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Personality Test",
              test_source: test.test_source,
            })),
          },
          {
            label: "-----------Psychotechnical Test-------",
            options: psychoTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Technical Test",
              test_source: test.test_source,
            })),
          },
        ];

        setTestsOptions(updatedTestsOptions);
    
      })
      .catch((err) => console.log(err));
  }, []);

  const [height, setHeight] = useState("200px");
  function handleChangeStyle(e) {
    e.preventDefault();
    setHeight("300px");
    let newHeight = e.target.scrollHeight + "px";
    setHeight(newHeight);
  }
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  //Style Select
  const selectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "15px",
      boxShadow: "none",
      border: "none",
      "&:hover": {
        border: "none",
      },
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#333",
      "&:hover": {},
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "15px",
      marginTop: "-1px",
      boxShadow: "none",
      border: "none",
      "&:before": {
        display: "none",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#555",
    }),
  };
  const [jobProfileData, setJobProfileData] = useState({
    title: "",
    description: "",
    requiredQualifications: [],
    languages: [],
    Tests: [],
    testsUpdate: [],
    testsToDisplay: [],
    personality: "",
    technical: "",
    psycho: "",
    assignment: ""
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/update-coefficient/getprofilebyId/` +
          id, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
      )
      .then((res) => {
        const parsedRequiredQualifications = res.data[0].requiredQualifications
          .replace(/[\[\]']+/g, "") 
          .split(/\), /) 
          .map((qualification, index) => {
            const [skill, count] = qualification.split(", ");
            const cleanedSkill = skill.replace(/[\(\)]/g, "").trim();
            const cleanCoefficient = count.replace(/[\(\)]/g, "").trim();

            return {
              skill: cleanedSkill,
              count: cleanCoefficient,
            };
          });
        const testsUpdateData = JSON.parse(res.data[0].testsUpdate);
        const filteredTestsPsychoUp = testsUpdateData.filter(test => test.test_source === "Psychoest");

        const personalityTests = testsUpdateData
          .filter((test) => test.test_source === "PersonalityTest")
          .map((test) => test.value);
          setPersoTest(personalityTests)

        const technicalTests = testsUpdateData
          .filter((test) => test.test_source === "TechnicalTest")
          .map((test) => test.value);
          setTechnicalTest(technicalTests)

        const psychoTests = testsUpdateData
          .filter((test) => test.test_source === "Psychoest")
          .map((test) => test.value);
          setPsychoTest(psychoTests)

        const technicalTestIds = Object.values(technicalTests).map(id => parseInt(id, 10));
        const technicalTestList=allTests.filter(
          (test) => test.test_source === "TechnicalTest"
        );

        const testDetailsTech = technicalTestList.filter(test => technicalTestIds.includes(test.Id));
        setTestsTechDetails(testDetailsTech)

        const psychotechnicalTestIds = Object.values(psychoTests).map(id => parseInt(id, 10));
        const psychotechnicalTestList=allTests.filter(
          (test) => test.test_source === "Psychoest"
        );
        const testDetailsPsycho = psychotechnicalTestList.filter(test => psychotechnicalTestIds.includes(test.Id));
        setTestsPsychoDetails(testDetailsPsycho)

        const persoTestIds = Object.values(personalityTests).map(id => parseInt(id, 10));
        const persoTestList=allTests.filter(
          (test) => test.test_source === "PersonalityTest"
        );

        const testDetailsPerso= persoTestList.filter(test => persoTestIds.includes(test.Id));
        setTestsPersoDetails(testDetailsPerso)






        setJobProfileData({
          ...jobProfileData,
          title: res.data[0].title,
          description: res.data[0].description,
          requiredQualifications: parsedRequiredQualifications,
          languages: JSON.parse(res.data[0].languages),
          testsUpdate: JSON.parse(res.data[0].testsUpdate),
          personality: personalityTests,
         // technical:technicalTests,
          psycho: psychoTests,
          
        });
      })
      .catch((err) => console.log(err));
  }, [id,allTests]);
  useEffect(() => {
  }, [jobProfileData,testsTechDetails])
  const handleTestsChange = (selectedTests) => {
    let updatedPersonality = 0;
    let updatedTechnical = 0;
    let updatedPsycho = 0;

    selectedTests.forEach((test) => {
      if (test.test_source === "PersonalityTest") {
        updatedPersonality =
          updatedPersonality === 0
            ? test.value
            : updatedPersonality + "," + test.value;
      } else if (test.test_source === "TechnicalTest") {
        updatedTechnical =
          updatedTechnical === 0
            ? test.value
            : updatedTechnical + "," + test.value;
      } else if (test.test_source === "Psychoest") {
        updatedPsycho =
          updatedPsycho === 0 ? test.value : updatedPsycho + "," + test.value;
      }
    });

    setJobProfileData({
      ...jobProfileData,
      testsUpdate: selectedTests,
      personality: updatedPersonality,
      technical: updatedTechnical,
      psycho: updatedPsycho,
    });
  };
  const [TestsOptions, setTestsOptions] = useState([]);



  const updateAssignment = async ()=>{
    try {
      
      const idjob = id;
      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/updateAssignmentByjobprofile/${idjob}/${jobProfileData.assignment.value}`,{}, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      

    } catch (error) {
      console.error(error);
    }
  }
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    await updateAssignment();
    localStorage.setItem("updateProfile",1);

    await axios
      .put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/update-job-profile/updatebyId/` +
          id,
        jobProfileData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((res) => {
        const newProfileId = res.data.id;
        swal(lang.menu.updateMsgSucc, {
          icon: "success",
        });
        history.push(`/update-coefficient/${newProfileId}`);
      })
      .catch((err) => {
        swal(
          lang.menu.deleteTitleWrong,
          lang.menu.deleteMsgProfileJob,
          "error"
        );
        console.log(err);
      });
  };

  const handleTechChange = (selectedOption) => {
    setSelectedTech(selectedOption);
  
    const selectedValues = selectedOption.map(option => option.value);
    const filteredTestsUp = jobProfileData.testsUpdate.filter(test => test.test_source !== "TechnicalTest");
  
    const testTech = selectedOption.map(option => {
      const filteredTest = allTests.find(test => (
        test.Id === option.value &&
        test.test_source === "TechnicalTest" &&
        test.name === option.label
      ));
  
      return filteredTest ? {
        value: filteredTest.Id,
        label: filteredTest.name,
        type: "Technical Test",
        test_source: filteredTest.test_source
      } : null;
    }).filter(Boolean);
  
    const combinedTests = [...filteredTestsUp, ...testTech];
    const uniqueTestsArray = Array.from(new Set(combinedTests.map(JSON.stringify))).map(JSON.parse);
    const technicalTestList=allTests.filter(
      (test) => test.test_source === "TechnicalTest"
    );
    const testDetailsTech = technicalTestList.filter(test => selectedValues.includes(test.Id));
    setTestsTechDetails(testDetailsTech)
    setJobProfileData(prevData => ({
      ...prevData,
      testsUpdate: uniqueTestsArray,
      technical: selectedValues
    }));
  };


  
  
  
  
  
  
  
  
  
  
  
  





  const handleDropdownSelect12 = (selectedOption) => {  
    setPsycho(selectedOption);

    const testUp = jobProfileData.testsUpdate;
    const filteredTestsUp = testUp.filter(test => test.test_source !== "Psychoest");
    const filteredTestUpDetails = allTests.filter(test => test.test_source === "Psychoest" && test.Id===selectedOption.value);
    const filteredTest = allTests.find(test => (
      test.Id === selectedOption.value &&
      test.test_source === "Psychoest" &&
      test.name === selectedOption.label
    ));
  
    if (filteredTest) {
      const testSelect = {
        value: filteredTest.Id,
        label: filteredTest.name,
        type: "Psychoest",
        test_source: filteredTest.test_source
      };
  
      const combinedTests = [...filteredTestsUp, testSelect];
      const uniqueTests = new Set(combinedTests.map(JSON.stringify));
      const uniqueTestsArray = Array.from(uniqueTests).map(JSON.parse);
  
      setJobProfileData(prevData => ({
        ...prevData,
        testsUpdate: uniqueTestsArray,
        psycho: selectedOption.value
      }));
    }
    setTestsPsychoDetails(filteredTestUpDetails)
  
  };
  


//***Perso */
const handleDropdownSelect22 = (selectedOption) => {  
  setPerso(selectedOption);

  const testUp = jobProfileData.testsUpdate;
  const filteredTestsUp = testUp.filter(test => test.test_source !== "PersonalityTest");

  const filteredTestUpDetails = allTests.filter(test => 
    test.test_source === "PersonalityTest" && 
    test.Id === selectedOption.value
  );

  const filteredTest = allTests.find(test => (
    test.Id === selectedOption.value &&
    test.test_source === "PersonalityTest" &&
    test.name === selectedOption.label
  ));

  if (filteredTest) {
    const testSelect = [{
      value: filteredTest.Id,
      label: filteredTest.name,
      type: "PersonalityTest",
      test_source: filteredTest.test_source
    }];

    const combinedTests = [...filteredTestsUp, ...testSelect];

    const uniqueTests = new Set(combinedTests.map(JSON.stringify));
    const uniqueTestsArray = Array.from(uniqueTests).map(JSON.parse);

    setJobProfileData(prevData => ({
      ...prevData,
      testsUpdate: uniqueTestsArray,
      personality: selectedOption.value
    }));
  }

  setTestsPersoDetails(filteredTestUpDetails);
};


//assignment

  const [allAssignments, setAllAssignments] = useState([]);
  useEffect(()=>{
    const getAllAssignments = async ()=>{
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;

        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/getAll/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setAllAssignments(response.data.assignments);

      } catch (error) {
        console.error(error);
      }
    }

    getAllAssignments();
    

  },[]);

  useEffect(()=>{
    const getAssignment = async ()=>{
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/findAssignmentByjobprofile/${id}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        if(allAssignments.length > 0){
          console.log("response", response.data.assignment_id, "filter",allAssignments.filter(assignment => assignment.id === response.data.assignment_id)[0]);
          setJobProfileData({
          ...jobProfileData,
          assignment: allAssignments.map(test => ({ value: test.id, label: test.name })).filter(assignment => assignment.value === response.data.assignment_id)[0],
        });}

      } catch (error) {
        console.error(error);
      }
    }

    getAssignment();
  },[allAssignments])


  const handleAssignment = (selectedOptions)=>{
    setJobProfileData({
      ...jobProfileData,
      assignment: selectedOptions,
    });
  }

  
  return (
    <>
      <h1>{lang.menu.UpdateJobProfile} </h1>
      <br />
      <div className="max-widht">
        <form onSubmit={handleUpdateProfile}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h3 className="left-align-container ">
                    {lang.menu.jobTitle}
                  </h3>

                  <div className="input-group ">
                    <input
                      value={jobProfileData.title}
                      required
                      type="text"
                      className={`form-control `}
                      placeholder=" Title"
                      maxLength={128} 
                      onChange={(e) =>
                        setJobProfileData({
                          ...jobProfileData,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <br />
                  <h3 className="left-align-container smallscreen">
                    {lang.menu.description}
                  </h3>
                  <div>
                    <textarea
                      value={jobProfileData.description}
                      required
                      style={{ height: height }}
                      maxLength={1000} 
                      onChange={(e) =>
                        setJobProfileData({
                          ...jobProfileData,
                          description: e.target.value,
                        })
                      }
                      type="text"
                      className={`form-control`}
                      placeholder=" Job Description"
                    />
                  </div>
                  <br />

                  <div className="select-container">
                    <div className="select-wrapper-left">
                      <h4 className="smallscreen">
                        {lang.menu.RequiredQualifications}
                      </h4>
                      <Select
                        className={`select-multi`}
                        required
                        isMulti
                        options={data.SpecialismOptions}
                        styles={selectStyles}
                        value={jobProfileData.requiredQualifications.map(
                          (item) => ({
                            value: item.skill, 
                            label: `${item.skill}`,
                          })
                        )}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map((option) => {
                            const existingSkill = jobProfileData.requiredQualifications.find(
                              (item) => item.skill === option.value
                            );
                        
                            return {
                              skill: option.value,
                              count: existingSkill ? existingSkill.count : 1,
                            };
                          });
                        
                          setJobProfileData({
                            ...jobProfileData,
                            requiredQualifications: selectedValues,
                          });
                        }}
                        
                      />
                    </div>
                   
                  </div>
                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-right">
                      <h4>{lang.menu.language}</h4>
                      <Select
                        isMulti
                        className={`form-control`}
                        required
                        options={data.Languages}
                        styles={selectStyles}
                        value={
                          jobProfileData.languages &&
                          Array.isArray(jobProfileData.languages) // Check if it's an array
                            ? jobProfileData.languages.map((item) => ({
                                value: item,
                                label: item,
                              }))
                            : JSON.parse(jobProfileData.languages).map(
                                (item) => ({
                                  value: item,
                                  label: item,
                                })
                              )
                        }
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.value
                          );

                          setJobProfileData({
                            ...jobProfileData,
                            languages: selectedValues,
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/**   <div className="select-container">
                <div className="select-wrapper-right">
                  <h4>{lang.menu.TestsTypes}</h4>
                  <Select
  required
  isMulti
  className={`form-control`}
  options={data.TestsTypes}
  styles={selectStyles}
  value={
    jobProfileData.Tests &&
    typeof jobProfileData.Tests === "string"
      ? JSON.parse(jobProfileData.Tests).map((item) => ({
          value: item,
          label: item,
        }))
      : jobProfileData.Tests.map((item) => ({
          value: item,
          label: item,
        }))
  }
  onChange={(selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    setJobProfileData({
      ...jobProfileData,
      Tests:selectedValues
    });
  }}
/>

                </div>
              </div>*/}
                  <br />
                
                  <Row>

                  <Col>
                    <label><strong>{lang.menu.Assignment}:</strong> </label>
                    <br/>
                    <Select
    options={allAssignments.filter(assignment => assignment.id !== jobProfileData.assignment.id).map(test => ({ value: test.id, label: test.name }))}
    
    value={[jobProfileData.assignment]}
    onChange={
      handleAssignment
    }
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  />
                <br/>
                    </Col>

                <Col className="form-group">
                    <label><strong>{lang.menu.testTechnique}:</strong> </label>
                    <br/>
                    <Select
  options={allTests.filter(test => test.test_source === "TechnicalTest").map(test => ({ value: test.Id, label: test.name }))}
  value={testsTechDetails.map(test => ({ value: test.Id, label: test.name }))}
    onChange={handleTechChange}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  isMulti
/>
 <br/>
                    
                    </Col>
                    <Col>
                    <label><strong>{lang.menu.testPsychoTechnique}:</strong> </label>
                    <br/>
                    <Select
  options={allTests.filter(test => test.test_source === "Psychoest").map(test => ({ value: test.Id, label: test.name }))}
  onChange={handleDropdownSelect12}
  value={testsPsychoDetails.map(test => ({ value: test.Id, label: test.name }))}
  styles={{
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
      lineHeight: "40px",
      borderRadius: "0",
    }),
  }}
/>
                <br/>
                    </Col>
                    <Col>
                    <label><strong>{lang.menu.perTest}:</strong> </label>
                    <br/>
                    <Select
    options={allTests.filter(test => test.test_source === "PersonalityTest").map(test => ({ value: test.Id, label: test.name }))}
    
    onChange={
      handleDropdownSelect22
    }
    value={testsPersoDetails.map(test => ({ value: test.Id, label: test.name }))}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  /> <br/>
                    </Col>
                    </Row>
                </div>

                <div className="card-footer border-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      className="btn btn-primary btn sweet-confirm"
                    >
                      {lang.menu.PostNow}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default UpdateJobProfile;
